import React, { useState, useEffect } from 'react'
import { IoChevronBackCircleOutline } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';
import DriversDataService from '../../../../../../context/drivers.services'
import { AiFillEdit } from 'react-icons/ai';
import { formatDateComplete } from '../../../../../../utils/DateToString';

export default function DriverDetailPages() {

  const { id } = useParams();
  const navigate = useNavigate();
  const [driver, setDriver] = useState({});

  const getDriver = async () => {
      try {
        const docSnapDriver = await DriversDataService.getDriver(id)
        setDriver(docSnapDriver.data());
        console.log(docSnapDriver.data())
        //const docSnapCustomerAdress = await CommandeDataService.getUserAdress(docSnap.data().idClient, docSnap.data().adresse)
      //   setCustomer({
      //     ...docSnapCustomer.data(),
      //     informationCompl: docSnapCustomerAdress.data().informationCompl
      // });
      } catch (err) {
        console.log(err.message)
      }
    };


    useEffect(() => {
        getDriver();
        console.log(driver)
    }, []);
return (
  <div className='view_page'>
    <IoChevronBackCircleOutline size={40} className='my-5 mx-5' color='#008080' style={{cursor: 'pointer'}} onClick={() => navigate(-1)}/>
    {driver ? <>
      <div className="container">
      <div className="d-flex justify-content-between mx-md-5 px-md-5">
        <p style={{letterSpacing: '2px', fontWeight: '600', fontSize: '1.8rem'}}>Details de chauffeur</p>
        <p className='btn' style={{letterSpacing: '2px', fontWeight: '600', fontSize: '1.6rem', background: '#f1f1f1', color: '#008080'}}><AiFillEdit className='me-3 mb-2' />Modifier</p>
      </div>
      <div className="container my-5 py-2 mx-md-5 px-md-5">

        <div className="row">
          <div className="col-md-3" style={{borderRight: '1px solid #C0C0C0'}}>
            <p style={{letterSpacing: '2px', fontWeight: '600'}}>Chauffeur</p>
          </div>
          <div className="col-md-9">
            <p className='ps-3'>{driver.firstName} {driver.lastName}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3" style={{borderRight: '1px solid #C0C0C0'}}>
            <p style={{letterSpacing: '2px', fontWeight: '600'}}>Date d'inscription</p>
          </div>
          <div className="col-md-9">
            <p className='ps-3'>{formatDateComplete(driver.created_at?.toDate().toISOString())}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3" style={{borderRight: '1px solid #C0C0C0'}}>
            <p style={{letterSpacing: '2px', fontWeight: '600'}}>Note</p>
          </div>
          <div className="col-md-9">
            <p className='ps-3'>{driver.note} / 5</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3" style={{borderRight: '1px solid #C0C0C0'}}>
            <p style={{letterSpacing: '2px', fontWeight: '600'}}>Level</p>
          </div>
          <div className="col-md-9">
            <p className='ps-3'>{driver.level}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3" style={{borderRight: '1px solid #C0C0C0'}}>
            <p style={{letterSpacing: '2px', fontWeight: '600'}}>Status</p>
          </div>
          <div className="col-md-9">
            <p className='ps-3'>{driver.status === 1 ? 'Activé' : driver.status === 2 ? 'suspendu' : 'archivé'}</p>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-md-3" style={{borderRight: '1px solid #C0C0C0'}}>
            <p style={{letterSpacing: '2px', fontWeight: '600'}}>Code de parrainage</p>
          </div>
          <div className="col-md-9">
            <p className='ps-3'>{customer.codeParrainage}</p>
          </div>
        </div> */}



        <div className="row">
          <div className="col-md-3" style={{borderRight: '1px solid #C0C0C0'}}>
            <p style={{letterSpacing: '2px', fontWeight: '600'}}>Email</p>
          </div>
          <div className="col-md-9">
            <p className='ps-3'>{driver.email === null || !driver.email ? 'Non renseigné' : driver.email}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3" style={{borderRight: '1px solid #C0C0C0'}}>
            <p style={{letterSpacing: '2px', fontWeight: '600'}}>N° de téléphone</p>
          </div>
          <div className="col-md-9">
            <p className='ps-3'>{driver.telNumber}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3" style={{borderRight: '1px solid #C0C0C0'}}>
            <p style={{letterSpacing: '2px', fontWeight: '600'}}>Location</p>
          </div>
          <div className="col-md-9">
            <p className='ps-3'>{driver.location?.[0]?.city} [{driver.location?.[0]?.country}]</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3" style={{borderRight: '1px solid #C0C0C0'}}>
            <p style={{letterSpacing: '2px', fontWeight: '600'}}>Type de contrat</p>
          </div>
          <div className="col-md-9">
            <p className='ps-3'>{driver.typeOfContrat}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3" style={{borderRight: '1px solid #C0C0C0'}}>
            <p style={{letterSpacing: '2px', fontWeight: '600'}}>Siret</p>
          </div>
          <div className="col-md-9">
            <p className='ps-3'>{driver.siret}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3" style={{borderRight: '1px solid #C0C0C0'}}>
            <p style={{letterSpacing: '2px', fontWeight: '600'}}>Taux horaire</p>
          </div>
          <div className="col-md-9">
            <p className='ps-3'>{driver.honoraire?.toFixed(2)} €</p>
          </div>
        </div>



        {/* <div className="row">
          <div className="col-md-3" style={{borderRight: '1px solid #C0C0C0'}}>
            <p style={{letterSpacing: '2px', fontWeight: '600'}}>Adresse actuelle</p>
          </div>
          <div className="col-md-9">
            <p className='ps-3'>{customer.adresse === null || !customer.adresse ? 'Non renseigné' : customer.adresse}</p>
          </div>
        </div> */}

        <div className="row">
          <div className="col-md-3" style={{borderRight: '1px solid #C0C0C0'}}>
            <p style={{letterSpacing: '2px', fontWeight: '600'}}>Total de commande effectuée</p>
          </div>
          <div className="col-md-9">
            <p className='ps-3'>{driver.nbOrders}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3" style={{borderRight: '1px solid #C0C0C0'}}>
            <p style={{letterSpacing: '2px', fontWeight: '600'}}>Total de tournée effectuée</p>
          </div>
          <div className="col-md-9">
            <p className='ps-3'>{driver.nbTours}</p>
          </div>
        </div>



        {/* <div className="row">
          <div className="col-md-3" style={{borderRight: '1px solid #C0C0C0'}}>
            <p style={{letterSpacing: '2px', fontWeight: '600'}}>Carte actuelle</p>
          </div>
          <div className="col-md-9">
            <p className='ps-3'>{customer.card === null || !customer.card ? 'Non renseigné' : customer.card}</p>
          </div>
        </div> */}
</div>
          {/* <div className="p-3 mb-2 bg-info text-white">
              <p>Date creation commande : {formatDateComplete(order.date_creation?.toDate().toISOString())}</p>
          </div>
          <div className="p-3 mb-2 bg-secondary text-white">
              <div className="row">
              <div className="col-sm">
                  <p>Etat : {order.etat}</p>
              </div>
              <div className="col-sm">
                  <p>Code promo : {order.codePromo}</p>
              </div>
              <div className="col-sm">
                  <p>Tel : {order.tel}</p>
              </div>
          </div>
          {customer ? <>
              <div className="row">
              <div className="col-sm">
                  <p>Nom : {customer.nom} {customer.prenom}</p>
              </div>
              <div className="col-sm">
                  <p>mail : {customer.mail}</p>
              </div>
              <div className="col-sm">
                  <p>Complément d'adresse : {customer.informationCompl}</p>
              </div>
          </div>
          </> :  <p>ERROR</p>}

          <p>Adresse : {order.adresse}</p>
          <p>Collecte : {formatDate(order.dateCollecte?.toDate().toISOString())} | {order.creneauCollecte}</p>
          <p>Livraison : {formatDate(order.dateLivraison?.toDate().toISOString())} | {order.creneauLivraison}</p>
          <p>Commentaire : {order.commentaire}</p>
          <Form onSubmit={handleSubmit} className="mb-3">
        <Form.Group className="mb-3" controlId="formBookTitle">
          <InputGroup>
            <InputGroup.Text id="formBookTitle">Etape</InputGroup.Text>
            <Form.Control
              type="number"
              placeholder="Etape"
              value={steps}
              onChange={(e) => setSteps(e.target.value)}
            />
          </InputGroup>
        </Form.Group>
        <div className="d-grid gap-2">
          <Button variant="primary" type="Submit">
            Modifier
          </Button>
        </div>
          </Form>
          <Form onSubmit={handleSubmitEtat} className="mb-3">
            <Form.Group className="mb-3" controlId="formEtat">
              <InputGroup>
                <InputGroup.Text id="formEtat">Etat</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Etat"
                  value={etat}
                  onChange={(e) => setEtat(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
            <div className="d-grid gap-2">
              <Button variant="primary" type="Submit">
                Modifier
              </Button>
            </div>
          </Form>
          <div className="row">
              <div className="col-sm">
              <p>N° Commande : {order.idFacture}</p>
              </div>
              <div className="col-sm">
                  <p>Service : {order.service}</p>
              </div>
              <div className="col-sm">
                  <p>Etape : {order.steps}</p>
              </div>
          </div>
          </div> */}
      </div>
    </> : <>ERROR</>}

  </div>
)
}
