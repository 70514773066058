import React from 'react'
import { useState } from 'react'
import { Button, Col, Dropdown, DropdownButton, Form, Modal, Row } from 'react-bootstrap'
import DatePicker from 'react-multi-date-picker';
import opacity from "react-element-popper/animations/opacity";
import "react-multi-date-picker/styles/colors/teal.css";
import transition from "react-element-popper/animations/transition";
import './CreateTours.css'
import filterOrdersForTodayB2CWithDate from '../../../../../utils/filterOrdersForTodayB2CWithDate';
import { FormGroup, FormControlLabel, Switch, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { filterOrdersForTodayPU } from '../../../../../utils/filterOrdersForTodayPU';
import { filterOrdersForTodayDo } from '../../../../../utils/filterOrdersForTodayDo';
import filterOrdersForTodayB2CWithDateShiftMatin from '../../../../../utils/filterOrdersForTodayB2CWithDateShiftMatin';
import filterOrdersForTodayB2CWithDateShiftSoir from '../../../../../utils/filterOrdersForTodayB2CWithDateShiftSoir';
import { filterOrdersForTodayB2BWithDate } from '../../../../../utils/filterOrdersForTodayB2BWithDate';
import { useSnackbar } from 'notistack';
import { formatDateDayMonth } from '../../../../../utils/DateToString'
import { BsChevronDown } from 'react-icons/bs';
import { useEffect } from 'react';

export default function CreateTours(props) {
    const [isB2C, setIsB2C] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    const [showInfoB2C, setShowInfoB2C] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    const handleDateChange = (date) => {
        setStartDate(date);
        setChecked(false);
      };

    const totalCommandeB2C = filterOrdersForTodayB2CWithDate(props.ordersB2C, startDate);
    const totalCommandeB2B = filterOrdersForTodayB2BWithDate(props.ordersB2B, startDate);


    const [checked, setChecked] = useState(false);
    
    const handleChange = (event) => {
      setChecked(event.target.checked);
    };
    const MaterialUISwitch = styled(Switch)(({ theme }) => ({
      width: 62,
      height: 34,
      padding: 7,
      '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
          color: '#fff',
          transform: 'translateX(22px)',
          '& .MuiSwitch-thumb:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
              '#fff',
            )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
          },
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#008080' : '#008080',
          },
        },
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#008080',
        width: 32,
        height: 32,
        '&:before': {
          content: "''",
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff',
          )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
      },
      '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
      },
    }));

    const totalCommandeB2CShiftMatin = filterOrdersForTodayB2CWithDateShiftMatin(totalCommandeB2C, startDate);
    const totalCommandeB2CShiftSoir = filterOrdersForTodayB2CWithDateShiftSoir(totalCommandeB2C, startDate);
    
    const totalCommandeB2CPUMatin = filterOrdersForTodayPU(totalCommandeB2CShiftMatin, startDate);
    const totalCommandeB2CDOMatin = filterOrdersForTodayDo(totalCommandeB2CShiftMatin, startDate);
    const totalCommandeB2CPUSoir = filterOrdersForTodayPU(totalCommandeB2CShiftSoir, startDate);
    const totalCommandeB2CDOSoir = filterOrdersForTodayDo(totalCommandeB2CShiftSoir, startDate);

    const handleClosePopup = () => {
      props.handleClose();
      setIsB2C(true);
      setChecked(false);
      setShowInfoB2C(true);
      setStartDate(new Date());
    }

    const handleConfirmedTours = () => {
      if(newTours.shifts.length > 0) {

        props.handleClose();
        props.handleShowConfirmedTours();
        setIsB2C(true);
        setChecked(false);
        setShowInfoB2C(true);
        setStartDate(new Date());
        console.log(newTours)
        props.setTours(newTours);
      }else{
        enqueueSnackbar(`Le ${formatDateDayMonth(startDate)} ne contient aucune commande`, {
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          autoHideDuration: 3000,
          style: {fontSize: '1.6rem'}
         
        });
      }


    }

    const chauffeurAPrevoirSoirB2C = Math.ceil(
      ((totalCommandeB2CShiftSoir.length / 15 < 1) && (totalCommandeB2CShiftSoir.length !== 0) ? 1 : totalCommandeB2CShiftSoir.length / 15)
    );
    const chauffeurAPrevoirMatinB2C = Math.ceil(
      ((totalCommandeB2CShiftMatin.length / 15 < 1) && (totalCommandeB2CShiftMatin.length !== 0) ? 1 : totalCommandeB2CShiftMatin.length / 15)
    );

    const getIdTour = (chunkId) => {
      let alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"; 
      let letter = alphabet[chunkId % 26];
      let numberPart = Math.floor(chunkId / 26);
      return letter + (numberPart > 0 ? numberPart.toString() : "");
  }
    const chunkArray = (array, chunkSize) => {
      let results = [];
      const dateSpec = new Date(startDate);
      dateSpec.setHours(0, 0, 0, 0);
      for (let i = 0; i < array.length; i += chunkSize) {
        const currentOrder = array.slice(i, i + chunkSize);

        const order = currentOrder.map(item => {
          const livraisonDate = item.dateLivraison?.toDate();
          livraisonDate.setHours(0, 0, 0, 0);
          return ({
          id_order: item.idCommande,
          depart: null,
          arrivee: null,
          recap_driver: [],
          recollecte: {},
          do: livraisonDate.getTime() === dateSpec.getTime(),
          signature: null

        })}); 
          results.push({ 
            id_driver: null,
            isView: false,
            id_tour: getIdTour(i),
            orders: order 
          });
      }
      return results;
  }

  const [selectedHub, setSelectedHub] = useState('');
  // Initialisez l'état avec la première valeur de hubs une fois qu'ils sont chargés
  useEffect(() => {
    if (props.hubs && props.hubs.length > 0) {
        setSelectedHub(props.hubs[0].id_hub);
    }
}, [props.hubs]);
const handleSelectChange = (event) => {
  setSelectedHub(event.target.value);
};
    const newTours = {
      type: isB2C ? 'B2C' : 'B2B',
      evening: checked,
      tour_date: new Date(startDate),
      depart_point: {
        hub: selectedHub,
        depart: new Date(startDate),
        go: false
      },
      arrivee_point: {
        hub: selectedHub,
        arrivee: new Date(startDate),
        go: false
      },
      // nb_drivers: isB2C ? checked ? chauffeurAPrevoirSoirB2C : chauffeurAPrevoirMatinB2C : 0,
      nb_drivers: isB2C ? checked ? chunkArray(totalCommandeB2CShiftSoir, 2).length : chunkArray(totalCommandeB2CShiftMatin, 2).length : 0,
      shifts: isB2C ? checked ? chunkArray(totalCommandeB2CShiftSoir, 2) : chunkArray(totalCommandeB2CShiftMatin, 2) : [],
      //isView: false, Pr mettre sur l'application chauffeur
    }


  return (
    <>
        <Modal show={props.show} onHide={handleClosePopup}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontWeight: '600', fontSize: '1.6rem', letterSpacing: '1.2px', color: '#008080'}}>Plannification des commandes</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{fontSize: '1.6rem'}}>
        <Form.Group controlId="selectedTypeContrat" className="d-flex align-items-center me-5 ps-1">
    <Form.Label className="mb-0 me-3" style={{fontWeight: 'bold' }}>Dépôt</Form.Label>
    <div className="flex-grow-1 position-relative">
            <Form.Control
                as="select"
                className="w-80"
                style={{ color: '#000', fontSize: '1.4rem'}}
                name="typeContrat"
                value={selectedHub}
                onChange={handleSelectChange}
            >
                {props.hubs?.map((hub) => (
                    <option key={hub.id_hub} value={hub.id_hub}>{hub.name}</option>
                ))}
            </Form.Control>
            <BsChevronDown className="dropdown-icon" />
        </div>
</Form.Group>
<p className='my-3 p-2' style={{fontWeight: 'bold'}}>Jour de plannification      <DatePicker
        animations={[opacity(), transition({ from: 35, duration: 800 })]}
        className="teal"
        value={startDate} // Use the new date for visualization
        onChange={handleDateChange} // Update the new date immediately
        editable={false}
        format="DD/MM/YYYY"
        minDate={new Date()}
        inputClass='datepicker ms-2 px-1'
      /></p>
        <div className="d-flex justify-content-center gap-3 my-5">
            <button onClick= {() => {setIsB2C(true); setShowInfoB2C(true)}} className='btn px-5 py-4' style={isB2C ? {background: '#008080', color: '#FFF', fontWeight: 'bold', borderRadius: '10px', fontSize: '1.8rem', letterSpacing: '3px', border: 'none', transition: 'all 0.5s ease-in-out' } : {background: 'transparent', color: '#008080', fontWeight: 'bold', borderRadius: '10px', fontSize: '1.8rem', letterSpacing: '3px', border: '1px solid #008080', transition: 'all 0.5s ease-in-out' }}>B2C</button>
    <button onClick= {() => {setIsB2C(false); setShowInfoB2C(false); setChecked(false)}} className='btn px-5 py-4' style={isB2C ? {background: 'transparent', color: '#008080', fontWeight: 'bold', borderRadius: '10px', fontSize: '1.8rem', letterSpacing: '3px', border: '1px solid #008080', transition: 'all 0.5s ease-in-out' } : {background: '#008080', color: '#FFF', fontWeight: 'bold', borderRadius: '10px', fontSize: '1.8rem', letterSpacing: '3px', border: 'none', transition: 'all 0.5s ease-in-out' }}>B2B</button>
</div>


      {
        showInfoB2C ?
        <FormGroup style={{display: 'inline'}}>
<FormControlLabel
        control={<MaterialUISwitch sx={{ m: 1 }}  defaultChecked={checked} 
          onChange={handleChange}/>}
          label={ <Typography variant="body1" style={{ fontSize: '1.4rem', fontWeight: '600' }}>
          {checked ? `Soir (${totalCommandeB2CShiftSoir.length}) PU ${totalCommandeB2CPUSoir.length} / DO ${totalCommandeB2CDOSoir.length}` : `Matin (${totalCommandeB2CShiftMatin.length}) PU ${totalCommandeB2CPUMatin.length} / DO ${totalCommandeB2CDOMatin.length}`}
        </Typography>}
      />
    </FormGroup>
: 
<p className='my-2 p-2' style={{fontWeight: 'bold'}}>Total de passage {totalCommandeB2C.length}</p>
      }

      <p className='my-2 p-2' style={{fontWeight: 'bold'}}>Chauffeur à prévoir : {isB2C ? checked ? chunkArray(totalCommandeB2CShiftSoir, 15).length : chunkArray(totalCommandeB2CShiftMatin, 15).length : 0}</p>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClosePopup} className='btn btn-outline-danger me-3' style={{fontSize: '1.4rem'}}>
            Annuler
          </button>
          <button  className='btn me-3 px-4 py-2' style={{fontSize: '1.4rem', background: '#008080', fontWeight: '600', letterSpacing: '1.2px', border: 'none', borderRadius: '999px', color: '#fff'}} onClick={handleConfirmedTours}>
            Créer les tournées
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
