import React, { useEffect, useState } from 'react'
import { Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap'
import DatePicker from 'react-multi-date-picker';
import "react-datepicker/dist/react-datepicker.css";
import InputIcon from 'react-multi-date-picker/components/input_icon';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import PricesDataService from "../../../../../../context/Prices.services";
import PhoneInput from 'react-phone-input-2';
import { useSnackbar } from 'notistack';
import DriverPasswordGenerator from '../../../Drivers/Compoenents/AddDriver/DriverPasswordGenerator';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { AiOutlineLoading } from 'react-icons/ai';

const slots = ['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00'];
const weekDays = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

const functions = getFunctions();
const addCorporate = httpsCallable(functions, 'addCorporate');
const envoiMailCreationCorporate = httpsCallable(functions, 'envoiMailCreationCorporate');
const createCustomerB2BStripe = httpsCallable(functions, 'createCustomerB2BStripe');

export default function AddNewCorporate({ show, onHide, setPasswordProProvisoire, onShowConfirmedNewCorporate }) {

    const { enqueueSnackbar } = useSnackbar();
    const [isAddingCorporate, setIsAddingCorporate] = useState(false);
    const [prices, setPrices] = useState([]);
    const [dates, setDates] = useState([]);
    const [newCorporate, setNewCorporate] = useState({
        nameCompany: '',
        emailCompany: '',
        siretCompany: '',
        telCompany: '',
        adressInvoiceCompany: '',
        emailInvoiceCompany: '',
        paiement: '',
        price: '',
        adressLivraisonCompany: '',
        slot_end: '',
        slot_start: '',
        homeDelivery : false,
        services: [],
        underAccount: false,
        tokenCard: '',
        status: 1,
        nameContact: '',
        emailContact: '',
        telContact: ''
    });

    const formatDate = (dateString) => {
        const options = { weekday: 'long' }
        return new Date(dateString).toLocaleDateString('fr-FR', options)
    }
    const handleChangeService = (e) => {
        const { value, checked } = e.target;
    
        setNewCorporate(prevState => {
            // Copie de l'ancien état des services
            const updatedServices = [...prevState.services];
    
            if (checked) {
                // Ajoute la nouvelle valeur au tableau si elle n'est pas déjà présente
                if (!updatedServices.includes(value)) {
                    updatedServices.push(value);
                }
            } else {
                // Supprime la valeur du tableau
                const index = updatedServices.indexOf(value);
                if (index > -1) {
                    updatedServices.splice(index, 1);
                }
            }
    
            return {
                ...prevState,
                services: updatedServices
            };
        });
    };
    const handleChange = (e, name) => {
        const chose = e.target.value;
        setNewCorporate({...newCorporate, [name] : chose});
        if(name === 'homeDelivery'){
            // Convertir la chaîne de caractères en booléen
            const isHomeDelivery = chose === 'true';
            setNewCorporate({...newCorporate, homeDelivery: isHomeDelivery});
        }
    };
    const getPrices = async() => {
        const data = await PricesDataService.getAllPrices();
        setPrices(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }
    const handleClose = () => {
        setNewCorporate({
            nameCompany: '',
            emailCompany: '',
            siretCompany: '',
            telCompany: '',
            adressInvoiceCompany: '',
            emailInvoiceCompany: '',
            paiement: '',
            price: '',
            adressLivraisonCompany: '',
            slot_end: '',
            slot_start: '',
            homeDelivery : false,
            services: [],
            underAccount: false,
            tokenCard: '',
            status: 1,
            nameContact: '',
            emailContact: '',
            telContact: ''
        })
        setDates([]);
        onHide();
    };
    const handleForm = async (e) => {
      e.preventDefault();

      if(newCorporate.nameCompany === ''){
          enqueueSnackbar(`Veuillez renseigner le nom de l'entreprise.`, {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              autoHideDuration: 3000,
              style: {fontSize: '1.6rem'}
             
            });
          return ;
      }
      if(!/^[\w\.-]+@[\w\.-]+\.\w+$/.test(newCorporate.emailCompany)) {

          enqueueSnackbar(`Le mail de l'entreprise est incorrect.`, {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              autoHideDuration: 3000,
              style: {fontSize: '1.6rem'}
             
            });
          return ;
      }
      if(newCorporate.siretCompany === ''){
          enqueueSnackbar(`Le siret est incorrect.`, {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              autoHideDuration: 3000,
              style: {fontSize: '1.6rem'}
             
            });
          return ;
      }
      if(newCorporate.telCompany.length !== 12){
          enqueueSnackbar(`Le numéro de l'entreprise est incorrect.`, {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              autoHideDuration: 3000,
              style: {fontSize: '1.6rem'}
             
            });
          return ;
      }
      if(newCorporate.adressInvoiceCompany === ''){
          enqueueSnackbar(`L'adresse de facturation est incorrect.`, {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              autoHideDuration: 3000,
              style: {fontSize: '1.6rem'}
             
            });
          return ;
      }
      if(!/^[\w\.-]+@[\w\.-]+\.\w+$/.test(newCorporate.emailInvoiceCompany)) {

          enqueueSnackbar(`Le mail de facturation est incorrect.`, {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              autoHideDuration: 3000,
              style: {fontSize: '1.6rem'}
             
            });
          return ;
      }
      if(newCorporate.paiement === ''){
          enqueueSnackbar(`Veuillez séléctionner un mode de paiment.`, {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              autoHideDuration: 3000,
              style: {fontSize: '1.6rem'}
             
            });
          return ;
      }
      if(newCorporate.price === ''){
          enqueueSnackbar(`Veuillez choisir un tarif.`, {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              autoHideDuration: 3000,
              style: {fontSize: '1.6rem'}
             
            });
          return ;
      }
      if(newCorporate.adressLivraisonCompany === ''){
          enqueueSnackbar(`L'adresse de livraison est incorrect est incorrect.`, {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              autoHideDuration: 3000,
              style: {fontSize: '1.6rem'}
             
            });
          return ;
      }
      if(newCorporate.slot_start === ''){
          enqueueSnackbar(`Veuillez choisir un créneau de début.`, {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              autoHideDuration: 3000,
              style: {fontSize: '1.6rem'}
             
            });
          return ;
      }
      if(newCorporate.slot_end === ''){
          enqueueSnackbar(`Veuillez choisir un créneau de fin.`, {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              autoHideDuration: 3000,
              style: {fontSize: '1.6rem'}
             
            });
          return ;
      }
      if(newCorporate.services.length < 1){
          enqueueSnackbar(`Veuillez selectionner au moins un service.`, {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              autoHideDuration: 3000,
              style: {fontSize: '1.6rem'}
             
            });
          return ;
      }
      if(dates.length < 2){
          enqueueSnackbar(`Veuillez séléctionner au moins deux dates.`, {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              autoHideDuration: 3000,
              style: {fontSize: '1.6rem'}
             
            });
          return ;
      }
      if(newCorporate.nameContact === ''){
          enqueueSnackbar(`Le nom de contact est incorrect.`, {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              autoHideDuration: 3000,
              style: {fontSize: '1.6rem'}
             
            });
          return ;
      }
      if(!/^[\w\.-]+@[\w\.-]+\.\w+$/.test(newCorporate.emailContact)) {

          enqueueSnackbar(`Le mail de contact est incorrect.`, {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              autoHideDuration: 3000,
              style: {fontSize: '1.6rem'}
             
            });
          return ;
      }
      if(newCorporate.telContact.length !== 12){
          enqueueSnackbar(`Le numéro de contact est incorrect.`, {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              autoHideDuration: 3000,
              style: {fontSize: '1.6rem'}
             
            });
          return ;
      }

      const password = DriverPasswordGenerator();
      setPasswordProProvisoire(password);

      try{
          setIsAddingCorporate(true); // Commence le chargement
          addCorporate({
              email: newCorporate.emailCompany,
              password: password,
              corporate : newCorporate,
              passages : dates.map((r) => new Date(r)),
       
          })
          .then((result) => {
              createCustomerB2BStripe({
                id: result.data.uid,
                email: newCorporate.emailInvoiceCompany,
                description: newCorporate.nameCompany,
                name: newCorporate.nameCompany,
                address: newCorporate.adressInvoiceCompany
              });
              envoiMailCreationCorporate({
                nameCompany: newCorporate.nameCompany,
                email: newCorporate.emailCompany,
                password: password
              });
              setIsAddingCorporate(false); // Arrête le chargement une fois que la fonction est terminée
              enqueueSnackbar(`Le compte pour ${newCorporate.nameCompany} a bien été créer`, {
                variant: 'success',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}
               
              });
              handleClose();
              onShowConfirmedNewCorporate();
            })
            .catch((error) => {
              setIsAddingCorporate(false); // Arrête le chargement une fois que la fonction est terminée
              enqueueSnackbar(`Erreur lors de la création de l'utilisateur`, {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}
               
              });
            });
      }catch (e){
          setIsAddingCorporate(false); // Arrête le chargement une fois que la fonction est terminée
          enqueueSnackbar(`Un problème est survenu....`, {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              autoHideDuration: 3000,
              style: {fontSize: '1.6rem'}
             
            });
      }
    };

    useEffect(() => {
        getPrices();
    }, []);
    useEffect(() => {
        if (newCorporate.slot_start && newCorporate.slot_end < newCorporate.slot_start) {
            setNewCorporate({ ...newCorporate, slot_end: '' });
        }
    }, [newCorporate.slot_start]); // Se déclenche uniquement lorsque ChoseSlots.start change

  return (
            <Modal show={show} fullscreen={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize: '1.6rem', fontWeight: '600', letterSpacing: '1.2px'}} className='ms-3 my-2'>Ajouter une nouvelle entreprise</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflowY: 'scroll'}}>
            <p className="m-0 p-0 mt-5 ms-4 mb-3" style={{letterSpacing: '2px', fontWeight: '600'}}>Entreprise</p>
              <Row className="mb-3 ms-4">
                <Col>
                  <Form.Group role="form">
                    <Form.Label style={{fontWeight: '500'}} >Nom de la société </Form.Label>
                    <Form.Control type="text" placeholder="Nom de la socité" required style={{fontSize: '1.4rem' }} onChange={(e) => handleChange(e, 'nameCompany')} name='nameCompany' value={newCorporate.nameCompany}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group role="form">
                    <Form.Label>Email de l'entreprise</Form.Label>
                    <Form.Control type="email" placeholder="Entrer adresse Email de l'entreprise" required onChange={(e) => handleChange(e, 'emailCompany')} name='emailCompany' value={newCorporate.emailCompany} style={{fontSize: '1.4rem' }}/>
                  </Form.Group>
                </Col>
                <Col>
                <Form.Group role="form">
                  <Form.Label>Siret</Form.Label>
                  <Form.Control type="text" placeholder="Siret" required onChange={(e) => handleChange(e, 'siretCompany')} name='siretCompany' value={newCorporate.siretCompany} style={{fontSize: '1.4rem' }}/>
                </Form.Group>
                </Col>
                <Col className='mt-2'>

                    <PhoneInput
                  inputStyle={{ border: '1px solid #008080', padding: '0.6rem 5rem', borderRadius: '5px', marginTop: '1.5rem' }}
                  inputClassName="phone-form-input"
                  name="phone Number"
                  country="fr"
                  value={newCorporate.telCompany}
                  onChange={(value) => setNewCorporate((prevData) => ({ ...prevData, telCompany: `+${value}` }))}
                />

                </Col>
              </Row>

              <p className="m-0 p-0 mt-5 ms-4 mb-3" style={{letterSpacing: '2px', fontWeight: '600'}}>Facturation</p>
              <Row className="mb-3 ms-4">
                <Col>
                  <Form.Group role="form">
                    <Form.Label>Adresse de facturation </Form.Label>
                    <Form.Control type="text" placeholder="Entrer adresse de facturation" required onChange={(e) => handleChange(e, 'adressInvoiceCompany')} name='adressInvoiceCompany' value={newCorporate.adressInvoiceCompany} style={{fontSize: '1.4rem' }}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group role="form">
                    <Form.Label>Email de facturation</Form.Label>
                    <Form.Control type="email" placeholder="Entrer adresse Email de l'entreprise" required onChange={(e) => handleChange(e, 'emailInvoiceCompany')} name='emailInvoiceCompany' value={newCorporate.emailInvoiceCompany} style={{fontSize: '1.4rem' }}/>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3 ms-4">
              <Col md>
                    <FloatingLabel
                        controlId="floatingSelectGrid"
                        label="Mode de paiment"
                        style={{fontSize: '1.4rem'}}
          
                    >
                    <Form.Select aria-label="Mode de paiement" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={(e) => handleChange(e, 'paiement')} >
                    <option value=''>Séléctionner un mode de paiement</option>
                    <option value='Mensuel'>Mensuel</option>
                    <option value='Par commande'>Par commande</option>
          </Form.Select>
        </FloatingLabel>
    </Col>
    <Col md>
                    <FloatingLabel
                        controlId="floatingSelectGrid"
                        label="Tarif"
                        style={{fontSize: '1.4rem'}}
          
                    >
                    <Form.Select aria-label="Tarif" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={(e) => handleChange(e, 'price')} >
                    <option value=''>Séléctionner un tarif</option>
                    {prices.map((price) => (
                    <option key={price.id} value={price.id}>{price.id}</option>
                ))}
          </Form.Select>
        </FloatingLabel>
    </Col>
              </Row>

              <p className="m-0 p-0 mt-5 ms-4 mb-3" style={{letterSpacing: '2px', fontWeight: '600'}}>Services</p>
              <Row className="mb-3 ms-4">
                <Col>
                  <Form.Group role="form">
                    <Form.Label>Adresse de livraison </Form.Label>
                    <Form.Control type="text" placeholder="Entrer adresse de livraison" required onChange={(e) => handleChange(e, 'adressLivraisonCompany')} name='adressLivraisonCompany' value={newCorporate.adressLivraisonCompany} style={{fontSize: '1.4rem' }}/>
                  </Form.Group>
                </Col>
                <Col md>
                    <FloatingLabel
                        controlId="floatingSelectGrid"
                        label="Créneau de début"
                        style={{fontSize: '1.4rem'}}
          
                    >
                    <Form.Select aria-label="Créneau de début" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={(e) => handleChange(e, 'slot_start')} >
                    <option value=''>Séléctionner un créneau de début</option>
                    {slots.map((slot, index) => (
                    <option key={index} value={slot}>{slot}</option>
                ))}
          </Form.Select>
        </FloatingLabel>
                </Col>
                <Col md>
                                <FloatingLabel
                                    controlId="floatingSelectGrid"
                                    label="Créneau de fin"
                                    style={{fontSize: '1.4rem'}}
                      
                                >
                                <Form.Select aria-label="Créneau de fin" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={(e) => handleChange(e, 'slot_end')}>
                                <option value=''>Séléctionner un créneau de fin</option>
                                {slots.map((slot, index) => {
                        // Afficher seulement si le créneau est postérieur au créneau de début sélectionné
                        if (newCorporate.slot_start && slot > newCorporate.slot_start) {
                            return <option key={index} value={slot}>{slot}</option>;
                        }
                    })}

                      </Form.Select>
                    </FloatingLabel>
                </Col>

              </Row>
              <Row className="mb-3 ms-4 mt-4">
                <Col md>
                    <FloatingLabel
                        controlId="floatingSelectGrid"
                        label="Option de livraison à domicile"
                        style={{fontSize: '1.4rem'}}
          
                    >
                    <Form.Select aria-label="Date collecte" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={(e) => handleChange(e, 'homeDelivery')} >
                    <option value={false}>Désactiver</option>
                    <option value={true}>Activer</option>
          </Form.Select>
        </FloatingLabel>
    </Col>

              <Col>
                  <Form.Group >
                    <Form.Check
                      inline
                      label="Pressing & Blanchisserie"
                      name="Pressing & Blanchisserie"
                      value="Pressing & Blanchisserie"
                      type="checkbox"
                      id="inline-checkbox-1"
                      onChange={handleChangeService}
                      />
                    <Form.Check
                      inline
                      label="Linge au kilo"
                      name="Linge au kilo"
                      type="checkbox"
                      id="inline-checkbox-2"
                      value="Linge au kilo"
                      onChange={handleChangeService}
                    />
                    <Form.Check
                      inline
                      label="Retouches"
                      name="Retouches"
                      type="checkbox"
                      id="inline-checkbox-3"
                      value='Retouches'
                      onChange={handleChangeService}
                    />
                    <Form.Check
                      inline
                      label="Cordonnerie"
                      name="Cordonnerie"
                      type="checkbox"
                      value='Cordonnerie'
                      id="inline-checkbox-4"
                      onChange={handleChangeService}
                    />
                  </Form.Group>
                </Col>
                <Col>
                <Form.Check
                  className="mb-3"
                  type="switch"
                  id="custom-switch"
                  label="Contient des sous comptes"
                  value={newCorporate.underAccount}
                  onChange={(e) => setNewCorporate({ ...newCorporate, underAccount: !newCorporate.underAccount})}
                />
              </Col>
              </Row>
              <Row className="mb-3 ms-4 mt-4">
                <Col>
                
                <Row>
                  <Col>
                  <p>Date de passage dans la semaine :</p>
                  </Col>
                  <Col>
                  <DatePicker
                    value={dates}
                    onChange={setDates}
                    format="DD MMMM YYYY"
                    weekDays={weekDays}
                    months={months}
                    sort
                    plugins={[
                      <DatePanel />
                    ]}
                    render={<InputIcon/>}
                  />
                  </Col>
                </Row>
                </Col>
                <Col>
                <Row>
                  <Col>
                  <p>Jours de passage :</p>
                  </Col>
                  <Col>
                  {dates.map((doc, index) => {
                      return (
                        <p className="p-0 m-0 mx-1" style={{display:'inline'}} key={index}>- {formatDate(doc)}</p>
                      )
                    })}
                  </Col>
                </Row>
                </Col>
              </Row>

            <p className="m-0 p-0 mt-5 ms-4 mb-3" style={{letterSpacing: '2px', fontWeight: '600'}}>Personne de contact</p>

            <Row className="mb-3 ms-4 mt-4">
                <Col>
                    <Form.Group role="form">
                        <Form.Label>Nom de contact</Form.Label>
                        <Form.Control type="text" placeholder="Entrer le nom de contact" required onChange={(e) => handleChange(e, 'nameContact')} name='nameContact' value={newCorporate.nameContact} style={{fontSize: '1.4rem' }}/>
                    </Form.Group>
                </Col>
                <Col>
                <Form.Group role="form">
                    <Form.Label>Email de contact</Form.Label>
                    <Form.Control type="email" placeholder="Entrer Email de contact" required onChange={(e) => handleChange(e, 'emailContact')} name='emailContact' value={newCorporate.emailContact} style={{fontSize: '1.4rem' }}/>
                </Form.Group>
                </Col>
                <Col className='mt-2'>

                    <PhoneInput
                  inputStyle={{ border: '1px solid #008080', padding: '0.6rem 5rem', borderRadius: '5px', marginTop: '1.5rem' }}
                  inputClassName="phone-form-input"
                  name="phone Number"
                  country="fr"
                  value={newCorporate.telContact}
                  onChange={(value) => setNewCorporate((prevData) => ({ ...prevData, telContact: `+${value}` }))}
                />

                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
       
        <button className="add-driver-button btn my-3 me-3" onClick={handleForm}>
        {isAddingCorporate ? <><AiOutlineLoading className="loading-icon me-2" /> en cours d'enregistrement...</> : "Enregistrer"}
        </button>
      </Modal.Footer>
      </Modal>
  )
}
