export const filterOrdersForTodayB2BWithDate = (orders, specificDate) => {
    const today = new Date(specificDate);
    today.setHours(0, 0, 0, 0);

    return orders.filter(order => {
        // Vérifier si dateCollecte existe avant de continuer.
        if (!order.date_collecte) {
            return false; // si dateCollecte n'existe pas, on ignore cette commande.
        }

        const collecteDate = order.date_collecte?.toDate();
        collecteDate.setHours(0, 0, 0, 0);

        // Pour la robustesse, vous pouvez également vérifier si 'toDate' retourne une date valide ici.

        const isCollecteDateMatch = collecteDate.getTime() === today.getTime() && order.etat_commande === 1;

        const isLivraisonDateMatch = order.under_user.some((user) => {
            if (!user.date_livraison) {
                return false; // si date_livraison n'existe pas pour cet utilisateur, on l'ignore.
            }

            const dateLivraison = user.date_livraison.toDate();
            dateLivraison.setHours(0, 0, 0, 0);

            return (order.etat_commande === 3 || order.etat_commande === 2) && dateLivraison.getTime() === today.getTime();
        });

        return isCollecteDateMatch || isLivraisonDateMatch;
    });
};
