import React from 'react'
import { useSnackbar } from 'notistack';
import { Modal } from 'react-bootstrap'
import { FaCopy } from 'react-icons/fa';

export default function ModalConfirmedAddingCorporate({show, onHide, passwordProvisoire }) {

    const { enqueueSnackbar } = useSnackbar();

    const copyToClipboard = text => {
        navigator.clipboard.writeText(text)
          .then(() => {
            enqueueSnackbar(`Mot de passe copié avec succès !.`, {
                variant: 'success',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}
               
              });
          })
          .catch(err => {
            enqueueSnackbar(`Erreur lors de la copie du texte : ${err}`, {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}
               
              });
          });
      };
      const handleClick = (e) => {
        const text = e.target.textContent;
        copyToClipboard(text);
      };
  return (
    <Modal
    show={show}
    onHide={onHide}
    backdrop="static"
    keyboard={false}
    centered
  >
    <Modal.Body className='p-4 text-center'>
        <p style={{letterSpacing: '1.2px', fontWeight: '600', lineHeight: '35px', cursor: 'pointer'}}>Veuillez noter le mot de passe provisoire : <span style={{background: '#008080', borderRadius: '5px', display: 'inline-block'}} className='p-2 text-light' onClick={handleClick}><FaCopy /> {passwordProvisoire}</span> dans le fichier "<a href='https://docs.google.com/spreadsheets/d/1PnyC85T2NgySGV9rfl7ti89-29qwfqP6EdKIUtQH7q8/edit?usp=sharing' target="_blank">CONFIDENTIAL_CUSTOMER_B2B</a>" disponible sur <a href='https://cleamyadmin.slack.com' target="_blank">Slack</a>.</p>
        <p style={{letterSpacing: '1.2px', fontWeight: '600', lineHeight: '35px'}}>⚠️Cette étape est importante pour maintenir la confidentialité et le suivi.</p>
     
    </Modal.Body>
    <Modal.Footer>
        <button className="add-driver-button btn my-3 me-3" onClick={onHide}>Confirmer</button>
    </Modal.Footer>
  </Modal>
  )
}
