import React, { useState } from 'react'
import TitleCard from './TitleCard'
import { BsExclamationTriangle } from 'react-icons/bs';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import './DropdownStyle.css'
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import InputIcon from "react-multi-date-picker/components/input_icon";
import DatePicker from "react-multi-date-picker";
import { filterOrdersForTodayB2C } from '../../utils/filterOrdersForTodayB2C';
import { filterOrdersForTodayPU } from '../../utils/filterOrdersForTodayPU';
import { filterOrdersForTodayDo } from '../../utils/filterOrdersForTodayDo';

export default function Card(props) {

    const [dates, setDates] = useState([new Date()]);
    const weekDays = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
    const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];




    const filterOrdersForTodayStandard = (orders) => {    
        return orders.filter(order => {    
            return order.service === 'Standard' && order.etat === 'valider';
        });
    }
    const filterOrdersForTodayPrime = (orders) => {    
        return orders.filter(order => {    
            return order.service === 'Prime' && order.etat === 'valider';
        });
    }
    const commandeDuJour = filterOrdersForTodayB2C(props.orders);
    const todayPUDO = new Date();
    todayPUDO.setHours(0, 0, 0, 0);
    const commandeDuJourPU = filterOrdersForTodayPU(commandeDuJour, todayPUDO);
    const commandeDuJourDO = filterOrdersForTodayDo(commandeDuJour, todayPUDO);
    const commandeDuJourStandard = filterOrdersForTodayStandard(commandeDuJour);
    const commandeDuJourPrime = filterOrdersForTodayPrime(commandeDuJour);

    const filterUpcomingOrders = (orders) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
    
        return orders.filter(order => {
            const collecteDate = order.dateCollecte?.toDate();
            const livraisonDate = order.dateLivraison?.toDate();
    
            collecteDate.setHours(0, 0, 0, 0);
            livraisonDate.setHours(0, 0, 0, 0);
    
            return (collecteDate.getTime() > today.getTime() || livraisonDate.getTime() > today.getTime()) && order.etat === 'valider';
        });
    }
    const filterUpcomingOrdersPU = (orders) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
    
        return orders.filter(order => {
            const collecteDate = order.dateCollecte?.toDate();
            collecteDate.setHours(0, 0, 0, 0);
            return collecteDate.getTime() > today.getTime() && order.etat === 'valider';
        });
    }
    const filterUpcomingOrdersDo = (orders) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
    
        return orders.filter(order => {
            const livraisonDate = order.dateLivraison?.toDate();
            livraisonDate.setHours(0, 0, 0, 0);
            return livraisonDate.getTime() > today.getTime() && order.etat === 'valider';
        });
    }
 
    const commandeAVenir = filterUpcomingOrders(props.orders);
    const commandeAVenirPU = filterUpcomingOrdersPU(commandeAVenir);
    const commandeAVenirDO = filterUpcomingOrdersDo(commandeAVenir);
    const commandeAVenirStandard = filterOrdersForTodayStandard(commandeAVenir);
    const commandeAVenirPrime = filterOrdersForTodayPrime(commandeAVenir);

    const filterIncidentsOrders = (orders) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
    
        return orders.filter(order => {
            const collecteDate = order.dateCollecte?.toDate();
            const livraisonDate = order.dateLivraison?.toDate();
    
            collecteDate.setHours(0, 0, 0, 0);
            livraisonDate.setHours(0, 0, 0, 0);
    
            return (collecteDate.getTime() < today.getTime() && livraisonDate.getTime() < today.getTime()) && order.etat === 'valider';
        });
    }
    const filterIncidentsOrdersPU = (orders) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
    
        return orders.filter(order => {
            const collecteDate = order.dateCollecte?.toDate();
            collecteDate.setHours(0, 0, 0, 0);
            return collecteDate.getTime() < today.getTime() && order.etat === 'valider';
        });
    }
    const filterIncidentsOrdersDo = (orders) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
    
        return orders.filter(order => {
            const livraisonDate = order.dateLivraison?.toDate();
            livraisonDate.setHours(0, 0, 0, 0);
            return livraisonDate.getTime() < today.getTime() && order.etat === 'valider';
        });
    }
    const commandeIncidents = filterIncidentsOrders(props.orders);
    const commandeIncidentsPU = filterIncidentsOrdersPU(commandeIncidents);
    const commandeIncidentsDO = filterIncidentsOrdersDo(commandeIncidents);
    const commandeIncidentsStandard = filterOrdersForTodayStandard(commandeIncidents);
    const commandeIncidentsPrime = filterOrdersForTodayPrime(commandeIncidents);
    
  return (
    <>
        <div className="d-flex flex-wrap justify-content-center">
            <div className="flex-fill p-2 d-flex justify-content-center align-items-center">
                <div className='row'>
                    <div className='col'>
                        <TitleCard>Commandes du jour</TitleCard>
                    </div>
                    <div 
                        className='mx-auto p-4 pt-2 pb-2 mt-2 mb-2'
                        style={{ background: '#F1F1F1', width: '95%', borderRadius: '5px' }}
                    >
                                            <div className="row mb-3">
                            <div className="col">
                            <p className='m-0 p-0' style={{fontWeight: 'bold', fontSize: '4rem'}}>{commandeDuJour.length}</p>
                            </div>
                            <div className="col">
                            <p className='m-0 p-0 text-danger' style={{fontSize: '1.2rem'}}><BsExclamationTriangle className='me-2 mb-1' size={18}/>commandes non assigné</p>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col">
                                <p className='m-0 p-0'>PU : <span style={{fontWeight: '600'}}>{commandeDuJourPU.length}</span></p>
                            </div>
                            <div className="col">
                                <p className='m-0 p-0'>Standard : <span style={{fontWeight: '600'}}>{commandeDuJourStandard.length}</span></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className='m-0 p-0'>DO : <span style={{fontWeight: '600'}}>{commandeDuJourDO.length}</span></p>
                            </div>
                            <div className="col">
                                <p className='m-0 p-0'>Prime : <span style={{fontWeight: '600'}}>{commandeDuJourPrime.length}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-fill p-2 d-flex justify-content-center align-items-center">
                <div className='row'>
                    <div className='col'>
                        <TitleCard>Commandes à venir</TitleCard>
                    </div>
                    <div 
                        className='mx-auto p-4 pt-2 pb-2 mt-2 mb-2 text-secondary'
                        style={{ background: '#F1F1F1', width: '95%', borderRadius: '5px' }}
                    >
                        <p className='m-0 p-0 ' style={{fontWeight: 'bold', fontSize: '4rem'}}>{commandeAVenir.length}</p>
                        <div className="row">
                            <div className="col">
                                <p className='m-0 p-0'>PU : <span style={{fontWeight: '600'}}>{commandeAVenirPU.length}</span></p>
                            </div>
                            <div className="col">
                                <p className='m-0 p-0'>Standard : <span style={{fontWeight: '600'}}>{commandeAVenirStandard.length}</span></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className='m-0 p-0'>DO : <span style={{fontWeight: '600'}}>{commandeAVenirDO.length}</span></p>
                            </div>
                            <div className="col">
                                <p className='m-0 p-0'>Prime : <span style={{fontWeight: '600'}}>{commandeAVenirPrime.length}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-fill p-2 d-flex justify-content-center align-items-center">
            <div className='row'>
                    <div className='col'>
                    <TitleCard>Incident(s)</TitleCard>
  
                    </div>

                    <div 
                        className='mx-auto p-4 pt-2 pb-2 mt-2 mb-2'
                        style={{ background: '#F1F1F1', width: '95%', borderRadius: '5px' }}
                    >
                        <p className='m-0 p-0 ' style={{fontWeight: 'bold', fontSize: '4rem'}}>{commandeIncidents.length}</p>

                        <div className="row">
                            <div className="col">
                                <p className='m-0 p-0'>PU : <span style={{fontWeight: '600'}}>{commandeIncidentsPU.length}</span></p>
                            </div>
                            <div className="col">
                                <p className='m-0 p-0'>Standard : <span style={{fontWeight: '600'}}>{commandeIncidentsStandard.length}</span></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className='m-0 p-0'>DO : <span style={{fontWeight: '600'}}>{commandeIncidentsDO.length}</span></p>
                            </div>
                            <div className="col">
                                <p className='m-0 p-0'>Prime : <span style={{fontWeight: '600'}}>{commandeIncidentsPrime.length}</span></p>
                            </div>
                        </div>
                    </div>

                    </div>

      
            </div>
        </div>
    </>
  )
}
