import React,{ useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AddCodePromo from "./AddCodePromo";
import CodePromoList from "./CodePromoList";
import "./codePromo.css";

export default function CouponsPage() {
  const [codePromosId, setCodePromosId] = useState("");

  const getBookIdHandler = (id) => {
    setCodePromosId(id);
  };
  return (
    <div className="global-container">
      <Container>
        <Row>
            <AddCodePromo id={codePromosId} setCodePromoId={setCodePromosId} />
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <CodePromoList getCodePromoId={getBookIdHandler} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}