export const filterUpcomingOrdersB2C = (orders) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return orders.filter(order => {
        const collecteDate = order.dateCollecte?.toDate();
        const livraisonDate = order.dateLivraison?.toDate();

        collecteDate.setHours(0, 0, 0, 0);
        livraisonDate.setHours(0, 0, 0, 0);

        return (collecteDate.getTime() > today.getTime() || livraisonDate.getTime() > today.getTime()) && order.etat === 'valider';
    });
}