import React from 'react'
// import ButtonCreate from '../../../../components/Buttons/ButtonCreate'
// import Titre from '../../../../components/Titre'
// import ProvidersTable from './components/ProvidersTable/ProvidersTable'

export default function ServiceProvidersPage() {
  return (
    <>
    <div className='text-center'>
      <p>Cette page est en cours de construction</p>
    </div>
        {/* <Titre>PROVIDERS</Titre>
        <ButtonCreate>Ajouter un prestataire</ButtonCreate>
        <ProvidersTable/>
        <div>Presta Inscri</div> */}

    </>
  )
}