import React, { useContext, useRef, useState } from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import "./connexion.css";
import { useSnackbar } from "notistack";


function Connexion() {
  const { enqueueSnackbar } = useSnackbar();
    const { signIn } = useContext(UserContext);
    const inputs = useRef([]);
    const addInputs = (el) => {
      if (el && !inputs.current.includes(el)) {
        inputs.current.push(el);
      }
    };

    const handleForm = async (e) => {
      e.preventDefault();
      try {
        await signIn(
          inputs.current[0].value,
          inputs.current[1].value
        );
      } catch {
        enqueueSnackbar(`Le mail / mot de passe incorrect`, {
          variant: 'error',
          style:{fontSize: '1.2rem'},
          anchorOrigin: { vertical: 'bottom', horizontal: 'center'},
          autoHideDuration: 3000,
        });
      }
    };

    return (
    <>
      <Container className="mt-5">
        {/* <Row>
          <Col lg={4} md={6} sm={12} className="text-center">
          <h1 className="shadow-sm text-info mt-5 p-3 rounded">CLEAMY-Admin</h1>
            <Form>
              <Form.Group controlId="formEmail">
                <Form.Control type="email" placeholder="Entez votre mail" required ref={addInputs}/>
              </Form.Group>
              <Form.Group controlId="formPassword">
                <Form.Control type="password" placeholder="Entrez votre mot de passe" required ref={addInputs}/>
              </Form.Group>
              <Button variant="primary btn-block" type="submit">Se connecter</Button>
              <div>
                <a></a>
              </div>
            </Form>
          </Col>
          <Col lg={4} md={6} sm={12}></Col>
        </Row> */}
            <h1 className="shadow-sm text-info mt-5 p-3 text-center rounded">Cleamy - Admin</h1>
            <Row className="mt-5" >
                <Col lg={5} md={6} sm={12} className="p-5 m-auto shadow-sm rounded-lg">
                    <form onSubmit={handleForm}>
                        <Form.Group role="form">
                            <Form.Label>Email </Form.Label>
                            <Form.Control type="email" placeholder="Enter email" required ref={addInputs}/>

                            <Form.Label>Mot de passe</Form.Label>
                            <Form.Control type="password" placeholder="Password" required ref={addInputs}/>


                        <div className="d-grid gap-2 mt-5">
                          <Button variant="info btn-block" type="submit">
                              Se connecter
                          </Button>
                        </div>
                        </Form.Group>
                    </form>
                </Col>
            </Row>
            <h6 className="mt-5 p-5 text-center text-secondary ">Copyright © 2022 Cleamy. All Rights Reserved.</h6>
        </Container>
    </>
);
}

export default Connexion;
