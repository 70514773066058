import { db } from "../firebase-config";
import { collection, getDocs, getDoc, query, updateDoc, doc, orderBy, addDoc, where, Timestamp} from "firebase/firestore";

const bookCollectionRef = collection(db, "orders");
const corporateCollectionRef = collection(db, "corporate");
const B2BCollectionRef = collection(db, "Orders_B2B");
const ToursCollectionRef = collection(db, "Tours");
const PrestaCollectionRef = collection(db, "Prestataire");
class CommandeDataService {


  // Fonction pour obtenir le Timestamp de début et de fin de la journée spécifiée
  getDayStartEndTimestamps(date) {
  // Créer une nouvelle date pour le début de la journée
  const startOfDay = new Date(date.setHours(0, 0, 0, 0));
  const endOfDay = new Date(date.setHours(23, 59, 59, 999));

  // Convertir en Timestamp Firestore
  const startTimestamp = Timestamp.fromDate(startOfDay);
  const endTimestamp = Timestamp.fromDate(endOfDay);

  return { startTimestamp, endTimestamp };
  }

  getOrdersB2C6Month = () => {
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
    sixMonthsAgo.setHours(0, 0, 0, 0); // Réinitialiser l'heure pour inclure toute la journée

    // Obtenir toutes les commandes pour la journée spécifiée, triées par date de collecte limite à 6 mois pour la perfermance
    const q = query(bookCollectionRef,
      where("dateCollecte", ">=", sixMonthsAgo), // Ajouter cette condition
      orderBy("dateCollecte", "asc"));

    return getDocs(q);
  };
  getCorporate = () => {

    // Obtenir toutes les commandes pour la journée spécifiée, triées par date de collecte limite à 6 mois pour la perfermance
    const q = query(corporateCollectionRef,
      where("status", "==", 1));

    return getDocs(q);
  };

  getOrdersB2CPU = (date) => {

    const { startTimestamp, endTimestamp } = this.getDayStartEndTimestamps(date);

    // Obtenir toutes les commandes pour la journée spécifiée, triées par créneau
    const q = query(bookCollectionRef,
              where("etat", "==", "valider"),
              where("steps", "==", 1),
              where("dateCollecte", ">=", startTimestamp),
              where("dateCollecte", "<=", endTimestamp),
              orderBy("dateCollecte", "asc"),
              orderBy("creneauCollecte", "asc"));

    return getDocs(q);
  };
  getOrdersB2CDO = (date) => {

    const { startTimestamp, endTimestamp } = this.getDayStartEndTimestamps(date);

    // Obtenir toutes les commandes pour la journée spécifiée, triées par créneau
    const q = query(bookCollectionRef,
              where("etat", "==", "valider"),
              where("steps", "==", 3),
              where("dateLivraison", ">=", startTimestamp),
              where("dateLivraison", "<=", endTimestamp),
              orderBy("dateLivraison", "asc"),
              orderBy("creneauLivraison", "asc"));

    return getDocs(q);
  };
  getOrdersB2BPU = (date) => {

    const { startTimestamp, endTimestamp } = this.getDayStartEndTimestamps(date);

    // Obtenir toutes les commandes pour la journée spécifiée, triées par date de création
    const q = query(B2BCollectionRef,
              where("date_collecte", ">=", startTimestamp),
              where("date_collecte", "<=", endTimestamp),
              where("etat_commande", "==", 1),
              orderBy("date_collecte", "asc"),
              orderBy("date_creation_order", "asc"));

    return getDocs(q);
  };
  getOrdersB2BDO = (date) => {
    const sixMonthsAgo = new Date(date);
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
    sixMonthsAgo.setHours(0, 0, 0, 0); // Réinitialiser l'heure pour inclure toute la journée

    // Obtenir toutes les commandes pour la journée spécifiée, triées par date de collecte limite à 6 mois pour la perfermance
    const q = query(B2BCollectionRef,
      where("etat_commande", "==", 4),
      where("date_collecte", ">=", sixMonthsAgo), // Ajouter cette condition
      orderBy("date_collecte", "asc"));

    return getDocs(q);
  };
  getToursOfStatusOne = () => {
    const q = query(ToursCollectionRef,
      where("groupeStatus", "==", 1),
      orderBy("tourDate", "asc"));

    return getDocs(q);
  };
  getPresta = (date) => {
    const sixMonthsAgo = new Date(date);
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
    sixMonthsAgo.setHours(0, 0, 0, 0); // Réinitialiser l'heure pour inclure toute la journée

    const q = query(PrestaCollectionRef,
      where("createdAt", ">=", sixMonthsAgo), // Ajouter cette condition
      orderBy("createdAt", "asc"));

    return getDocs(q);
  };

  getAllCommandes = () => {
    const q = query(bookCollectionRef, orderBy("date_creation", "desc"));
    return getDocs(q);
  };

  getUser = (id) => {
    const userDoc = doc(db, "Users", id);
    return getDoc(userDoc);
  };

  getUserCard = (id, cardId) => {
    const userDoc = doc(db, "Users", id, "cards", cardId);
    return getDoc(userDoc);
  };

  getUserAdress = (id, adressId) => {
    const userDoc = doc(db, "Users", id, "adresses", adressId);
    return getDoc(userDoc);
  };

  getCommande = (id) => {
    const bookDoc = doc(db, "orders", id);
    return getDoc(bookDoc);
  };
  getCommandeB2B = (id) => {
    const bookDoc = doc(db, "corporate", id);
    return getDoc(bookDoc);
  };

  majSteps = (newBook, idDoc) => {
    return updateDoc(doc(db, "orders", idDoc), {steps: parseInt(newBook, 10)});
  };

  majEtape = (newBook, idDoc) => {
    return updateDoc(doc(db, "orders", idDoc), {etat: newBook});
  };

  postOrder = (newOrder) => {
    return addDoc(collection(db, "corporate_order"), newOrder);
  };
}

export default new CommandeDataService();