import React, { useEffect, useState } from 'react';
import Titre from '../../../../components/Titre';
import Searchbar from '../../../../components/Searchbar';

import driversServices from '../../../../context/drivers.services';
import DriverSearchBar from './Compoenents/SearchBar/DriverSearchBar';
import DriversTable from './Compoenents/DriversTable/DriversTable';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import AddDriverModal from './Compoenents/AddDriver/AddDriverModal';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../../../../firebase-config';
import OrdersList from '../Orders/Components/OrdersList';
import DriversList from './Compoenents/DriversTable/DriversList';



function DriversPage() {
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchOption, setSearchOption] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [driversData, setDriversData] = useState([]);
  const [hubs, setHubs] = useState([]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    const q = query(collection(db, "Hubs"), orderBy("created_at", "desc"))  ;
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const hubs = [];
        querySnapshot.forEach((doc) => {
          hubs.push({...doc.data(), id_hub: doc.id});
    });
    setHubs(hubs);
    });

    return () => unsubscribe(); 
  }, []);



  const [searchTerm, setSearchTerm] = useState("");
  const [currentList, setCurrentList] = useState([]);
  const [filteredDriversData, setFilteredDriversData] = useState([]);  
  const handleSearch = (term) => {
    setSearchTerm(term.toLowerCase());
    setCurrentPage(0); // Réinitialiser la page courante lors de chaque recherche

    if (term) {
      const filtered = driversData.filter(driver => 
        driver.telNumber?.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredDriversData(filtered);
    } else {
      setFilteredDriversData(driversData); // Remettre la liste complète si la barre de recherche est vide
    }
  };



  // const handleSearch = (option, text) => {
  //   setSearchOption(option);
  //   setSearchText(text);
  //   setCurrentPage(1); // Reset current page to 1
  // };







  

  const rowsPerPage = 3;



  useEffect(() => {
    const q = query(collection(db, "Drivers"), orderBy("created_at", "desc"))  ;
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const drivers = [];
        querySnapshot.forEach((doc) => {
          drivers.push({...doc.data(), id_driver: doc.id});
    });
    setDriversData(drivers); // Mettre à jour la liste complète des chauffeurs
    setFilteredDriversData(drivers); // Initialiser la liste filtrée avec tous les chauffeurs
    setCurrentPage(0); // Réinitialiser la page courante lors de chaque recherche
    });

    return () => unsubscribe(); 
  }, []);

  const filteredDrivers = driversData.filter((driver) => {
    const driverValue = driver[searchOption] ? driver[searchOption].toLowerCase() : '';
    return driverValue.includes(searchText.toLowerCase());
  });

  return (
    <div>
    {/* drivers={drivers} */}
    <AddDriverModal show={showModal} onHide={handleCloseModal} hubs={hubs}/>
     <Titre>Chauffeurs</Titre><span style={{color:'#008080', cursor:'pointer'}} className='btn-link' onClick={handleShowModal}><AiOutlinePlusCircle className='ms-5 me-2' size={14} color='#008080'/>Ajouter un nouveau chauffeur</span>

     <Searchbar placeholder='Rechercher...' onSearch={handleSearch}/>
     <DriversList orders={filteredDriversData} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
     {/* <DriverSearchBar onSearch={handleSearch} /> */}
        {/* 
        <OrdersList orders={filteredOrders} currentPage={currentPage} setCurrentPage={setCurrentPage}/> */}

{/* 
      <DriversTable
        data={filteredDrivers}
        dataSize={driversData.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
      /> */}
    </div>
  );
}

export default DriversPage;