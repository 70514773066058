import { db } from "../firebase-config";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";

const PricesCollectionRef = collection(db, "tarifs");
class PricesDataService {

  getAllPrices = () => {
    return getDocs(PricesCollectionRef);
  };
  getPrice = (id) => {
    const getPrice = doc(PricesCollectionRef, id);
    return getDoc(getPrice);
  };

}

export default new PricesDataService();