import React, { useState, useEffect } from 'react'
import { Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import PhoneInput from 'react-phone-input-2';
import { useSnackbar } from 'notistack';
import PricesDataService from "../../../../../../context/Prices.services";
import AddressAutocomplete from '../../../../../../components/Address/AddressAutocomplete';
import ConfirmedButton from '../../../../../../components/Buttons/ConfirmedButton';
import { UpdateStatusCompany } from '../../../../../../utils/Corporate/Account/Update/UpdateStatusCompany';
import { UpdateEmailCompany } from '../../../../../../utils/Corporate/Account/Update/UpdateEmailCompany';
import { UpdateNameCompany } from '../../../../../../utils/Corporate/Account/Update/UpdateNameCompany';
import { UpdatePhoneCompany } from '../../../../../../utils/Corporate/Account/Update/UpdatePhoneCompany';
import { UpdateDeliveryAddress } from '../../../../../../utils/Corporate/Account/Update/UpdateDeliveryAddress';
import { UpdateSiretCompany } from '../../../../../../utils/Corporate/Account/Update/UpdateSiretCompany';
import { UpdateTypeAccountCompany } from '../../../../../../utils/Corporate/Account/Update/UpdateTypeAccountCompany';
import { UpdateOptionHomeDelivery } from '../../../../../../utils/Corporate/Account/Update/UpdateOptionHomeDelivery';
import { UpdateSlotCompany } from '../../../../../../utils/Corporate/Account/Update/UpdateSlotCompany';
import { UpdateNameContact } from '../../../../../../utils/Corporate/Account/Update/UpdateNameContact';
import { UpdateEmailContact } from '../../../../../../utils/Corporate/Account/Update/UpdateEmailContact';
import { UpdatePhoneContact } from '../../../../../../utils/Corporate/Account/Update/UpdatePhoneContact';
import { UpdateInvoiceAddress } from '../../../../../../utils/Corporate/Account/Update/UpdateInvoiceAddress';
import { UpdateInvoiceEmail } from '../../../../../../utils/Corporate/Account/Update/UpdateInvoiceEmail';
import { UpdateMethodPayment } from '../../../../../../utils/Corporate/Account/Update/UpdateMethodPayment';
import { UpdatePriceCompany } from '../../../../../../utils/Corporate/Account/Update/UpdatePriceCompany';
import { UpdateServicesCompany } from '../../../../../../utils/Corporate/Account/Update/UpdateServicesCompany';
import { UpdatePassageCompany } from '../../../../../../utils/Corporate/Account/Update/UpdatePassageCompany';




const slots = ['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00'];
const weekDays = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

export default function EditCorporate({modalShow, setModalShow, modalShowIndex, id, getCorporate, corporate}) {

    const { enqueueSnackbar } = useSnackbar();
    const [selects, setSelects] = useState('');
    const [dates, setDates] = useState([]);
    const [inputChange, setInputeChange] = useState('');
    const [slot, setSlot] = useState({start: '', end: ''});
    const [services, setServices] = useState([]);
    const [prices, setPrices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getPrices = async() => {
        const data = await PricesDataService.getAllPrices();
        setPrices(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }

    const handleChangeService = (e) => {
        const { value, checked } = e.target;
        let newValue = [ ...services];

        if (checked) {
            // Ajoute la nouvelle valeur au tableau si elle n'est pas déjà présente
            if (!newValue.includes(value)) {
                newValue.push(value);
                setServices(newValue);
            }
        } else {
            // Supprime la valeur du tableau
            const index = newValue.indexOf(value);
            if (index > -1) {
                newValue.splice(index, 1);
                setServices(newValue);
            }
        }
    };

    const validatorUpdate = () => {
        getCorporate();
        setModalShow(false);
        setSelects('');
        setInputeChange('');
        setSlot({start: '', end: ''});
        setServices([]);
        setDates([]);
        setIsLoading(false);
    }

    const updateInfo = async () => {

        let updateResult = true;

        if(selects.length > 0) {
            switch (Number(selects)) {
                case 0 : 
                    updateResult = await UpdateNameCompany({ id, value: inputChange, enqueueSnackbar, setIsLoading });
                    break;
                case 1 : 
                    updateResult = await UpdateStatusCompany({ id, value: inputChange, enqueueSnackbar, setIsLoading });
                    break;
                case 2 : 
                    updateResult = await UpdatePhoneCompany({ id, value: inputChange, enqueueSnackbar, setIsLoading });
                    break;
                case 3 : 
                    updateResult = await UpdateDeliveryAddress({ id, value: inputChange, enqueueSnackbar, setIsLoading });
                    break;
                case 4 : 
                    updateResult = await UpdateSiretCompany({ id, value: inputChange, enqueueSnackbar, setIsLoading });
                    break;
                case 5 : 
                    updateResult = await UpdateEmailCompany({ id, value: inputChange, enqueueSnackbar, setIsLoading });
                    break;
                case 6 : 
                    updateResult = await UpdateTypeAccountCompany({ id, value: inputChange, enqueueSnackbar, corporate, setIsLoading });
                    break;
                case 7 : 
                    updateResult = await UpdateOptionHomeDelivery({ id, value: inputChange, enqueueSnackbar, setIsLoading });
                    break;
                case 8 :
                    updateResult = await UpdateSlotCompany({ id, start: slot.start, end: slot.end, enqueueSnackbar, setIsLoading });
                    break;
                case 9 : 
                    updateResult = await UpdateNameContact({ id, value: inputChange, enqueueSnackbar, setIsLoading });
                    break;
                case 10 :
                    updateResult = await UpdateEmailContact({ id, value: inputChange, enqueueSnackbar, setIsLoading });
                    break;
                case 11 :
                    updateResult = await UpdatePhoneContact({ id, value: inputChange, enqueueSnackbar, setIsLoading });
                    break;
                case 12 :
                    updateResult = await UpdateInvoiceAddress({ id, value: inputChange, enqueueSnackbar, setIsLoading });
                    break;
                case 13 :
                    updateResult = await UpdateInvoiceEmail({ id, value: inputChange, enqueueSnackbar, setIsLoading });
                    break;
                case 14 :
                    updateResult = await UpdateMethodPayment({ id, value: inputChange, enqueueSnackbar, setIsLoading });
                    break;
                case 15 :
                    updateResult = await UpdatePriceCompany({ id, value: inputChange, enqueueSnackbar, setIsLoading });
                    break;
                case 16 :
                    updateResult = await UpdateServicesCompany({ id, value: services, enqueueSnackbar, setIsLoading });
                    break;
                case 17 :
                    updateResult = await UpdatePassageCompany({ id, value: dates, enqueueSnackbar,setIsLoading });
                    break;
                default : return;
              }
              updateResult ? validatorUpdate() : setModalShow(true);
        }else{
            enqueueSnackbar(`Veuillez selectionner un champs valide`, {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}
               
              });
        }
    };

    const onHide = () => {
        setModalShow(false);
        setSelects('');
        setInputeChange('');
        setDates([]);
        setSlot({start: '', end: ''});
        setServices([]);
        setIsLoading(false);
    }
  useEffect(() => {
    getPrices();
}, [modalShowIndex === 2 && Number(selects) === 15]);

  return (
    <Modal
      show={modalShow}
        onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className='m-4'>
        <div className="form-group">
                <FloatingLabel
                    controlId="floatingSelectGrid"
                    label="Séléctionner le champ à modifié"
                    style={{fontSize: '1.4rem'}}
          
                >
                    <Form.Select aria-label="Séléctionner le champ à modifié" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={e => {setSelects(e.target.value); setInputeChange(''); setSlot({start: '', end: ''}); setServices([]); setDates([]); setIsLoading(false);}} >
                    {modalShowIndex === 0 && <>
        <option value=''>Séléctionner un champ</option>
        <option value={0}>Nom de l'entreprise</option>
        <option value={1}>Status</option>
        <option value={2}>Téléphone de l'entreprise</option>
        <option value={3}>Adresse de livraison</option>
        <option value={4}>Siret/Siren</option>
        <option value={5}>Email de l'entreprise</option>
        <option value={6}>Type de compte</option>
        {corporate.underAccount && <option value={7}>Livraison à domicile</option>}
        <option value={8}>Créneaux horaires</option>
    </>}
    {modalShowIndex === 1 && <>
        <option value=''>Séléctionner un champ</option>
        <option value={9}>Nom de contact</option>
        <option value={10}>Email de contact</option>
        <option value={11}>Téléphone de contact</option>
    </>}
    {modalShowIndex === 2 && <>
        <option value=''>Séléctionner un champ</option>
        <option value={12}>Adresse de facturation</option>
        <option value={13}>Email de facturation</option>
        <option value={14}>Mode de paiement</option>
        <option value={15}>Tarifs appliqué</option>
    </>}
    {modalShowIndex === 3 && <>
        <option value=''>Séléctionner un champ</option>
        <option value={16}>Prestations</option>
        <option value={17}>Passage en cours</option>
    </>}
          </Form.Select>
        </FloatingLabel>

  </div>

  {selects !== '' && <>
  <div className="form-group mt-5">
    <label for="exampleFormControlInput1" style={{letterSpacing: '2px', fontWeight: '600' }} className='mb-2' >Saisir la nouvelle valeur</label>
    {modalShowIndex === 0 && <>
        {
            Number(selects) === 1 ? 
        <>
        <FloatingLabel
                        controlId="floatingSelectGrid"
                        label="Status"
                        style={{fontSize: '1.4rem'}}
          
                    >
                    <Form.Select aria-label="Mode de paiement" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={e => setInputeChange(e.target.value)} >
                    <option value=''>Séléctionner un status</option>
                    <option value={1}>Activée</option>
        <option value={2}>Archivée</option>
        <option value={3}>En pause</option>
          </Form.Select>
        </FloatingLabel>

        </> 
    : Number(selects) === 2 ? 
        <>
        <PhoneInput
                  inputStyle={{ border: '1px solid #008080', padding: '0.6rem 5rem', borderRadius: '5px', marginTop: '1.5rem' }}
                  inputClassName="phone-form-input"
                  name="phone Number"
                  country="fr"
                  value={inputChange}
                  onChange={(value) => setInputeChange((prevData) => (`+${value}`))}
                />
        </> 
    : Number(selects) === 3 ? 
        <>
            <AddressAutocomplete setData={setInputeChange} />
        </> 
    : Number(selects) === 6 ? 
        <>
        <FloatingLabel
                        controlId="floatingSelectGrid"
                        label="Type de compte"
                        style={{fontSize: '1.4rem'}}
          
                    >
                    <Form.Select aria-label="Mode de paiement" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={e => setInputeChange(e.target.value)} >
                    <option value=''>Séléctionner un type</option>
                    <option value='true'>Avec des sous compte</option>
                    <option value='false'>Compte unique</option>
          </Form.Select>
        </FloatingLabel>
        </> 
    : (corporate.underAccount && Number(selects) === 7) ? 
        <>
        <FloatingLabel
                        controlId="floatingSelectGrid"
                        label="Livraison à domicile"
                        style={{fontSize: '1.4rem'}}
          
                    >
                    <Form.Select aria-label="Mode de paiement" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={e => setInputeChange(e.target.value)} >
                    <option value=''>Séléctionner une option</option>
                    <option value='true'>Activer</option>
                    <option value='false'>Désactiver</option>
          </Form.Select>
        </FloatingLabel>
        </> 
    : Number(selects) === 8 ? 
        <>
            <Row>
                <Col md>
                    <FloatingLabel
                        controlId="floatingSelectGrid"
                        label="Créneau de début"
                        style={{fontSize: '1.4rem'}}
                    >
                    <Form.Select aria-label="Créneau de début" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={(e) => setSlot({...slot, start: e.target.value})} >
                                <option value=''>Séléctionner un créneau de début</option>
                                {slots.map((slot, index) => (
                                <option key={index} value={slot}>{slot}</option>
                            ))}
                    </Form.Select>
                    </FloatingLabel>
                </Col>
                <Col md>
                    <FloatingLabel
                        controlId="floatingSelectGrid"
                        label="Créneau de fin"
                        style={{fontSize: '1.4rem'}}
                    >
                    <Form.Select aria-label="Créneau de fin" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={(e) => setSlot({...slot, end: e.target.value})}>
                                <option value=''>Séléctionner un créneau de fin</option>
                                {slots.map((slotItem, index) => {
                        // Afficher seulement si le créneau est postérieur au créneau de début sélectionné
                        if (slot.start && slotItem > slot.start) {
                            return <option key={index} value={slotItem}>{slotItem}</option>;
                        }
                    })}

                    </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>
        </> 
    :
        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="nouvelle valeur" onChange={e => setInputeChange(e.target.value)} style={{letterSpacing: '2px', fontWeight: '600', fontSize: '1.4rem'}} />
        }
    </>}


    {modalShowIndex === 1 && <>
            {
                Number(selects) === 11 ? 
            <>
                <PhoneInput
                  inputStyle={{ border: '1px solid #008080', padding: '0.6rem 5rem', borderRadius: '5px', marginTop: '1.5rem' }}
                  inputClassName="phone-form-input"
                  name="phone Number"
                  country="fr"
                  value={inputChange}
                  onChange={(value) => setInputeChange((prevData) => (`+${value}`))}
                />
            </> 
    :
        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="nouvelle valeur" onChange={e => setInputeChange(e.target.value)} style={{letterSpacing: '2px', fontWeight: '600', fontSize: '1.4rem'}} />
            }
     </>}

    {modalShowIndex === 2 && <>
        { Number(selects) === 12 ? 
            <>
                <AddressAutocomplete setData={setInputeChange} />
            </> 
    : Number(selects) === 14 ?
            <>
                <FloatingLabel
                        controlId="floatingSelectGrid"
                        label="Mode de paiment"
                        style={{fontSize: '1.4rem'}}
          
                    >
                    <Form.Select aria-label="Mode de paiement" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={e => setInputeChange(e.target.value)} >
                    <option value=''>Séléctionner un mode de paiement</option>
                    <option value='Mensuel'>Mensuel</option>
                    <option value='Par commande'>Par commande</option>
          </Form.Select>
        </FloatingLabel>
            </> 
    : Number(selects) === 15 ?
            <>
                <FloatingLabel
                        controlId="floatingSelectGrid"
                        label="Tarifs"
                        style={{fontSize: '1.4rem'}}
          
                    >
                    <Form.Select aria-label="Mode de paiement" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={e => setInputeChange(e.target.value)} >
                    <option value=''>Séléctionner un tarif</option>
                    {prices.map((price) => (
                    <option key={price.id} value={price.id}>{price.id}</option>
                ))}
          </Form.Select>
        </FloatingLabel>
            </> 
    :
        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="nouvelle valeur" onChange={e => setInputeChange(e.target.value)} style={{letterSpacing: '2px', fontWeight: '600', fontSize: '1.4rem'}} />}
    </>}

    {modalShowIndex === 3 && <>
        {
            Number(selects) === 16 ? 
            <>
            <Form.Group >
                    <Form.Check
                      inline
                      label="Pressing & Blanchisserie"
                      name="Pressing & Blanchisserie"
                      value="Pressing & Blanchisserie"
                      type="checkbox"
                      id="inline-checkbox-1"
                      onChange={handleChangeService}
                      />
                    <Form.Check
                      inline
                      label="Linge au kilo"
                      name="Linge au kilo"
                      type="checkbox"
                      id="inline-checkbox-2"
                      value="Linge au kilo"
                      onChange={handleChangeService}
                    />
                    <Form.Check
                      inline
                      label="Retouches"
                      name="Retouches"
                      type="checkbox"
                      id="inline-checkbox-3"
                      value='Retouches'
                      onChange={handleChangeService}
                    />
                    <Form.Check
                      inline
                      label="Cordonnerie"
                      name="Cordonnerie"
                      type="checkbox"
                      value='Cordonnerie'
                      id="inline-checkbox-4"
                      onChange={handleChangeService}
                    />
                  </Form.Group>
            </> 
    :
            Number(selects) === 17 ? 
            <>
            <div>
  <DatePicker                      
                    value={dates}
                    onChange={setDates}
                    format="dddd, DD MMMM YYYY"
                    weekDays={weekDays}
                    months={months}
                    sort
                    plugins={[
                      <DatePanel />
                    ]}
                    render={<InputIcon style={{ width: '53vw', height: '45px',}} className='px-3'/>}
                  />
  </div>
            </> 
    :
        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="nouvelle valeur" onChange={e => setInputeChange(e.target.value)} style={{letterSpacing: '2px', fontWeight: '600', fontSize: '1.4rem'}} />
            }
        </>}
  </div>

  </>}

  
  <div>
  </div>
        
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onHide} className='btn btn-outline-danger' style={{fontSize: '1.4rem'}}>Annuler</button>
        <ConfirmedButton 
            onClick={updateInfo}
            isLoading={isLoading}
            labelLoading={"en cours d'enregistrement..."}
            labelButton={"Confirmer"}
        />
      </Modal.Footer>
    </Modal>
  )
}
