import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { FaPlus } from 'react-icons/fa'
import AddFactureModal from './AddFactureModal';

function AddFacture({ corporatesData }) {

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    
    return (
        <div>
            <Button className='add-planning-button' onClick={handleShowModal}>
                    <FaPlus />
                    <span className='ms-2'>Ajouter une Facture</span>
            </Button>
            <AddFactureModal show={showModal} onHide={handleCloseModal} corporatesData={corporatesData} />
        </div>
    )
}

export default AddFacture