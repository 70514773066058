import React,{useContext, useEffect, useState } from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import { SnackbarProvider, useSnackbar } from "notistack";
import { UserContext } from "./context/userContext";
import { checkPageAccess } from "./utils/navbar/AccessControl";
import { items } from "./utils/navbar/NavbarItems";
import NavbarUserInformation from "./components/navbar/NavbarUserInformation";
import Navbar from "./components/navbar/Navbar";
import Page404 from "./components/pageErreur/erreur404";
import Connexion from "./pages/connexion/connexion";
import './pages/private/style.css'
import OrderDetailsPage from "./pages/private/pages/Orders/OrderDetailsPage";
import CustomerDetailsPage from "./pages/private/pages/Customers/CustomerDetailsPage";
import DriverDetailPages from "./pages/private/pages/Drivers/Compoenents/DetailDriver/DriverDetailsPage";
import DetailsCorporatePage from "./pages/private/pages/Corporate/DetailsCorporatePage";
import ModalDetailB2C from "./pages/private/pages/Provisoir/ModalDetailB2C";
import ModalDetailB2B from "./pages/private/pages/Provisoir/ModalDetailB2B";

function App() {
  const {currentUser, user, logOut} = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [idB2B, setIdB2B] = useState('');
  const getIdB2BIdHandler = (id) => {
    setIdB2B(id);
  };

  useEffect(() => {
    if(user?.status !== 1){
      logOut();
    }
  },[currentUser, user])

  return (
    <SnackbarProvider maxSnack={1} anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}>
    {currentUser && (
      <>
        <NavbarUserInformation />
        <Navbar navItems={items}/>
      </>
    )}

      <Routes>
        <Route path="/" element={currentUser ? <Navigate to="/private/Dashboard" />: <Connexion />}/>
          {
            items.map((item, index) => {
              const hasAccess = checkPageAccess(currentUser, item, user?.role);
              if(hasAccess){
                return <Route
                  key={index}
                  path={item.url}
                  element={item.label !== 'Sign Out' && <div className="view_page container-fluid">{item.element}</div>}
                  setIdB2B={setIdB2B}
                />
              }
            })
          }
   

          <Route path="/private/Corporate/:id" element={<DetailsCorporatePage />}/>
          <Route path="/private/Orders/:id" element={<OrderDetailsPage />}/>
          <Route path="/private/Customers/:id" element={<CustomerDetailsPage />}/>
          <Route path="/private/Drivers/:id" element={<DriverDetailPages />}/>
          <Route path="/private/Provisoir/dispatch/B2C/:id" element={<ModalDetailB2C />}/>
          <Route path="/private/Provisoir/dispatch/B2B/:id" element={<ModalDetailB2B />}/>
        <Route path='*' element={<Page404 />} />
      </Routes>
    </SnackbarProvider>
);
}

export default App;