import { Timestamp } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { BsChevronDown } from 'react-icons/bs';
import facturesServices from '../../../../../context/Bills.services';
import FactureDate from './FactureDate';

function AddFactureModal({ show, onHide,  corporatesData }) {

    const initialFormData = {
        date: new Date(),
        factureId: '',
        ht: 0,
        lien: '',
        numeroOrder: '',
        orders: [],
        status: 'En attente de paiement',
        ttc: 0,
        user: '',
    };
    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };

    const [shouldClearForm, setShouldClearForm] = useState(false);

    useEffect(() => {
        if (shouldClearForm) {
          setFormData(initialFormData);
          setShouldClearForm(false);
        }
    }, [shouldClearForm]);

    const [factureDate, setFactureDate] = useState(new Date());

    const addFacture = async () => {
    
        const newFacture = {
            date: Timestamp.fromDate(new Date(factureDate)),
            factureId: formData.factureId,
            ht: parseFloat(formData.ht),
            lien: formData.lien,
            numeroOrder: formData.numeroOrder,
            orders: [],
            status: 'En attente de paiement',
            ttc: parseFloat(formData.ttc),
            user: formData.user,
        };
        try {          
    
          await facturesServices.addFacture(newFacture);
    
          setShouldClearForm(true);
          onHide();
        } catch (error) {
          console.error('Error creating facture:', error);
        }
      };
    
      const handleClose = () => {
        setFormData(initialFormData);
        onHide();
    };    

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
            <Modal.Title>Ajouter une Facture</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
            <Row>
                <Col>
                    <div className="mb-3 driver-form-field">
                        <Form.Control
                        className="driver-form-input"
                        type="text"
                        name="factureId"
                        required
                        value={formData.factureId}
                        onChange={handleChange}
                        />
                        <Form.Label className="floating-label">ID Facture</Form.Label>
                    </div>
                    <div className="mb-3 driver-form-field">
                        <Form.Control
                        className="driver-form-input"
                        type="number"
                        name="ht"
                        required
                        value={formData.ht}
                        onChange={handleChange}
                        />
                        <Form.Label className="floating-label">Prix HT</Form.Label>
                    </div>
                    <div className="mb-3 driver-form-field">
                        <Form.Control
                        className="driver-form-input"
                        type="number"
                        name="ttc"
                        required
                        value={formData.ttc}
                        onChange={handleChange}
                        />
                        <Form.Label className="floating-label">Prix TTC</Form.Label>
                    </div>
                </Col>
                <Col>
                    <div className="mb-3 driver-form-field">
                        <Form.Group controlId="selectedZone">
                            <Form.Label>Société</Form.Label>
                            <div className="position-relative">
                                <Form.Control
                                as="select"
                                className='driver-form-input'
                                style={{ color: '#808080' }}
                                name="user"
                                value={formData.user}
                                onChange={handleChange}
                                >
                                <option value="">Sélectionner la société</option>
                                {corporatesData.map((corporate) => (
                                    <option key={corporate.id} value={corporate.id}>
                                    {corporate.nameCompany}
                                    </option>
                                ))}
                                </Form.Control>
                                <BsChevronDown className="dropdown-icon" />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="mb-3 driver-form-field">
                        <Form.Control
                        className="driver-form-input"
                        type="text"
                        name="numeroOrder"
                        required
                        value={formData.numeroOrder}
                        onChange={handleChange}
                        />
                        <Form.Label className="floating-label">N° Facture</Form.Label>
                    </div>
                    <div className="mb-3 driver-form-field">
                        <Form.Control
                        className="driver-form-input"
                        type="text"
                        name="lien"
                        required
                        value={formData.lien}
                        onChange={handleChange}
                        />
                        <Form.Label className="floating-label">Lien Facture</Form.Label>
                    </div>
                    <div className="mb-3 driver-form-field">
                        <Form.Label>Date Facture</Form.Label>
                        <FactureDate selectedDate={factureDate} onDateChange={setFactureDate}/>
                    </div>

                </Col>
            </Row>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={addFacture} className="add-planning-button">
            Ajouter Facture
            </Button>
        </Modal.Footer>
        </Modal>
    )
}

export default AddFactureModal