// Liste des pages et de contrôle d'accès avec des autorisations spécifiques pour chaque page
import React from "react";
import { userRoles } from "../../model/user/User";
import BillsPage from "../../pages/private/pages/Bills/BillsPage";
import CorporatePage from "../../pages/private/pages/Corporate/CorporatePage";
import CouponsPage from "../../pages/private/pages/Coupons/CouponsPage";
import CustomersPage from "../../pages/private/pages/Customers/CustomersPage";
import DashboardPage from "../../pages/private/pages/Dashboard/DashboardPage";
import DriversPage from "../../pages/private/pages/Drivers/DriversPage";
import HubsPage from "../../pages/private/pages/Hubs/HubsPage";
import ItemsPage from "../../pages/private/pages/Items/ItemsPage";
import OrdersPage from "../../pages/private/pages/Orders/OrdersPage";
import ServiceProvidersPage from "../../pages/private/pages/Providers/ServiceProvidersPage";
import SettingsPage from "../../pages/private/pages/Settings/SettingsPage";
import SlotsPage from "../../pages/private/pages/Slots/SlotsPage";
import ToursPage from "../../pages/private/pages/Tours/ToursPage";
import DispatchsPage from "../../pages/private/pages/Dispatchs/DispatchPage";
import PrestatairePage from "../../pages/private/pages/Provisoir/PrestatairePage";
import TourPage from "../../pages/private/pages/Provisoir/Tours/TourPage";

export const items = [
    {
        label: 'Dashboard',
        url: '/private/Dashboard',
        role_access: [userRoles.ADMIN],
        icon: <ion-icon name="speedometer-outline"></ion-icon>,
        element: <DashboardPage />
    },
    {
        label: 'Orders',
        url: '/private/Orders',
        role_access: [userRoles.ADMIN, userRoles.LEVEL2],
        icon: <ion-icon name="cart-outline"></ion-icon>,
        element: <OrdersPage />
       
    },
    {
        label: 'Tours',
        url: '/private/Tours',
        role_access: [userRoles.ADMIN, userRoles.LEVEL2],
        icon: <ion-icon name="navigate-outline"></ion-icon>,
        element: <ToursPage />
    },
    {
        label: 'B2C',
        url: '/private/Customers',
        role_access: [userRoles.ADMIN, userRoles.LEVEL2],
        icon: <ion-icon name="person-outline"></ion-icon>,
        element: <CustomersPage/>
    },
    {
        label: 'B2B',
        url: '/private/Corporate',
        role_access: [userRoles.ADMIN, userRoles.LEVEL2],
        icon: <ion-icon name="briefcase-outline"></ion-icon>,
        element: <CorporatePage />
    },
    {
        label: 'Service Providers',
        url: '/private/Providers',
        role_access: [userRoles.ADMIN, userRoles.LEVEL2],
        icon: <ion-icon name="people-outline"></ion-icon>,
        element: <ServiceProvidersPage />
    },
    {
        label: 'Dispatchs',
        url: '/private/Dispatchs',
        role_access: [userRoles.ADMIN],
        icon: <ion-icon name="barcode-outline"></ion-icon>,
        element: <DispatchsPage />
    },
    {
        label: 'Hubs',
        url: '/private/Hubs',
        role_access: [userRoles.ADMIN],
        icon: <ion-icon name="cube-outline"></ion-icon>,
        element: <HubsPage />
    },
    {
        label: 'Drivers',
        url: '/private/Drivers',
        role_access: [userRoles.ADMIN, userRoles.DEV],
        icon: <ion-icon name="car-sport-outline"></ion-icon>,
        element: <DriversPage />
    },
    {
        label: 'Items',
        url: '/private/Items',
        role_access: [userRoles.ADMIN, userRoles.LEVEL2],
        icon: <ion-icon name="shirt-outline"></ion-icon>,
        element: <ItemsPage />
    },
    {
        label: 'Slots',
        url: '/private/Slots',
        role_access: [userRoles.ADMIN, userRoles.LEVEL2],
        icon: <ion-icon name="time-outline"></ion-icon>,
        element: <SlotsPage />
    },
    {
        label: 'Coupons',
        url: '/private/Coupons',
        role_access: [userRoles.ADMIN, userRoles.LEVEL2],
        icon: <ion-icon name="pricetag-outline"></ion-icon>,
        element: <CouponsPage />
    },
    {
        label: 'Bills',
        url: '/private/Bills',
        role_access: [userRoles.ADMIN],
        icon: <ion-icon name="document-text-outline"></ion-icon>,
        element: <BillsPage />
    },
    {
        label: 'Settings',
        url: '/private/Settings',
        role_access: [userRoles.ADMIN],
        icon: <ion-icon name="settings-outline"></ion-icon>,
        element: <SettingsPage />
    },
    {
        label: 'Prestaire (provisoir)',
        url: '/private/Provisoir/dispatch',
        role_access: [userRoles.ADMIN],
        icon: <ion-icon name="construct-outline"></ion-icon>,
        element: <PrestatairePage />
    },
    {
        label: 'Tours (provisoir)',
        url: '/private/Provisoir/Tours',
        role_access: [userRoles.ADMIN],
        icon: <ion-icon name="construct-outline"></ion-icon>,
        element: <TourPage />
    },
    {
        label: 'Sign Out',
        url: '/',
        role_access: [userRoles.ADMIN, userRoles.LEVEL2, userRoles.DEV],
        icon: <ion-icon name="log-out-outline"></ion-icon>
    }
];