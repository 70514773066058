import React from 'react'
import { Table } from 'react-bootstrap'
import './OrdersList.css'
import { useNavigate } from 'react-router-dom';
import { formatDateComplete, formatDate } from '../../../../../utils/DateToString'
export default function OrdersList(props) {

    const navigate = useNavigate();
    const PAGE_SIZE = 5;
    const nextPage = () => {
        if ((props.currentPage + 1) * PAGE_SIZE < props.orders.length) {
            props.setCurrentPage(props.currentPage + 1);
        }
    };


    const prevPage = () => {
        if (props.currentPage > 0) {
            props.setCurrentPage(props.currentPage - 1);
        }
    };

    const paginatedOrders = props.orders.slice(props.currentPage * PAGE_SIZE, (props.currentPage + 1) * PAGE_SIZE);


  return (
    <>
    <Table responsive="sm" className=' mx-auto text-start table-personnalisee '>
    <thead>
      <tr>
        <th>Date de création</th>
        <th>N° de commande</th>
        <th>Date de collecte / livraison</th>
        <th>Status</th>
        <th>Driver</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      
        {paginatedOrders?.length > 0 ? paginatedOrders?.map((order) => {
            return (
                <tr key={order.idCommande} className='py-5'>
                <td>{formatDateComplete(order.date_creation?.toDate().toISOString())}</td>
                <td>{order.idFacture}</td>
                <td>{formatDate(order.dateCollecte?.toDate().toISOString())} - {order.creneauCollecte} <br /> {formatDate(order.dateLivraison?.toDate().toISOString())} - {order.creneauLivraison}</td>
                <td>
                    <span style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: order.etat === 'annuler' ? '#FF5733' : order.etat === 'terminer' ?'#CBCBCB' : order.modifier ? '#FFBC6C' : '#39FF33',
                        marginRight: '5px'
                    }}>
                    </span>{order.etat}</td>
                <td>Non assignée</td>
                <td className='btn-link' style={{cursor: 'pointer'}} onClick={() => navigate(`/private/Orders/${order.idCommande}`)}>Voir détails</td>
                </tr>

            )
        }) : <tr><td colSpan="6" className='text-center secondary' style={{fontStyle: 'italic'}}>Aucune résultat</td></tr>}


    </tbody>
  </Table>
  <div className="d-flex flex-wrap justify-content-center my-5 bg-light py-2 rounded">
  <div className='flex-fill p-2 d-flex justify-content-center align-items-center'>{props.orders.length} résultats</div>
  <div className='flex-fill p-2 d-flex justify-content-center align-items-center'>
  <button onClick={prevPage} disabled={props.currentPage === 0} style={{border: '1px solid #e0e0e0', borderRadius: '8px', fontSize: '1.2rem'}} className="btn">Précédent</button>
                <button onClick={nextPage} className="ms-3 btn" style={{border: '1px solid #e0e0e0', borderRadius: '8px', fontSize: '1.2rem'}} disabled={(props.currentPage + 1) * PAGE_SIZE >= props.orders.length}>Suivant</button>
  </div>
            </div>
            </>
  )
}
