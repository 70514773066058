import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { FaTrash, FaEdit } from 'react-icons/fa';
import { db } from '../../../../firebase-config';
import ModalConfirmed from './ModalConfirmed';

const priceLevels = ['standard', 'standardPlus', 'premium'];

const initialArticleState = {
  from: false,
  name: '',
  priceHT: { standard: 0, standardPlus: 0, premium: 0 },
  priceTTC: { standard: 0, standardPlus: 0, premium: 0 },
  url: '',
  position: 0,
  archived: false,
  creation_date: Timestamp.fromDate(new Date()),
};

const options = ['Pressing', 'Cordonnerie', 'Retouche', 'lk'];

export default function AddTarif() {
  const [selectedOption, setSelectedOption] = useState('');
  const [article, setArticle] = useState(initialArticleState);
  const [editingArticle, setEditingArticle] = useState(null);
  const [editingArticleIndex, setEditingArticleIndex] = useState(-1);
  const [show, setShow] = useState(false);
  const [nameTarif, setNameTarif] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const [articlesList, setArticlesList] = useState({
    Pressing: [],
    Cordonnerie: [],
    Retouche: [],
    lk: [],
  });

  useEffect(() => {
    const calculatePriceHT = (priceTTC, taxRate) => {
      return priceTTC / (1 + taxRate);
    };
  
    const taxRate = 0.2;
    const updatedPriceHT = priceLevels.reduce((acc, level) => {
      const parsedValue = calculatePriceHT(article.priceTTC[level], taxRate);
      acc[level] = isNaN(parsedValue) ? 0 : parseFloat(parsedValue.toFixed(2));
      return acc;
    }, {});
    setArticle({ ...article, priceHT: updatedPriceHT });
  }, [article.priceTTC]);
  useEffect(() => {
    if (editingArticle) {
      setArticle(editingArticle);
    }
  }, [editingArticle]);
  
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleOptionChange = (e) => setSelectedOption(e.target.value);
  const handleEditArticle = (option, index) => {
    setEditingArticle(articlesList[option][index]);
    setEditingArticleIndex(index);
    setSelectedOption(option);
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'from') {
      setArticle({ ...article, [name]: !article.from });
    } else if (name.startsWith('priceTTC')) {
      const priceLevel = name.split('_')[1];
      const parsedValue = parseFloat(value);
      setArticle({
        ...article,
        priceTTC: {
          ...article.priceTTC,
          [priceLevel]: isNaN(parsedValue) ? 0 : parseFloat(parsedValue.toFixed(2)),
        },
      });
    } else if (name === 'position') {
      const parsedValue1 = parseFloat(value);
      setArticle({
        ...article,
        [name]: isNaN(parsedValue1) ? 0 : parsedValue1,
      });
    } else {
      setArticle({ ...article, [name]: value });
    }
  };
  
  

  const handleAddArticle = () => {
    if (editingArticleIndex >= 0) {
      const updatedList = [...articlesList[selectedOption]];
      updatedList[editingArticleIndex] = article;
      setArticlesList({ ...articlesList, [selectedOption]: updatedList });
      setEditingArticleIndex(-1);
      setEditingArticle(null);
    } else {
      setArticlesList({
        ...articlesList,
        [selectedOption]: [...articlesList[selectedOption], article],
      });
    }
    setArticle(initialArticleState);
  };
  

  const saveToFirebase = async () => {
    try {
        // Créez un nouveau document avec le nom "nameTarif" dans la collection "tarifs"
        const tarifDocRef = doc(db, "tarifs", nameTarif);

    
        // Enregistrez les données "articlesList" dans le document créé
        await setDoc(tarifDocRef, { ...articlesList });
        enqueueSnackbar('Grille tarifaire enregistrée avec succès', { 
            variant: 'success',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoHideDuration: 3000,
            className:'text-center'
           
          });
      } catch (error) {
        enqueueSnackbar(`Erreur lors de l'enregistrement du document : ${error}`, { 
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoHideDuration: 3000,
            className:'text-center'
           
          });
      }

    handleClose()
  };

  const handleRemoveArticle = (option, index) => {
    const updatedList = articlesList[option].filter((_, i) => i !== index);
    setArticlesList({ ...articlesList, [option]: updatedList });
  };
  

  const renderPriceInputs = () =>
    <div className='d-flex justify-content-start mt-4'>
        {
            priceLevels.map((level, index) => (
                <div key={level} className={`form-group ${index === 1 && 'mx-4'}`}>
                    <label>
                    {`Prix TTC - ${level.charAt(0).toUpperCase() + level.slice(1)}`}
                    <input
                        className='form-control mt-2'
                        type="number"
                        name={`priceTTC_${level}`}
                        value={article.priceTTC[level]}
                        onChange={handleInputChange}
                    />
                    </label>
                </div>
            ))
        }
    </div>

  const renderArticleLists = () =>
        <div className='d-flex justify-content-between mt-4 text-center'>
            {
                options.map((option) => (
      <div key={option} className='mx-2'>
        <p style={{fontWeight: '600', fontSize: '24px', textTransform: 'uppercase'}}>{option}</p>

          {articlesList[option]?.map((item, index) => (
            <div key={index} className='mx-auto px-3 py-2 my-2' style={{ background: '#F1F1F1', borderRadius: '5px', minWidth: '320px' }} >
            <div className='d-flex justify-content-between mt-2'>
            <p className='text-start m-0 p-0' style={{fontWeight: '600', textTransform: 'uppercase'}}>{item.name}</p>
            <p className='text-end text-secondary m-0 p-0'>Position : {item.position}</p>
            </div>

            <div className='d-flex justify-content-between mt-3'>
            <p className='text-start m-0 p-0' style={{fontWeight: '400', textTransform: 'uppercase'}}>Standard (TTC)</p>
            <p className='text-end text-secondary m-0 p-0'>{item.from && 'à partir de '} {item.priceTTC.standard.toFixed(2)} €</p>
            </div>
            <div className='d-flex justify-content-between'>
            <p className='text-start m-0 p-0' style={{fontWeight: '400', textTransform: 'uppercase'}}>Standard+ (TTC)</p>
            <p className='text-end text-secondary m-0 p-0'>{item.from && 'à partir de '} {item.priceTTC.standardPlus.toFixed(2)} €</p>
            </div>
            <div className='d-flex justify-content-between'>
            <p className='text-start m-0 p-0' style={{fontWeight: '400', textTransform: 'uppercase'}}>Premium (TTC)</p>
            <p className='text-end text-secondary m-0 p-0'>{item.from && 'à partir de '} {item.priceTTC.premium.toFixed(2)} €</p>
            </div>

            <div className='d-flex justify-content-end'>
            <button onClick={() => handleRemoveArticle(option, index)} className='btn '><FaTrash className='text-danger'/></button>
            <button onClick={() => handleEditArticle(option, index)} className='btn'><FaEdit className='text-info'/></button>


            </div>

            </div>
          ))}
      </div>
    ))
            }
        </div>

  return (
    <div>
      <div class="form-group row mb-3">
        <label class="col-md-2 col-form-label">Nom de la grille tarifaire</label>
        <div class="col-md-4">
            <input type="text" className="form-control" placeholder="Le nom de la grille tarifaire" onChange={(e) => setNameTarif(e.target.value)} value={nameTarif}/>
        </div>
    </div>
    <div class="form-group row mb-3">
        <label class="col-md-2 col-form-label">Service</label>
        <div class="col-md-4">
        <select value={selectedOption} onChange={handleOptionChange} className="form-control">
                <option value="">--Choisir un service--</option>
                {options.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
                ))}
            </select>
        </div>
    </div>

        <div className='d-flex justify-content-start mt-4'>
            
            <div className="form-group">
                <label >Article</label>
                <input type="text" name="name" value={article.name} onChange={handleInputChange} className='form-control mt-2'/>
            </div>

            <div className="form-group mx-4">
                <label >Position</label>
                <input type="number" name="position" value={article.position} onChange={handleInputChange} className='form-control mt-2'/>
            </div>
        </div>

        <div class="form-group">
            <div class="form-check">
                <input type="checkbox" name="from" checked={article.from} onChange={handleInputChange} className="form-check-input"/>
                <label class="form-check-label" for="gridCheck">à partir de (cocher cette case uniquement si le prix n'est pas fixe)</label>
            </div>
        </div>

      {renderPriceInputs()}
      <button onClick={handleAddArticle} className='btn btn-link my-3'>
        {editingArticleIndex >= 0 ? 'Mettre à jour l\'article' : 'Ajouter un article'}
      </button>

      <button onClick={handleShow} className='btn btn-success my-3 mx-2'>Finaliser la liste</button>
      {renderArticleLists()}

      <ModalConfirmed 
        show={show}
        onHide={handleClose}
        onClick={saveToFirebase}
      />
    </div>
  );
}

