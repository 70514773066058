import React from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { formatDate, formatDateComplete } from "../../../../../../utils/DateToString";
import '../../../../pages/Customers/Components/UsersList.css'

const UnderUserCorporatesBills = ({invoices, currentPage, setCurrentPage}) => {

  const PAGE_SIZE = 5;
  const nextPage = () => {
      if ((currentPage + 1) * PAGE_SIZE < invoices.length) {
          setCurrentPage(currentPage + 1);
      }
  };
  const prevPage = () => {
      if (currentPage > 0) {
          setCurrentPage(currentPage - 1);
      }
  };
  const paginatedOrders = invoices.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE);
  return (
    <>

      <Table responsive="sm" className=' mx-auto text-start table-personnalisee '>
        <thead>
          <tr>
            <th>Date de facturation</th>
            <th>N° facture</th>
            <th className="text-center">Mantant (H.T)</th>
            <th className="text-center">Mantant (TTC)</th>
            <th className="text-center">Status</th>
          </tr>
        </thead>
        <tbody>
          {paginatedOrders.map((invoice, index) => {
            return (
              <tr key={index}>
                <td>{formatDate(invoice.date?.toDate().toISOString())}</td>
                <td>{invoice.numeroOrder}</td>
                <td className="text-center">{invoice.ht.toFixed(2)} €</td>
                <td className="text-center">{invoice.ttc.toFixed(2)} €</td>
                <td className='btn-link text-center' style={{cursor: 'pointer'}}>{invoice.status}</td>
                
                <td>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="d-flex flex-wrap justify-content-center my-5 bg-light py-2 rounded">
  <div className='flex-fill p-2 d-flex justify-content-center align-items-center'>{invoices.length} résultats</div>
  <div className='flex-fill p-2 d-flex justify-content-center align-items-center'>
  <button onClick={prevPage} disabled={currentPage === 0} style={{border: '1px solid #e0e0e0', borderRadius: '8px', fontSize: '1.2rem'}} className="btn">Précédent</button>
                <button onClick={nextPage} className="ms-3 btn" style={{border: '1px solid #e0e0e0', borderRadius: '8px', fontSize: '1.2rem'}} disabled={(currentPage + 1) * PAGE_SIZE >= invoices.length}>Suivant</button>
  </div>
            </div>
    </>
  );
};

export default UnderUserCorporatesBills;