import CorporateDataService from "../../../../context/corporates.services";
import { validateSiret } from "../../../Validators/SiretValidator";

function formatSiret(siret) {
    if (siret.length <= 3) return siret;
    if (siret.length <= 6) return siret.replace(/(\d{3})(\d+)/, '$1 $2');
    if (siret.length <= 9) return siret.replace(/(\d{3})(\d{3})(\d+)/, '$1 $2 $3');
    return siret.replace(/(\d{3})(\d{3})(\d{3})(\d+)/, '$1 $2 $3 $4');
}

export const UpdateSiretCompany = async ({ id, value, enqueueSnackbar, setIsLoading }) => {
    try {
        if(validateSiret(value)){
            setIsLoading(true);
            await CorporateDataService.updateCorporate('siretCompany', formatSiret(value), id);
            enqueueSnackbar(`Le SIRET a bien été modifié en ${formatSiret(value)}.`, {
                variant: 'success',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}
                   
            });
            return true;
        }else{
            enqueueSnackbar(`${formatSiret(value)} ne respecte pas le format requis ; il doit contenir 14 chiffres.`, {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}  
            });
            return false;
        }


    }catch(e) {
        enqueueSnackbar(`${e.message}`, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoHideDuration: 3000,
            style: {fontSize: '1.6rem'}  
        });
        setIsLoading(false);
        return false;
    }
};