import React, { createContext, useEffect, useState } from "react";
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth, db } from '../firebase-config';
import { useSnackbar } from "notistack";
import { onSnapshot, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";


export const UserContext = createContext()

export function UserContextProvider(props) {

    const [currentUser, setCurrentUser] = useState();
    const [user, setUser] = useState(null);
    const [loadingData, setLoadingData] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const signIn = (email, pwd) => signInWithEmailAndPassword(auth, email, pwd);
    const logOut = async () => {
        try {
            await signOut(auth);
            navigate('/');
        } catch {
            enqueueSnackbar(`Un problème est survenu Veuillez verifier votre connexion internet et recommencer.`, { 
                variant: 'error',
                style:{fontSize: '1.2rem'},
                anchorOrigin: { vertical: 'bottom', horizontal: 'center'},
                autoHideDuration: 3000,
               
              });
        }
    }


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
          if (currentUser) {
            try {
              const userDocRef = doc(db, "permissions", currentUser.uid);
              const unsubscribeSnapshot = onSnapshot(userDocRef, (userDocSnap) => {
                const userData = userDocSnap.data();
                    setCurrentUser(currentUser);
                    setUser(userData);
                    setLoadingData(false);
              });
              return () => unsubscribeSnapshot();

            } catch (e) {
                enqueueSnackbar(e, { 
                    variant: 'error',
                    style:{fontSize: '1.2rem'},
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center'},
                    autoHideDuration: 3000,
                   
                  });
            }
          } else {
            setCurrentUser(null);
            setUser(null);
            setLoadingData(false);
          }
        });
        return () => unsubscribe();
      }, []);


    return (
        <UserContext.Provider value={{ currentUser, signIn, logOut, user }}>
            {!loadingData && props.children}
        </UserContext.Provider>
    )
}