import React, { useState, useEffect } from 'react';
import DatePicker from 'react-multi-date-picker';
import opacity from "react-element-popper/animations/opacity";
import "react-multi-date-picker/styles/colors/teal.css";
import transition from "react-element-popper/animations/transition";

function FactureDate({ selectedDate, onDateChange }) {
  const [newDate, setNewDate] = useState(selectedDate);

  useEffect(() => {
    setNewDate(selectedDate);
  }, [selectedDate]);

  const handleDateChange = (date) => {
    setNewDate(date);
    onDateChange(date); // Update the parent component's selectedDate
  };

  return (
    <>
      <DatePicker
        animations={[opacity(), transition({ from: 35, duration: 800 })]}
        className="teal"
        value={newDate}
        onChange={handleDateChange}
        editable={false}
        format="DD/MM/YYYY"
        inputClass='datepicker'
      />
    </>
  );
}

export default FactureDate;