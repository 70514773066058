import React, { useState } from 'react';
import Titre from '../../../../components/Titre';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useEffect } from 'react';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../../../../firebase-config';
import CardCostumer from './Components/CardCostumer';
import Searchbar from '../../../../components/Searchbar';
import NavbarVertical from '../../../../components/NavbarVertical';
import UsersList from './Components/UsersList';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const items = [
  "Tous",
  "Meilleurs clients",
  "Clients novices",
  "Clients inactifs"
];

export default function CustomersPage() {

  const [users, setUsers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);  
  const [currentPage, setCurrentPage] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentList, setCurrentList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isUsersLoading, setIsUsersLoading] = useState(true);
const [isOrdersLoading, setIsOrdersLoading] = useState(true);





  const handleSearch = (term) => {
    setSearchTerm(term);
    if(term) {
        const lowercasedSearch = term.toLowerCase();
        const filtered = currentList.filter(user => 
          user.tel?.toLowerCase().includes(lowercasedSearch)
        );
        setFilteredUsers(filtered);
        setCurrentPage(0);
    } else {
      setFilteredUsers(currentList);
    }
}

const filterCostumerInactifs = (users) => {
  return users.filter(user => {    
      return (user.nbCommandes < 1 || !user.nbCommandes || user.nbCommandes === undefined);
  });
}
const filterCostumersNovices = (users) => {
  return users.filter(user => {    
      return (user.nbCommandes === 1);
  });
}
const filterCostumersBest = (users) => {
  return users.sort((a, b) => {
    const aValue = a.nbCommandes || 0; // Si a.nbCommandes est indéfini, utilisez 0
    const bValue = b.nbCommandes || 0; // Si b.nbCommandes est indéfini, utilisez 0
    return bValue - aValue;
  });
}

const filterCostumersDesc = (users) => {
  return users.sort((a, b) => {
    const aValue = a.date_inscription;
    const bValue = b.date_inscription;
    return bValue - aValue;
  });
}

const changeData = (index) => {
  let newList = [];
  switch (index) {
      case 0:
        newList = filterCostumersDesc(users);
          break;
      case 1:
        newList = filterCostumersBest(users);
          break;
      case 2:
        newList = filterCostumersNovices(users)
          break;
      case 3:
        newList = filterCostumerInactifs(users)
          break;
      default:
        newList = [...users];
          break;
  }
  setCurrentList(newList);
  // Si un terme de recherche est défini, filtrez la liste en conséquence
  if (searchTerm) {
    const lowercasedSearch = searchTerm.toLowerCase();
    newList = newList.filter(user => 
        user.tel?.toLowerCase().includes(lowercasedSearch)
    );
}

setFilteredUsers(newList);
setCurrentPage(0);

}


  useEffect(() => {
    const q = query(collection(db, "Users"), orderBy("date_inscription", "desc"))  ;
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const users = [];
        querySnapshot.forEach((doc) => {
          users.push(doc.data());
    });
    setUsers(users);
    let newList = [];
    newList = [...users];
    setFilteredUsers(newList);
    setCurrentList(newList);
    setCurrentPage(0);
    setIsUsersLoading(false); // Mettez fin au chargement des utilisateurs
    });

    return () => unsubscribe(); 
  }, []);

  useEffect(() => {
    const q = query(collection(db, "orders"), orderBy("date_creation", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const orders = [];
        querySnapshot.forEach((doc) => {
          orders.push(doc.data());
    });
    setOrders(orders);
    setIsOrdersLoading(false); // Mettez fin au chargement des commandes
    });

    return () => unsubscribe(); 
  }, []);

  useEffect(() => {
    if (!isUsersLoading && !isOrdersLoading) {
        setIsLoading(false);
    }
}, [isUsersLoading, isOrdersLoading]);

    return (
      <div>
       {
        isLoading ? (
          <p><Skeleton /></p>
        ) : (
            <>
            
            <Titre>Clients</Titre><span style={{color:'#008080', cursor:'pointer'}} className='btn-link'><AiOutlinePlusCircle className='ms-5 me-2' size={14} color='#008080'/>Ajouter un client</span>
            </>
        )
      }
      <CardCostumer users={users} isUsersLoading={isUsersLoading}/>
      {
        isLoading ? (
          <>
          <p><Skeleton count={2}/></p>
          </>
        ) : (
            <>
            
            <Searchbar placeholder='Rechercher...' onSearch={handleSearch}/>
            <NavbarVertical items={items} changeData={changeData} setActiveIndex={setActiveIndex} activeIndex={activeIndex}/>
            </>
        )
      }
      <UsersList users={filteredUsers} orders={orders} currentPage={currentPage} setCurrentPage={setCurrentPage} isLoading={isLoading}/> 
       
      </div>

    );
}