import React, { useState } from 'react'
import { IoChevronBackCircleOutline } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';
import CommandeDataService from '../../../../context/commande.services'
import { formatDateComplete, formatDate } from '../../../../utils/DateToString'
import { useEffect } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useSnackbar } from 'notistack';

export default function OrderDetailPages() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [order, setOrder] = useState({});
    const [customer, setCustomer] = useState({});
    const [steps, setSteps] = useState(0);
    const [etat, setEtat] = useState("");
    const { enqueueSnackbar } = useSnackbar();

    const getOrder = async () => {
        try {
          const docSnap = await  CommandeDataService.getCommande(id);
          setOrder(docSnap.data());
          const docSnapCustomer = await CommandeDataService.getUser(docSnap.data().idClient)
          const docSnapCustomerAdress = await CommandeDataService.getUserAdress(docSnap.data().idClient, docSnap.data().adresse)
          setCustomer({
            ...docSnapCustomer.data(),
            informationCompl: docSnapCustomerAdress.data().informationCompl
        });
        setEtat(docSnap.data().etat)
        setSteps(docSnap.data().steps)
        } catch (err) {
          console.log(err.message)
        }
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          if (id !== undefined && id !== "") {
            await  CommandeDataService.majSteps(steps, id);
            enqueueSnackbar(`L'étape à bien été changer`, {
                variant: 'success',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}
               
              });
          }
        } catch (err) {}
      };
      const handleSubmitEtat = async (e) => {
        e.preventDefault();
        try {
          if (id !== undefined && id !== "") {
            await  CommandeDataService.majEtape(etat, id);
            enqueueSnackbar(`L'état à bien été changer`, {
                variant: 'success',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}
               
              });
          }
        } catch (err) {}
      };
      useEffect(() => {
        getOrder();

      }, []);
  return (
    <div className='view_page'>
      <IoChevronBackCircleOutline size={40} className='my-5 mx-5' color='#008080' style={{cursor: 'pointer'}} onClick={() => navigate(`/private/Orders`)}/>
      {order ? <>
        <div className="container">
            <div className="p-3 mb-2 bg-info text-white">
                <p>Date creation commande : {formatDateComplete(order.date_creation?.toDate().toISOString())}</p>
            </div>
            <div className="p-3 mb-2 bg-secondary text-white">
                <div className="row">
                <div className="col-sm">
                    <p>Etat : {order.etat}</p>
                </div>
                <div className="col-sm">
                    <p>Code promo : {order.codePromo}</p>
                </div>
                <div className="col-sm">
                    <p>Tel : {order.tel}</p>
                </div>
            </div>
            {customer ? <>
                <div className="row">
                <div className="col-sm">
                    <p>Nom : {customer.nom} {customer.prenom}</p>
                </div>
                <div className="col-sm">
                    <p>mail : {customer.mail}</p>
                </div>
                <div className="col-sm">
                    <p>Complément d'adresse : {customer.informationCompl}</p>
                </div>
            </div>
            </> :  <p>ERROR</p>}

            <p>Adresse : {order.adresse}</p>
            <p>Collecte : {formatDate(order.dateCollecte?.toDate().toISOString())} | {order.creneauCollecte}</p>
            <p>Livraison : {formatDate(order.dateLivraison?.toDate().toISOString())} | {order.creneauLivraison}</p>
            <p>Commentaire : {order.commentaire}</p>
            <Form onSubmit={handleSubmit} className="mb-3">
          <Form.Group className="mb-3" controlId="formBookTitle">
            <InputGroup>
              <InputGroup.Text id="formBookTitle">Etape</InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Etape"
                value={steps}
                onChange={(e) => setSteps(e.target.value)}
              />
            </InputGroup>
          </Form.Group>
          <div className="d-grid gap-2">
            <Button variant="primary" type="Submit">
              Modifier
            </Button>
          </div>
            </Form>
            <Form onSubmit={handleSubmitEtat} className="mb-3">
              <Form.Group className="mb-3" controlId="formEtat">
                <InputGroup>
                  <InputGroup.Text id="formEtat">Etat</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Etat"
                    value={etat}
                    onChange={(e) => setEtat(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
              <div className="d-grid gap-2">
                <Button variant="primary" type="Submit">
                  Modifier
                </Button>
              </div>
            </Form>
            <div className="row">
                <div className="col-sm">
                <p>N° Commande : {order.idFacture}</p>
                </div>
                <div className="col-sm">
                    <p>Service : {order.service}</p>
                </div>
                <div className="col-sm">
                    <p>Etape : {order.steps}</p>
                </div>
            </div>
            </div>
        </div>
      </> : <>ERROR</>}

    </div>
  )
}
