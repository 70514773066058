import React, { useState } from 'react';
import { Button, Container, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import InputIcon from "react-multi-date-picker/components/input_icon";
import { Timestamp } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import CommandeDataService from '../../../../context/commande.services'


// const initialClients = [
//   { id: 1, first_name: 'Client 1', last_name: 'last_name1', email: "email1",  date_create: '1', id_company: 'id_company1'},
//   { id: 2, first_name: 'Client 2', last_name: 'last_name2', email: "email2",  date_create: '2', id_company: 'id_company2'},
//   { id: 3, first_name: 'Client 3', last_name: 'last_nam3', email: "email3",  date_create: '3', id_company: 'id_company3'},
// ];

const initialOptions = [
  { id: 1, name: 'Pressing' },
  { id: 2, name: 'Linge au kilo' },
  { id: 3, name: 'Retouche' },
  { id: 4, name: 'Cordonnerie' }
];

function AddOrder({initialClients, showModalAddOrder, setShowModalAddOrder, weekDays, months, idB2B, corporate, getOrders}) {

    const { enqueueSnackbar } = useSnackbar();
    const [optionsUnique, setOptionsUnique] = useState([
        { id: 1, label: "Pressing", checked: false, articles: [] },
        { id: 2, label: "Linge au kilo", checked: false, articles: [] },
        { id: 3, label: "Retouche", checked: false, articles: [] },
        { id: 4, label: "Cordonnerie", checked: false, articles: [] }
    ]);
    const handleOptionChangeUnique = (index) => {
        setOptionsUnique((prevState) => {
          const updatedOptions = [...prevState];
          updatedOptions[index].checked = !prevState[index].checked;
          return updatedOptions;
        });
    };
    const handleArticleChangeUnique = (optionIndex, articleIndex, field, value) => {
        setOptionsUnique((prevState) => {
          const updatedOptions = [...prevState];
          const updatedArticle = { ...prevState[optionIndex].articles[articleIndex], [field]: value, total: 0 };
          updatedOptions[optionIndex].articles[articleIndex] = updatedArticle;
          updatedOptions[optionIndex].articles[articleIndex].total = updatedOptions[optionIndex].articles[articleIndex].price * updatedOptions[optionIndex].articles[articleIndex].total_article;
          return updatedOptions;
        });
    };
    const handleArticleDeleteUnique = (optionIndex, articleIndex) => {
        setOptionsUnique((prevState) => {
          const updatedOptions = [...prevState];
          updatedOptions[optionIndex].articles.splice(articleIndex, 1);
          return updatedOptions;
        });
    };
    const renderArticlesUnique = (optionIndex) => {
        const articles = optionsUnique[optionIndex].articles;
        return articles.map((article, index) => (
          <div key={index}>
            <div className="d-flex flex-wrap justify-content-between my-2 border-top border-bottom">
                <h6 className='pt-2'>Article {index + 1}</h6>
                <button className='btn btn-danger my-1' onClick={() => handleArticleDeleteUnique(optionIndex, index)}>Supprimer</button>
            </div>
                      <h5></h5>
            <div className='row'>
                <div className='col mx-1'>
                    <label htmlFor={`name-${index}`} className='mb-1'>Article</label>
                    <input type="text" className="form-control" id={`name-${index}`}
                    value={article.article}
                    onChange={(e) =>
                    handleArticleChangeUnique(optionIndex, index, "article", e.target.value)
                    } />
                </div>
                <div className='col mx-1'>
                    <label className='mb-1' htmlFor={`price-${index}`}>Prix</label>
                    <input className="form-control" type="number"
                        id={`price-${index}`}
                        value={article.price}
                        onChange={(e) =>
                            handleArticleChangeUnique(optionIndex, index, "price", Number(e.target.value))
                        } 
                    />
                </div>
                <div className='col mx-1'>
                    <label className='mb-1' htmlFor={`quantity-${index}`}>Qte</label>
                    <input className="form-control" type="number"
                        id={`quantity-${index}`}
                        value={article.total_article}
                        onChange={(e) =>
                        handleArticleChangeUnique(optionIndex, index, "total_article", Number(e.target.value))
                        } 
                    />
                </div>
                <div className='col mx-1'>
                    <label className='mb-1'>Total TTC</label>
                    <input type="text" className="form-control" id="inputCity" disabled value={isNaN(article.total_article*article.price) ? 0 : article.total_article*article.price}/>
                </div>
            </div>
          </div>
        ));
    };
    let totalIci = 0;
    optionsUnique.forEach((article) => {
      article.articles.forEach((item) => {
        totalIci += item.total;
      })
    });
    const renderOptionsUnique = () => {

        return optionsUnique.map((option, index) => (
          <Container key={index} style={{width: '100%'}}>
          <div className='col'>
          <input
              type="checkbox"
              className='mx-2'
              checked={option.checked}
              onChange={() => handleOptionChangeUnique(index)}
            />
            <span>{option.label}</span>
          </div>
          
            {option.checked && (
              <div className='col'>
                <p className='text-secondary mx-2' style={{fontSize: '14px'}}>Ajouter des articles pour {option.label}</p>
                {renderArticlesUnique(index)}
                <button className='btn btn-light my-2' style={{color: '#008080'}} onClick={() => handleArticleChangeUnique(index, option.articles.length, "article", "")}>
                Ajouter un nouvel article
                </button>
              </div>
            )}
          </Container>
        ));
    };

  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [showSelectedClients, setShowSelectedClients] = useState(false);
  const [etat, setEtat] = useState(1);
  const [datesOrder, setDatesOrder] = useState([new Date()]);

  const generate = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
      .toUpperCase();
  }

  const handleClientChange = (event) => {
    const clientId = event.target.value;
    if (clientId) {
      const client = initialClients.find((c) => c.id === clientId);
      setSelectedClients([...selectedClients, client]);
    }
  };

  const handleRemoveClient = (clientToRemove) => {
    setSelectedClients(selectedClients.filter((client) => client.id !== clientToRemove.id));
    setSelectedOptions((prev) => {
      const newSelectedOptions = { ...prev };
      Object.keys(prev).forEach((key) => {
        if (key.startsWith(`${clientToRemove.id}-`)) {
          delete newSelectedOptions[key];
        }
      });
      return newSelectedOptions;
    });
  };

  const handleOptionChange = (event, clientId) => {
    const optionId = parseInt(event.target.value);
    const optionSelected = event.target.checked;

    setSelectedOptions((prev) => {
      const newSelectedOptions = { ...prev };
      newSelectedOptions[`${clientId}-${optionId}`] = {
        selected: optionSelected,
        articles: [],
      };
      return newSelectedOptions;
    });
  };

  const handleAddArticle = (clientId, optionId) => {
    setSelectedOptions((prev) => {
      const newSelectedOptions = { ...prev };
      newSelectedOptions[`${clientId}-${optionId}`].articles.push({
        article: '',
        price: 0,
        total_article: 1,
        total: 0

      });
      return newSelectedOptions;
    });
  };

  const handleRemoveArticle = (clientId, optionId, index) => {
    setSelectedOptions((prev) => {
      const newSelectedOptions = { ...prev };
      newSelectedOptions[`${clientId}-${optionId}`].articles.splice(index, 1);
      return newSelectedOptions;
    });
  };

  const handleArticleNameChange = (event, clientId, optionId, index) => {
    setSelectedOptions((prev) => {
      const newSelectedOptions = { ...prev };
      newSelectedOptions[`${clientId}-${optionId}`].articles[index].article =
        event.target.value;
      return newSelectedOptions;
    });
  };

  const handleQuantityChange = (event, clientId, optionId, index) => {
    setSelectedOptions((prev) => {
      const newSelectedOptions = { ...prev };
      newSelectedOptions[`${clientId}-${optionId}`].articles[index].total_article =
        parseInt(event.target.value);
        newSelectedOptions[`${clientId}-${optionId}`].articles[index].total =
        newSelectedOptions[`${clientId}-${optionId}`].articles[index].total_article * newSelectedOptions[`${clientId}-${optionId}`].articles[index].price;
      return newSelectedOptions;
    });
  };

  const handlePriceChange = (event, clientId, optionId, index) => {
    setSelectedOptions((prev) => {
      const newSelectedOptions = { ...prev };
      newSelectedOptions[`${clientId}-${optionId}`].articles[index].price =
        parseFloat(event.target.value);
        newSelectedOptions[`${clientId}-${optionId}`].articles[index].total = newSelectedOptions[`${clientId}-${optionId}`].articles[index].total_article * newSelectedOptions[`${clientId}-${optionId}`].articles[index].price;
      return newSelectedOptions;
    });
  };
  const hasRecap = (clients) => {
    return clients.every(client => {
        const recapValues = client.recap_presta ? Object.values(client.recap_presta) : [];
        return recapValues.some(tableau => tableau.length > 0);
      });
  }
  const hasRecapDriver = (clients) => {
    return clients.every(client => {
        const recapValues = client.recap_driver ? Object.values(client.recap_driver) : [];
        return recapValues.some(tableau => tableau.length > 0);
      });
  }
  const handleReturnSelectedClients = async () => {
    const result = selectedClients.map((client) => {
      const selectedOptionsForClient = Object.keys(selectedOptions).filter((key) =>
        key.startsWith(`${client.id}-`)
      );
      const options = selectedOptionsForClient.map((key) => {
        const optionId = parseInt(key.split('-')[1]);
        const option = initialOptions.find((o) => o.id === optionId);
        const selectedOption = selectedOptions[key];
        return {
            articles: selectedOption.articles,
            id: optionId,
        //   name: option.name,
        //   selected: selectedOption.selected,
        //   articles: selectedOption.articles,
        };
      });
      let toalFinal = 0;
      options.forEach((article) => {
        article.articles.forEach((item) => {
            toalFinal += item.total;
        })
      });

      return {
        id_facture: "",
        id_userUnder: client.id,
        name: `${client.first_name} ${client.last_name}`,
        recap_driver: {
            Cordonnerie: options.filter((obj) => obj.id === 4)[0] ? options.filter((obj) => obj.id === 4)[0].articles.filter((article) => article.article.trim() !== "" && article.price >=0 && article.total_article > 0) : [],
            Pressing: options.filter((obj) => obj.id === 1)[0] ? options.filter((obj) => obj.id === 1)[0].articles.filter((article) => article.article.trim() !== "" && article.price >=0 && article.total_article > 0) : [],
            Retouche: options.filter((obj) => obj.id === 3)[0] ? options.filter((obj) => obj.id === 3)[0].articles.filter((article) => article.article.trim() !== "" && article.price >=0 && article.total_article > 0) : [],
            lk: options.filter((obj) => obj.id === 2)[0] ? options.filter((obj) => obj.id === 2)[0].articles.filter((article) => article.article.trim() !== "" && article.price >=0 && article.total_article > 0) : [],
        },
        recap_presta: {
            Cordonnerie: options.filter((obj) => obj.id === 4)[0] ? options.filter((obj) => obj.id === 4)[0].articles.filter((article) => article.article.trim() !== "" && article.price >=0 && article.total_article > 0) : [],
            Pressing: options.filter((obj) => obj.id === 1)[0] ? options.filter((obj) => obj.id === 1)[0].articles.filter((article) => article.article.trim() !== "" && article.price >=0 && article.total_article > 0) : [],
            Retouche: options.filter((obj) => obj.id === 3)[0] ? options.filter((obj) => obj.id === 3)[0].articles.filter((article) => article.article.trim() !== "" && article.price >=0 && article.total_article > 0) : [],
            lk: options.filter((obj) => obj.id === 2)[0] ? options.filter((obj) => obj.id === 2)[0].articles.filter((article) => article.article.trim() !== "" && article.price >=0 && article.total_article > 0) : [],
        },
        total: toalFinal,
      };
    });
    const result2 = optionsUnique.filter((option) => option.checked && option.articles.length > 0).map((option) => {
        // const articles = option.articles.filter((article) => article.name.trim() !== "" && article.price !== "" && article.quantity !== "");
        return { 
          // option: option.id,
          // articles: articles,
          recap_driver: {
            Cordonnerie: optionsUnique.filter((obj) => obj.id === 4)[0] ? optionsUnique.filter((obj) => obj.id === 4)[0].articles.filter((article) => article.article.trim() !== "" && article.price >=0 && article.total_article > 0) : [],
            Pressing: optionsUnique.filter((obj) => obj.id === 1)[0] ? optionsUnique.filter((obj) => obj.id === 1)[0].articles.filter((article) => article.article.trim() !== "" && article.price >=0 && article.total_article > 0) : [],
            Retouche: optionsUnique.filter((obj) => obj.id === 3)[0] ? optionsUnique.filter((obj) => obj.id === 3)[0].articles.filter((article) => article.article.trim() !== "" && article.price >=0 && article.total_article > 0) : [],
            lk: optionsUnique.filter((obj) => obj.id === 2)[0] ? optionsUnique.filter((obj) => obj.id === 2)[0].articles.filter((article) => article.article.trim() !== "" && article.price >=0 && article.total_article > 0) : [],
        },
        recap_presta: {
            Cordonnerie: optionsUnique.filter((obj) => obj.id === 4)[0] ? optionsUnique.filter((obj) => obj.id === 4)[0].articles.filter((article) => article.article.trim() !== "" && article.price >=0 && article.total_article > 0) : [],
            Pressing: optionsUnique.filter((obj) => obj.id === 1)[0] ? optionsUnique.filter((obj) => obj.id === 1)[0].articles.filter((article) => article.article.trim() !== "" && article.price >=0 && article.total_article > 0) : [],
            Retouche: optionsUnique.filter((obj) => obj.id === 3)[0] ? optionsUnique.filter((obj) => obj.id === 3)[0].articles.filter((article) => article.article.trim() !== "" && article.price >=0 && article.total_article > 0) : [],
            lk: optionsUnique.filter((obj) => obj.id === 2)[0] ? optionsUnique.filter((obj) => obj.id === 2)[0].articles.filter((article) => article.article.trim() !== "" && article.price >=0 && article.total_article > 0) : [],
        },
        };
      });
      let toalFinalUnique = 0;
      optionsUnique.forEach((article) => {
        article.articles.forEach((item) => {
            toalFinalUnique += item.total;
        })
      });
    const newOrder = {
        date_collecte: Timestamp.fromDate(new Date(datesOrder[0])),
        date_creation: Timestamp.fromDate(new Date()),
        date_livraison: Timestamp.fromDate(new Date(datesOrder[1])),
        number_order: 'CP-'+generate(),
        etat_commande: etat,
        facture_id: '',
        recap_driver: corporate.underAccount ? {
            Cordonnerie: [],
            Pressing: [],
            Retouche: [],
            lk: [],
        } : result2.length > 0 ? result2[0].recap_driver : {
            Cordonnerie: [],
            Pressing: [],
            Retouche: [],
            lk: [],
        },
        recap_presta: corporate.underAccount ? {
            Cordonnerie: [],
            Pressing: [],
            Retouche: [],
            lk: [],
        } : result2.length > 0 ? result2[0].recap_presta : {
            Cordonnerie: [],
            Pressing: [],
            Retouche: [],
            lk: [],
        },
        total_global: corporate.underAccount ? 0 : toalFinalUnique,
        under_user: result,
        user: idB2B,
      };
      
      if(corporate.underAccount){
        if(newOrder.under_user.length > 0){
            const hasRecapDataPresta = hasRecap(newOrder.under_user);
            const hasRecapDataDriver = hasRecapDriver(newOrder.under_user);
            if(hasRecapDataPresta && hasRecapDataDriver){
                await CommandeDataService.postOrder(newOrder);
                enqueueSnackbar('La commande a bien été ajoutée.', { variant: 'success' });
                setSelectedClients([]);
                setSelectedOptions({});
                setOptionsUnique([
                    { id: 1, label: "Pressing", checked: false, articles: [] },
                    { id: 2, label: "Linge au kilo", checked: false, articles: [] },
                    { id: 3, label: "Retouche", checked: false, articles: [] },
                    { id: 4, label: "Cordonnerie", checked: false, articles: [] }
                ]);
                getOrders();
                setShowModalAddOrder(false);
            }else{
                enqueueSnackbar('Veuillez selectionner au moins un article pour chaque client séléctionner.', { variant: 'error'});
            }
        }else{
            enqueueSnackbar('Veuillez selectionner un client.', { variant: 'error' });
        }
      }else{
        if(newOrder.recap_driver.Cordonnerie.length > 0 || newOrder.recap_driver.Pressing.length > 0 || newOrder.recap_driver.Retouche.length > 0 || newOrder.recap_driver.lk.length > 0 || newOrder.recap_presta.Cordonnerie.length > 0 || newOrder.recap_presta.Pressing.length > 0 || newOrder.recap_presta.Retouche.length > 0 || newOrder.recap_presta.lk.length > 0){
            await CommandeDataService.postOrder(newOrder);
            enqueueSnackbar('La commande a bien été ajoutée.', { variant: 'success' });
            setSelectedClients([]);
            setSelectedOptions({});
            setOptionsUnique([
                { id: 1, label: "Pressing", checked: false, articles: [] },
                { id: 2, label: "Linge au kilo", checked: false, articles: [] },
                { id: 3, label: "Retouche", checked: false, articles: [] },
                { id: 4, label: "Cordonnerie", checked: false, articles: [] }
            ]);
            getOrders();
            setShowModalAddOrder(false);
            
        }else{
            enqueueSnackbar('choisir une option et Ajouter au moins un article.', { variant: 'error' });
        }

      }
  };
  const handleOptionChangeEtat = (event) => {
    setEtat(Number(event.target.value));
  }


  return (
    <Modal
    show={showModalAddOrder}
    onHide={() => {
        setShowModalAddOrder(false);
        setSelectedClients([]);
        setSelectedOptions({});
        setOptionsUnique([
                { id: 1, label: "Pressing", checked: false, articles: [] },
                { id: 2, label: "Linge au kilo", checked: false, articles: [] },
                { id: 3, label: "Retouche", checked: false, articles: [] },
                { id: 4, label: "Cordonnerie", checked: false, articles: [] }
            ]);}}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
  <Modal.Body>
    <Container>
        <div className="d-flex flex-wrap justify-content-start">
            <label className="my-2 p-1 mx-1" style={{fontWeight: 'bold'}}>Etat de la commande : </label>
            <select value={etat} onChange={handleOptionChangeEtat} className="my-2 mx-1 p-1">
                <option value={1}>Commande en cours</option>
                <option value={2}>Commande récupérée</option>
                <option value={3}>Commande livrée</option>
                <option value={4}>Commande annulée</option>
            </select>
        </div>

        <div className="d-flex flex-wrap justify-content-start">
            <label className="my-2 p-1 mx-1" style={{fontWeight: 'bold'}}>Date collecte / Livraison : </label>
            <div className="my-2 mx-1 p-1">
                <DatePicker
                value={datesOrder}
                onChange={setDatesOrder}
                format="DD MMMM YYYY"
                weekDays={weekDays}
                months={months}
                sort
                plugins={[
                    <DatePanel />
                ]}
                render={<InputIcon/>}
                />
            </div>
        </div>
        {/* <ClientSelection /> */}
        {corporate.underAccount ? 
        <div>
            <div className="d-flex flex-wrap justify-content-start">
                <label className="my-2 p-1 mx-1" style={{fontWeight: 'bold'}} htmlFor="clients">Selectionner un client : </label>
                <select name="clients" id="clients" onChange={handleClientChange} className="my-2 mx-1 p-1">
                    <option value="">-- Séléctionner un client --</option>
                    {initialClients.map((client) => {
                        if (!selectedClients.find((c) => c.id === client.id)) {
                            return (
                                <option key={client.id} value={client.id}>
                                    {client.last_name} {client.first_name}
                                </option>
                            );
                        }
                        return null;
                    })}
                </select>
            </div>
            <div>
            <h6 className='border-top border-bottom py-2 my-2'>Clients séléctionner :</h6>
            {selectedClients.map((client) => (
            <div key={client.id}>
                <div className="d-flex flex-wrap justify-content-between border-top border-bottom my-2 py-1">
                    <p className='mt-2' style={{fontWeight: 'bold'}}>{client.last_name} {client.first_name}</p>
                    <button onClick={() => handleRemoveClient(client)} className='btn btn-danger my-2'>supprimer {client.last_name} {client.first_name}</button>
                </div>
                
                {initialOptions.map((option) => (
                <div key={option.id}>
                    <input
                    type="checkbox"
                    className='mx-2'
                    id={`${client.id}-${option.id}`}
                    name={`${client.id}-${option.id}`}
                    value={option.id}
                    onChange={(event) => handleOptionChange(event, client.id)}
                    />
                    <label htmlFor={`${client.id}-${option.id}`}>{option.name}</label>
                    {selectedOptions[`${client.id}-${option.id}`]?.selected && (
                    <div>
                        <button
                        className='btn btn-light m-2'
                        style={{color: '#008080'}}
                        onClick={() => handleAddArticle(client.id, option.id)}
                        >
                        Ajouter un article
                        </button>
                        <ul>
                        {selectedOptions[`${client.id}-${option.id}`].articles.map((article, index) => (
                            <li key={index}>
                            <div className='row'>
                                <div className='col form-group mx-1'>
                                    <label>Article</label>
                                    <input
                                        className='form-control'
                                        type="text"
                                        value={article.articleName}
                                        onChange={(event) =>
                                            handleArticleNameChange(event, client.id, option.id, index)
                                        }
                                    />
                                </div>
                                <div className='col form-group form-group mx-1'>
                                    <label>Prix</label>
                                    <input
                                        className='form-control'
                                        type="number"
                                        value={article.price}
                                        onChange={(event) => handlePriceChange(event, client.id, option.id, index)}
                                    />
                                </div>
                                <div className='col form-group form-group mx-1'>
                                    <label>Qte</label>
                                    <input
                                        className='form-control'
                                        type="number"
                                        value={article.quantity}
                                        onChange={(event) =>
                                        handleQuantityChange(event, client.id, option.id, index)
                                        }
                                    />
                                </div>
                                <div className='col form-group form-group mx-1'>
                                    <label>Total TTC</label>
                                    <input
                                        disabled
                                        className='form-control'
                                        type="number"
                                        step="0.01"
                                        value={(article.price * article.total_article).toFixed(2)}
                                    />
                                </div>
                                <div className='col form-group form-group mx-1'>
                                    <button
                                    className='btn btn-danger mt-4'
                                        onClick={() => handleRemoveArticle(client.id, option.id, index)}
                                    >
                                        Supprimer
                                    </button>
                                </div>
            
                            </div>


                            

                            </li>
                        ))}
                        </ul>
                        
                    </div>
                    )}
                    
                </div>
                ))}

                <div className='bg-warning my-3' style={{height: '3px'}}></div>
            </div>
            ))}
            {showSelectedClients && (
            <div>
            <h3>Selected clients with options:</h3>
                <ul>
                {selectedClients.map((client) => (
                    <li key={client.id}>
                    <h4>{client.name}</h4>
                    <ul>
                        {client.options.map((option) => (
                        <li key={option.id}>
                            <p>{option.name}</p>
                            {option.selected && (
                            <div>
                                <ul>
                                {option.articles.map((article, index) => (
                                    <li key={index}>
                                    <p>{article.articleName}</p>
                                    <p>{article.quantity}</p>
                                    <p>{article.price}</p>
                                    </li>
                                ))}
                                </ul>
                            </div>
                            )}
                        </li>
                        ))}
                    </ul>
                    </li>
                ))}
                </ul>
            </div>
            )}
        </div>
        </div> : 
        <div>
            <div className="d-flex flex-wrap justify-content-start">
                <label className="my-2 p-1 mx-1" style={{fontWeight: 'bold'}}>Services :</label>
            </div>
            <div className="d-flex flex-wrap justify-content-start">
                <div className="my-2 p-1 mx-1">{renderOptionsUnique()}</div>
            </div>
        </div>
        }
    </Container>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => {
            setShowModalAddOrder(false);
            setSelectedClients([]);
            setSelectedOptions({});
            setOptionsUnique([
                { id: 1, label: "Pressing", checked: false, articles: [] },
                { id: 2, label: "Linge au kilo", checked: false, articles: [] },
                { id: 3, label: "Retouche", checked: false, articles: [] },
                { id: 4, label: "Cordonnerie", checked: false, articles: [] }
            ]);}} className=' btn btn-secondary'>Annuler</button>
          <Button onClick={handleReturnSelectedClients}>Confirmer</Button>
          <p className='text-start' style={{fontWeight: '600'}}>{!corporate.underAccount && `TOTAL : ${isNaN(totalIci) ? (0).toFixed(2): totalIci.toFixed(2)} €` }</p>
        </Modal.Footer>
  </Modal>
  );
}

export default AddOrder;

