import { collection, getDocs, getDoc, query, where, updateDoc, deleteDoc, doc, setDoc } from "firebase/firestore";
import { db } from "../firebase-config";

class CodePromoDataService {
  addCodesPromos = (newBook) => {
    return setDoc(doc(db, "codes_promo", newBook.code), newBook);
  };

  updateCodePromo = (id, updatedBook) => {
    const bookDoc = doc(db, "codes_promo", id);
    return updateDoc(bookDoc, updatedBook);
  };

  deleteCodePromo = (id) => {
    const bookDoc = doc(db, "codes_promo", id);
    return deleteDoc(bookDoc);
  };

  getAllCodePromos = () => {
    const q = query(collection(db, "codes_promo"), where("idClient", "==", ""));
    return getDocs(q);
  };

  getCodePromo = (id) => {
    const bookDoc = doc(db, "codes_promo", id);
    return getDoc(bookDoc);
  };
}

export default new CodePromoDataService();