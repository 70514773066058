import CorporateDataService from "../../../../context/corporates.services";

export const UpdateSlotCompany = async ({ id, start, end, enqueueSnackbar, setIsLoading }) => {
    try {
        if(start === ''){
            enqueueSnackbar(`Veuillez séléctionner un créneau de début`, {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}  
            });
        } else if(end === ''){
            enqueueSnackbar(`Veuillez séléctionner un créneau de fin`, {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}  
            });
        }else {
            setIsLoading(true);
            await CorporateDataService.updateCorporate('slot_start', start, id); 
            await CorporateDataService.updateCorporate('slot_end', end, id);
            enqueueSnackbar(`Les créneaux de début et de fin de passage ont bien été modifiés en ${start} - ${end}.`, {
                variant: 'success',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}
            });
            return true;
        }
    }catch(e) {
        enqueueSnackbar(`${e.message}`, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoHideDuration: 3000,
            style: {fontSize: '1.6rem'}  
        });
        setIsLoading(false);
        return false;
    }
};