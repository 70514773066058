import CorporateDataService from "../../../../context/corporates.services";
import { validateEmail } from "../../../Validators/EmailValidator";

export const UpdateInvoiceEmail = async ({ id, value, enqueueSnackbar, setIsLoading }) => {
    try{
        if (validateEmail(value)) {
            setIsLoading(true);
            await CorporateDataService.updateCorporate('emailInvoiceCompany', value, id);
            enqueueSnackbar(`L'adresse mail ${value} a bien été modifiée.`, {
                variant: 'success',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}
               
              });
              return true;
        } else {
            enqueueSnackbar(`Adresse e-mail invalide`, {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}
               
              });
              return false;
        };
    }catch(e){
        enqueueSnackbar(`${e.message}`, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoHideDuration: 3000,
            style: {fontSize: '1.6rem'}  
        });
        setIsLoading(false);
        return false;
    }

};