import { db } from "../firebase-config";
import { collection, getDocs, query, setDoc, doc, orderBy, getDoc, where, updateDoc, addDoc, deleteDoc} from "firebase/firestore";

const corporateCollectionRef = collection(db, "corporate");
const ordersCorporateCollectionRef = collection(db, "Orders_B2B");
const uderUserCorporateCollectionRef = collection(db, "customers_corporate_underAccount");

class CorporateDataService {
  getCorporate = (id) => {
    const corporateDoc = doc(db, "corporate", id);
    return getDoc(corporateDoc);
  };

  getAllCorporates = () => {
    const q = query(corporateCollectionRef, orderBy("date_creation", "desc"));
    return getDocs(q);
  };

  getAllUnderUser = (id) => {
    const q = query(uderUserCorporateCollectionRef, where("id_company", "==", id), orderBy("date_create", "desc"));
    return getDocs(q);
  };
  updateCorporate = (name, value, idDoc) => {
    return updateDoc(doc(corporateCollectionRef, idDoc), {
      [name] : value
    });
  };

  updateOrderCorporate = (name, value, idDoc) => {
    return updateDoc(doc(ordersCorporateCollectionRef, idDoc), {
      [name] : value
    });
  };














  
  geOrderCorporate = (id) => {
    const userDoc = doc(ordersCorporateCollectionRef, id);
    return getDoc(userDoc);
  };











  // getAllOrders = (id) => {
  //   const q = query(ordersCorporateCollectionRef, where("user", "==", id), orderBy("date_livraison", "desc"));
  //   return getDocs(q);
  // };



  updateStepOrder = (step, idDoc) => {
    return updateDoc(doc(db, "corporate_order", idDoc), {etat_commande: step});
  };
  DeleteOrder = (idDoc) => {
    return deleteDoc(doc(db, "corporate_order", idDoc));
  };


  addCorporateAccount = (data, docId) => {
    setDoc(doc(corporateCollectionRef, docId), data);
  }
  getCorporateAccount = (id) => {
    const getCorporate = doc(corporateCollectionRef, id);
    return getDoc(getCorporate);
  };



  addUnderUser = (data) => {
    return addDoc(uderUserCorporateCollectionRef, data);
  }


//   getUserAdress = (id, adressId) => {
//     const userDoc = doc(db, "Users", id, "adresses", adressId);
//     return getDoc(userDoc);
//   };




//   majSteps = (newBook, idDoc) => {
//     return updateDoc(doc(db, "orders", idDoc), {steps: parseInt(newBook, 10)});
//   };


}

export default new CorporateDataService();