import React from 'react'
import { IoChevronBackCircleOutline } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom';

export default function ModalDetailB2B() {

    const navigate = useNavigate();
    const { id } = useParams();
  return (
    <div className="view_page">
        <IoChevronBackCircleOutline size={40} className='my-5 mx-5' color='#008080' style={{cursor: 'pointer'}} onClick={() => navigate(-1)}/>
      B2B
    </div>
  )
}
