import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { Container, Dropdown, DropdownButton } from 'react-bootstrap'
import { FaPlus } from "react-icons/fa"
import AddTarif from './AddTarif';
import ProduitsList from './ProduitsList';


export default function Produits({tarifs}) {
    const [selectedTarif, setSelectedTarif] = useState(null);
    const [showAddTarif, setShowAddTarif] = useState(false);

    const handleSelect = (item) => {
        setSelectedTarif(selectedTarif => item);
    };

    useEffect(() => {
        if(!selectedTarif){
            setSelectedTarif(selectedTarif => tarifs?.[0]?.id);
        }

  }, [tarifs]);

  return (
    <Container>
    {/* Choix de tarif */}
        <div className="d-flex align-items-start align-items-md-center flex-md-row flex-column mb-3">
            <p className='pt-3' style={{ fontWeight: '600' }}>Selectionner un Tarif </p>
            <DropdownButton
                title={selectedTarif ? selectedTarif : 'Sélectionner un tarif'}
                onSelect={handleSelect}
                id='dropdown-button-drop-down-centered'
                drop='down-centered'
                className='custom-dropdown mx-md-3'
          >
          <Dropdown.Item onClick={() => setShowAddTarif(showAddTarif => true)} eventKey={null}>
            <FaPlus size={12} color='#008080'/><span className='mx-1' style={{color: '#008080'}}>Ajouter un compte</span>
          </Dropdown.Item>
          <Dropdown.Divider />
            {tarifs?.map((item, index) => (
                <Dropdown.Item 
                    key={index}
                    eventKey={item.id}
                    onClick={() => setShowAddTarif(showAddTarif => false)}
                    disabled={item.id === 'global'} style={item.id === 'global' ? {background: '#F1F1F1', color: 'grey'} : {}}>
                  {item.id}
                </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
    {/* PARTIE II : Liste des articles de tarif / l'ajout d'une liste de tarif */}
    {
        showAddTarif ?
        (
            <AddTarif />
        ) :
        (
            <ProduitsList 
                selectedTarif={selectedTarif}
                tarifs={tarifs}
            />
        )
    }
    </Container>
  )
}
