import React, { useEffect, useState } from 'react'
import DatePicker from 'react-multi-date-picker';
import "react-multi-date-picker/styles/colors/teal.css";
import { Button, Col, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../../../../../firebase-config';
import { BsChevronDown } from 'react-icons/bs';
import { useSnackbar } from 'notistack';
import CommandeDataService from '../../../../../context/commande.services'
import { SpinnerDiamond, SpinnerDotted } from 'spinners-react';
import Titre from '../../../../../components/Titre';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { formatDate } from '../../../../../utils/DateToString';
import ConfirmedTours from './ConfirmedToursP';

 // Réinitialise l'heure de l'objet Date à minuit pour aujourd'hui
 const today = new Date();
 today.setHours(0, 0, 0, 0); // Réinitialise heure, minutes, secondes, et millisecondes à 0

export default function TourPage() {

  const { enqueueSnackbar } = useSnackbar();
  const [startDate, setStartDate] = useState(new Date());
  const [typeTour, setTypeTour] = useState('');
  const [evening, setEvening] = useState(false);
  const [hubs, setHubs] = useState([]);
  const [selectedHub, setSelectedHub] = useState('');
  const [showTour, setShowTour] = useState(false);
  const [tours, setTours] = useState(null);

  useEffect(() => {
    const q = query(collection(db, "Hubs"), orderBy("created_at", "desc"))  ;
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const hubs = [];
        querySnapshot.forEach((doc) => {
          hubs.push({...doc.data(), id_hub: doc.id});
    });
    setHubs(hubs);
    });

    return () => unsubscribe(); 
  }, []);
const handleSelectChange = (event) => {
  setSelectedHub(event.target.value);
};



  const handleDateChange = (date) => {
    setStartDate(date);
  };

  // Fonction pour formater la date sélectionnée pour l'affichage
  const formatDateForDisplay = (date) => {
    // Vérifier si la date est une instance de Date et utiliser toISOString
    if (date instanceof Date) {
      return date.toISOString();
    }
    // Si date est un objet géré par DatePicker, vous pourriez avoir besoin d'accéder à ses valeurs différemment
    // Cela dépend de la structure de l'objet date que le DatePicker retourne
    // Exemple: return `${date.year}-${date.month}-${date.day}`
    // Ou si la date est déjà au format souhaité pour l'affichage, retournez-la directement
    return date.toString();
  };
  const handleChange = (e) => {
    const chose = e.target.value;
    setTypeTour(chose);
    (chose === 'B2B' || chose === 'B2B2C') && setEvening(false);
};
const handleChangeEvening = (e) => {
  // Convertir la valeur récupérée en booléen
  const chose = e.target.value === "true"; // cela retourne true si la valeur est "true", false sinon
  setEvening(chose);
};



const getPUB2C = async (evening) => {
  const data = await CommandeDataService.getOrdersB2CPU(new Date(startDate));
  // Appliquer le filtrage directement ici et ensuite retourner les données filtrées
  const filteredData = data.docs
    .map(doc => ({ ...doc.data(), id: doc.id }))
    .filter(commande => {
      const creneau = commande.creneauCollecte.split(' - ')[0]; // Prend le début du créneau
      const isEveningCreneau = creneau >= '16:00';
      return evening ? isEveningCreneau : !isEveningCreneau;
    }).map(filteredCommande => filteredCommande.id); // Extraire uniquement l'ID

  return filteredData; // Retourner les données filtrées directement
};

const getDOB2C = async (evening) => {
  const data = await CommandeDataService.getOrdersB2CDO(new Date(startDate));
  // Appliquer le filtrage directement ici et ensuite mettre à jour l'état
  const filteredData = data.docs
    .map(doc => ({ ...doc.data(), id: doc.id }))
    .filter(commande => {
      const creneau = commande.creneauLivraison.split(' - ')[0]; // Prend le début du créneau
      const isEveningCreneau = creneau >= '16:00';
      return evening ? isEveningCreneau : !isEveningCreneau;
    }).map(filteredCommande => filteredCommande.id); // Extraire uniquement l'ID;

  // Mise à jour de l'état avec les données filtrées
  return filteredData;
};

const getPUB2B = async () => {
  const data = await CommandeDataService.getOrdersB2BPU(new Date(startDate));
  // Mise à jour de l'état avec les données filtrées
  return data.docs
    .map(doc => ({ ...doc.data(), id: doc.id }));
};
const getDOB2B = async () => {
  const dataB2B = await CommandeDataService.getOrdersB2BDO(new Date(startDate));
  const date = new Date(startDate);
  const startOfTheDay = new Date(date.setHours(0,0,0,0)); // Définir à minuit
  const endOfTheDay = new Date(date.setHours(23,59,59,999)); // Définir à la fin de la journée
  const orders = [];

  dataB2B.forEach(doc => {
    const data = doc.data();
    // Filtrer pour garder uniquement les under_user qui satisfont la condition
    const validUnderUsers = data.under_user.filter(underUser => {
      const dateLivraison = underUser.date_livraison.toDate(); // Convertir Timestamp en objet Date
      dateLivraison.setHours(0, 0, 0, 0); // Réinitialiser l'heure pour comparer uniquement les dates

      // Vérifiez si la date de livraison est dans la journée donnée et si etat_second est égal à 4 ou vide
      return dateLivraison >= startOfTheDay && dateLivraison <= endOfTheDay && (underUser.etat_second === "4" || underUser.etat_second === "");
    });

    // Ajouter le document à la liste des commandes si au moins un under_user est valide
    if (validUnderUsers.length > 0) {
      orders.push({
        ...data,
        id: doc.id,
        under_user: validUnderUsers // Conserver uniquement les under_user valides dans le résultat
      });
    }
  });

  // Mise à jour de l'état avec les données filtrées
  return orders;
};

const getToursStatusOne = async () => {
  const dataTours = await CommandeDataService.getToursOfStatusOne();
  return dataTours.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
};
const filterIds = (orders, tours) => {
  // Initialiser les tableaux de résultats
  let idsFound = [];
  let idsNotFound = Array.isArray(orders) ? [...orders] : []; // Assurez-vous que orders est un tableau
  // Itérer à travers les tours pour identifier les IDs trouvés et non trouvés
  tours.forEach(tourStatus => {
    tourStatus.tours.forEach(tour => {
      tour.orders.forEach(order => {
        const index = idsNotFound.indexOf(order.id); // Trouve l'index de l'ID dans idsNotFound
        if (index > -1) {
          // Si l'ID est trouvé, le retirer de idsNotFound et l'ajouter à idsFound
          idsNotFound.splice(index, 1); // Retire l'ID de idsNotFound
          idsFound.push(order.id); // Ajoute l'ID à idsFound
        }
      });
    });
  });

  // Retourner les tableaux des IDs trouvés et non trouvés
  return { idsNotFound, idsFound };
};

const filterIdsB2B = (orders, tours, type) => {
  let idsFound = [];
  let idsNotFound = [...orders]; // Copie initiale des commandes B2B
  let underUserNotFound = []; // Pour stocker les éléments de under_user non trouvés et retirés

  tours.forEach(tourStatus => {
    tourStatus.tours.forEach(tour => {
      tour.orders.forEach(order => {
        // Vérifier si l'ordre est présent dans idsNotFound
        const orderIndex = idsNotFound.findIndex(detail => detail.id === order.id);

        if (orderIndex > -1) {
          // Créer un nouveau tableau pour stocker les éléments de under_user qui restent après avoir enlevé les non correspondants
          const remainingUnderUser = [];
          const companyId = idsNotFound[orderIndex]?.user || null;

          // Pour chaque orderUnder correspondant au type spécifié
          idsNotFound[orderIndex].under_user.forEach(underUser => {
            const isUnderUserNotFound = order.orderUnder.some(orderUnder => {
              // Effectuer la comparaison
              return orderUnder.orderType === type &&
                     underUser.name.trim().toLowerCase() === orderUnder.customerName.trim().toLowerCase()  &&
                     underUser.number_order === orderUnder.orderID;
            });

 
            if (!isUnderUserNotFound) {
              // Si under_user ne correspond pas à orderUnder, ajouter à underUserNotFound et ne pas l'ajouter à remainingUnderUser
              underUserNotFound.push({
                ...underUser,
                orderID: underUser.orderID // Supposant que under_user a un champ orderID
              });
            } else {
              // Si under_user correspond, le garder dans le tableau
              remainingUnderUser.push(underUser);
            }
          });

          // Mettre à jour idsNotFound avec les under_user restants
          idsNotFound[orderIndex].under_user = underUserNotFound;
          if (idsNotFound[orderIndex].under_user.length === 0){
            idsNotFound.splice(orderIndex, 1); // Retire l'ID de idsNotFound
          }

          if (remainingUnderUser.length > 0) {
            // Créer un nouvel objet pour la commande avec les under_user mis à jour

            const updatedOrder = {
              id: order.id,
              under_user: remainingUnderUser,
              companyId: companyId
            };
            // Ajouter cet objet mis à jour à idsFound
            idsFound.push(updatedOrder);
          }



          // // Après avoir filtré under_user, si idsNotFound[orderIndex] doit être déplacé à idsFound
          // const [orderDetails] = idsNotFound.splice(orderIndex, 1);
          // idsFound.push(orderDetails); // Ajouter à idsFound
        }
      });
    });
  });

  // À ce stade, underUserNotFound contient les éléments de under_user non trouvés et retirés
  // et idsNotFound a été mis à jour pour enlever les commandes trouvées et leurs under_user non correspondants.
  return { idsNotFound, idsFound };
};
const getPrestataire = async () => {
  const dataPresta = await CommandeDataService.getPresta(new Date(startDate));
  return dataPresta.docs.map((doc) => ({ ...doc.data(), idPresta: doc.id }));
};
const filterIdsDo = (orders, presta) => {
  let idsFound = [];
  let idsNotFound = [...orders]; // Commence avec une copie de tous les ID, et retire ceux qu'on trouve
 // Parcourir chaque document dans presta
 presta.forEach(prestaDoc => {
  const index = idsNotFound.indexOf(prestaDoc.id); // Cherche l'ID de prestaDoc dans idsNotFound
  if (index > -1) {
    // Si l'ID est trouvé
    if (prestaDoc.statusPresta === 3) {
      // Et que le statusPresta est égal à 3, traiter cet ID
      idsFound.push(prestaDoc.id); // Ajoute l'ID trouvé à idsFound
      idsNotFound.splice(index, 1); // Retire cet ID de idsNotFound
    }
  }
});


  return { idsNotFound, idsFound };
}

const filterIdsDoB2B = (orders, presta) => {
  let idsFound = []; //(orders)
  let idsNotFound = [...orders]; // Commence avec une copie de tous les ID, et retire ceux qu'on trouve (Incident)
  let underUserNotFound = []; // Pour stocker les éléments de under_user non trouvés et retirés
 // Parcourir chaque document dans presta

 presta.forEach(prestaDoc => {
  const index = idsNotFound.findIndex(detail => detail.id === prestaDoc.id); // Utilisez `.trim()` pour supprimer les espaces blancs

  if (index > -1) {

  // Créer un nouveau tableau pour stocker les éléments de under_user qui restent après avoir enlevé les non correspondants
  const remainingUnderUser = [];
  const companyId = idsNotFound[index]?.user || null;

  // Pour chaque orderUnder correspondant au type spécifié
  idsNotFound[index].under_user.forEach(underUser => {
    const isUnderUserNotFound = prestaDoc.under_items.some(orderUnder => {
      // Effectuer la comparaison
      return Number(orderUnder.status) === 3 && underUser.name.trim().toLowerCase() === orderUnder.customerName.trim().toLowerCase()  &&
             underUser.number_order === orderUnder.orderID;
    });
    if (!isUnderUserNotFound) {
      // Si under_user ne correspond pas à orderUnder, ajouter à underUserNotFound et ne pas l'ajouter à remainingUnderUser
      underUserNotFound.push({
        ...underUser,
        orderID: underUser.orderID // Supposant que under_user a un champ orderID
      });
    } else {
      // Si under_user correspond, le garder dans le tableau
      remainingUnderUser.push(underUser);
    }
  });

  // Mettre à jour idsNotFound avec les under_user restants
  idsNotFound[index].under_user = underUserNotFound;
  if (idsNotFound[index].under_user.length === 0){
    idsNotFound.splice(index, 1); // Retire l'ID de idsNotFound
  }

  if (remainingUnderUser.length > 0) {
    // Créer un nouvel objet pour la commande avec les under_user mis à jour
    
    const updatedOrder = {
      id: prestaDoc.id,
      under_user: remainingUnderUser,
      companyId: companyId
    };
    // Ajouter cet objet mis à jour à idsFound
    idsFound.push(updatedOrder);
  }
  }
});


  return { idsNotFound, idsFound };
}

const getPrestaVerifTypeOrder = (id, presta) => {
  // Trouver l'élément dans le tableau 'prestaEtiquetage' qui a le même id que le paramètre
  const found = presta.find(presta => presta.id === id);

  // Si un élément est trouvé et que son champ 'totalTTC.company' est égal à 0, retourner 'RE', sinon 'DO'
  if (found) {
    return found.totalTTC.company === 0 ? 'RE' : 'DO';
  } else {
    // Si aucun élément correspondant n'est trouvé, On retourner null par défaut
    return null; // ou 'DO'/'RE' selon notre logique par défaut
  }
};

  // Exemple de fonction de validation
  function validateTourInputs() {
    if (!typeTour) {
      enqueueSnackbar(`Sélectionner le type de la tournée.`, {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        autoHideDuration: 3000,
        style: {fontSize: '1.6rem'}
       
      });
      return false;
    }
    if (!selectedHub) {
      enqueueSnackbar(`Sélectionner le point de départ & d'arrivée.`, {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        autoHideDuration: 3000,
        style: {fontSize: '1.6rem'}
       
      });
      return false;
    }
    // Ajouter d'autres validations selon les besoins
    return true; // La validation a réussi
  }
  const initResult = () => {
    // Initialisez ici l'objet résultat basé sur votre structure
    return {
      tours: {
        createdAt: new Date(Date.now()),
        tourDate: new Date(startDate),
        tourType : typeTour,
        departureAndArrivalPoint: selectedHub,
        evening: evening,
        groupeStatus: 1,
        tours: []
      },
      incidents: []
    };
  };
  const prepareTourEnvironment = async () => {
    // Initialiser toutes les variables à null pour gérer la portée
    let puB2CAAA = null;
    let doB2CAAA = null;
    let puB2BAAA = null;
    let doB2BAAA = null;

    // Les tournée en cours
    const tour = await getToursStatusOne();
    // Presta
    const presta = await getPrestataire();

    if (typeTour === 'B2C') {
        // PU (B2C) matin ou soir trés par creneaux
        puB2CAAA = await getPUB2C(evening);
        // DO (B2C) matin ou soir trés par creneaux
        doB2CAAA = await getDOB2C(evening);
    } else if (typeTour === 'B2B') {
        // PU (B2B) trés par date creation corpo
        puB2BAAA = await getPUB2B();
        // DO (B2B) trés par date creation corpo
        doB2BAAA = await getDOB2B();
    }

    // Retourner un objet avec toutes les données chargées
    return { tour, presta, puB2CAAA, doB2CAAA, puB2BAAA, doB2BAAA };
};
// Fonction auxiliaire pour ajouter un incident
const addIncident = (result, id, type, typeCommande, under_user = null, companyId = null) => {
  result.incidents.push({ id, type, typeCommande, under_user, companyId });
};

// Fonction auxiliaire pour ajouter une pré-commande
const addPreOrder = (PreOrders, prestaDoc, serviceType, orderType, under_user = null, companyId = null) => {
  PreOrders.push({
    id: prestaDoc,
    serviceType,
    orderType,
    orderStatus: 1,
    orderUnder: under_user,
    companyId: companyId,
    comment: null,
    recap: [],
    start: { date: null, location: null },
    arrived: { date: null, location: null },
    signature: { date: null, location: null },
    end: { date: null, location: null },
  });
};

const generateIncidentsAndPreOrders = (result, tour, presta, puB2CAAA, doB2CAAA, puB2BAAA, doB2BAAA, PreOrders) => {
  // Générez ici les incidents et pré-commandes basés sur les données chargées
  if (typeTour === 'B2C') {
        // Incident
    const puOrdersB2C = filterIds(puB2CAAA, tour);
    puOrdersB2C.idsFound.forEach(prestaDoc => addIncident(result, prestaDoc, 'PU', 'B2C'));
    puOrdersB2C.idsNotFound.forEach(prestaDoc => addPreOrder(PreOrders, prestaDoc, 'PU', 'B2C'));

    //dans un premier temps on cherche dans les tournée 
    const doOrdersB2CV1 = filterIds(doB2CAAA, tour);
    doOrdersB2CV1.idsFound.forEach(prestaDoc => addIncident(result, prestaDoc, 'DO', 'B2C'));

    //Dans la parti presta si existe et bien le status est pret a livrer
    const doOrdersB2CV2 = filterIdsDo(doOrdersB2CV1.idsNotFound, presta);
    
    doOrdersB2CV2.idsNotFound.forEach(prestaDoc => addIncident(result, prestaDoc, 'DO', 'B2C'));
    doOrdersB2CV2.idsFound.forEach(prestaDoc => addPreOrder(PreOrders, prestaDoc, getPrestaVerifTypeOrder(prestaDoc, presta), 'B2C'));
    
} else if (typeTour === 'B2B') {

        //Vérification de la collecte on regarde une ournée B2B ou B2BB2C
        const filteredByTourType = tour.filter(tour => ["B2B", "B2B2C"].includes(tour.tourType));

        //regarder si le id de commande existe PU
        const puOrdersB2B = filterIdsB2B(puB2BAAA, filteredByTourType, 'PU');
        puOrdersB2B.idsFound.forEach(prestaDoc => addIncident(result, prestaDoc.id, 'PU', 'B2B', prestaDoc.under_user, prestaDoc.companyId));

        puOrdersB2B.idsNotFound.forEach(prestaDoc => addPreOrder(PreOrders, prestaDoc.id, 'PU', 'B2B', prestaDoc.under_user.map(orderUnder => ({ customerName: orderUnder.name, orderID: orderUnder.number_order, serviceType: 'PU'})),prestaDoc.user ));


        //dans un premier temps on cherche dans les tournée 
        const doOrdersB2BV1 = filterIdsB2B(doB2BAAA, filteredByTourType, 'DO');
        doOrdersB2BV1.idsFound.forEach(prestaDoc => addIncident(result, prestaDoc.id, 'DO', 'B2B', prestaDoc.under_user, prestaDoc.companyId));

        //Dans la parti presta si existe et bien le status est pret a livrer
        const doOrdersB2BV2 = filterIdsDoB2B(doOrdersB2BV1.idsNotFound, presta);
        doOrdersB2BV2.idsNotFound.forEach(prestaDoc => addIncident(result, prestaDoc.id, 'DO', 'B2B', prestaDoc.under_user, prestaDoc.user));

        doOrdersB2BV2.idsFound.forEach(prestaDoc => addPreOrder(PreOrders, prestaDoc.id, 'DO', 'B2B', prestaDoc.under_user.map(orderUnder => ({ customerName: orderUnder.name, orderID: orderUnder.number_order, serviceType: 'DO'})),prestaDoc.companyId));
}
};
const finalizeTourResult = (result, PreOrders) => {
  // Finalisez la structuration du résultat ici
  let resultSousTableau = []; // Le tableau final qui contiendra tous les sous-tableaux
  const tailleTournee = 10;


  for (let i = 0; i < PreOrders.length; i += tailleTournee) {
    // Créer un sous-tableau à partir de la tranche actuelle
    const sousTableau = PreOrders.slice(i, i + tailleTournee);
    let resultOrdersValidation = []; // Le tableau final qui contiendra tous les livraisons
    let resultOrdersDropoffs = []; // Le tableau final qui contiendra tous les livraisons




    sousTableau.forEach(prestaDoc => {

      let underOrders = prestaDoc.orderUnder?.filter(uo => uo.serviceType === 'DO' || uo.serviceType === 'RE')
                                         .map(uo => ({ id: uo.orderID, date: null, location: null })) || [];

      if (underOrders.length > 0) {
        resultOrdersDropoffs.push({ id: prestaDoc.id, underOrder: underOrders, date: null, location: null, companyId: prestaDoc.companyId });
      }
      if (result?.tours?.tourType === 'B2C' && (prestaDoc.serviceType === 'DO' || prestaDoc.serviceType === 'RE')) {
        resultOrdersDropoffs.push({ id: prestaDoc.id, date: null, location: null, underOrder: null, companyId: null });
      }
    });

    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    const letterIndex = (i / tailleTournee) % alphabet.length; // Assurez-vous de ne pas dépasser la longueur de l'alphabet
    const tourLetter = alphabet[letterIndex]; // Obtenez la lettre correspondante
    // Créer un objet pour chaque sous-tableau avec les champs 'name' et 'orders'
    const objetSousTableau = {
      tourName: tourLetter, // Exemple de nom basé sur l'index du groupe
      driverId: '',
      statusTour: 1,
      ordersDropoffs: resultOrdersDropoffs,
      ordersValidation: resultOrdersValidation,
      orders: sousTableau
    };

    // Pousser l'objet du sous-tableau dans le tableau de résultat
    resultSousTableau.push(objetSousTableau);
  }

  result.tours.tours = resultSousTableau;

};


const genererTour = async (e) => {
  setShowTour(true);
e.preventDefault()
  const PreOrders = [];
  // Initialisation du résultat
  const result = initResult();


  // Validation des entrées utilisateur avant de procéder
  if (!validateTourInputs()) {
    setShowTour(false);
    return; // Sortir de la fonction si la validation échoue
  }
  try {
  // Préparation de l'environnement pour la génération de la tournée
  const { tour, presta, puB2CAAA, doB2CAAA, puB2BAAA, doB2BAAA } = await prepareTourEnvironment();
  // Génération des incidents et des pré-commandes basées sur le type de tournée
  generateIncidentsAndPreOrders(result, tour, presta, puB2CAAA, doB2CAAA, puB2BAAA, doB2BAAA, PreOrders);
  // Traitement final pour structurer le résultat de la tournée
  finalizeTourResult(result, PreOrders);
    if(result.incidents.length === 0 && result.tours.tours.length === 0){
      enqueueSnackbar(`Vous n'avez pas de commande pour cette date.`, {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        autoHideDuration: 3000,
        style: {fontSize: '1.6rem'}
       
      });
    }else{
      enqueueSnackbar(`Les tournée étaient générer avec succes.`, {
        variant: 'success',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        autoHideDuration: 3000,
        style: {fontSize: '1.6rem'}
       
      });
      handleClose();
      handleShowConfirmed();
    }
    console.log("result:", result);
    setTours(result);
    setShowTour(false);
    return result;
  }catch (e) {
    console.error("Erreur lors de la génération de la tournée:", e);
  }
}
const [show, setShow] = useState(false);
const [showConfirmed, setShowConfirmed] = useState(false);
const handleClose = () => {
  setStartDate(new Date());
  setTypeTour('');
  setEvening(false);
  setSelectedHub('');
  setShowTour(false);
  setShow(false);
}
const handleShow = () => setShow(true);

const handleCloseConfirmed = () => setShowConfirmed(false);
const handleShowConfirmed = () => setShowConfirmed(true);

  return (
    <div>
      <Titre>Tours</Titre><span style={{color:'#008080', cursor:'pointer'}} className='btn-link' onClick={handleShow}><AiOutlinePlusCircle className='ms-5 me-2' size={14} color='#008080'/>générer les tournée</span>


      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize: '1.4rem', fontWeight: '600'}} className='m-3'>Générer la tournée pour {formatDate(startDate)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>Sélectionner une date : </p>
      <DatePicker
        className="teal"
        value={startDate} // Utilisez la nouvelle date pour la visualisation
        onChange={handleDateChange} // Mettez à jour la nouvelle date immédiatement
        editable={false}
        format="DD/MM/YYYY"
        minDate={today} // Utilisez 'today' réinitialisé à minuit
        inputClass='datepicker ms-2 px-1'
      />
      <Col md className='my-5'>
                    <FloatingLabel
                        controlId="floatingSelectGrid"
                        label="Sélectionner le type de la tournée :"
                        style={{fontSize: '1.4rem'}}
          
                    >
                    <Form.Select aria-label="Sélectionner le type de la tournée :" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={handleChange}>
                    <option value=''>Séléctionner le type de la tournée</option>
                    <option value='B2C'>B2C</option>
                    <option value='B2B'>B2B</option>
                    {/* <option value='B2B2C'>B2C/B2B</option> */}
          </Form.Select>
        </FloatingLabel>
    </Col>

    <Col md className='mb-5'>
                    <FloatingLabel
                        controlId="floatingSelectGrid"
                        label="Sélectionner le point de départ & d'arrivée :"
                        style={{fontSize: '1.4rem'}}
          
                    >
                    <Form.Select aria-label="Sélectionner le point de départ & d'arrivée :" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={handleSelectChange}>
                    <option value=''>Sélectionner le point de départ & d'arrivée</option>
                    {hubs?.map((hub) => (
                    <option key={hub.id_hub} value={hub.id_hub}>{hub.name}</option>
                ))}
          </Form.Select>
        </FloatingLabel>
    </Col>

    {typeTour === 'B2C' &&  <Col md className='my-5'>
                    <FloatingLabel
                        controlId="floatingSelectGrid"
                        label="Séléctionner le sous type de la tournée :"
                        style={{fontSize: '1.4rem'}}
          
                    >
                    <Form.Select aria-label="Sélectionner le type de la tournée :" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={handleChangeEvening}>
                    <option value= {false}>Matin</option>
                    <option value={true}>Soir</option>
          </Form.Select>
        </FloatingLabel>
    </Col>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleClose} style={{fontSize: '1.4rem'}}>
            Annuler
          </Button>
          <button className="btn btn-success" style={{fontSize: '1.4rem', background: '#008080'}} onClick={genererTour}><SpinnerDiamond size={20} color='#fff' enabled={showTour} speed={200}/><span className={` ${showTour && 'ms-3'}`}>Générer la tournée</span></button>
        </Modal.Footer>
      </Modal>
      <ConfirmedTours 
        showConfirmed={showConfirmed}
        handleCloseConfirmed={handleCloseConfirmed}
        hubs={hubs}
        tours={tours}
      />








      {/* <p>{formatDateForDisplay(startDate)}</p>
      <p>{typeTour}</p>
      <p>{selectedHub}</p>
      <p>{evening}</p> */}
      <div className="d-grid gap-2 mx-5 px-5 mt-5 pt-5">
      
      </div>
    </div>
  );
}
