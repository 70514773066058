import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap'
import { BsChevronDown } from 'react-icons/bs';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowUp } from 'react-icons/md'
import { db } from '../../../../../firebase-config';
import { capitalizeFirstLetter } from '../../../../../utils/apis/capitalizeFirstLetter';
import { toUpperCase } from '../../../../../utils/apis/toUpperCase';
import CommandeDataService from '../../../../../context/commande.services'
import { formatDate, formatDateheureMinute, formatDateheureMinuteComplete } from '../../../../../utils/DateToString';
import GetUser from './GetUser';

export default function ModelAddOrder({ show, handleClose }) {
    const [active, setActive] = useState(0);
    const [users, setUsers] = useState(null);
    const [userSelected, setUserSelected] = useState(null);
    const [slots, setSlots] = useState([]);

    const handleCloseModal = () => {
        handleClose();
        setActive(0);
    }
    const showBloc = (active) => {
        setActive(active);
    }
    const handleSelectChange = async (event, indexTour) => {
        // Récupérer l'ID du client sélectionné
        const selectedUserId = event.target.value;
        try {
            const docSnap = await CommandeDataService.getUser(selectedUserId);
            const docSnapUserCard = await CommandeDataService.getUserCard(selectedUserId, docSnap.data().card)
            setUserSelected({ ...docSnap.data(), card: docSnapUserCard.data()});
            // setUser(docSnapUser.data());
          } catch (err) {
            setUserSelected(null)
            // console.log(err.message);
          }
      };
    useEffect(() => {
        const q = query(collection(db, "Users"), orderBy("date_inscription", "desc"))  ;
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const users = [];
            querySnapshot.forEach((doc) => {
              users.push(doc.data());
        });
        setUsers(users);
        });
    
        return () => unsubscribe(); 
      }, [active === 1]);

    useEffect(() => {
        const q = query(collection(db, "slots"), orderBy("start", "asc"))  ;
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const slots = [];
            querySnapshot.forEach((doc) => {
              slots.push({ ...doc.data(), id: doc.id });
        });
        setSlots(slots);
        });
    
        return () => unsubscribe(); 
    }, [active === 1]);





   









    const [service, setService] = useState('');
    const [codePromos, setCodePromos] = useState(null);
    const [selectCodePromo, setSelectCodePromo] = useState('');
    const [selectDateCollecteListCreneaux, setSelectDateCollecteListCreneaux] = useState(null);
    const [selectDateCollecte, setSelectDateCollecte] = useState('');
    const [selectDateCreneau, setSelectDateCreneau] = useState('');
    const [selectDateLivraison, setSelectDateLivraison] = useState('');
    const [selectDateCreneauLivraison, setSelectDateCreneauLivraison] = useState('');
    const [selectDateLivraisonListCreneaux, setSelectDateLivraisonListCreneaux] = useState(null);
    const handleSelectService = (event) => {
        const service = event.target.value;
        setService(service);
    };
    const handleSelectCodePromo = (event) => {
        const codePromo = event.target.value;
        setSelectCodePromo(codePromo);
    };
    //Collecte
    const slotsactive = () => {
        const now = new Date();
        const twoHoursLater = new Date(now.getTime() + 2 * 60 * 60 * 1000); // Ajouter 2 heures à l'heure actuelle
        const slotsActive = [];
    
        slots.forEach((slot) => {
            const slotStart = new Date(slot.start?.toDate()); // Convertir Timestamp en Date
    
            // Ajouter le créneau seulement s'il commence après "twoHoursLater"
            if (slotStart > twoHoursLater) {
                slotsActive.push(slot);
            }
        });
    
        return slotsActive;
    }
    const slotsForRender = slotsactive();
    const slotsactiveSansDoublons = slotsForRender.reduce((unique, item) => {
        const slotDate = new Date(item.start?.toDate()); // Convertir Timestamp en Date
        slotDate.setHours(0, 0, 0, 0); // Réinitialiser l'heure pour comparer uniquement les dates


        if (!unique.find(slot => {
            const slotDateUnique = new Date(slot.start?.toDate()); // Convertir Timestamp en Date
            slotDateUnique.setHours(0, 0, 0, 0); // Réinitialiser l'heure pour comparer uniquement les dates 

            return slotDateUnique.toDateString() === slotDate.toDateString()
        })) {
            unique.push(item);
        }

        return unique;
        

    }, []);
    const handleSelectChangeCollecte = (event) => {
        // Convertir la chaîne de caractères en nombre (timestamp)
        const timestamp = event.target.value;
        const creneauxPourCetteDate = getCreneauxPourDate(slotsForRender, timestamp);
        setSelectDateCollecteListCreneaux(creneauxPourCetteDate);
        const select = new Date(timestamp); // Convertir Timestamp en Date
        //select.setHours(0, 0, 0, 0); // Réinitialiser l'heure pour comparer uniquement les dates
        setSelectDateCollecte(new Date(select));
    };
    const getCreneauxPourDate = (slots, dateRecher) => {
        // Convertir la date de recherche en format Date, réinitialiser l'heure pour comparer uniquement les dates
        const dateRechercheeFormattee = new Date(dateRecher);
    
        dateRechercheeFormattee.setHours(0, 0, 0, 0);
    
        // Filtrer et transformer les créneaux pour la date spécifiée
        const creneauxPourDate = slots.reduce((acc, slot) => {
            // Convertir le timestamp de début en objet Date, puis en chaîne de caractères formatée
            const dateDebut = slot.start;
            const dateDebutFormattee = new Date(dateDebut.toDate());
            dateDebutFormattee.setHours(0, 0, 0, 0);
    
            // Ajouter le créneau au tableau si la date correspond à la date recherchée
            if (dateDebutFormattee.getTime() === dateRechercheeFormattee.getTime()) {
                acc.push({
                    start: dateDebut,
                    end: slot.end
                });
            }
    
            return acc;
        }, []);
        return creneauxPourDate;
    };
    const handleSelectCrenaux = (event) => {
        // Convertir la chaîne de caractères en nombre (timestamp)
        const timestamp = event.target.value;
        setSelectDateCreneau(timestamp);
        const heureDebut = timestamp.split(' - ')[0];
        const [heure, minutes] = heureDebut.split(':').map(Number);
        const dateNew = selectDateCollecte;
        dateNew.setHours(heure, minutes, 0, 0);
        setSelectDateCollecte(dateNew);
    };

    //Livraison
    const slotsactiveLivraison = (decal) => {
        const now = new Date(selectDateCollecte);
        const twoHoursLater = new Date(now.getTime() + decal * 60 * 60 * 1000); // Ajouter 2 heures à l'heure actuelle
       
        const slotsActive = [];
    
        slots.forEach((slot) => {
            const slotStart = new Date(slot.start?.toDate()); // Convertir Timestamp en Date

            
            // Ajouter le créneau seulement s'il commence après "twoHoursLater"
            if (slotStart > twoHoursLater) {

                slotsActive.push(slot);
            }
        });
    
        return slotsActive;
    }
    const slotsForRenderLivraison = slotsactiveLivraison(service === 'Standard' ? 48 : 24);

    const slotsactiveSansDoublonsLivraison = slotsForRenderLivraison.reduce((unique, item) => {
        const slotDate = new Date(item.start?.toDate()); // Convertir Timestamp en Date
        slotDate.setHours(0, 0, 0, 0); // Réinitialiser l'heure pour comparer uniquement les dates


        if (!unique.find(slot => {
            const slotDateUnique = new Date(slot.start?.toDate()); // Convertir Timestamp en Date
            slotDateUnique.setHours(0, 0, 0, 0); // Réinitialiser l'heure pour comparer uniquement les dates 

            return slotDateUnique.toDateString() === slotDate.toDateString()
        })) {
            unique.push(item);
        }

        return unique;
        

    }, []);  
    const getCreneauxPourDateLivraison = (slots, dateRecher, heureSpecifique) => {
        // Convertir la date de recherche en format Date
        const dateRechercheeFormattee = new Date(dateRecher);
        //dateRechercheeFormattee.setHours(0, 0, 0, 0);
        // Extraire seulement la première partie de la chaîne (ex : "09:00" de "09:00 - 09:30")
        const heureDebut = heureSpecifique.split(' - ')[0];
        const [heure, minutes] = heureDebut.split(':').map(Number);
        const heureSpecifiqueDate = new Date(dateRechercheeFormattee);
        heureSpecifiqueDate.setHours(0, 0, 0, 0);
    
        // Filtrer et transformer les créneaux pour la date spécifiée
        const creneauxPourDate = slots.reduce((acc, slot) => {
            const dateDebut = new Date(slot.start.toDate());
    
            // Ajouter le créneau au tableau si la date et l'heure correspondent
            if(dateDebut.toDateString() === heureSpecifiqueDate.toDateString()){
                heureSpecifiqueDate.setHours(heure, minutes, 0, 0);
                if (dateDebut >= heureSpecifiqueDate) {
                    acc.push({
                        start: slot.start,
                        end: slot.end
                    });
                }else {
                    // Pour les dates futures, ajouter tous les créneaux
                    acc.push({
                        start: slot.start,
                        end: slot.end
                    });
                }
            }

    
            return acc;
        }, []);
    
        return creneauxPourDate;
    };
    
    // Usage de la fonction
    // const heureSpecifique = "09:00 - 09:30"; // Exemple d'entrée
    // const creneauxFiltrés = getCreneauxPourDateLivraison(slots, dateChoisie, heureSpecifique);
    
    const handleSelectChangeLivraison = (event) => {
        // Convertir la chaîne de caractères en nombre (timestamp)
        const timestamp = event.target.value;
        const creneauxPourCetteDate = getCreneauxPourDateLivraison(slotsForRenderLivraison, timestamp, selectDateCreneau);
              setSelectDateLivraisonListCreneaux(creneauxPourCetteDate);
              const select = new Date(timestamp); // Convertir Timestamp en Date
              select.setHours(0, 0, 0, 0); // Réinitialiser l'heure pour comparer uniquement les dates
              setSelectDateLivraison(new Date(select));

        // // Convertir la chaîne de caractères en nombre (timestamp)
        // const timestamp = event.target.value;
        // const creneauxPourCetteDate = getCreneauxPourDate(slotsForRenderLivraison48, timestamp);
        // setSelectDateLivraison(creneauxPourCetteDate);
    };









    useEffect(() => {
        const q = query(collection(db, "codes_promo"))  ;
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const codePromos = [];
            querySnapshot.forEach((doc) => {
                codePromos.push({ ...doc.data(), id: doc.id });
        });
        setCodePromos(codePromos);
        });
    
        return () => unsubscribe(); 
    }, [active === 1]);

  return (
    <Modal show={show} onHide={handleCloseModal}>
    <Modal.Header closeButton>
      <Modal.Title className='mx-3' style={{fontSize: '1.6rem', fontWeight: '600', letterSpacing: '1.2px'}}>Ajouter un nouveau point</Modal.Title>
    </Modal.Header>
    <Modal.Body className='p-5'>
        <div className='d-flex justify-content-between border-bottom pb-2 mb-3' style={{cursor: 'pointer'}} onClick={() => {active === 1 ? showBloc(0) : showBloc(1)}}>
            <p className='p-0 m-0' style={{fontSize: '1.6rem', fontWeight: '500', letterSpacing: '1.2px', color: '#008080'}}>Ajouter une nouvelle commande</p>
            {active === 1 ? <MdOutlineKeyboardArrowDown size={20} color='#008080'/> : <MdOutlineKeyboardArrowRight size={20} color='#008080'/>}

        </div>
        { active === 1 &&
        <div>
        <Form.Group controlId="selectedDriver" className="d-flex align-items-center mb-4 mx-5">
    <div className="flex-grow-1 position-relative">
            <Form.Control
                as="select"
                className="w-100"
                style={{ color: '#000', fontSize: '1.4rem'}}
                name="selectedDriver"
                onChange={(event) => handleSelectChange(event)} // Utilisez une fonction fléchée
            >
            <option value="" >Sélectionnez un client</option>
                {users.map((user) => (
                    user.prenom && user.nom && user.adresse && user.card && user.adresse !== null && <option key={user.id} value={user.id}>{capitalizeFirstLetter(user.prenom)} {toUpperCase(user.nom)}</option>
                ))}
            </Form.Control>
            <BsChevronDown className="dropdown-icon" />
        </div>
</Form.Group>
{ userSelected &&
    <div className='my-5 mx-3'>
    <p><span style={{letterSpacing: '2px', fontWeight: '600'}} >Adresse : </span>{userSelected?.adresse}</p>
    <p><span style={{letterSpacing: '2px', fontWeight: '600'}} >Téléphone : </span>{userSelected?.tel}</p>
    <p><span style={{letterSpacing: '2px', fontWeight: '600'}} >Email : </span>{userSelected?.mail}</p>
    <p><span style={{letterSpacing: '2px', fontWeight: '600'}} >Moyen de paiement : </span>**** **** **** {userSelected?.card?.last}</p>
    <p><span style={{letterSpacing: '2px', fontWeight: '600'}} >total de commande : </span>{userSelected?.nbCommandes}</p>
    <Row className="g-2 mt-3">
        <Col md>
            <FloatingLabel
                controlId="floatingSelectGrid"
                label="Service"
                style={{fontSize: '1.6rem'}}
                
                >
            <Form.Select aria-label="Service" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={(event) => handleSelectService(event)}>
                <option value=''>Séléctionner un service</option>
                <option value="Standard">Standard (48h)</option>
                <option value="Prime">Prime (24h)</option>
            </Form.Select>
        </FloatingLabel>
    </Col>
    <Col md>
            <FloatingLabel
                controlId="floatingSelectGrid"
                label="Code promotion"
                style={{fontSize: '1.6rem'}}
                
                >
            <Form.Select aria-label="Service" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={(event) => handleSelectCodePromo(event)}>
                <option value=''>Séléctionner un code promotionnel</option>
                {codePromos?.map((codePromo) => (
                    <option key={codePromo.id} value={codePromo.code}>{codePromo.code} <GetUser userId={codePromo.idClient} /></option>
                ))}
            </Form.Select>
        </FloatingLabel>
    </Col>
    </Row>
    <Row className="g-2 mt-3">
    <Col md>
    <FloatingLabel
          controlId="floatingSelectGrid"
          label="Date de collecte"
          style={{fontSize: '1.6rem'}}
          
        >
          <Form.Select aria-label="Date collecte" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={(event) => handleSelectChangeCollecte(event)}>
            <option value=''>Séléctionner une Date de collecte</option>
            {slotsactiveSansDoublons.map((slot) => (
                    <option key={slot.start} value={slot.start.toDate()}>{formatDate(slot.start.toDate().toISOString())}</option>
                ))}
          </Form.Select>
        </FloatingLabel>
    </Col>
    <Col md>
    <FloatingLabel
          controlId="floatingSelectGrid"
          label="Heure de collecte"
          style={{fontSize: '1.6rem'}}
          handleSelectCollecte
        >
          <Form.Select aria-label="Heure collecte" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={(event) => handleSelectCrenaux(event)}>
            <option>Séléctionner une heure de collecte</option>
            {selectDateCollecteListCreneaux?.map((creneau, index) => (

                    <option key={index} value={`${formatDateheureMinute(creneau.start.toDate().toISOString())} - ${formatDateheureMinute(creneau.end.toDate().toISOString())}`}>{formatDateheureMinute(creneau.start.toDate().toISOString())} - {formatDateheureMinute(creneau.end.toDate().toISOString())}</option>
                ))}
          </Form.Select>
        </FloatingLabel>
    </Col>

    </Row>
    <Row className="g-2 mt-3">
    <Col md>
    <FloatingLabel
          controlId="floatingSelectGrid"
          label="Date de livraison"
          style={{fontSize: '1.6rem'}}
          
        >
          <Form.Select aria-label="Date livraison" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={(event) => handleSelectChangeLivraison(event)}>
            <option value=''>Séléctionner une Date de livraison</option>
            {slotsactiveSansDoublonsLivraison.map((slot) => (
                    <option key={slot.start} value={slot.start.toDate()}>{formatDate(slot.start.toDate().toISOString())}</option>
                ))}
          </Form.Select>
        </FloatingLabel>
    </Col>
    <Col md>
    <FloatingLabel
          controlId="floatingSelectGrid"
          label="Heure de livraison"
          style={{fontSize: '1.6rem'}}
          
        >
          <Form.Select aria-label="Heure livraison" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5'>
            <option>Séléctionner une heure de livraison</option>
            {selectDateLivraisonListCreneaux?.map((creneau, index) => (

                    <option key={index} value={creneau}>{formatDateheureMinute(creneau.start.toDate().toISOString())} - {formatDateheureMinute(creneau.end.toDate().toISOString())}</option>
                ))}
          </Form.Select>
        </FloatingLabel>
    </Col>

    </Row>


    <Form.Check // prettier-ignore
        type="switch"
        id="custom-switch"
        label="Envoyer un mail de confirmation"
        style={{fontStyle: 'italic'}}
        className='text-secondary mt-1'
      />
</div>
}

 
  
        </div>
        }






        <div className='d-flex justify-content-between border-bottom pb-2 mb-3' style={{cursor: 'pointer'}} onClick={() => {active === 2 ? showBloc(0) : showBloc(2)}}>
            <p className='p-0 m-0' style={{fontSize: '1.6rem', fontWeight: '500', letterSpacing: '1.2px', color: '#008080'}}>Modifier une commande à venir / incident</p>
            {active === 2 ? <MdOutlineKeyboardArrowDown size={20} color='#008080'/> : <MdOutlineKeyboardArrowRight size={20} color='#008080'/>}

        </div>
        { active === 2 &&
        <div>2</div>
        }

        <div className='d-flex justify-content-between border-bottom pb-2 mb-3' style={{cursor: 'pointer'}} onClick={() => {active === 3 ? showBloc(0) : showBloc(3)}}>
            <p className='p-0 m-0' style={{fontSize: '1.6rem', fontWeight: '500', letterSpacing: '1.2px', color: '#008080'}}>Séléctionner une commande non assigné</p>
            {active === 3 ? <MdOutlineKeyboardArrowDown size={20} color='#008080'/> : <MdOutlineKeyboardArrowRight size={20} color='#008080'/>}
        </div>
        { active === 3 &&
        <div>3</div>
        }
        <p>Service : {service}</p>
        <p>Code promo : {selectCodePromo}</p>
        <p>Date collecte : {selectDateCollecte.toString()}</p>
        <p>Créneaux collecte : {selectDateCreneau}</p>
        <p>Date livraison : {selectDateLivraison.toString()}</p>
        
        
        
        
        
    </Modal.Body>
    {/* <Modal.Footer>
      <Button variant="secondary" onClick={handleCloseModal}>
        Close
      </Button>
      <Button variant="primary" onClick={handleCloseModal}>
        Save Changes
      </Button>
    </Modal.Footer> */}
  </Modal>
  )
}
