import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import SlotsDataService from '../../../../context/creneaux.services';

const SlotsList = () => {
  const [slots, setSlots] = useState([]);
  useEffect(() => {
    getSlots();
  }, []);

  const getSlots = async () => {
    const data = await SlotsDataService.getAllSlots();
    setSlots(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const deleteHandler = async (id) => {
    await SlotsDataService.deleteSlot(id);
    getSlots();
  };

  const formatDate = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',  hour: '2-digit', minute: '2-digit' }
    return new Date(dateString).toLocaleDateString('fr-FR', options)
  }

  return (
    <>
      <div className="mb-2">
        <Button variant="btn btn-warning edit text-light" onClick={getSlots}>
        rafraîchir la liste
        </Button>
      </div>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Id</th>
            <th>Début</th>
            <th>Fin</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {slots.map((doc, index) => {
            
            return (
              <tr key={doc.id}>
                <td>{index + 1}</td>
                <td>{formatDate(doc.start.toDate().toISOString())}</td>
                <td>{formatDate(doc.end.toDate().toISOString())}</td>
                <td>
                  <Button
                    variant="danger"
                    className="delete"
                    onClick={(e) => deleteHandler(doc.id)}
                  >
                  Supprimer
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default SlotsList;