export function formatPhoneNumber(phoneNumber) {
    // Supprimer tout caractère qui n'est pas un chiffre
    let cleaned = ('' + phoneNumber).replace(/\D/g, '');

    // Vérifier si le numéro commence par '33' (indicatif de la France)
    if (cleaned.startsWith('33') && cleaned.length === 11) {
        // Appliquer le format souhaité
        return `+${cleaned.substring(0,2)} ${cleaned.substring(2,3)} ${cleaned.substring(3,5)} ${cleaned.substring(5,7)} ${cleaned.substring(7,9)} ${cleaned.substring(9)}`;
    } else {
        // Retourner le numéro original s'il ne correspond pas aux critères
        return phoneNumber;
    }
}