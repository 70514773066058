import CorporateDataService from "../../../../context/corporates.services";

export const UpdateInvoiceAddress = async ({ id, value, enqueueSnackbar, setIsLoading }) => {
    try {
        if(value !== ''){
            setIsLoading(true);
            await CorporateDataService.updateCorporate('adressInvoiceCompany', value, id);
            enqueueSnackbar(`L'adresse de facturation a bien été modifiée en ${value}.`, {
                variant: 'success',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}
                   
            });
            return true;
        }else{
            enqueueSnackbar(`Veuillez séléctionner une adresse`, {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}  
            });
        }
        

    }catch(e) {
        enqueueSnackbar(`${e.message}`, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoHideDuration: 3000,
            style: {fontSize: '1.6rem'}  
        });
        setIsLoading(false);
        return false;
    }
};