import React, { useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default function CardMap() {
    const centerPosition = [48.8566, 2.3522]; // Coordonnées de Paris
    const tours = [
        {
          markers: [
            
            { position: [48.857451, 2.3285128], label: 'BACU' },
            { position: [48.8803404, 2.3174408], label: 'N8' },
            { position: [48.880384, 2.3269639], label: '55A' },
            { position: [48.87520980834961, 2.3353466987609863], label: 'VICTOIRE' },
          ],
          color: 'blue',
        },
        {
          markers: [
            { position: [48.87128829956055, 2.3569037914276123], label: 'Tuili' },
            { position: [48.9333, 2.3667], label: 'WOODWORK' },
          ],
          color: 'red',
        },
      ];



  return (
    <div>
              <MapContainer center={centerPosition} zoom={11} style={{ height: "500px", width: "100%" }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
       {tours.map((group, groupIndex) => (
                <>
                    {group.markers.map((marker, markerIndex) => (
                        <Marker
                            key={`${groupIndex}-${markerIndex}`}
                            position={marker.position}
                        >
                            <Popup>{marker.label}</Popup>
                        </Marker>
                    ))}
                    <Polyline positions={group.markers.map(marker => marker.position)} color={group.color} />
                </>
            ))}
    </MapContainer>
    </div>
  )
}
