import CorporateDataService from "../../../../context/corporates.services";

const showStatusOrder = (status) => {

    switch (status) {
        case 1 : 
            return ' En cours de récupération';
            break;
        case 2 : 
            return ' Récéptionner';
            break;
        case 3 : 
            return ' En cours de traitement';
            break;
        case 4 : 
            return ' En cours de livraison';
            break;
        case 5 : 
            return ' Livrée';
            break;
        case 6 : 
            return ' Annulée';
            break;
        default : return 'ERROR';
      }
};

export const UpdateStatusOrder = async ({ id, value, enqueueSnackbar, setIsLoading, orderB2B }) => {
    try {
        if(value !== '') {
            setIsLoading(true);
            await CorporateDataService.updateOrderCorporate('etat_commande', Number(value), id);
            let underUsersOrders = [ ...orderB2B.under_user];

            //En cours de récupération
            if(Number(value) === 1) {
                let updatedUnderUsersOrders = underUsersOrders.map(order => {
                    return {
                        ...order,
                        recap_driverBis: [], // Définir le champ recap_driverBis comme un tableau vide
                        recap_presta: [] // Définir le champ recap_presta comme un tableau vide
                    };
                });
                await CorporateDataService.updateOrderCorporate('under_user', updatedUnderUsersOrders, id); // Mettre à jour dans newOrderB2B
            }
            //Récéptionner
            if(Number(value) === 2) {
                let updatedUnderUsersOrders = underUsersOrders.map(order => {
                    return {
                        ...order,
                        recap_driverBis: order.recap_driver, // Définir le champ recap_driverBis apres récupération du chauffeur
                        recap_presta: [] // Définir le champ recap_presta comme un tableau vide
                    };
                });
                await CorporateDataService.updateOrderCorporate('under_user', updatedUnderUsersOrders, id); // Mettre à jour dans newOrderB2B
            }
            //En cours de traitement
            if(Number(value) === 3) {
                let updatedUnderUsersOrders = underUsersOrders.map(order => {
                    return {
                        ...order,
                        recap_presta: order.recap_driverBis, // Définir le champ recap_presta apres que le presta a etiquete
                    };
                });
                await CorporateDataService.updateOrderCorporate('under_user', updatedUnderUsersOrders, id); // Mettre à jour dans newOrderB2B
            }

            enqueueSnackbar(`Le status a bien été modifié : ${showStatusOrder(Number(value))}.`, {
                variant: 'success',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}
               
              });
              return true;
        }else{
            enqueueSnackbar(`Veuillez séléctionner un status`, {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}  
            });
        }
    }catch(e) {
        enqueueSnackbar(`${e.message}`, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoHideDuration: 3000,
            style: {fontSize: '1.6rem'}  
        });
        setIsLoading(false);
        return false;
    }
};