import React from 'react';
import Modal from 'react-bootstrap/Modal';

export default function ModalConfirmed({onClick, show, onHide}) {
  
  return (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center' style={{fontWeight: '600'}}>Vous êtes sur d'avoir ajouter une nouvelle grille tarifaire ?</Modal.Body>
        <Modal.Footer>
          <button className='btn btn-outline-danger' onClick={onHide}>
            Annuler
          </button>
          <button className='btn btn-success' onClick={onClick}>
            Confirmer
          </button>
        </Modal.Footer>
    </Modal>
  )
}
