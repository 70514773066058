import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import SlotsList from './SlotsList';
import AddSlots from './AddSlots';

export default function SlotsPage() {

    return (
        <div className="global-container">

      <Container>
        <Row>
          <Col>
            <AddSlots />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <SlotsList />
          </Col>
        </Row>
      </Container>
        </div>
    );
}