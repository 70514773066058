import React, { useState, useEffect } from 'react'
import Titre from '../../../../components/Titre'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import CardTour from './CardTour'
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../../../../firebase-config';
import NavbarVertical from '../../../../components/NavbarVertical';
import CreateTours from './Components/CreateTours';
import ConfirmedTours from './Components/ConfirmedTours';

const items = [
  "Tournée en cours",
  "Tournée à venir",
  "Historique"
];

export default function ToursPage() {

  const [ordersB2C, setOrdersB2C] = useState([]);
  const [ordersB2B, setOrdersB2B] = useState([]);
  const [show, setShow] = useState(false);
  const [showConfirmedTours, setShowConfirmedTours] = useState(false);
  const [tours, setTours] = useState([]);
  const [hubs, setHubs] = useState([]);
  const [ordersTrakings, setOrdersTrakings] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const changeData = (index) => {
  //   let newList = [];
  //   switch (index) {
  //       case 0:
  //         newList = [...orders];
  //           // setFilteredOrders(prevOrders => orders);
  //           // setCurrentPage(0);
  //           break;
  //       case 1:
  //         newList = filterOrdersForToday(orders);
  //           // setFilteredOrders(prevOrders => filterOrdersForToday(orders));
  //           // setCurrentPage(0);
  //           break;
  //       case 2:
  //         newList = filterUpcomingOrders(orders)
  //           // setFilteredOrders(prevOrders => filterUpcomingOrders(orders));
  //           // setCurrentPage(0);
  //           break;
  //       case 3:
  //         newList = filterRemoveEditOrders(orders)
  //           // setFilteredOrders(prevOrders => filterRemoveEditOrders(orders));
  //           // setCurrentPage(0);
  //           break;
  //       case 4:
  //         newList = filterIncidentsOrders(orders)
  //           // setFilteredOrders(prevOrders => filterIncidentsOrders(orders));
  //           // setCurrentPage(0);
  //           break;
  //       default:
  //         newList = [...orders];
  //           // setFilteredOrders(prevOrders => orders);
  //           // setCurrentPage(0);
  //           break;
  //   }
  //   setCurrentList(newList);
  //   // Si un terme de recherche est défini, filtrez la liste en conséquence
  //   if (searchTerm) {
  //     const lowercasedSearch = searchTerm.toLowerCase();
  //     newList = newList.filter(order => 
  //         order.idFacture?.toLowerCase().includes(lowercasedSearch)
  //     );
  // }
  
  // setFilteredOrders(newList);
  // setCurrentPage(0);
  
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseConfirmedTours = () => {
    setTours([]);
    setShowConfirmedTours(false);
  }
  const handleShowConfirmedTours = () => setShowConfirmedTours(true);

  useEffect(() => {
    const q = query(collection(db, "Orders_B2B"), orderBy("date_collecte", "desc"))  ;
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const orders = [];
        querySnapshot.forEach((doc) => {
          orders.push(doc.data());
    });
    setOrdersB2B(orders);
    });

    return () => unsubscribe(); 
  }, []);

  useEffect(() => {
    const q = query(collection(db, "orders"), orderBy("date_creation", "desc"))  ;
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const orders = [];
        querySnapshot.forEach((doc) => {
          orders.push(doc.data());
    });
    setOrdersB2C(orders);
    });

    return () => unsubscribe(); 
  }, []);

  useEffect(() => {
    const q = query(collection(db, "Order_tracking"), orderBy("created_at", "desc"))  ;
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const ordersTrakings = [];
        querySnapshot.forEach((doc) => {
          ordersTrakings.push({...doc.data(), id_order_traking: doc.id});
    });
    setOrdersTrakings(ordersTrakings);
    });

    return () => unsubscribe(); 
  }, []);

  useEffect(() => {
    const q = query(collection(db, "Hubs"), orderBy("created_at", "desc"))  ;
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const hubs = [];
        querySnapshot.forEach((doc) => {
          hubs.push({...doc.data(), id_hub: doc.id});
    });
    setHubs(hubs);
    });

    return () => unsubscribe(); 
  }, []);

  return (
    <div>
      <CreateTours handleClose={handleClose} handleShowConfirmedTours={handleShowConfirmedTours} show={show} ordersB2C={ordersB2C} ordersB2B={ordersB2B} setTours={setTours} hubs={hubs}/>
      <ConfirmedTours handleCloseConfirmedTours={handleCloseConfirmedTours} showConfirmedTours={showConfirmedTours} tours={tours} setTours={setTours} hubs={hubs} ordersB2C={ordersB2C} ordersTrakings={ordersTrakings}/>
      <Titre>Tournées</Titre><span style={{color:'#008080', cursor:'pointer'}} className='btn-link' onClick={handleShow}><AiOutlinePlusCircle className='ms-5 me-2' size={14} color='#008080'/>Générer une tournée</span>

      <CardTour ordersB2C={ordersB2C} ordersB2B={ordersB2B}/>
      <NavbarVertical items={items} changeData={changeData} setActiveIndex={setActiveIndex} activeIndex={activeIndex}/>
        {/* <OrdersList orders={filteredOrders} currentPage={currentPage} setCurrentPage={setCurrentPage}/> */}
    </div>
  )
}
