import React, { useState } from 'react'
import { BsExclamationTriangle } from 'react-icons/bs';
import { Dropdown, DropdownButton } from 'react-bootstrap';
// import './DropdownStyle.css'
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import InputIcon from "react-multi-date-picker/components/input_icon";
import DatePicker from "react-multi-date-picker";
import TitleCard from '../../../../components/Card/TitleCard';
import { filterOrdersForTodayB2B } from '../../../../utils/filterOrdersForTodayB2B';
import { filterOrdersForTodayB2C } from '../../../../utils/filterOrdersForTodayB2C';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { filterOrdersForCurrentWeekB2B } from '../../../../utils/filterOrdersForCurrentWeekB2B';
import { filterOrdersForCurrentWeekB2C } from '../../../../utils/filterOrdersForCurrentWeekB2C';
import { filterUpcomingOrdersB2C } from '../../../../utils/filterUpcomingOrdersB2C';
import { filterUpcomingOrdersB2B } from '../../../../utils/filterUpcomingOrdersB2B';

export default function CardTour(props) {

    // const [dates, setDates] = useState([new Date()]);
    // const weekDays = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
    // const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

 
    // const filterOrdersForTodayPU = (orders) => {
    //     const today = new Date();
    //     today.setHours(0, 0, 0, 0);
    
    //     return orders.filter(order => {
    //         const collecteDate = order.dateCollecte?.toDate();
    //         collecteDate.setHours(0, 0, 0, 0);
    //         return collecteDate.getTime() === today.getTime() && order.etat === 'valider';
    //     });
    // }
    // const filterOrdersForTodayDo = (orders) => {
    //     const today = new Date();
    //     today.setHours(0, 0, 0, 0);
    
    //     return orders.filter(order => {
    //         const livraisonDate = order.dateLivraison?.toDate();
    //         livraisonDate.setHours(0, 0, 0, 0);
    //         return livraisonDate.getTime() === today.getTime() && order.etat === 'valider';
    //     });
    // }
    // const filterOrdersForTodayStandard = (orders) => {    
    //     return orders.filter(order => {    
    //         return order.service === 'Standard' && order.etat === 'valider';
    //     });
    // }
    // const filterOrdersForTodayPrime = (orders) => {    
    //     return orders.filter(order => {    
    //         return order.service === 'Prime' && order.etat === 'valider';
    //     });
    // }
    const commandeDuJourB2C = filterOrdersForTodayB2C(props.ordersB2C);
    const commandeDuJourB2B = filterOrdersForTodayB2B(props.ordersB2B);
    const chauffeurAPrevoir = Math.ceil(
        ((filterOrdersForTodayB2C(props.ordersB2C).length / 15 < 1) && (filterOrdersForTodayB2C(props.ordersB2C).length !== 0) ? 1 : filterOrdersForTodayB2C(props.ordersB2C).length / 15) + 
        ((filterOrdersForTodayB2B(props.ordersB2B).length / 15 < 1) && (filterOrdersForTodayB2B(props.ordersB2B).length !== 0) ? 1 : filterOrdersForTodayB2B(props.ordersB2B).length / 15)
      );
    const commandeDeLaSemaineB2B = filterOrdersForCurrentWeekB2B(props.ordersB2B);
    const commandeDeLaSemaineB2C = filterOrdersForCurrentWeekB2C(props.ordersB2C);
    const chauffeurAPrevoirWeek = Math.ceil(
        ((filterOrdersForCurrentWeekB2C(props.ordersB2C).length / 15 < 1) && (filterOrdersForCurrentWeekB2C(props.ordersB2C).length !== 0) ? 1 : filterOrdersForCurrentWeekB2C(props.ordersB2C).length / 15) + 
        ((filterOrdersForCurrentWeekB2B(props.ordersB2B).length / 15 < 1) && (filterOrdersForCurrentWeekB2B(props.ordersB2B).length !== 0) ? 1 : filterOrdersForCurrentWeekB2B(props.ordersB2B).length / 15)
      );

    const commandeAVenirB2C = filterUpcomingOrdersB2C(props.ordersB2C);
    const commandeAVenirB2B = filterUpcomingOrdersB2B(props.ordersB2B);
    const chauffeurAPrevoirAVenir = Math.ceil(
        ((filterUpcomingOrdersB2C(props.ordersB2C).length / 15 < 1) && (filterUpcomingOrdersB2C(props.ordersB2C).length !== 0) ? 1 : filterUpcomingOrdersB2C(props.ordersB2C).length / 15) + 
        ((filterUpcomingOrdersB2B(props.ordersB2B).length / 15 < 1) && (filterUpcomingOrdersB2B(props.ordersB2B).length !== 0) ? 1 : filterUpcomingOrdersB2B(props.ordersB2B).length / 15)
      );
    // const filterUpcomingOrdersPU = (orders) => {
    //     const today = new Date();
    //     today.setHours(0, 0, 0, 0);
    
    //     return orders.filter(order => {
    //         const collecteDate = order.dateCollecte?.toDate();
    //         collecteDate.setHours(0, 0, 0, 0);
    //         return collecteDate.getTime() > today.getTime() && order.etat === 'valider';
    //     });
    // }
    // const filterUpcomingOrdersDo = (orders) => {
    //     const today = new Date();
    //     today.setHours(0, 0, 0, 0);
    
    //     return orders.filter(order => {
    //         const livraisonDate = order.dateLivraison?.toDate();
    //         livraisonDate.setHours(0, 0, 0, 0);
    //         return livraisonDate.getTime() > today.getTime() && order.etat === 'valider';
    //     });
    // }
 
    // const commandeAVenir = filterUpcomingOrders(props.orders);
    // const commandeAVenirPU = filterUpcomingOrdersPU(commandeAVenir);
    // const commandeAVenirDO = filterUpcomingOrdersDo(commandeAVenir);
    // const commandeAVenirStandard = filterOrdersForTodayStandard(commandeAVenir);
    // const commandeAVenirPrime = filterOrdersForTodayPrime(commandeAVenir);

    // const filterIncidentsOrders = (orders) => {
    //     const today = new Date();
    //     today.setHours(0, 0, 0, 0);
    
    //     return orders.filter(order => {
    //         const collecteDate = order.dateCollecte?.toDate();
    //         const livraisonDate = order.dateLivraison?.toDate();
    
    //         collecteDate.setHours(0, 0, 0, 0);
    //         livraisonDate.setHours(0, 0, 0, 0);
    
    //         return (collecteDate.getTime() < today.getTime() && livraisonDate.getTime() < today.getTime()) && order.etat === 'valider';
    //     });
    // }
    // const filterIncidentsOrdersPU = (orders) => {
    //     const today = new Date();
    //     today.setHours(0, 0, 0, 0);
    
    //     return orders.filter(order => {
    //         const collecteDate = order.dateCollecte?.toDate();
    //         collecteDate.setHours(0, 0, 0, 0);
    //         return collecteDate.getTime() < today.getTime() && order.etat === 'valider';
    //     });
    // }
    // const filterIncidentsOrdersDo = (orders) => {
    //     const today = new Date();
    //     today.setHours(0, 0, 0, 0);
    
    //     return orders.filter(order => {
    //         const livraisonDate = order.dateLivraison?.toDate();
    //         livraisonDate.setHours(0, 0, 0, 0);
    //         return livraisonDate.getTime() < today.getTime() && order.etat === 'valider';
    //     });
    // }
    // const commandeIncidents = filterIncidentsOrders(props.orders);
    // const commandeIncidentsPU = filterIncidentsOrdersPU(commandeIncidents);
    // const commandeIncidentsDO = filterIncidentsOrdersDo(commandeIncidents);
    // const commandeIncidentsStandard = filterOrdersForTodayStandard(commandeIncidents);
    // const commandeIncidentsPrime = filterOrdersForTodayPrime(commandeIncidents);
    const now = new Date();
    const options = { month: 'numeric', day: 'numeric' };
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - (now.getDay() || 7) + 1); // Si c'est dimanche, considérez-le comme 7
    startOfWeek.setHours(0, 0, 0, 0);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);
  return (
    <>
        <div className="d-flex flex-wrap justify-content-center">
        <div className="flex-fill p-2 d-flex justify-content-center align-items-center" style={{maxWidth: '450px'}}>
                <div className='row'>
                    <div className='col'>
                        <TitleCard>Commandes en cours</TitleCard>
                        
                    </div>
                    <div 
                        className='mx-auto p-4 pt-2 pb-2 mt-2 mb-2'
                        style={{ background: '#F1F1F1', width: '95%', borderRadius: '5px' }}
                    >
                                            <div className="row mb-3">
                            <div className="col">
                            <p className='m-0 p-0' style={{fontWeight: 'bold', fontSize: '4rem'}}>{filterOrdersForTodayB2B(props.ordersB2B).length + filterOrdersForTodayB2C(props.ordersB2C).length}</p>
                            </div>
                            <div className="col">
                            <p className='m-0 p-0 text-danger text-end' style={{fontSize: '1.2rem'}}><BsExclamationTriangle className='me-2 mb-1' size={18}/>commandes non assigné</p>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col">
                                <p className='m-0 p-0'>B2C : <span style={{fontWeight: '600'}}>{commandeDuJourB2C.length}</span></p>
                            </div>
                            <div className="col">
                                <p className='m-0 p-0'>B2B : <span style={{fontWeight: '600'}}>{commandeDuJourB2B.length}</span></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className='m-0 p-0'>Chauffeurs à prévoir : <span style={{fontWeight: '600'}}>{chauffeurAPrevoir}</span></p>
                            </div>
                            <div className="col">
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button style={{color:'#FFFFFF', cursor:'pointer', fontSize: '1.5rem', background: '#008080', borderRadius: '99px'}} className='btn btn-light my-2'>Générer <MdKeyboardArrowRight size={18}/></button>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-fill p-2 d-flex justify-content-center align-items-center" style={{maxWidth: '450px'}}>
                <div className='row'>
                    <div className='col'>
                        <TitleCard>Prévision de la semaine</TitleCard>
                    </div>
                    <div 
                        className='mx-auto p-4 pt-2 pb-2 mt-2 mb-2'
                        style={{ background: '#F1F1F1', width: '95%', borderRadius: '5px' }}
                        
                    >
                                                                <div className="row mb-3 mx-0">
                            <div className="col m-0">
                            <p className='m-0 p-0' style={{fontWeight: 'bold', fontSize: '4rem'}}>{commandeDeLaSemaineB2B.length + commandeDeLaSemaineB2C.length}</p>
                            </div>
                            <div className="col m-0 p-0">
                            <p className='m-0 pt-2 text-end' style={{fontSize: '1.4rem'}}>Du <span style={{fontWeight: 'bold' }}>{startOfWeek.toLocaleDateString('fr-FR', options)}</span> au <span style={{fontWeight: 'bold' }}>{endOfWeek.toLocaleDateString('fr-FR', options)}</span></p>
                            </div>
                        </div>
                                            <div className="row mb-3">
                            <div className="col">
                            <p className='m-0 p-0' style={{fontWeight: 'bold', fontSize: '4rem'}}>{}</p>
                            <p className='m-0 p-0'></p>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col">
                                <p className='m-0 p-0'>B2C : <span style={{fontWeight: '600'}}>{commandeDeLaSemaineB2C.length}</span></p>
                            </div>
                            <div className="col">
                                <p className='m-0 p-0'>B2B : <span style={{fontWeight: '600'}}>{commandeDeLaSemaineB2B.length}</span></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className='m-0 p-0'>Chauffeurs à prévoir : <span style={{fontWeight: '600'}}>{chauffeurAPrevoirWeek}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-fill p-2 d-flex justify-content-center align-items-center" style={{maxWidth: '450px'}}>
            <div className='row'>
                    <div className='col'>
                    <TitleCard>Prévision à venir</TitleCard>
  
                    </div>

                    <div 
                        className='mx-auto p-4 pt-2 pb-2 mt-2 mb-2'
                        style={{ background: '#F1F1F1', width: '95%', borderRadius: '5px' }}
                    >
                                            <div className="row mb-3">
                            <div className="col">
                            <p className='m-0 p-0' style={{fontWeight: 'bold', fontSize: '4rem'}}>{commandeAVenirB2C.length + commandeAVenirB2B.length}</p>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col">
                                <p className='m-0 p-0'>B2C : <span style={{fontWeight: '600'}}>{commandeAVenirB2C.length}</span></p>
                            </div>
                            <div className="col">
                                <p className='m-0 p-0'>B2B : <span style={{fontWeight: '600'}}>{commandeAVenirB2B.length}</span></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className='m-0 p-0'>Chauffeurs à prévoir : <span style={{fontWeight: '600'}}>{chauffeurAPrevoirAVenir}</span></p>
                            </div>
                        </div>
                    </div>

                    </div>

      
            </div>
        </div>
    </>
  )
}
