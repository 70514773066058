import CorporateDataService from "../../../../context/corporates.services";

export const UpdateTypeAccountCompany = async ({ id, value, enqueueSnackbar, corporate, setIsLoading }) => {
    try {
        if(value !== ''){
            if(value.toLowerCase() === 'true'){
                setIsLoading(true);
                await CorporateDataService.updateCorporate('underAccount', true, id); 
                enqueueSnackbar(`Le type de compte a bien été modifié avec la possibilité d'avoir des sous-comptes.`, {
                    variant: 'success',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    autoHideDuration: 3000,
                    style: {fontSize: '1.6rem'}
                       
                });
                return true;
            }else{
                setIsLoading(true);
                corporate.homeDelivery && await CorporateDataService.updateCorporate('homeDelivery', false, id);
                await CorporateDataService.updateCorporate('underAccount', false, id);
                enqueueSnackbar(`Le type de compte a bien été modifié en compte unique.`, {
                    variant: 'success',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    autoHideDuration: 3000,
                    style: {fontSize: '1.6rem'}  
                });
                return true;
            }
        }else{
            enqueueSnackbar(`Veuillez séléctionner une option`, {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}  
            });
        }
    }catch(e) {
        enqueueSnackbar(`${e.message}`, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoHideDuration: 3000,
            style: {fontSize: '1.6rem'}  
        });
        setIsLoading(false);
        return false;
    }
};