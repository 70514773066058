import React, { useState } from 'react'
import { Form, Modal } from 'react-bootstrap';
import ConfirmedButton from '../../../../../../components/Buttons/ConfirmedButton';
import { formatDate } from '../../../../../../utils/DateToString';
import { UpdatePassageCompany } from '../../../../../../utils/Corporate/Account/Update/UpdatePassageCompany';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';



export default function ModalConfirmed({show, setShow, onClick, isLoading, selectedStatus, status, passages, setIsLoading, id, getCorporate}) {

    const [underAccount, setUnderAccount] = useState(false);
    const [dates, setDates] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    const handleToggle = () => {
        setUnderAccount(!underAccount);
    };
    const handleClose = () => {
        setShow(false);
        setUnderAccount(false);
    };
    const trouverProchainJourSemaine = (date) => {
        // Trouver le jour de la semaine (0 pour dimanche, 1 pour lundi, etc.)
        const jourDeLaSemaine = date.getDay();
    
        // Ajouter 7 jours pour arriver au même jour la semaine suivante
        date.setDate(date.getDate() + 7);
    
        return date;
    }
    // const appliquerAuxElementsDuTableau = (tableauTimestamps) => {
    //     const newDates = tableauTimestamps.map(timestamp => {

    //         // Convertir le timestamp Firebase en objet Date JavaScript
    //         const date = new Date(timestamp.toDate());
    
    //         // Trouver le prochain jour de la semaine
    //         return trouverProchainJourSemaine(date);
    //     });
    //     setDates(newDates);
    //     return newDates;
    // }
    
    const updateOrder = async () => {
        onClick();
        handleClose();
        if((status === 5 || status === 6) && setUnderAccount) {
            await UpdatePassageCompany({ id, value: dates, enqueueSnackbar, setIsLoading });
            getCorporate();
        }
    }

    // useEffect(() => {
    //     passages && trouverProchainJourSemaine(passages);
    // }, [passages]);

    useEffect(() => {
        if (passages && passages.length > 0) {
            // Convertir le premier timestamp Firebase en objet Date JavaScript et trouver le prochain jour
            const dateModifiee = trouverProchainJourSemaine(new Date(passages[0].toDate()));
    
             // Convertir tous les passages en objets Date, sauf le premier
            const autresPassages = passages.slice(1).map(passage => new Date(passage.toDate()));

            // Créer un nouveau tableau avec les éléments restants et ajouter la date modifiée à la fin
            const newPassages = [...autresPassages, dateModifiee];
            setDates(newPassages); // Mettre à jour l'état avec le nouveau tableau
        }
    }, [passages]);

  return (
    <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{zIndex: '99999999999'}}
        centered
      >
        <Modal.Body className='m-5 text-center' style={{fontSize: '1.4rem'}}>
          <p className='mb-5'>Confirmez-vous le passage du statut à : <span style={{fontWeight: '600'}}>« {selectedStatus} »</span> ?</p>
          {(status === 5 || status === 6) &&
          (
            <>
            <Form.Check
            type="switch"
            id="custom-switch"
            label={        <span 
            style={{ fontStyle: 'italic' }} 
            className="text-secondary"
        >
            Souhaitez-vous également reporter les jours de passage à la prochaine occurrence prévue ?
        </span>}
            checked={underAccount}
            onChange={handleToggle}
        />
        {underAccount &&
            <>
                <p className='mt-3' style={{fontWeight: '600'}}>Les jours sélectionnés sont : </p>
                {dates && dates?.map((doc, index) => {
                      return (
                        <span className="p-0 m-0 mx-1" key={index} style={{fontWeight: '600', fontStyle: 'italic'}}>- {formatDate(doc)}</span>
                      )
                    })}
            </>
        }
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
            <button onClick={handleClose} className='btn btn-outline-danger' style={{fontSize: '1.4rem'}}>Annuler</button>
            <ConfirmedButton onClick={updateOrder} isLoading={isLoading} labelLoading="Traitement en cours" labelButton="Confirmer"/>
        </Modal.Footer>
      </Modal>
  )
}
