import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import CorporatesList from './Components/CorporatesList';
import { db } from '../../../../firebase-config';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Titre from '../../../../components/Titre';
import AddNewCorporate from './Components/Account/AddNewCorporate';
import Searchbar from '../../../../components/Searchbar';
import ModalConfirmedAddingCorporate from './Components/Account//ModalConfirmedAddingCorporate';

const collectionCorporates = process.env.REACT_APP_FIREBASE_COLLECTION_CORPORATES;
const ordersCorporateCollectionRef = process.env.REACT_APP_FIREBASE_COLLECTION_ORDERSB2B;

export default function CorporatePage({setIdB2B}) {

  const [showAddNewCorporate, setShowAddNewCorporate] = useState(false);
  const [showConfirmedNewCorporate, setShowConfirmedNewCorporate] = useState(false);
  const [corporates, setCorporates] = useState([]);
  // const [ordersB2B, setOrdersB2B] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [passwordProvisoire, setPasswordProProvisoire] = useState('');
  const [filteredCorporates, setFilteredCorporates] = useState([]);

  const onHideAddNewCorporate = () => setShowAddNewCorporate(false);

  const onShowAddNewCorporate = () => setShowAddNewCorporate(true);

  const onHideConfirmedNewCorporate = () => {
    setShowConfirmedNewCorporate(false)
    setPasswordProProvisoire('')
  };

  const onShowConfirmedNewCorporate = () => setShowConfirmedNewCorporate(true);

  const handleSearch = (term) => {
    setSearchTerm(term);
    if(term) {
        const lowercasedSearch = term.toLowerCase();
        const filtered = corporates.filter(corporate => 
          corporate.nameCompany?.toLowerCase().includes(lowercasedSearch)
        );
        setFilteredCorporates(filtered);
        setCurrentPage(0);
    } else {
      setFilteredCorporates(corporates);
    }
}
  useEffect(() => {
    const q = query(collection(db, collectionCorporates), orderBy("date_creation", "desc"))  ;
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const corporates = [];
        querySnapshot.forEach((doc) => {
          corporates.push({...doc.data(), idB2B: doc.id});
    });

    setCorporates(corporates);
    setFilteredCorporates(corporates);
    setCurrentPage(0);
    // Mettez fin au chargement des utilisateurs
    setIsLoading(false); 
    });

    return () => unsubscribe(); 
  }, []);

  // useEffect(() => {
  //   const q = query(collection(db, ordersCorporateCollectionRef), orderBy("date_collecte", "desc"));
  //   const unsubscribe = onSnapshot(q, (querySnapshot) => {
  //     const ordersB2B = [];
  //       querySnapshot.forEach((doc) => {
  //         ordersB2B.push({...doc.data(), idB2B: doc.id});
  //   });

  //   setOrdersB2B(ordersB2B);
  //   //Qd je gere la recherche par numero de commande je modifie ici
  //   // setFilteredCorporates(corporates);
  //   // setCurrentPage(0);
  //   // Mettez fin au chargement des utilisateurs
  //   setIsLoading(false); 
  //   });

  //   return () => unsubscribe(); 
  // }, []);

    return (
        <div>
        <AddNewCorporate  show={showAddNewCorporate} onHide={onHideAddNewCorporate} onShowConfirmedNewCorporate={onShowConfirmedNewCorporate} setPasswordProProvisoire={setPasswordProProvisoire}/>
        <ModalConfirmedAddingCorporate show={showConfirmedNewCorporate} onHide={onHideConfirmedNewCorporate} passwordProvisoire={passwordProvisoire} setPasswordProProvisoire={setPasswordProProvisoire}/>
        { isLoading ? (
          <>
            <p><Skeleton /></p>

            <p><Skeleton /></p>

            <p><Skeleton count={5}/></p>
          </>
        ) : (
          <>
            <Titre>Corporates</Titre><span style={{color:'#008080', cursor:'pointer', fontSize: '1.6rem'}} className='btn-link' onClick={onShowAddNewCorporate}><AiOutlinePlusCircle className='ms-5 me-2' size={14} color='#008080'/>Ajouter une nouvelle entreprise</span>

            <Searchbar placeholder='Rechercher...' onSearch={handleSearch}/>

            <CorporatesList corporates={filteredCorporates} currentPage={currentPage} setCurrentPage={setCurrentPage} />
          </>
        )}
        </div>
    );
}

