import React, { useEffect, useState } from 'react';
import Titre from '../../../../components/Titre';
import Card from '../../../../components/Card/Card';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../../../../firebase-config';
import Searchbar from '../../../../components/Searchbar';
import NavbarVertical from '../../../../components/NavbarVertical';
import OrdersList from './Components/OrdersList';
import { AiOutlinePlusCircle } from 'react-icons/ai';

const items = [
  "Tous",
  "Commande du jour",
  "Commande à venir",
  "Commande modifier / annuler",
  "Incidents"
];

export default function OrdersPage() {

  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);  
  const [currentPage, setCurrentPage] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentList, setCurrentList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");



  const handleSearch = (term) => {
    setSearchTerm(term);
    if(term) {
        const lowercasedSearch = term.toLowerCase();
        const filtered = currentList.filter(order => 
          order.idFacture?.toLowerCase().includes(lowercasedSearch)
        );
        setFilteredOrders(filtered);
        setCurrentPage(0);
    } else {
        setFilteredOrders(currentList);
    }
}

  
const filterOrdersForToday = (orders) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return orders.filter(order => {
        const collecteDate = order.dateCollecte?.toDate();
        const livraisonDate = order.dateLivraison?.toDate();

        collecteDate.setHours(0, 0, 0, 0);
        livraisonDate.setHours(0, 0, 0, 0);

        return (collecteDate.getTime() === today.getTime() || livraisonDate.getTime() === today.getTime()) && order.etat === 'valider';
    });
}
const filterUpcomingOrders = (orders) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return orders.filter(order => {
        const collecteDate = order.dateCollecte?.toDate();
        const livraisonDate = order.dateLivraison?.toDate();

        collecteDate.setHours(0, 0, 0, 0);
        livraisonDate.setHours(0, 0, 0, 0);

        return (collecteDate.getTime() > today.getTime() || livraisonDate.getTime() > today.getTime()) && order.etat === 'valider';
    });
}
const filterIncidentsOrders = (orders) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return orders.filter(order => {
        const collecteDate = order.dateCollecte?.toDate();
        const livraisonDate = order.dateLivraison?.toDate();

        collecteDate.setHours(0, 0, 0, 0);
        livraisonDate.setHours(0, 0, 0, 0);

        return (collecteDate.getTime() < today.getTime() && livraisonDate.getTime() < today.getTime()) && order.etat === 'valider';
    });
}
const filterRemoveEditOrders = (orders) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return orders.filter(order => {
      const collecteDate = order.dateCollecte?.toDate();
      const livraisonDate = order.dateLivraison?.toDate();

      collecteDate.setHours(0, 0, 0, 0);
      livraisonDate.setHours(0, 0, 0, 0);

      return (collecteDate.getTime() < today.getTime() && livraisonDate.getTime() < today.getTime()) && (order.etat === 'annuler' || (order.modifier && order.etat === 'valider'));
  });
}


const changeData = (index) => {
  let newList = [];
  switch (index) {
      case 0:
        newList = [...orders];
          // setFilteredOrders(prevOrders => orders);
          // setCurrentPage(0);
          break;
      case 1:
        newList = filterOrdersForToday(orders);
          // setFilteredOrders(prevOrders => filterOrdersForToday(orders));
          // setCurrentPage(0);
          break;
      case 2:
        newList = filterUpcomingOrders(orders)
          // setFilteredOrders(prevOrders => filterUpcomingOrders(orders));
          // setCurrentPage(0);
          break;
      case 3:
        newList = filterRemoveEditOrders(orders)
          // setFilteredOrders(prevOrders => filterRemoveEditOrders(orders));
          // setCurrentPage(0);
          break;
      case 4:
        newList = filterIncidentsOrders(orders)
          // setFilteredOrders(prevOrders => filterIncidentsOrders(orders));
          // setCurrentPage(0);
          break;
      default:
        newList = [...orders];
          // setFilteredOrders(prevOrders => orders);
          // setCurrentPage(0);
          break;
  }
  setCurrentList(newList);
  // Si un terme de recherche est défini, filtrez la liste en conséquence
  if (searchTerm) {
    const lowercasedSearch = searchTerm.toLowerCase();
    newList = newList.filter(order => 
        order.idFacture?.toLowerCase().includes(lowercasedSearch)
    );
}

setFilteredOrders(newList);
setCurrentPage(0);

}


  useEffect(() => {
    const q = query(collection(db, "orders"), orderBy("date_creation", "desc"))  ;
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const orders = [];
        querySnapshot.forEach((doc) => {
          orders.push(doc.data());
    });
    setOrders(orders);
    let newList = [];
    newList = [...orders];
    setFilteredOrders(newList);
    setCurrentList(newList);
    setCurrentPage(0);
    });

    return () => unsubscribe(); 
  }, []);


    return (
      <div>
        <Titre>Commandes</Titre><span style={{color:'#008080', cursor:'pointer'}} className='btn-link'><AiOutlinePlusCircle className='ms-5 me-2' size={14} color='#008080'/>Ajouter une nouvelle commande</span>
        <Card orders={orders}/>
        <Searchbar placeholder='Rechercher...' onSearch={handleSearch}/>
        <NavbarVertical items={items} changeData={changeData} setActiveIndex={setActiveIndex} activeIndex={activeIndex}/>
        <OrdersList orders={filteredOrders} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
        </div>
    );
}