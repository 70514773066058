import React from 'react'
import { Table } from 'react-bootstrap'
import './UsersList.css'
import { useNavigate } from 'react-router-dom';
import { formatDateComplete, formatDate } from '../../../../../utils/DateToString'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function OrdersList(props) {

    // Fonction pour capitaliser la première lettre d'une chaîne
    const capitalizeFirstLetter = (string) => {
        if (!string) return ''; // vérifie si la chaîne est définie
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      }
  
  // Fonction pour mettre une chaîne entière en majuscules
  const toUpperCase = (string) => {
    return string ? string.toUpperCase() : ''; 
  }
    const navigate = useNavigate();
    const PAGE_SIZE = 5;
    const nextPage = () => {
        if ((props.currentPage + 1) * PAGE_SIZE < props.users.length) {
            props.setCurrentPage(props.currentPage + 1);
        }
    };


    const prevPage = () => {
        if (props.currentPage > 0) {
            props.setCurrentPage(props.currentPage - 1);
        }
    };

    const paginatedOrders = props.users.slice(props.currentPage * PAGE_SIZE, (props.currentPage + 1) * PAGE_SIZE);
/**
 * Calcule la catégorie d'un client en fonction de sa moyenne de commandes par mois depuis son inscription
 * @param {string} clientId - L'identifiant du client
 * @param {Array} orders - La liste des commandes
 * @param {Date} registrationDate - La date d'inscription du client
 * @returns {string} - La catégorie du client : 'lite', 'standard', 'vip' ou 'none'
 */
const classifyClient = (clientId, registrationDate, nbCommandes) => {
    // Filtrer les commandes pour ce client
    const clientOrders = props.orders.filter(order => order.idClient === clientId);

    // Initialiser un objet pour stocker le nombre de commandes par mois
    let monthlyOrders = {};

    clientOrders.forEach(order => {
        const month = order.date_collecte?.toDate().getMonth();
        const year = order.date_collecte?.toDate().getFullYear();
        const key = `${year}-${month}`;

        if (!monthlyOrders[key]) {
            monthlyOrders[key] = 0;
        }
        monthlyOrders[key]++;
    });

    // Calculer le total de commandes
    const totalOrders = Object.values(monthlyOrders).reduce((a, b) => a + b, 0);

    // Calculer le nombre de mois depuis la date d'inscription jusqu'à aujourd'hui
    const currentDate = new Date();
    const monthsSinceRegistration = (currentDate.getFullYear() - registrationDate?.toDate().getFullYear()) * 12 + currentDate.getMonth() - registrationDate?.toDate().getMonth() + 1;

    // Calculer la moyenne
    const averageOrdersPerMonth = totalOrders / monthsSinceRegistration;

    if (!nbCommandes) {
        return '';
    } else if (averageOrdersPerMonth <= 5) {
        return 'Lite';
    } else if (averageOrdersPerMonth <= 10) {
        return 'Standard';
    } else {
        return 'Vip';
    }
}

  return (
    <>
    <Table responsive="sm" className=' mx-auto text-start table-personnalisee '>
    <thead>
      <tr>
        <th>Date de création</th>
        <th>Téléphone</th>
        <th>Client</th>
        <th>Email</th>
        <th>Orders</th>
        <th>Status</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      {props.isUsersLoading ?
        <p><Skeleton count={5}/></p> :
<>
{paginatedOrders?.length > 0 ? paginatedOrders?.map((user) => {
            return (
                <tr key={user.id} className='py-5'>
                <td>{formatDateComplete(user.date_inscription?.toDate().toISOString())}</td>
                <td>{user.tel}</td>
                <td>{capitalizeFirstLetter(user.prenom)} {toUpperCase(user.nom)}</td>
                <td>{user.mail}</td>
                <td>{user.nbCommandes}</td>
                <td>
                    <span style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        borderRadius: '35%',
                        backgroundColor: classifyClient(user.id, user.date_inscription, user.nbCommandes) === 'Lite' ? '#39FF33' : classifyClient(user.id, user.date_inscription, user.nbCommandes) === 'Standard' ?'#FF5733' : classifyClient(user.id, user.date_inscription, user.nbCommandes) ? '#FFD700' : 'transparent',
                        marginRight: '5px'
                    }}>
                    </span>{classifyClient(user.id, user.date_inscription, user.nbCommandes)}</td>
                <td className='btn-link' style={{cursor: 'pointer'}} onClick={() => navigate(`/private/Customers/${user.id}`)}>Voir détails</td>
                </tr>

            )
        }) : <tr><td colSpan="6" className='text-center secondary' style={{fontStyle: 'italic'}}>{props.isLoading ? <Skeleton count={5}/> : 'Aucune résultat'}</td></tr>}
</>

      }
        


    </tbody>
  </Table>
  <div className="d-flex flex-wrap justify-content-center my-5 bg-light py-2 rounded">
  <div className='flex-fill p-2 d-flex justify-content-center align-items-center'>{props.users.length} résultats</div>
  <div className='flex-fill p-2 d-flex justify-content-center align-items-center'>
  <button onClick={prevPage} disabled={props.currentPage === 0} style={{border: '1px solid #e0e0e0', borderRadius: '8px', fontSize: '1.2rem'}} className="btn">Précédent</button>
                <button onClick={nextPage} className="ms-3 btn" style={{border: '1px solid #e0e0e0', borderRadius: '8px', fontSize: '1.2rem'}} disabled={(props.currentPage + 1) * PAGE_SIZE >= props.users.length}>Suivant</button>
  </div>
            </div>
            </>
  )
}
