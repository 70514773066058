import { doc, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { IoChevronBackCircleOutline } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '../../../../firebase-config';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { formatDate } from '../../../../utils/DateToString';

export default function ModalDetailB2C() {

    const navigate = useNavigate();
    const { id } = useParams();
    const [order, setOrder] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);

    useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, "orders", id), (doc) => {
            setOrder({ ...doc.data(), id: doc.data()});
            setIsLoading(false);
        });
      
          return () => unsubscribe(); 
}, []);

    const showStatusOrder = (status) => {

        switch (status) {
            case 1 : 
                return ' En attente de récupération';
                break;
            case 2 : 
                return ' En attente de nettoyage';
                break;
            case 3 : 
                return ' En attente de livraison';
                break;
            case 4 : 
                return ' En cours de livraison';
                break;
            default : return 'ERROR';
        }
    };
  return (
    <div className="view_page">
       {
        isLoading ? (
          <>
          <p className='m-5'>
          <Skeleton />
          </p>

          <p className='m-3'>
          <Skeleton count={2}/>
          </p>

          <p className='m-5'>
          <Skeleton />
          <Skeleton count={12}/>
          </p>
          </>

          
        ) : (
            <>
            <div className='d-flex justify-content-between'>
            <p className='my-5'><IoChevronBackCircleOutline size={40} className='ms-5' color='#008080' style={{cursor: 'pointer'}} onClick={() => navigate(-1)}/><span style={{fontWeight: '600', letterSpacing: '1.4px'}} className='ms-3'>{order?.idFacture}</span></p>
            <div className='align-self-center'>
            <button className='btn me-4 m-0 px-3 py-0' style={{fontSize: '1.5rem', border: '1px solid #008080', color: '#008080'}}>étiqueté la commande</button>
            </div>

            </div>
           
            <div className='mx-auto px-4 py-3 my-2 mb-3' style={{ background: '#F1F1F1', width: '95%', borderRadius: '5px' }}>
            {order?.modifier && <div className='d-grid gap-2 d-flex justify-content-end'>   
            <span style={{
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: '#FF5733',
                          marginRight: '5px'
                        }}></span>
                        </div>}
                  <div className="row justify-content-between">
                    <div className='col-sm-12 col-md-6 align-self- text-start'>
                      <p style={{ fontWeight: '600' }} className= 'py-1'>N° de commande : <span style={{ fontWeight: '500' }}>{order?.idFacture}</span></p>
                      <p className= 'py-1' style={{ fontWeight: '600' }}>Service : <span style={{ fontWeight: '500' }}>{order?.service}</span></p>
                      <p className= 'py-1' style={{ fontWeight: '600' }}>Etat : <span style={{ fontWeight: '500' }}>{order?.etat}</span></p>
                    </div>

                    
                    <div className='col-sm-12 col-md-6 align-self-center text-start'>
                    <p style={{ fontWeight: '600' }}>Collecte : <span style={{ fontWeight: '500' }}>
                    {formatDate(order?.dateCollecte.toDate())} | {order.creneauCollecte}</span></p>
                    <p style={{ fontWeight: '600' }}>Livraison : <span style={{ fontWeight: '500' }}>
                    {formatDate(order?.dateLivraison.toDate())} | {order.creneauLivraison}</span></p>
                    <p className= 'py-1' style={{ fontWeight: '600' }}>Suivi : <span style={{ fontWeight: '500' }}>{showStatusOrder(order?.steps)}</span></p>
                    </div>
                  </div>
                </div>

                <p className='p-4 text-light m-5' style={{ fontWeight: '600', fontStyle: 'italic', background: '#008080', borderRadius: '5px', letterSpacing: '1.2px'}}>Récapulatif Chauffeur</p>

                <p className='text-center' style={{fontSize: '1.2rem', fontWeight:'400', fontStyle: 'italic', letterSpacing: '1.4px'}}>Non disponible</p>

                <p className='p-4 text-light m-5' style={{ fontWeight: '600', fontStyle: 'italic', background: '#008080', borderRadius: '5px', letterSpacing: '1.2px'}}>Récapulatif Prestataire</p>

                {items.length < 1 && <p className='text-center' style={{fontSize: '1.2rem', fontWeight:'400', fontStyle: 'italic', letterSpacing: '1.4px'}}>Veuillez étiqueté la commande</p>}
            </>
        )
       }
    </div>
  )
}
