import { collection, doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { Container, Dropdown, DropdownButton } from 'react-bootstrap';
import { db } from '../../../../firebase-config';
import NavbarItems from './NavbarItems'
import Produits from './Produits';

export default function ItemsPage() {
    const [isActive, setIsActive] = useState(true);
    const [tarifs, setTarifs] = useState([]);

    useEffect(() => {
			const unsubscribe = onSnapshot(collection(db, "tarifs"), (querySnapshot) => {
			  const newTarifs = [];
				querySnapshot.forEach((doc) => {
					newTarifs.push({ ...doc.data(), id: doc.id });
		  });
		  setTarifs(newTarifs);
		  });
		
			return () => unsubscribe(); 
	  }, []);


    const [search, setSearch] = useState('');
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedUnderUserIndex, setSelectedUnderUserIndex] = useState(0);
    const [selectedOrderId, setSelectedOrderId] = useState('');
    const [selectedEtat, setSelectedEtat] = useState(null);
    const [selectedEtatFirebase, setSelectedEtatFirebase] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
			const unsubscribe = onSnapshot(collection(db, "Orders_B2B"), (querySnapshot) => {
			  const newOrders = [];
				querySnapshot.forEach((doc) => {
					newOrders.push({ ...doc.data(), id: doc.id });
		  });
		  setOrders(newOrders);
		  });
		
			return () => unsubscribe(); 
	  }, []);
    const handleSelect = (item) => {
      setSelectedOrder(selectedTarif => item);
  };
  const handleSelectEtat = (item) => {
    setSelectedEtatFirebase(parseInt(item));
    switch (parseInt(item)) {
      case 2: setSelectedEtat('Récéptionner'); break;
      case 3: setSelectedEtat('En cours de traitement'); break;
      case 4: setSelectedEtat('En cours de livraison'); break;
      case 5: setSelectedEtat('Livrée'); break;
      case 6: setSelectedEtat('Annulée'); break;
      default: setSelectedEtat('');
    }
};

const handleSave = async() => {
  if(selectedOrder && selectedEtat){
          // Récupérez le document courant
          const orderDocRef = doc(db, "Orders_B2B", selectedOrderId);
    if(selectedEtatFirebase === 2){
      const orderDocSnap = await getDoc(orderDocRef);
      const orderData = orderDocSnap.data();
      const recap = orderData.under_user?.[selectedUnderUserIndex]?.recap_driver;
      const updatedUnderUser = orderData.under_user.map((user, index) => {
        if (index === selectedUnderUserIndex) {
          return { ...user, recap_driverBis: recap, recap_presta: recap };
        }
        return user;
      });
      await updateDoc(orderDocRef, {
        etat_commande: selectedEtatFirebase,
        under_user: updatedUnderUser
      });
    }else{
      await updateDoc(orderDocRef, {
        etat_commande: selectedEtatFirebase,
      });
    }
    enqueueSnackbar('Grille tarifaire enregistrée avec succès', { 
      variant: 'success',
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      autoHideDuration: 3000,
      className:'text-center'
     
    });

  }else{
    enqueueSnackbar('Error', { 
      variant: 'error',
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      autoHideDuration: 3000,
      className:'text-center'
     
    });
  }
};
  return (
    <div>
    <NavbarItems 
        isActive={isActive}
        setIsActive={setIsActive}
    />
     {
        isActive ? (
            
            <Produits 
                tarifs={tarifs}
            />
        ) :
        (
            <Container className='mt-4'>
            <div className="d-flex align-items-start align-items-md-center flex-md-row flex-column mb-3">
            <p className='pt-3' style={{ fontWeight: '600' }}>Selectionner une commande </p>
            <DropdownButton
                title={selectedOrder ? selectedOrder : 'Sélectionner une commande'}
                onSelect={handleSelect}
                id='dropdown-button-drop-down-centered'
                drop='down-centered'
                className='custom-dropdown mx-md-3'
          >
          <Dropdown.Item  eventKey={null} onClick={(event) => event.stopPropagation()}>
            <input type="text" className="form-control" placeholder="ex: CP-...." onChange={(e) => setSearch(e.target.value)}/>
          </Dropdown.Item>
          <Dropdown.Divider />
            {orders?.map((item) => (
              item.under_user?.map((user, index3) => ({...user, indexHistory: index3})).filter((doc) => {
          return search.toLowerCase() === ""
            ? doc
            : doc.number_order.toLowerCase().includes(search.toLowerCase());
        }).map((user, index2) => {
                return (
                  <Dropdown.Item 
                    key={index2}
                    eventKey={user.number_order}
                    onClick={() => {setSelectedUnderUserIndex(selectedUnderUserIndex => user.indexHistory) ; setSelectedOrderId(selectedOrder => item.id); console.log(item)}}
                    disabled={item.id === 'global'} style={item.id === 'global' ? {background: '#F1F1F1', color: 'grey'} : {}}>
                  {user.number_order}
                </Dropdown.Item>
                )
              })
               
            ))}
          </DropdownButton>
            </div>

            <div className="d-flex align-items-start align-items-md-center flex-md-row flex-column mb-3">
            <p className='pt-3' style={{ fontWeight: '600' }}>Etat </p>
            <DropdownButton
              title={selectedEtat ? selectedEtat : "Séléctionner l'état"}
              onSelect={handleSelectEtat}
              id='dropdown-button-drop-down-centered'
              drop='down-centered'
              className='custom-dropdown mx-md-3'
            >
              <Dropdown.Item eventKey={2}>Récéptionner</Dropdown.Item>
              <Dropdown.Item eventKey={3}>En cours de traitement</Dropdown.Item>
              <Dropdown.Item eventKey={4}>En cours de livraison</Dropdown.Item>
              <Dropdown.Item eventKey={5}>Livrée</Dropdown.Item>
              <Dropdown.Item eventKey={6}>Annulée</Dropdown.Item>
            </DropdownButton>
            </div>

            <button className='btn btn-success' onClick={handleSave}>Valider</button>
            
            </Container>
        )
     }
    </div>
  )
}
