import React, { useEffect, useState } from 'react';
import { Button, Container, Modal, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import CorporateDataService from "../../../../context/corporates.services";
import AddOrder from './AddOrder';
import { AiFillEdit, AiOutlinePlusCircle } from 'react-icons/ai';
import { MdDeleteForever, MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { IoChevronBackCircleOutline } from 'react-icons/io5';
import { formatDate, formatDateComplete } from '../../../../utils/DateToString';
import { FaEye, FaPencilAlt } from 'react-icons/fa'; // Icône de crayon de Font Awesome
import Searchbar from '../../../../components/Searchbar';
import OrdersCorporateList from './Components/ListOrders/OrdersCorporateList';
import UnderUserCorporatesList from './Components/UnderAccount/UnderUserCorporatesList';
import EditCorporate from './Components/Account/EditCorporate';
import ModalPrice from './Components/Price/ModalPrice';
import { TrainRounded } from '@mui/icons-material';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../../../firebase-config';
import Skeleton from 'react-loading-skeleton';
import ModalDetailOrderB2B from './Components/ListOrders/ModalDetailOrderB2B';
import UnderUserCorporatesBills from './Components/UnderAccount/UnderUserCorporateBills';

const ordersCorporateCollectionRef = process.env.REACT_APP_FIREBASE_COLLECTION_ORDERSB2B;
const uderUserCorporateCollectionRef = process.env.REACT_APP_FIREBASE_COLLECTION_CUSTOMERSCORPORATEUNDERACCOUNT;
const billsCorporateCollectionRef = process.env.REACT_APP_FIREBASE_COLLECTION_BILLSB2B;

export default function DetailsCorporatePage({}) {

  const { id } = useParams();
  const [corporate, setCorporate] = useState({});
  const [orders, setOrders] = useState([]);
  const [underUsers, setUnderUsers] = useState([]);
  const [bills, setBills] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermUnderUser, setSearchTermUnderUser] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageUnderUser, setCurrentPageUnderUser] = useState(0);
  const [currentPageBills, setCurrentPageBills] = useState(0);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filteredUnderUser, setFilteredUnderUser] = useState([]);
  const [filteredBills, setFilteredBills] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowIndex, setModalShowIndex] = useState(0);
  const [showPrice, setShowPrice] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showDetailsOrder, setShowDetailsOrder] = useState(false);
  const [orderB2BID, setOrderB2BID] = useState({});

  const handleEditCorporate = (index) => {
    setModalShowIndex(index);
    setModalShow(true)
  }
  const handleSearch = (term) => {
    setSearchTerm(term);
    
    if (term) {
      const lowercasedSearch = term.toLowerCase();
      const filtered = orders.filter(order =>
        order.under_user?.some(underUser => 
          underUser.number_order?.toLowerCase().includes(lowercasedSearch)
        )
      );
  
      setFilteredOrders(filtered);
      setCurrentPage(0);
    } else {
      setFilteredOrders(orders);
    }
  };
  const handleSearchUnderUser = (term) => {
    setSearchTermUnderUser(term);
    if(term) {
        const lowercasedSearch = term.toLowerCase();
        const filtered = underUsers.filter(underUser => 
          underUser.first_name?.toLowerCase().includes(lowercasedSearch) || underUser.last_name?.toLowerCase().includes(lowercasedSearch)
        );
        setFilteredUnderUser(filtered);
        setCurrentPageUnderUser(0);
    } else {
      setFilteredUnderUser(underUsers);
    }
  };
  const getCorporate = async () => {
    const data = await CorporateDataService.getCorporateAccount(id);
    setCorporate({ ...data.data(), id: data.id});
  };

  useEffect(() => {
    const q = query(collection(db, ordersCorporateCollectionRef), where("user", "==", id), orderBy("date_collecte", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const ordersB2B = [];
      querySnapshot.forEach((doc) => {
        ordersB2B.push({...doc.data(), idB2B: doc.id});
      });
      setOrders(ordersB2B);
      setFilteredOrders(ordersB2B);
      setCurrentPage(0);
      // Mettez fin au chargement des utilisateurs
      setIsLoading(false); 
    });
    return () => unsubscribe(); 
  }, []);
  useEffect(() => {
    const q = query(collection(db, uderUserCorporateCollectionRef), where("id_company", "==", id), orderBy("date_create", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const underUser = [];
      querySnapshot.forEach((doc) => {
        underUser.push({...doc.data(), idUnderUser: doc.id});
      });
      setUnderUsers(underUser);
      setFilteredUnderUser(underUser);
      setCurrentPageUnderUser(0);
      // Mettez fin au chargement des utilisateurs
      setIsLoading(false); 
    });
    return () => unsubscribe(); 
  }, []);
  useEffect(() => {
    const q = query(collection(db, billsCorporateCollectionRef), where("user", "==", id), orderBy("date", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const billsB2B = [];
      querySnapshot.forEach((doc) => {
        billsB2B.push({...doc.data(), idB2B: doc.id});
      });
      setBills(billsB2B);
      setFilteredBills(billsB2B);
      setCurrentPageBills(0);
      // Mettez fin au chargement des utilisateurs
      setIsLoading(false); 
    });
    return () => unsubscribe(); 
  }, []);
  useEffect(() => {
    getCorporate();
    setCurrentPage(0);
    setCurrentPageUnderUser(0);
  }, []);
















  //Champs pour ajouter les sous compte
  const [emailUnderUser, setEmailUnderUser] = useState('');
  const [firstNameUnderUser, setFirstNameUnderUser] = useState('');
  const [lastNameUnderUser, setLastNameUnderUser] = useState('');
  //Afficher le popUp ajouter un sous compte
  const [showModalAddUnderUser, setShowModalAddUnderUser] = useState(false);
  const [showModalAddOrder, setShowModalAddOrder] = useState(false);
  const navigate = useNavigate();
  const [sommeTotal, setSommeTotal] = useState(0);
  //Fonction pour ajouter un sous compte
  const pushUnderUser = async () => {
    const newUnderUser = {
      date_create: new Date(),
      email: emailUnderUser,
      first_name: firstNameUnderUser,
      id_company: id,
      last_name: lastNameUnderUser
    }
    const data = await CorporateDataService.addUnderUser(newUnderUser);
    setFirstNameUnderUser('');
    setLastNameUnderUser('');
    setEmailUnderUser('');
    setShowModalAddUnderUser(false);
  };
  const [modalShowDetail, setModalShowDetail] = useState(false);
  const [orderCorporate, setOrderCorporate] = useState();
  const [idOrder, setIdOrder] = useState('');
  const [editStepOrder, setEditStepOrder] = useState(false);
  const [showConfirmationDeleteOrder, setShowConfirmationDeleteOrder] = useState(false);
  const handleDeleteOrder = async () => {
    await CorporateDataService.DeleteOrder(idOrder);
    setShowConfirmationDeleteOrder(false)
    setEditStepOrder(false);
    setModalShowDetail(false);
    setEditStepOrder(false);
  }







  return (
    <div className="view_page">
      <EditCorporate 
        modalShow={modalShow} 
        setModalShow={setModalShow}
        modalShowIndex={modalShowIndex}
        id={id}
        getCorporate={getCorporate}
        corporate={corporate}
      />
      <ModalPrice 
        show={showPrice}
        onHide={() => setShowPrice(false)}
        id={corporate?.price}
      />

      <ModalDetailOrderB2B 
        showDetailsOrder={showDetailsOrder}
        setShowDetailsOrder={setShowDetailsOrder}
        underUsers={underUsers}
        orderB2BID={orderB2BID}
        getCorporate={getCorporate}




        corporate={corporate}
        
        orderCorporate={orderCorporate}
        setModalShowDetail={setModalShowDetail}
        sommeTotal={sommeTotal}
        setShowConfirmationDeleteOrder={setShowConfirmationDeleteOrder}
        editStepOrder={editStepOrder}
        setEditStepOrder={setEditStepOrder}
        setIdOrder={setIdOrder}
        setOrderCorporate={setOrderCorporate}
        setSommeTotal={setSommeTotal}
        idOrder={idOrder}
      />

      <IoChevronBackCircleOutline size={40} className='my-5 mx-5' color='#008080' style={{cursor: 'pointer'}} onClick={() => navigate(-1)}/>
      { isLoading ? (
          <>
            <p><Skeleton /></p>

            <p><Skeleton /></p>

            <p><Skeleton count={5}/></p>

            <p><Skeleton count={5}/></p>

            <p><Skeleton count={5}/></p>

            <p><Skeleton count={5}/></p>

            <p><Skeleton count={5}/></p>
          </>
        ) : (
          <>
          {corporate ? <>
        <div className="container">
        <div className="row">
          <div className="col" style={{borderRight: '2px solid #008080'}}>
          <div className="mx-md-5 px-md-2">
            <p style={{letterSpacing: '2px', fontWeight: '600', fontSize: '1.8rem', borderBottom: '1px solid #008080' }}>Informations entreprise <FaPencilAlt color='#008080' style={{borderBottom: 'none', cursor: 'pointer'}} size={12} onClick={() => handleEditCorporate(0)}/></p>
          </div>
          <div className="container my-5 py-2 mx-md-5 px-md-2">
            <div className="row">
              <div className="col-md-5" style={{borderRight: '1px solid #C0C0C0'}}>
                <p style={{letterSpacing: '2px', fontWeight: '600'}}>Entreprise</p>
              </div>
              <div className="col-md-7">
                <p className='ps-3'>{corporate.nameCompany}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5" style={{borderRight: '1px solid #C0C0C0'}}>
                <p style={{letterSpacing: '2px', fontWeight: '600'}}>Status</p>
              </div>
              <div className="col-md-7">
                <p className='ps-3'><span style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        borderRadius: '15%',
                        backgroundColor: corporate.status === 1 ? '#39FF33' : corporate.status === 2 ? '#FF5733' : corporate.status === 3 ? '#FFD700' : 'transparent',
                        marginRight: '5px'
                    }}>
                    </span>{corporate.status === 1 ? 'Activé' : corporate.status === 2 ? 'Archivé' : corporate.status === 3 ? 'En pause' : 'Error'}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5" style={{borderRight: '1px solid #C0C0C0'}}>
                <p style={{letterSpacing: '2px', fontWeight: '600'}}>Date d'inscription</p>
              </div>
              <div className="col-md-7">
                <p className='ps-3'>{formatDateComplete(corporate.date_creation?.toDate().toISOString())}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5" style={{borderRight: '1px solid #C0C0C0'}}>
                <p style={{letterSpacing: '2px', fontWeight: '600'}}>N° de téléphone</p>
              </div>
              <div className="col-md-7">
                <p className='ps-3'>{corporate.telCompany}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5" style={{borderRight: '1px solid #C0C0C0'}}>
                <p style={{letterSpacing: '2px', fontWeight: '600'}}>Adresse de livraison</p>
              </div>
              <div className="col-md-7">
                <p className='ps-3'>{corporate.adressLivraisonCompany}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5" style={{borderRight: '1px solid #C0C0C0'}}>
                <p style={{letterSpacing: '2px', fontWeight: '600'}}>Siret/Siren</p>
              </div>
              <div className="col-md-7">
                <p className='ps-3'>{corporate.siretCompany}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5" style={{borderRight: '1px solid #C0C0C0'}}>
                <p style={{letterSpacing: '2px', fontWeight: '600'}}>Email</p>
              </div>
              <div className="col-md-7">
                <p className='ps-3'>{corporate.emailCompany}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5" style={{borderRight: '1px solid #C0C0C0'}}>
                <p style={{letterSpacing: '2px', fontWeight: '600'}}>Type de compte</p>
              </div>
              <div className="col-md-7">
                <p className='ps-3'>{!corporate.underAccount ? 'Compte unique' : 'Avec des sous comptes'}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5" style={{borderRight: '1px solid #C0C0C0'}}>
                <p style={{letterSpacing: '2px', fontWeight: '600'}}>Livraison à domicile</p>
              </div>
              <div className="col-md-7">
                <p className='ps-3'>{corporate.homeDelivery ? 'Activée' : 'désactivée'}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5" style={{borderRight: '1px solid #C0C0C0'}}>
                <p style={{letterSpacing: '2px', fontWeight: '600'}}>Créneaux horaire</p>
              </div>
              <div className="col-md-7">
                <p className='ps-3'>{corporate.slot_start} - {corporate.slot_end}</p>
              </div>
            </div>

          </div>
          </div>
          <div className="col">
          <div className="mx-md-5 px-md-2">
            <p style={{letterSpacing: '2px', fontWeight: '600', fontSize: '1.8rem', borderBottom: '1px solid #008080' }}>Personne de contact <FaPencilAlt color='#008080' style={{borderBottom: 'none', cursor: 'pointer'}} size={12} onClick={() =>  handleEditCorporate(1)}/></p>
          </div>

          <div className="container my-5 py-2 mx-md-5 px-md-2">
            <div className="row">
              <div className="col-md-5" style={{borderRight: '1px solid #C0C0C0'}}>
                <p style={{letterSpacing: '2px', fontWeight: '600'}}>Contact</p>
              </div>
              <div className="col-md-7">
                <p className='ps-3'>{corporate.nameContact}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5" style={{borderRight: '1px solid #C0C0C0'}}>
                <p style={{letterSpacing: '2px', fontWeight: '600'}}>Email de contact</p>
              </div>
              <div className="col-md-7">
                <p className='ps-3'>{corporate.emailContact}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5" style={{borderRight: '1px solid #C0C0C0'}}>
                <p style={{letterSpacing: '2px', fontWeight: '600'}}>N° de téléphone</p>
              </div>
              <div className="col-md-7">
                <p className='ps-3'>{corporate.telContact}</p>
              </div>
            </div>

          </div>


          <div className="mx-md-5 px-md-2">
            <p style={{letterSpacing: '2px', fontWeight: '600', fontSize: '1.8rem', borderBottom: '1px solid #008080' }}>Facturation <FaPencilAlt color='#008080' style={{borderBottom: 'none', cursor: 'pointer'}} size={12} onClick={() =>  handleEditCorporate(2)}/></p>
          </div>

          <div className="container my-5 py-2 mx-md-5 px-md-2">
            <div className="row">
              <div className="col-md-5" style={{borderRight: '1px solid #C0C0C0'}}>
                <p style={{letterSpacing: '2px', fontWeight: '600'}}>Adresse de facturation</p>
              </div>
              <div className="col-md-7">
                <p className='ps-3'>{corporate.adressInvoiceCompany}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5" style={{borderRight: '1px solid #C0C0C0'}}>
                <p style={{letterSpacing: '2px', fontWeight: '600'}}>Email de facturation</p>
              </div>
              <div className="col-md-7">
                <p className='ps-3'>{corporate.emailInvoiceCompany}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5" style={{borderRight: '1px solid #C0C0C0'}}>
                <p style={{letterSpacing: '2px', fontWeight: '600'}}>Mode de paiement</p>
              </div>
              <div className="col-md-7">
                <p className='ps-3'>{corporate.paiement}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5" style={{borderRight: '1px solid #C0C0C0'}}>
                <p style={{letterSpacing: '2px', fontWeight: '600'}}>Tarif appliqué</p>
              </div>
              <div className="col-md-7">
                <p className='ps-3'>{corporate.price} <FaEye color='#008080' style={{cursor: 'pointer'}}  onClick={() => setShowPrice(true)}/></p>
              </div>
            </div>
          </div>
          </div>
        </div>
         
        <div className="d-flex justify-content-between mx-md-5 px-md-2">
            <p style={{letterSpacing: '2px', fontWeight: '600', fontSize: '1.8rem', borderBottom: '1px solid #008080' }}>Services & passages <FaPencilAlt color='#008080' style={{borderBottom: 'none', cursor: 'pointer'}} size={12} onClick={() =>  handleEditCorporate(3)}/></p>
          </div>
          <div className="container my-5 py-2 mx-md-5 px-md-2">
            <div className="row">
              <div className="col-md-2" style={{borderRight: '1px solid #C0C0C0'}}>
                <p style={{letterSpacing: '2px', fontWeight: '600'}}>Prestations</p>
              </div>
              <div className="col-md-10">
              {corporate.services && corporate.services.map((r, index) => {
                    return(
                        <p className="m-0 px-2" style={{display: 'inline'}} key={index}>- {r}</p>
                        )
                    })}
              </div>
            </div>

            <div className="row">
              <div className="col-md-2" style={{borderRight: '1px solid #C0C0C0'}}>
                <p style={{letterSpacing: '2px', fontWeight: '600'}}>Passages</p>
              </div>
              <div className="col-md-10">
              {corporate.slots_passages && corporate.slots_passages.map((r, index) => {
                    return(
                        <p className={`m-0 px-2`} style={{display: 'inline'}} key={index}><span className={`${r.toDate() < new Date() ? "background-rouge-clignotant" : "background-vert-clignotant"} rounded p-3`}>{formatDate(r.toDate())}</span></p>
                        )
                    })}
              </div>
            </div>





          </div>
      </div>
    </> : <>ERROR</>}

    <div className="container">
      <div className="mx-md-5 px-md-2">
        <p style={{letterSpacing: '2px', fontWeight: '600', fontSize: '1.8rem',  borderBottom: '1px solid #008080'}}>Historique <span style={{color:'#008080', cursor:'pointer', fontSize: '1.6rem', fontWeight: '600'}} className='btn-link'><AiOutlinePlusCircle className=' me-2' size={14} color='#008080'/>Ajouter une nouvelle commande</span></p>
      </div>

      <Searchbar placeholder='Rechercher...' onSearch={handleSearch}/>
      <OrdersCorporateList 
        OrdersCorporates={filteredOrders}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setShowDetailsOrder={setShowDetailsOrder}
        setOrderB2BID={setOrderB2BID}
      />

    </div>


    <div className="container mt-5 pt-5">
      <div className="mx-md-5 px-md-2">
        <p style={{letterSpacing: '2px', fontWeight: '600', fontSize: '1.8rem',  borderBottom: '1px solid #008080'}}>Sous compte <span style={{color:'#008080', cursor:'pointer', fontSize: '1.6rem'}} className='btn-link'><AiOutlinePlusCircle className=' me-2' size={14} color='#008080'/>Ajouter un nouveau sous compte</span></p>
      </div>

      <Searchbar placeholder='Rechercher...' onSearch={handleSearchUnderUser}/>
      <UnderUserCorporatesList UnderUsers={filteredUnderUser} currentPage={currentPageUnderUser} setCurrentPage={setCurrentPageUnderUser}/>

    </div>

    <div className="container mt-5 pt-5">
      <div className="mx-md-5 px-md-2">
        <p style={{letterSpacing: '2px', fontWeight: '600', fontSize: '1.8rem',  borderBottom: '1px solid #008080'}}>Factures <span style={{color:'#008080', cursor:'pointer', fontSize: '1.6rem'}} className='btn-link'><AiOutlinePlusCircle className=' me-2' size={14} color='#008080'/>Ajouter une nouvelle facture</span></p>
      </div>

      <Searchbar placeholder='Rechercher...' onSearch={handleSearchUnderUser}/>
      <UnderUserCorporatesBills invoices={filteredBills} currentPage={currentPageBills} setCurrentPage={setCurrentPageBills}/>

    </div>
          </>
        )}



































    {/* Modal pour ajouter un sous compte */}
    <Modal
      show={showModalAddUnderUser}
        onHide={() => setShowModalAddUnderUser(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div class="form-group">
          <label for="exampleInputEmail1">Nom (Last name)</label>
          <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" onChange={(e) => setLastNameUnderUser(e.target.value)}/>
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Prénom (First name)</label>
          <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" onChange={(e) => setFirstNameUnderUser(e.target.value)}/>
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Email</label>
          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" onChange={(e) => setEmailUnderUser(e.target.value)}/>
        </div>
       
      </Modal.Body>
      <Modal.Footer>
        <button onClick={() => {setShowModalAddUnderUser(false); setLastNameUnderUser(''); setFirstNameUnderUser(''); setEmailUnderUser('');}} className=' btn btn-secondary'>Annuler</button>
        <Button onClick={pushUnderUser}>Confirmer</Button>
      </Modal.Footer>
    </Modal>
    {/* Modal pour ajouter une commande */}
    <AddOrder 
      showModalAddOrder={showModalAddOrder}
      setShowModalAddOrder={setShowModalAddOrder}
      // weekDays={weekDays}
      // months={months}
      initialClients={underUsers}
      idB2B={id}
      corporate={corporate}
    />


    {/* Modal pour la confirmation de la suppression définitive de la commande */}
    <Modal
      show={showConfirmationDeleteOrder}
      onHide={() => {setShowConfirmationDeleteOrder(false)}}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className='text-danger'>Suppression d'une commande</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <p className='text-danger text-center'>Êtes-vous certain(e) de vouloir valider cette opération ? Nous tenons à vous informer que cette action est irréversible et que, si vous continuez, la commande sera définitivement supprimée.</p>
          <p className='text-danger text-center'>RAPPEL : Si vous souhaitez annuler une commande, veuillez modifier directement son état.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleDeleteOrder}>
          Valider
        </Button>
        <Button variant="danger" onClick={() => setShowConfirmationDeleteOrder(false)}>Abondonner</Button>
      </Modal.Footer>
    </Modal>

        <Container>

            <Row>
                <div className='col-12'>

                {/* <Button className='btn-success-outline' size="sm" onClick={() => setShowModalAddOrder(true)}>Ajouter une commande</Button>              */}
                </div>
          
                
            </Row>

        </Container>
    </div>
  )
}
