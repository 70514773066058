import React, { useState } from 'react'
import { Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
// import CardMap from './CardMap'
import { FaArrowRight, FaPlus } from 'react-icons/fa';
import { MdDelete, MdLocationOn, MdMoreHoriz, MdMoreVert, MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { formatDateComplete } from '../../../../../utils/DateToString';
import { classifyClientTours } from '../../../../../utils/ClassifyClientTours';
import './ConfirmedToursP.css'
import { useEffect } from 'react';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../../../../../firebase-config';
import { BsChevronDown } from 'react-icons/bs';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import  iconPU  from '../../../../../images/iconPU.png'
import  iconDO  from '../../../../../images/iconDO.png'
import  iconB2B  from '../../../../../images/B2B.png'
import GetOrder from '../../Tours/Components/GetOrder';
import { IoIosAdd } from 'react-icons/io';
// import ModelAddOrder from './ModelAddOrder';
import CommandeDataService from '../../../../../context/commande.services'
import { useSnackbar } from 'notistack';
import GetOrderB2B from './GetOrderB2B';



// Données initiales pour les colonnes et les éléments
const initialColumns = {
    'column-1': {
      name: 'Jake',
      items: [
        { id: 'item-1', content: 'Sucking at something is the first step towards being sorta good at something.' },
        { id: 'item-2', content: 'You got to focus on what\'s real, man' }
      ],
    },
    'column-2': {
      name: 'BMO',
      items: [
        { id: 'item-3', content: 'Sometimes life is scary and dark' }
      ],
    },
    'column-3': {
        name: 'SALAH',
        items: [
          { id: 'item-4', content: 'Sometimes life is scary and dark' }
        ],
      },
    // Autres colonnes...
  };

export default function ConfirmedTours({showConfirmed, handleCloseConfirmed, hubs, tours}) {

  useEffect(() => {
    // Effectuez ici les actions qui doivent se produire après la mise à jour de tours
    if(tours && tours.tours.length > 0 && tours.incidents.length > 0){
    }
  }, [tours]); // Écoutez les changements sur `tours`

  //HUB
  const findHubById = () => {
    return hubs?.find(hub => hub.id_hub === tours?.tours?.departureAndArrivalPoint);
  }
  const hub = findHubById();

  const [showOrdersAction, setShowOrdersAction] = useState(false);
  const [columns, setColumns] = useState({});

  const onDragEnd = (result) => {
    if (!result.destination) return;
    
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumnIndex = parseInt(source.droppableId.split('-')[1], 10) - 1;
    const destColumnIndex = parseInt(destination.droppableId.split('-')[1], 10) - 1;
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
      tours.tours.tours[sourceColumnIndex].orders = sourceItems.map(item => ({ ...item.content }));
      tours.tours.tours[destColumnIndex].orders = destItems.map(item => ({ ...item.content }));

       // Gérer les ordersDropoffs
    // Supprimer l'élément déplacé de ordersDropoffs de la colonne source
    if(removed.content.serviceType === 'DO'){
      tours.tours.tours[sourceColumnIndex].ordersDropoffs = tours.tours.tours[sourceColumnIndex].ordersDropoffs.filter(dropoff => dropoff.id !== removed.id);
    
      // Ajouter un nouvel élément à ordersDropoffs dans la colonne de destination
      tours.tours.tours[destColumnIndex].ordersDropoffs.push({
        date: null,
        location: null,
        underOrder: null,
        id: removed.id, // Utiliser l'ID de l'élément déplacé
        // Ajouter d'autres propriétés nécessaires ici
      });
    }

 

    } else {
      const column = columns[source.droppableId];
      const columnIndex = parseInt(source.droppableId.split('-')[1], 10) - 1;
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
      tours.tours.tours[columnIndex].orders = copiedItems.map(item => ({ ...item.content }));
    }

  };

  const onDragEndB2B = (result) => {
    if (!result.destination) return;
    
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  };

  const deleteTour = (key) => {
    const index = parseInt(key.split('-')[1], 10) - 1; // Convertir 'column-2' en 1 (en prenant en compte que les indices de tableau commencent à 0)

    setColumns(prevColumns => {
      const newColumns = { ...prevColumns };
      if (newColumns[key] && newColumns[key].items.length === 0) {
        delete newColumns[key];
        tours.tours.tours.splice(index, 1);
      }


      return newColumns;
    });
  };

  const handleSelectChange = (event, indexTour) => {
    // Récupérer l'ID du chauffeur sélectionné
    const selectedDriverId = event.target.value;
    // Vérifier si l'indexTour est dans la plage valide

  if (tours.tours && indexTour >= 0 && indexTour < tours.tours.tours.length) {
  // Mettre à jour l'id_driver pour le shift spécifié
  tours.tours.tours[indexTour].driverId = selectedDriverId;

  }

    // props.setTours(newTours);
          // Mettre à jour les colonnes
          setColumns(prevColumns => {
            const newColumns = { ...prevColumns };
            const columnKey = `column-${indexTour+1}`; // Construire la clé de colonne
      
            if (newColumns[columnKey]) {
                newColumns[columnKey].name = selectedDriverId;
            }
      
            return newColumns;
        });
    // setSelectedHub(event.target.value);
    // Collecter les ID des chauffeurs déjà sélectionnés
  };
  const [drivers, setDrivers] = useState([]);
  useEffect(() => {
          const q = query(collection(db, "Drivers"), orderBy("created_at", "desc"))  ;
          const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const drivers = [];
              querySnapshot.forEach((doc) => {
                  drivers.push({...doc.data(), id_driver: doc.id});
          });
          setDrivers(drivers);
          });
      
          return () => unsubscribe(); 
  }, []);


  const getAvailableDriversForShift = (indexShift) => {
          // Vérifier que l'élément à l'index spécifié existe et a une propriété driverId avant d'accéder à driverId
          const currentShiftDriverId = tours?.tours?.tours?.[indexShift]?.driverId;
          // const columnValues = Object.values(columns);
          // const titles = columnValues.map(column => column.title);
          const selectedDriverIds = tours?.tours?.tours
            ?.map(shift => shift.driverId)
            .filter(id => id); // Filtrer pour éliminer les valeurs undefined ou null
        

          
          // Filtrer les conducteurs pour exclure ceux déjà sélectionnés, sauf le conducteur actuel de ce shift
          return drivers?.filter(driver => 
            !selectedDriverIds.includes(driver.id_driver) || 
            driver.id_driver === currentShiftDriverId
          );
  };
        

  const getNextColumnIndex = (prevColumns) => {
    const columnNumbers = Object.keys(prevColumns)
      .map(key => parseInt(key.replace('column-', ''), 10))
      .sort((a, b) => a - b);
    
    // Trouvez le premier nombre manquant dans la séquence
    let missingNumber = 1;
    for (let i = 0; i < columnNumbers.length; i++) {
      if (columnNumbers[i] !== i + 1) {
        missingNumber = i + 1;
        break;
      }
      missingNumber = columnNumbers[i] + 1;
    }
    
    return missingNumber;
  };

  const addTour = () => {
  // Créez un tableau des valeurs de 'columns', qui sont les objets de chaque colonne
  const columnValues = Object.values(columns);
  // Créez un tableau de toutes les lettres de l'alphabet
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

  // Mappez sur chaque colonne pour extraire le 'title' et le stocker dans un nouveau tableau
  const titles = columnValues.map(column => column.title);

  // Trouvez la première lettre qui n'est pas dans 'titles'
  const firstAvailableLetter = alphabet.find(letter => !titles.includes(letter.toUpperCase())).toUpperCase();

  setColumns(prevColumns => {
    const newColumnIndex = getNextColumnIndex(prevColumns);
    const columnKey = `column-${newColumnIndex}`;



  


    const title = firstAvailableLetter;
    const name = 'Ajouter Chauffeur';
    const items = [];

    const newColumns = {
      ...prevColumns,
      [columnKey]: {
        name,
        title,
        items
      }
    };

    tours.tours.tours.push({
      tourName: title, // Exemple de nom basé sur l'index du groupe
      driverId: '',
      statusTour: 1,
      ordersDropoffs: [],
      ordersValidation: [],
      orders: []
    })

    console.log('Add Tours', newColumns);
    console.log('Tours Apres add', tours)
 
    return newColumns;
  });

};

  const initialTours = () => {
    let initialColumns = {};

    if(tours?.tours?.tourType === 'B2C'){
      tours?.tours?.tours?.forEach((shift, index) => {

        // Créer un ID de colonne dynamique
        const columnId = `column-${index+1}`; // Assurez-vous que l'index ne se chevauche pas avec des colonnes statiques
        initialColumns[columnId] = {
            title: shift.tourName,
            name: '',
            items: shift.orders?.map(order => ({ 
                id: order.id, 
                content: order
            }))
        };
    });
    }

    if(tours?.tours?.tourType === 'B2B'){
      const items = groupOrdersByCompanyId(tours?.tours?.tours);
      const columnId = `column-1`;
      initialColumns[columnId] = {
        title: 'A',
        name: '',
        items: items
        
    };
    }
    return initialColumns;
   
}
const groupOrdersByCompanyId = (tours) => {
      //parcourir tours (tableau) et dans chaque objet on trouve un champ orders qui est un tableau et chaque element de tableau order possede un champs companyId je souhaite retourner un tableau d'objet sous forme {idCompany : []} on regroupe ensemble tout les element qui ont le meme companyId
   // Utiliser reduce pour transformer le tableau de tours en un objet groupé par companyId
   const groupedByCompanyId = tours.reduce((acc, tour) => {
    tour.orders.forEach(order => {
      const orderWithTourName = { ...order, tourName: tour.tourName };
      // Si le companyId n'existe pas encore dans l'accumulateur, l'initialiser avec un tableau vide
      if (!acc[order.companyId]) {
        acc[order.companyId] = [];
      }
      // Ajouter l'ordre dans le tableau correspondant à son companyId
      acc[order.companyId].push(orderWithTourName);
    });
    return acc;
  }, {});

  // Transformer l'objet groupé en tableau d'objets contenant companyId et les commandes associées
  const result = Object.keys(groupedByCompanyId).map(companyId => ({
    companyId,
    orders: groupedByCompanyId[companyId],
  }));

  return result;
}
useEffect(() => {
    setColumns(initialTours());
}, [tours]);

const deleteOrder = (key, indexItem) => {
  const index = parseInt(key.split('-')[1], 10) - 1; // Convertir 'column-2' en 1 (en prenant en compte que les indices de tableau commencent à 0)

  setColumns(prevColumns => {
    // Créez une copie profonde de l'objet columns pour éviter de modifier l'état directement
    const newColumns = { ...prevColumns };

    // Vérifiez si la clé existe et que l'indexItem est dans la plage valide du tableau items
    if (newColumns[key] && indexItem >= 0 && indexItem < newColumns[key].items.length) {
      const [removedItem] = newColumns[key].items.splice(indexItem, 1); // Supprimez correctement l'élément et récupérez-le
      // Supprimez 1 élément à l'index indexItem
      // newColumns[key].items.splice(indexItem, 1);
  // Supprimez l'élément correspondant dans orders de tours
  if (index >= 0 && index < tours.tours.tours.length) {
    const idOrder = tours.tours.tours[index].orders[indexItem]?.id;
    tours.tours.tours[index].orders.splice(indexItem, 1);
    if (removedItem?.content?.serviceType === 'DO'){
      const dropoffIndex = tours.tours.tours[index].ordersDropoffs.findIndex(dropoff => dropoff.id === idOrder);
      if (dropoffIndex !== -1) {
        tours.tours.tours[index].ordersDropoffs.splice(dropoffIndex, 1);
      }
    }
  }

 

      


      //pareil ici je souhaite quand je le supprime de orders je veridie si c'est DO si oui chercher l'id correspondant dans ordersDropoffs et supp l'element
    }

    // Affichez le nouvel état pour le débogage


    // Mettez à jour l'état avec les nouvelles colonnes
    return newColumns;
  });
};
const deleteOrderB2B = (key, indexItem) => {

  setColumns(prevColumns => {
    // Créez une copie profonde de l'objet columns pour éviter de modifier l'état directement
    const newColumns = { ...prevColumns };

    // Vérifiez si la clé existe et que l'indexItem est dans la plage valide du tableau items
    if (newColumns[key] && indexItem >= 0 && indexItem < newColumns[key].items.length) {
      // Supprimez 1 élément à l'index indexItem
      newColumns[key].items.splice(indexItem, 1);


    }
    return newColumns;
  });
};
  const [orders, setOrders] = useState([]);
    //orders
  useEffect(() => {
    const fetchIncidents = async () => {
      let filteredData = null;
      if(tours && tours?.tours?.tourType === 'B2C'){
        const data = await CommandeDataService.getOrdersB2C6Month();
        filteredData = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      } else if (tours && tours?.tours?.tourType === 'B2B'){
        const data = await CommandeDataService.getCorporate();
        filteredData = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      }
      // Appliquer le filtrage directement ici et ensuite retourner les données filtrées

      setOrders(filteredData);
    }
    fetchIncidents();
  }, [tours]);

  const findOrderById = (idCheking) => {
    return orders?.find(order => order.id === idCheking);
  }
const [show, setShow] = useState(false);
const handleShow = () => setShow(true);
//   const handleClose = () => setShow(false);
const { enqueueSnackbar } = useSnackbar();
const validationTours = () => {
  let isValid = true;

  if(tours && tours?.tours?.tourType === 'B2C'){
    if (tours.tours.tours.length === 0) {
      enqueueSnackbar(`Aucune tournée créer, Veuillez ajouter une nouvelle tournée.`, {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        autoHideDuration: 3000,
        style: {fontSize: '1.6rem'}
      });
      isValid = false;
    }else if (tours.incidents.length > 0) {
      enqueueSnackbar(`Veuillez corriger les incidents avant la création de la tournée.`, {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        autoHideDuration: 3000,
        style: {fontSize: '1.6rem'}
      });
      isValid = false;
    }else {
      tours.tours.tours.forEach(tour => {
        // Vérifier si le driverId est vide
        if (tour.orders.length > 0 && !tour.driverId) {
          enqueueSnackbar(`Veuillez sélectionner un chauffeur pour la tournée ${tour.tourName}.`, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoHideDuration: 3000,
            style: {fontSize: '1.6rem'}
          });
          isValid = false;
        }
    
        // Vérifier si le tableau d'orders est vide
        if (tour.orders.length === 0) {
          enqueueSnackbar(`La tournée ${tour.tourName} n'a pas de commandes assignées.`, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoHideDuration: 3000,
            style: {fontSize: '1.6rem'}
          });
          isValid = false;
        }
      });
    }





  }else if(tours && tours?.tours?.tourType === 'B2B'){

    if (tours.tours.tours.length === 0) {
      enqueueSnackbar(`Aucune tournée créer, Veuillez ajouter une nouvelle tournée.`, {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        autoHideDuration: 3000,
        style: {fontSize: '1.6rem'}
      });
      isValid = false;
    }else if (tours.incidents.length > 0) {
      enqueueSnackbar(`Veuillez corriger les incidents avant la création de la tournée.`, {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        autoHideDuration: 3000,
        style: {fontSize: '1.6rem'}
      });
      isValid = false;
    }else {
      // Parcourir l'objet columns
      Object.entries(columns).forEach(([key, value]) => {
        // Vérifier si le driverId est vide
        if (value.items.length > 0 && !value.name) {
          enqueueSnackbar(`Veuillez sélectionner un chauffeur pour la tournée ${value.title}.`, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoHideDuration: 3000,
            style: {fontSize: '1.6rem'}
          });
          isValid = false;
        }
        if (value.items.length === 0 ) {
          enqueueSnackbar(`La tournée ${value.title} n'a pas de commandes assignées.`, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoHideDuration: 3000,
            style: {fontSize: '1.6rem'}
          });
          isValid = false;
        }













console.log(`Clé: ${key}, Valeur: `, value);

// Accès à des propriétés spécifiques
console.log(`Titre: ${value.title}`);
console.log(`Nom: ${value.name}`);
console.log(`Items: `, value.items);
console.log(`Nombre d'items: ${value.items.length}`);

// Par exemple, pour parcourir les items si le tableau n'est pas vide
if (value.items.length > 0) {
  value.items.forEach((item, index) => {
      console.log(`Item ${index + 1}: `, item);
  });
} else {
  console.log("Pas d'items dans cette colonne.");
}
});
    }
  }
  return isValid;
}

const addToFirebaseTour = () => {
  const validator = validationTours();
  if(validator){
    if(tours.tours.tourType === 'B2B'){
      const newToursB2B = [];
      //Parcour Column pour créer l'équivalent a tours
      // Parcourir l'objet columns
      Object.entries(columns).forEach(([key, value]) => {
        const aggregatedOrders = [];
    
        value.items.forEach(item => {
          // Supposons que chaque item contient un tableau 'orders'
          const ordersWithoutTourName = item.orders.map(order => {
            const { tourName, ...orderWithoutTourName } = order; // Supprime 'tourName'
            return orderWithoutTourName;
          });
    
          // Ajout des commandes au tableau agrégé
          aggregatedOrders.push(...ordersWithoutTourName);
        });
    
        // Filtrer pour 'DO' et préparer 'ordersDropoffs'
        const ordersDropoffs = aggregatedOrders
          .filter(order => order.serviceType === 'DO')
          .map(order => ({
            date: null,
            location: null,
            companyId: order.companyId,
            id: order.id,
            underOrder: order.orderUnder?.map(uo => ({
              date: null,
              location: null,
              id: uo.orderID // Assurez-vous que `uo` a un champ `id`, ajustez sinon
            }))
          }));
    
        const newTour = {
          driverId: value.name, // ou autre champ approprié
          ordersValidation: [], // Selon votre logique
          statusTour: 1,
          tourName: value.title,
          ordersDropoffs,
          orders: aggregatedOrders,
        };
    
        newToursB2B.push(newTour); // Ajouter le nouveau tour au tableau des tours
    
      });
      tours.tours.tours = newToursB2B;
    }
  }
  //Rajouter l'objet tours.tours à la BDD
}
  return (
    <div>
    {/* <ModelAddOrder show={show} handleClose={handleClose} /> */}
      <Modal show={showConfirmed} fullscreen={true} onHide={handleCloseConfirmed}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize: '1.6rem', fontWeight: '600', letterSpacing: '1.2px'}}>Validations des tourneés</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pb-5'>

            <div className="d-flex flex-wrap justify-content-center my-5 border-bottom" style={{fontSize: '1.6rem'}}>
                <div className="flex-fill p-2 d-flex justify-content-center align-items-center">
                    <p>Point de départ : <span style={{fontWeight: '600'}}>{hub?.name}</span></p>
                </div>
                <div className="flex-fill p-2 d-flex justify-content-center align-items-center">
                    <p>Type : <span style={{fontWeight: '600'}}>{tours?.tours?.tourType}</span></p>
                </div>
                {
                    tours?.tours?.tourType === 'B2C' && 
                    <div className="flex-fill p-2 d-flex justify-content-center align-items-center">
                    <p>Période : <span style={{fontWeight: '600'}}>{tours?.tours?.evening ? 'Soir' : 'Matin'}</span></p>
                </div>
                }
                <div className="flex-fill p-2 d-flex justify-content-center align-items-center">
                    <p>Chauffeur(s) : <span style={{fontWeight: '600'}}>{tours?.tours?.tours?.length}</span></p>
                </div>
        </div>
               
        <div className='container-fluid'>
        <Row className='mb-5'>
            <div className='col-12 border-bottom'>
                <Row>
                    <div className='col-md-6'>
                        <p className="m-0 p-0 my-3 col-6" style={{fontWeight: 'bold'}}>Commande nécessite une action ({tours?.incidents.length})</p>                                  
                    </div>
                    <div className='col-md-6 text-end'>
                    {showOrdersAction ? <MdOutlineKeyboardArrowDown size={20} onClick={() => setShowOrdersAction(showHistory => false)}/> : <MdOutlineKeyboardArrowRight size={20} onClick={() => setShowOrdersAction(showHistory => true)}/>}
                </div>
                </Row>     
                </div>
        </Row>
        {
  showOrdersAction &&
  <Row className='scrollable-list'>
  {
    tours && tours?.tours?.tourType === 'B2C' ?
    
    
    tours && tours?.incidents && tours?.incidents?.map((order) => {

    const idFacture = findOrderById(order.id)?.idFacture;
    const date_creation = formatDateComplete(findOrderById(order.id)?.date_creation?.toDate());
      {/* 
      const status = classifyClientTours(findOrderById(order.id_order)?.idClient, props.ordersB2C);
       */}

  return (
     
      <div className="d-flex flex-wrap justify-content-center my-2 border-bottom text-start" style={{fontSize: '1.6rem'}}>
      <div className="flex-fill p-2 d-flex justify-content-start align-items-center">
      
          <p style={{fontWeight: '600'}}>
           <span style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              borderRadius: '35%',
              backgroundColor: 'red',
              marginRight: '5px'
          }}>
          </span>{idFacture}
          <br />
          <span style={{fontWeight: '400', fontStyle: 'italic', fontSize: '1.4rem'}} className='text-secondary'>
          Date de création : {date_creation}
          </span>
          </p>
      </div>
      <div className="flex-fill p-2 d-flex justify-content-start align-items-center">
          <p style={{fontWeight: '400', fontStyle: 'italic', fontSize: '1.4rem'}} className='text-secondary'>La commande ne se trouve pas dans le systeme</p>
      </div>
      <div className="flex-fill p-2 d-flex justify-content-center align-items-center">
          <OverlayTrigger

              placement='bottom'
              overlay={
                  <Tooltip id={`tooltip-bottom`}>
                  Accées à la commande.
                  </Tooltip>
              }
>
<button className='btn'><MdMoreHoriz className='btn-link' size={18} style={{cursor: 'pointer'}}/></button>
</OverlayTrigger>
          
      </div>
      
</div>
  )})


  :
  tours && tours?.incidents && tours?.incidents?.map((order) => {

    const corporate = findOrderById(order.companyId)?.nameCompany;
    const date_creation = formatDateComplete(findOrderById(order.companyId)?.date_creation?.toDate());


return (
 
  <div className="d-flex flex-wrap justify-content-center my-2 border-bottom text-start" style={{fontSize: '1.6rem'}}>
  <div className="flex-fill p-2 d-flex justify-content-start align-items-center">
  
      <p style={{fontWeight: '600'}}>
       <span style={{
          display: 'inline-block',
          width: '10px',
          height: '10px',
          borderRadius: '35%',
          backgroundColor: 'red',
          marginRight: '5px'
      }}>
      </span>{corporate}
      <br />
      <span style={{fontWeight: '400', fontStyle: 'italic', fontSize: '1.4rem'}} className='text-secondary'>
      Date de création : {date_creation}
      </span>
      </p>
  </div>
  <div className="flex-fill p-2 d-flex flex-column justify-content-start align-items-start">
  {order.under_user?.map((underOrder, index) => (
    <div key={index} style={{cursor: 'pointer'}}>
      <p style={{ fontWeight: '600' }}>
        {underOrder.name}
        <span style={{ fontWeight: '400', fontStyle: 'italic', fontSize: '1.4rem' }} className='text-secondary ms-1'>
          ({underOrder.number_order}) <FaArrowRight className='btn-link'/>
        </span>
      </p>
    </div>
  ))}
</div>

  <div className="flex-fill p-2 d-flex justify-content-start align-items-center">
      <p style={{fontWeight: '400', fontStyle: 'italic', fontSize: '1.4rem'}} className='text-secondary'>La commande ne se trouve pas dans le systeme</p>
  </div>
  
</div>
)})

}
  </Row>
}
        </div>
                  
                  <DragDropContext onDragEnd={tours && tours?.tours?.tourType === 'B2C' ? onDragEnd : onDragEndB2B}>
      <div style={{ display: 'flex', justifyContent: 'flex-start', overflowX: 'auto' }} className='flex-wrap'>
        {Object.entries(columns).map(([columnId, column], index) => {
          return (
            <Droppable droppableId={columnId} key={columnId}>
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    background: snapshot.isDraggingOver ? '#008080' : '#F0F0F0',
                    padding: 4,
                    width: 350,
                    minHeight: 500,
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '5px',
                    borderBottom: '5px solid #008080',
                  }}
                  className='mt-5 mx-3 p-3'
                >
                <h6 className='text-center my-3 mb-4' style={{fontSize: '1.8rem', fontWeight: '600', letterSpacing: '1.2px'}}>Tournée {column.title} {column.items.length === 0 && <MdDelete className='mb-1 text-danger' style={{cursor: 'pointer'}} onClick={() => deleteTour(columnId)}/>}</h6>
                <Form.Group controlId="selectedDriver" className="d-flex align-items-center mb-4">
    <div className="flex-grow-1 position-relative">
            <Form.Control
                as="select"
                className="w-100"
                style={{ color: '#000', fontSize: '1.4rem'}}
                name="selectedDriver"
                value={column.name}
                onChange={(event) => handleSelectChange(event, index)} // Utilisez une fonction fléchée
            >
            <option value="" >Sélectionnez un chauffeur</option>
                {getAvailableDriversForShift(index).map((driver) => (
                    <option key={driver.id_driver} value={driver.id_driver}>{driver.firstName} {driver.lastName}</option>
                ))}
            </Form.Control>
            <BsChevronDown className="dropdown-icon" />
        </div>
                </Form.Group>
                <div className='mb-3' style={{height: '2px', background: '#D3D3D3'}}></div>
                  {/* <h2>{column.name}</h2> */}
                  {
                    tours && tours?.tours?.tourType === 'B2C' ?

                    column.items.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            userSelect: 'none',
                            minHeight: '50px',
                            borderRadius: '3px',
                            backgroundColor: snapshot.isDragging ? 'white' : 'white',
                            color: '#000',
                            ...provided.draggableProps.style
                          }}
                          className='p-3 m-2'
                        >
                        <div className="row" style={{fontSize: '1.6rem'}}>
                        <div class="col-2 d-flex justify-content-center align-items-center">
    <img src={item?.content?.serviceType ==='DO' ? iconDO : iconPU} alt="" class="img-fluid"/>
</div>

            
        
            {<GetOrder orderId={item?.content?.id} isDO={item?.content?.serviceType} ordre={index} deleteOrder={() => deleteOrder(columnId, index)} />}
            
          
        </div>
                          {/* {item.content.id_order} */}
                          
                        </div>
                        
                      )}
                      
                    </Draggable>
                  ))

                  :
                  column.items.map((item, index) => (
                    <Draggable key={item.companyId} draggableId={item.companyId} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            userSelect: 'none',
                            minHeight: '50px',
                            borderRadius: '3px',
                            backgroundColor: snapshot.isDragging ? 'white' : 'white',
                            color: '#000',
                            ...provided.draggableProps.style
                          }}
                          className='p-3 m-2'
                        >
                        <div className="row" style={{fontSize: '1.6rem'}}>
                        <div class="col-2 d-flex justify-content-center align-items-center">
    <img src={iconB2B} alt="" class="img-fluid"/>
</div>

            
        
            {<GetOrderB2B id={item.companyId} orders={item.orders} ordre={index} deleteOrder={() => deleteOrderB2B(columnId, index)} />}
            
          
        </div>
                 
                          
                        </div>
                        
                      )}
                      
                    </Draggable>
                  ))
                  }
                  {provided.placeholder}
                  <div className='mx-auto mt-5' style={{border: '2px solid #008080', borderRadius: '5px', cursor: 'pointer'}} onClick={handleShow}>
                  <IoIosAdd className='mx-auto' size={26} color='#008080'/>
                  </div>
                  
                </div>
              )}
            </Droppable>
          );
        })}
        <div className='d-flex justify-content-center align-items-center'>
        <div className='ms-3' style={{ border: '2px solid #008080', borderRadius: '99px', cursor: 'pointer', display: 'inline' }} onClick={() => addTour()}>
        <IoIosAdd className='mx-auto' size={26} color='#008080'/>
    </div>
        </div>

      </div>
    </DragDropContext>
                   
    <div className="d-grid gap-2 m-5">
      <Button variant="primary" size="lg" onClick={addToFirebaseTour}>
        Confirmer
      </Button>
    </div>
                
      

        </Modal.Body>
      </Modal>
    </div>
  )
}


