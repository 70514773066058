import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import '../components/NavbarVertical.css'

export default function NavbarVertical(props) {

    const handleButtonClick = (index) => {
        props.setActiveIndex(index);
        props.changeData(index);
    };

  return (
    <Container fluid className='my-5 mx-md-5 px-md-5'>
      <Row>
      {props.items.map((item, index) => (
                    <Col xs="auto" className="mr-1" key={index}>
                        <button
                            className={`btn text-start ${props.activeIndex === index ? 'button-navbar-active' : 'button-navbar'}`}
                            onClick={() => handleButtonClick(index)}
                        >
                            {item}
                        </button>
                    </Col>
                ))}
      </Row>
    </Container>
  )
}
