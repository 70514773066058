import React, { useState, useEffect } from 'react'
import { FloatingLabel, Form, Modal } from 'react-bootstrap'
import Searchbar from '../../../../../../components/Searchbar'
import PricesDataService from '../../../../../../context/Prices.services'

export default function ModalPrice({ show, onHide, id }) {

    const [searchPriceByName, setSearchPriceByName] = useState("");
    const [price, setPrice] = useState([]);
    const [filteredPrice, setFilteredPrice] = useState([]);
    const [selectCatory, setSelectCatory] = useState('');
    const [selectPrestation, setSelectPrestation] = useState('standard');

    const filterCategories = (term, prices) => {
        const lowercasedSearch = term.toLowerCase();
        return {
            Pressing: prices?.Pressing?.filter(pressing => pressing.name?.toLowerCase().includes(lowercasedSearch)),
            Cordonnerie: prices?.Cordonnerie?.filter(cordonnerie => cordonnerie.name?.toLowerCase().includes(lowercasedSearch)),
            lk: prices?.lk?.filter(lk => lk.name?.toLowerCase().includes(lowercasedSearch)),
            Retouche: prices?.Retouche?.filter(retouche => retouche.name?.toLowerCase().includes(lowercasedSearch))
        };
    };

const handleSearch = (term) => {
    setSearchPriceByName(term);
    
    if (term) {
        setFilteredPrice(filterCategories(term, price));
    } else {
        setFilteredPrice(price);
    }
};

      const getPrice = async () => {
        try {
            const data = await PricesDataService.getPrice(id);
            setPrice({ ...data.data(), id: data.id });
            setFilteredPrice({ ...data.data(), id: data.id });
        } catch (error) {
            console.error("Erreur lors de la récupération du prix :", error);
        }
    };
    

      useEffect(() => {
        id && getPrice();
      }, [id]);

  return (
    <Modal
    show={show}
    onHide={onHide}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    style={{height: '95vh', overflow: 'hidden'}}
  >
    <Modal.Header closeButton>
    </Modal.Header>
    <Modal.Body>
        <div className="col-4">
            <FloatingLabel
                controlId="floatingSelectGrid"
                label="Catégories"
                style={{fontSize: '1.4rem'}}
            >
                <Form.Select aria-label="Catégories" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={e => setSelectCatory(e.target.value)} >
                <option value=''>Tous</option>
                <option value='Pressing'>Pressing & blanchisserie</option>
                <option value='Cordonnerie'>Cordonnerie</option>
                <option value='Retouche'>Retoucherie</option>
                <option value='lk'>Linge au kilo</option>
                </Form.Select>
            </FloatingLabel>
        </div>
        <div className="col-4 mt-2">
            <FloatingLabel
                controlId="floatingSelectGrid"
                label="Prestation"
                style={{fontSize: '1.4rem'}}
            >
                <Form.Select aria-label="Prestation" style={{fontSize: '1.4rem', height: '60px'}} className='pt-5' onChange={e => setSelectPrestation(e.target.value)} >
                <option value='standard'>Standard</option>
                <option value='standardPlus'>Standard plus</option>
                <option value='premium'>Premium</option>
                </Form.Select>
            </FloatingLabel>
        </div>
 
    <Searchbar placeholder='Rechercher...' onSearch={handleSearch}/>

    <div style={{height: '500px', overflowY: 'auto'}}>
        {(selectCatory === '' || selectCatory === 'Pressing') && 
            <>
                <p className='mt-5' style={{color: '#008080', fontWeight: '600'}}>PRESSING & BLANCHISSERIE</p>
                {filteredPrice?.Pressing?.map((price) => {
            return (
                <div className='mx-auto p-4 py-4 my-2 d-flex justify-content-between' style={{ background: '#F1F1F1', borderRadius: '5px' }} key={price.position}>

                <div className="d-flex align-items-center">
                    <p className='m-0 p-0' style={{ fontWeight: '600' }}>{price.name}</p>
                </div>
                <div>
                    <p className='m-0 py-1 text-end' style={{fontWeight: '600'}}>{selectPrestation} HT : {price.priceHT[selectPrestation]?.toFixed(2)} €</p>
                    <p className='m-0 py-1 text-end' style={{fontWeight: '600'}}>{selectPrestation} TTC : {price.priceTTC[selectPrestation]?.toFixed(2)} €</p>
                </div>
                    

                    
                </div>
            );
          })}
            </>
        }
        {(selectCatory === '' || selectCatory === 'Cordonnerie') && 
            <>
                <p className='mt-5' style={{color: '#008080', fontWeight: '600'}}>CORDONNERIE</p>
                {filteredPrice?.Cordonnerie?.map((price) => {
                    return (
                        <div className='mx-auto p-4 py-4 my-2 d-flex justify-content-between' style={{ background: '#F1F1F1', borderRadius: '5px' }} key={price.position}>

                            <div className="d-flex align-items-center">
                                <p className='m-0 p-0' style={{ fontWeight: '600' }}>{price.name}</p>
                            </div>
                            <div>
                                <p className='m-0 py-1 text-end' style={{fontWeight: '600'}}>{selectPrestation} HT : {price.priceHT[selectPrestation]?.toFixed(2)} €</p>
                                <p className='m-0 py-1 text-end' style={{fontWeight: '600'}}>{selectPrestation} TTC : {price.priceTTC[selectPrestation]?.toFixed(2)} €</p>
                            </div>
                        </div>
                    );
                })}
            </>
        }
        {(selectCatory === '' || selectCatory === 'Retouche') && 
            <>
                <p className='mt-5' style={{color: '#008080', fontWeight: '600'}}>RETOUCHERIE</p>
                {filteredPrice?.Retouche?.map((price) => {
                    return (
                        <div className='mx-auto p-4 py-4 my-2 d-flex justify-content-between' style={{ background: '#F1F1F1', borderRadius: '5px' }} key={price.position}>

                            <div className="d-flex align-items-center">
                                <p className='m-0 p-0' style={{ fontWeight: '600' }}>{price.name}</p>
                            </div>
                            <div>
                                <p className='m-0 py-1 text-end' style={{fontWeight: '600'}}>{selectPrestation} HT : {price.priceHT[selectPrestation]?.toFixed(2)} €</p>
                                <p className='m-0 py-1 text-end' style={{fontWeight: '600'}}>{selectPrestation} TTC : {price.priceTTC[selectPrestation]?.toFixed(2)} €</p>
                            </div>
                        </div>
                    );
                })}
            </>
        }
        {(selectCatory === '' || selectCatory === 'lk') && 
            <>
                <p className='mt-5' style={{color: '#008080', fontWeight: '600'}}>LINGE AU KILO</p>
                {filteredPrice?.lk?.map((price) => {
                    return (
                        <div className='mx-auto p-4 py-4 my-2 d-flex justify-content-between' style={{ background: '#F1F1F1', borderRadius: '5px' }} key={price.position}>

                            <div className="d-flex align-items-center">
                                <p className='m-0 p-0' style={{ fontWeight: '600' }}>{price.name}</p>
                            </div>
                            <div>
                                <p className='m-0 py-1 text-end' style={{fontWeight: '600'}}>{selectPrestation} HT : {price.priceHT[selectPrestation]?.toFixed(2)} €</p>
                                <p className='m-0 py-1 text-end' style={{fontWeight: '600'}}>{selectPrestation} TTC : {price.priceTTC[selectPrestation]?.toFixed(2)} €</p>
                            </div>
                        </div>
                    );
                })}
            </>
        }
    </div>
    </Modal.Body>
  </Modal>
  )
}
