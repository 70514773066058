import {collection, getDocs, getDoc, setDoc, deleteDoc, doc, query, orderBy} from "firebase/firestore";
import { db } from '../firebase-config';

class SlotsDataService {
  addSlots = (datas, id) => {
    return setDoc(doc(db, "slots", id), datas);
  };

  deleteSlot = (id) => {
    const slotDoc = doc(db, "slots", id);
    return deleteDoc(slotDoc);
  };

  getAllSlots = () => {
    const q = query(collection(db, "slots"), orderBy("start"));
    return getDocs(q);
  };

  getSlots = (id) => {
    const slotDoc = doc(db, "slots", id);
    return getDoc(slotDoc);
  };
}

export default new SlotsDataService();