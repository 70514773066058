import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import CodePromoDataService from "../../../../context/codePromo.services";


const CodePromoList = ({ getCodePromoId }) => {
  const [codePromos, setcodePromos] = useState([]);
  useEffect(() => {
    getCodePromo();
  }, []);

  const getCodePromo = async () => {
    const data = await CodePromoDataService.getAllCodePromos();
    setcodePromos(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const deleteHandler = async (id) => {
    await CodePromoDataService.deleteCodePromo(id);
    getCodePromo();
  };
  return (
    <>
      <div className="mb-2">
        <Button variant="btn btn-warning edit text-light" onClick={getCodePromo}>
        rafraîchir la liste
        </Button>
      </div>

      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Id</th>
            <th>Code promo</th>
            <th>Valeur</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {codePromos.map((doc, index) => {
            return (
              <tr key={doc.id}>
                <td>{index + 1}</td>
                <td>{doc.code}</td>
                <td>{doc.valeur}.00 €</td>
                <td>
                  <Button
                    variant="secondary"
                    className="edit"
                    onClick={(e) => getCodePromoId(doc.code)}
                  >
                    Modifier
                  </Button>
                  <Button
                    variant="danger"
                    className="delete"
                    onClick={(e) => deleteHandler(doc.code)}
                  >
                    Supprimer
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default CodePromoList;