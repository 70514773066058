import React, { useEffect, useState } from 'react';
import CommandeDataService from '../../../../../context/commande.services'
import { Badge } from 'react-bootstrap';
import { MdDelete } from 'react-icons/md';

const GetOrder = ({ orderId, isDO, ordre, deleteOrder }) => {
  const [order, setOrder] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const docSnap = await CommandeDataService.getCommande(orderId);
        const docSnapUser = await CommandeDataService.getUser(docSnap.data().idClient)
        setOrder(docSnap.data());
        setUser(docSnapUser.data());
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchOrder();
  }, [orderId]);

  if (!order) {
    return <p>Chargement...</p>; // ou tout autre indicateur de chargement
  }

  return (
    <div className="col" style={{fontSize: '1.4rem'}}>
            <div className="d-flex flex-wrap justify-content-center text-start p-0 m-0">
            <div className="flex-fill d-flex justify-content-start align-items-center">
            <p className='p-0 m-0' style={{fontWeight: '600'}}>
      {order.idFacture} ({isDO === 'DO' ? order.creneauLivraison : order.creneauCollecte})
    </p>
            </div>
            <div className="flex-fill d-flex justify-content-end align-items-center">
            <Badge className=' m-0' style={{color: '#008080'}} bg="light">{ordre+1}</Badge>
            </div>
        </div>
    
    <p className='p-0 m-0 mt-1'>
      {user.nom.toUpperCase()} {user.prenom}
    </p>
    <p className='p-0 m-0'>
      {order.adresse}
    </p>
    <p className='p-0 m-0'>
      {order.service}
    </p>

    <p className='p-0 m-0 mt-1' style={{fontStyle: 'italic', color: '#008080'}}>
      {isDO === 'DO' ? 'Livraison' : 'Collecte'} <MdDelete className='mb-1 text-danger' style={{cursor: 'pointer'}} size={18} onClick={deleteOrder}/>
    </p>
    </div>

  );
};

export default GetOrder;
