import React, { useState, useEffect } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { AiFillEdit } from 'react-icons/ai';
import { FiMinusCircle } from "react-icons/fi";
import Skeleton from 'react-loading-skeleton';
import PricesDataService from '../../../../../../context/Prices.services'
import { useSnackbar } from 'notistack';
import { UpdateRecapPrestaItem } from '../../../../../../utils/Corporate/Orders/Update/UpdateRecapPrestaItem';
import { UpdateTotalGlobal } from '../../../../../../utils/Corporate/Orders/Update/UpdateTotalGlobal';
import ModalConfirmedDeleteItem from './ModalConfirmedDeleteItem';
import { CalculerSommeTotalsByPassage } from '../../../../../../utils/Calculator/CalculerSommeTotalsByPassage';

export default function TbodyItem({isLoading, items, name, customer, editItem, id, underUser, champs, orderB2B, orderB2BID, setIsLoading, categories}) {

    const [prices, setPrices] = useState({});
    const [articleEnEdition, setArticleEnEdition] = useState(null); // null signifie aucun article en édition
    const [articleEnEditionPrice, setArticleEnEditionPrice] = useState(null); // null signifie aucun prix d'article en édition
    const [articleEnEditionQte, setArticleEnEditionQte] = useState(null); // null signifie aucune qte d'article en édition
    const [articleEnEditionDelete, setArticleEnEditionDelete] = useState(null); // null signifie aucune qte d'article en édition
    const [itemShow, setItemShow] = useState(items);
    const { enqueueSnackbar } = useSnackbar();
    const [valeurNewPrice, setValeurNewPrice] = useState('');
    const [valeurNewQte, setValeurNewQte] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const calculerPrixHT = (prixTTC, tauxTVA = 0.20) => {
        return prixTTC / (1 + tauxTVA);
    }
    const calculerMontantTVA = (prixTTC, tauxTVA = 0.20) => {
        const prixHT = prixTTC / (1 + tauxTVA);
        return prixTTC - prixHT;
    }
    function calculerPrixAvecTVA(prixHT, tauxTVA = 0.20) {
        return prixHT * (1 + tauxTVA);
    }
    const getPrices = async () => {
        const docSnap = await PricesDataService.getPrice(id)
        setPrices({ ...docSnap.data(), id: docSnap.id});
    };
    const choisirArticlePourEdition = (index) => {
        setArticleEnEditionPrice(null);
        setArticleEnEditionQte(null);
        setArticleEnEdition(index);
    };

    const calculerTotalParCategories = (obj) => {
        return categories.reduce((total, categorie) => {
            return total + CalculerSommeTotalsByPassage(obj[categorie] || []);
        }, 0);
    };
    const changerArticle = async (nouvelArticle, index) => {
        // Mettre à jour l'article à l'indice spécifié
        const newItems = [...itemShow];
        const newPriceTTC = nouvelArticle.priceTTC;
        const totalArticle = newItems[index].total_article;
        newItems[index] = { ...newItems[index], article: nouvelArticle.name,  price: newPriceTTC.standard, total: newPriceTTC.standard * totalArticle};
        // Mettre à jour l'état des items
        setItemShow(newItems);
        //changer le toal pour chaque article
        let newUnderUserRecapPresta = { ...underUser.recap_presta, [champs]: newItems};
        const totalOrderRecapPresta = calculerTotalParCategories(newUnderUserRecapPresta);
        let newOrderB2BUnderUsers = [ ...orderB2B.under_user];
        newOrderB2BUnderUsers[underUser.initialIndex].recap_presta[champs] = newItems;
        if(((newOrderB2BUnderUsers[underUser.initialIndex].total).toFixed(2)) !== (totalOrderRecapPresta.toFixed(2))){
            newOrderB2BUnderUsers[underUser.initialIndex].total = totalOrderRecapPresta;
            await UpdateRecapPrestaItem({id: orderB2BID, value: newOrderB2BUnderUsers, enqueueSnackbar, setIsLoading });
            // changer le total global 
            const totalGlobal = CalculerSommeTotalsByPassage(newOrderB2BUnderUsers).toFixed(2);
            await UpdateTotalGlobal({id: orderB2BID, value: totalGlobal, enqueueSnackbar, setIsLoading })
        }
        setArticleEnEdition(null); // Fin de l'édition
    };
    const choisirArticlePourEditionPrice = (index) => {
        setArticleEnEditionQte(null);
        setArticleEnEdition(null);
        setValeurNewPrice('');
        setArticleEnEditionPrice(index);
    };
    const handleChangePrice = (e) => {
        const val = e.target.value;
        if (!isNaN(val) && (val > 0 || val === '')) {
            setValeurNewPrice(val);
        }
    };
    const changerPrice = async (newPrice, index) => {
        // Mettre à jour l'article à l'indice spécifié
        const newPriceTTC = calculerPrixAvecTVA(Number(newPrice))
        const newItems = [...itemShow];
        const totalArticle = newItems[index].total_article;
        newItems[index] = { ...newItems[index],  price: newPriceTTC, total: newPriceTTC * totalArticle};
        // Mettre à jour l'état des items
        setItemShow(newItems);
        //changer le toal pour chaque article
        let newUnderUserRecapPresta = { ...underUser.recap_presta, [champs]: newItems};
        const totalOrderRecapPresta = calculerTotalParCategories(newUnderUserRecapPresta);
        let newOrderB2BUnderUsers = [ ...orderB2B.under_user];
        newOrderB2BUnderUsers[underUser.initialIndex].recap_presta[champs] = newItems;
        
        if(((newOrderB2BUnderUsers[underUser.initialIndex].total).toFixed(2)) !== (totalOrderRecapPresta.toFixed(2))){
            newOrderB2BUnderUsers[underUser.initialIndex].total = totalOrderRecapPresta;
            await UpdateRecapPrestaItem({id: orderB2BID, value: newOrderB2BUnderUsers, enqueueSnackbar, setIsLoading });
            // changer le total global 
            const totalGlobal = CalculerSommeTotalsByPassage(newOrderB2BUnderUsers).toFixed(2);
            await UpdateTotalGlobal({id: orderB2BID, value: totalGlobal, enqueueSnackbar, setIsLoading })
        }
        setArticleEnEditionPrice(null); // Fin de l'édition
    };
    const choisirArticlePourEditionQte = (index) => {
        setArticleEnEdition(null);
        setArticleEnEditionPrice(null);
        setValeurNewQte('');
        setArticleEnEditionQte(index);
    };
    const handleChangeQte = (e) => {
        const val = e.target.value;
        if (!isNaN(val) && (val > 0 || val === '')) {
            setValeurNewQte(val);
        }
    };
    const changerQte = async (newQte, index) => {
        // Mettre à jour l'article à l'indice spécifié
        const newItems = [...itemShow];
        const priceTTC = newItems[index].price;
        newItems[index] = { ...newItems[index], total_article: Number(newQte), total: Number((priceTTC * Number(newQte)).toFixed(2)) };
        // Mettre à jour l'état des items
        setItemShow(newItems);
        //changer le toal pour chaque article
        let newUnderUserRecapPresta = { ...underUser.recap_presta, [champs]: newItems};
        const totalOrderRecapPresta = calculerTotalParCategories(newUnderUserRecapPresta);
        let newOrderB2BUnderUsers = [ ...orderB2B.under_user];
        newOrderB2BUnderUsers[underUser.initialIndex].recap_presta[champs] = newItems;

        if(((newOrderB2BUnderUsers[underUser.initialIndex]?.total).toFixed(2)) !== (totalOrderRecapPresta.toFixed(2))){
            newOrderB2BUnderUsers[underUser.initialIndex].total = totalOrderRecapPresta;
            await UpdateRecapPrestaItem({id: orderB2BID, value: newOrderB2BUnderUsers, enqueueSnackbar, setIsLoading });
            // changer le total global 
            const totalGlobal = CalculerSommeTotalsByPassage(newOrderB2BUnderUsers).toFixed(2);
            await UpdateTotalGlobal({id: orderB2BID, value: totalGlobal, enqueueSnackbar, setIsLoading })
        }
        setArticleEnEditionQte(null); // Fin de l'édition
    };
    const choisirArticlePourEditionDelete = (index) => {
        setArticleEnEditionDelete(index);
        setShowDeleteModal(true)
    };
    const calculerNbItems = (obj) => {
        return categories.reduce((total, categorie) => {
            const nombreElementsCategorie = obj[categorie] ? obj[categorie].length : 0;
            return total + nombreElementsCategorie;
        }, 0);
    };
    
    

    const handleDeleteItem = async () => {
        // Créer un nouveau tableau sans l'élément à l'indice spécifié
        const newItems = [
            ...itemShow.slice(0, articleEnEditionDelete), // Tous les éléments avant l'indice
            ...itemShow.slice(articleEnEditionDelete + 1) // Tous les éléments après l'indice
        ];

        let newUnderUserRecapPresta = { ...underUser.recap_presta, [champs]: newItems};
        //Verification si on peut supp au moins un article dans un atbleau
        const validetDelete = calculerNbItems(newUnderUserRecapPresta);



        if(validetDelete > 0 ){
            //Mettre à jour l'état des items
            setItemShow(newItems);
             //changer le toal pour chaque article
            const totalOrderRecapPresta = calculerTotalParCategories(newUnderUserRecapPresta);
            let newOrderB2BUnderUsers = [ ...orderB2B.under_user];
            newOrderB2BUnderUsers[underUser.initialIndex].recap_presta[champs] = newItems;
            if(((newOrderB2BUnderUsers[underUser.initialIndex]?.total).toFixed(2)) !== (totalOrderRecapPresta.toFixed(2))){
                newOrderB2BUnderUsers[underUser.initialIndex].total = totalOrderRecapPresta;
                const updatRecap = await UpdateRecapPrestaItem({id: orderB2BID, value: newOrderB2BUnderUsers, enqueueSnackbar, setIsLoading });
                // changer le total global 
                const totalGlobal = CalculerSommeTotalsByPassage(newOrderB2BUnderUsers).toFixed(2);
               const updateTotal = await UpdateTotalGlobal({id: orderB2BID, value: totalGlobal, enqueueSnackbar, setIsLoading })
                updatRecap && updateTotal && setShowDeleteModal(false);
            }
        }else{
            enqueueSnackbar(`Vous devez garder au minimum un article.`, {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}  
            });
        }
    };

    useEffect(() => {
        if (editItem && articleEnEdition !== null) { // Vérifiez si articleEnEdition n'est pas null
            getPrices();
        }
    }, [id, articleEnEdition, editItem]); // Incluez toutes les variables pertinentes
    useEffect(() => {
        setItemShow(items);
    }, [items]); // Incluez toutes les variables pertinentes

  return (
    <>
        <ModalConfirmedDeleteItem
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            isLoading={isLoading}
            onClick={handleDeleteItem}

        />
      {isLoading ?
        <p><Skeleton count={5}/></p> 
    :
        <>
            {itemShow && itemShow.length > 0 && 
            <>
                <p className='pt-3' style={{ fontWeight: '600'}}>{name}</p>
                {itemShow?.map((item, index) => {
                    return (
                        <tr key={index} className='py-5'>
                        {articleEnEdition === index ?
<>
<DropdownButton
                            drop='down-centered'
                            style={{display: 'inline-block' }}
                            className='custom-dropdown1 my-2 col-9 col-sm-6 col-md-5 col-lg-3'
                            id='dropdown-button-drop-down-centered'
                            title={<span style={{display: 'inline-block', fontSize: '1.4rem' }}>{item.article}</span>}
                        >
                            {prices[champs]?.map((price) => (
                                <Dropdown.Item
                                    key={price.name}
                                    onClick={() => changerArticle(price, index)}
                                >
                                    {price.name}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                        <button className='btn-link text-danger btn m-0 p-0' style={{display: 'block'}} onClick={() => setArticleEnEdition(null)}>Annuler</button>
</>
                        :
                        <td>
                            {editItem && <FiMinusCircle color='red' className='me-2 mb-1' style={{cursor: 'pointer'}} onClick={() => choisirArticlePourEditionDelete(index)}/>}
                            {item.article}
                            {editItem && <AiFillEdit color='#008080' className='mx-2' onClick={() => choisirArticlePourEdition(index)} style={{cursor: 'pointer'}}/>}
                        </td>
                    }
                    {articleEnEditionPrice === index ? 
                        <>
                        <input
                            className='mx-2 btn p-1'
                            style={{background:'#FFF', color: '#008080', border: '1px solid #008080', width: '50px'}}
                            type="number" 
                            min="1"
                            step={1}
                            value={valeurNewPrice}
                            onChange={handleChangePrice}
                          />

                        <button className='btn btn-success my-2 py-1 bg-success' onClick={() => changerPrice(valeurNewPrice, index)}>Valider</button>
                        <button className='btn-link text-danger btn m-0 p-0 mx-2' style={{display: 'block'}} onClick={() => {setArticleEnEditionPrice(null); setValeurNewPrice('')}} >Annuler</button>
                        </>
                    :
                        <>
                            {!customer && <td className='text-end'>
                            {calculerPrixHT(item.price).toFixed(2)} € {editItem && <AiFillEdit color='#008080' className='mx-2' onClick={() => choisirArticlePourEditionPrice(index)} style={{cursor: 'pointer'}}/>}
                            </td>}
                        </>
                    }
                    {articleEnEditionQte === index ? 
                        <>
                        <input
                            className='mx-2 btn p-1'
                            style={{background:'#FFF', color: '#008080', border: '1px solid #008080', width: '50px'}}
                            type="number" 
                            min="1"
                            step={1}
                            value={valeurNewQte}
                            onChange={handleChangeQte}
                          />

                        <button className='btn btn-success my-2 py-1 bg-success' onClick={() => changerQte(valeurNewQte, index)}>Valider</button>
                        <button className='btn-link text-danger btn m-0 p-0 mx-2' style={{display: 'block'}} onClick={() => {setArticleEnEditionQte(null); setValeurNewQte('')}} >Annuler</button>
                        </>
                    :
                        <>
                            <td className='text-end'>{item.total_article} {editItem && <AiFillEdit color='#008080' className='mx-2' onClick={() => choisirArticlePourEditionQte(index)} style={{cursor: 'pointer'}}/>}</td>
                        </>
                    }   
                       

                            {!customer && <>
                                <td className='text-end'>{calculerPrixHT(item.total).toFixed(2)} €</td>
                                <td className='text-end'>{calculerMontantTVA(item.total).toFixed(2)} €</td>
                                <td className='text-end'>{item.total.toFixed(2)} €</td>
                                
                            </>}
                        </tr>

                        

            )
        })}


            </>}
</>


      }
        


    </>
  )
}
// {
//     edit === 1 ?
//         <DropdownButton
//             
            // onSelect={(selectedItemName) => updateItem(index, itemIndex, 'article', selectedItemName)}
// onToggle={(isOpen) => {
//   if (!isOpen) {
//     setSearch('');
//   }
// }}
// >
{/* <Dropdown.Item onClick={(event) => event.stopPropagation()}> */}
{/* <input 
type="text"
onKeyDown={(e) => e.key === ' ' && e.stopPropagation()}
className="form-control" placeholder="ex: chemise sur cintre..." onChange={(e) => setSearch(e.target.value)} value={search}/> */}
// </Dropdown.Item>
// <Dropdown.Divider />
// {prices?.Pressing?.map((price) => (
// <Dropdown.Item
   
//     key={price.name}
//     eventKey={price.name}
//   >
//     {price.name}
//   </Dropdown.Item>
// ))}
{/* {availableItems.filter((doc) => {
  return search.toLowerCase() === '' ? doc : doc.name.toLowerCase().includes(search.toLowerCase());
}).length === 0 && <Dropdown.Item disabled className='text-center' style={{fontStyle: 'italic', pointerEvents: 'none'}}>Aucun article trouvé</Dropdown.Item>}



{availableItems.filter((doc) => {
  return search.toLowerCase() === '' ? doc.name : doc.name.toLowerCase().includes(search.toLowerCase());
}).map((availableItem) => (
  <Dropdown.Item
    onClick={() => {
      setSearch('');
    }}
    key={availableItem.name}
    eventKey={availableItem.name}
  >
    {availableItem.name}
  </Dropdown.Item>
))} */}
// </DropdownButton>
//     :
//         <td>{item.article} {editItem && <AiFillEdit color='#008080' className='mx-2' onClick={() => setEdit(1)} style={{cursor: 'pointer'}}/>}</td>
// }