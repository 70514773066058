import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import './NavbarItems.css'

export default function NavbarItems({isActive, setIsActive}) {
  return (
    <Container>
      <Row>
        <Col xs="auto" className="mr-1">
          <Button className={`btn ${isActive ? 'button-navbar-active' : 'button-navbar'}`} onClick={() => setIsActive(isActive => true)}>Produits</Button>
        </Col>
        <Col xs="auto">
          <Button className={`btn ${!isActive ? 'button-navbar-active' : 'button-navbar'}`}  onClick={() => setIsActive(isActive => false)}>Produits archivés</Button>
        </Col>
      </Row>
    </Container>
  )
}
