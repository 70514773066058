import CorporateDataService from "../../../../context/corporates.services";
import { formatPhoneNumber } from "../../../Formats/phoneNumbers/formatPhoneNumber";
import { ValidatePhone } from "../../../Validators/PhoneValidator";

export const UpdatePhoneContact = async ({ id, value, enqueueSnackbar, setIsLoading }) => {
    try {
        if(value !== '' && ValidatePhone(value)){
            setIsLoading(true);
            await CorporateDataService.updateCorporate('telContact', formatPhoneNumber(value), id);
            enqueueSnackbar(`Le numéro de téléphone de contact a bien été modifié en ${formatPhoneNumber(value)}.`, {
                variant: 'success',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}
                   
            });
            return true;
        }else{
            enqueueSnackbar(`Numéro de téléphone ${value} est invalide`, {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}  
            });
        }

    }catch(e) {
        enqueueSnackbar(`${e.message}`, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoHideDuration: 3000,
            style: {fontSize: '1.6rem'}  
        });
        setIsLoading(false);
        return false;
    }
};