import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { db } from '../../../../firebase-config';
import '../Items/NavbarItems.css'
import Searchbar from '../../../../components/Searchbar';
import { formatDate } from '../../../../utils/DateToString';
import CommandeDataService from '../../../../context/corporates.services'
import './PrestatairePage.css'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useNavigate } from 'react-router-dom';

export default function PrestatairePage() {

  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(true);
  const [ordersB2B, setOrdersB2B] = useState([]);
  const [ordersB2C, setOrdersB2C] = useState([]);
  const [presta, setPresta] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  

  useEffect(() => {
    const q = query(collection(db, "Prestataire"), orderBy("created_at", "desc"))  ;
    const unsubscribePresta = onSnapshot(q, (querySnapshot) => {
      const newPresta = [];
      querySnapshot.forEach((doc) => {
        newPresta.push({ ...doc.data(), id: doc.id });
    });
    setPresta(newPresta);
    });
  
    return () => unsubscribePresta(); 
  }, []);

  useEffect(() => {
    const q = query(collection(db, "Orders_B2B"), orderBy("date_creation_order", "desc"))  ;
    const unsubscribeB2B = onSnapshot(q, (querySnapshot) => {
      const newOrdersB2B = [];
      querySnapshot.forEach((doc) => {
        newOrdersB2B.push({ 
          order: { ...doc.data() },
          id: doc.id,
          type: 'B2B'
        });
    });
    setOrdersB2B(newOrdersB2B);
    });
  
    return () => unsubscribeB2B(); 
  }, []);

  useEffect(() => {
    const q = query(collection(db, "orders"), orderBy("date_creation", "desc"))  ;
    const unsubscribeB2C = onSnapshot(q, (querySnapshot) => {
      const newOrdersB2C = [];
      querySnapshot.forEach((doc) => {
        newOrdersB2C.push({
          order: { ...doc.data() },
          id: doc.id,
          type: 'B2C'
        });
    });
    setOrdersB2C(newOrdersB2C);
    });
  
    return () => unsubscribeB2C(); 
  }, []);

  const filtredByEtat = (orders, etatRecherche) => {
    return orders.filter(order => order.order.etat === etatRecherche && order.order.steps === 2);
  }
  const filtredByEtatB2B = (orders) => {
    return orders.filter(order => order.order.etat_commande === 2);
  }
  const verifierIds = (orders) => {
    return orders.filter(order => 
      !presta.some(orderPresta => orderPresta.id === order.id)
    );
  }
  
  const ordersValides = filtredByEtat(ordersB2C, "valider"); 
  const ordersPrestataire = verifierIds(ordersValides);
  const ordersValidesB2B = filtredByEtatB2B(ordersB2B); 
  const ordersPrestataireB2B = verifierIds(ordersValidesB2B);
  const [filteredOrdersB2C, setFilteredOrdersB2C] = useState([]); 
  const [filteredOrdersB2B, setFilteredOrdersB2B] = useState([]); 
  useEffect(() => {
    setFilteredOrdersB2C(ordersPrestataire)
    setFilteredOrdersB2B(ordersPrestataireB2B)
  }, [ordersB2C,ordersB2B, presta]);




  
  const handleSearch = (term) => {
    setSearchTerm(term);
    if(term) {
        const lowercasedSearch = term.toLowerCase();
        const filtered = ordersPrestataire.filter(order => 
          order.order.idFacture?.toLowerCase().includes(lowercasedSearch)
        );
        const filteredB2B = ordersPrestataireB2B.filter(b2bOrder => 
          b2bOrder.order.under_user.some(underUserOrder => 
            underUserOrder.number_order?.toLowerCase().includes(lowercasedSearch)
          ));
        setFilteredOrdersB2C(filtered);
        setFilteredOrdersB2B(filteredB2B);
    } else {
      setFilteredOrdersB2C(ordersPrestataire);
      setFilteredOrdersB2B(ordersPrestataireB2B);
    }
  }


  const [corporates, setCorporates] = useState({});

  useEffect(() => {
    const fetchCorporates = async () => {
        let corporatesData = {};
        for (const item of filteredOrdersB2B) {
            const id = item.order.user;
            try {
                const corporateData = await CommandeDataService.getCorporate(id); // Supposons que cela retourne une promesse résolue avec l'objet corporate
                corporatesData[id] = corporateData.data(); // Stocker tout l'objet corporate récupéré
                setIsLoading(false);
            } catch (error) {
                console.error("Erreur lors de la récupération des données d'entreprise", error);
            }
        }
        setCorporates(corporatesData); // Mise à jour de l'état avec les objets d'entreprise complets
    };
    fetchCorporates();
}, [filteredOrdersB2B]); // Ajoutez d'autres dépendances si nécessaire



  return (
    <div className='mb-5 pb-5'>
           {
        isLoading ? (
          <>
          <p className='m-5'>
          <Skeleton />
          </p>

          <p className='m-3'>
          <Skeleton count={2}/>
          </p>

          <p className='m-5'>
          <Skeleton />
          <Skeleton count={12}/>
          </p>
          </>

          
        ) : (
            <>
                 {/* {Barre de recherche} */}
      <Searchbar placeholder='Rechercher...' onSearch={handleSearch}/>
      {/* NavBar */}
        <Container>
        <Row>
            <Col xs="auto" className="mr-1">
            <button className={`btn ${isActive ? 'button-navbar-active' : 'button-navbar'}`} onClick={() => setIsActive(isActive => true)}>Prestataire ({ordersPrestataire.length})</button>
            </Col>
            <Col xs="auto">
            <button className={`btn ${!isActive ? 'button-navbar-active' : 'button-navbar'}`}  onClick={() => setIsActive(isActive => false)}>Historique</button>
            </Col>
        </Row>
        </Container>

        {isActive ? 
        
        <>
        {
          filteredOrdersB2C?.map((item) => {
                return (
                  <div className='rounded py-2 pt-3 px-5 d-flex justify-content-between flex-wrap mt-4' style={{fontSize: '1.4rem', fontWeight:'400', background: '#F5F5F5'}} key={item.id}>
                    <p className='text-start align-self-center col-12 col-md-3'><span className='rounded bg-dark p-1 px-2 text-light me-2' style={{fontSize: '1.2rem', fontWeight:'400', fontStyle: 'italic', letterSpacing: '1.4px'}}>{item.type}</span>{item.order.idFacture}</p>
                    <p className='text-start col-12 col-md-3 align-self-center'>
                      {formatDate(item.order.dateCollecte.toDate())} | {item.order.creneauCollecte} <br />
                      {formatDate(item.order.dateLivraison.toDate())} | {item.order.creneauLivraison}
                      </p>
                      <p className='text-start ps-md-5 align-self-center col-12 col-md-3' style={{fontSize: '1.2rem', fontWeight:'400', fontStyle: 'italic', letterSpacing: '1.4px'}}> 
                        <span style={{
                          display: 'inline-block',
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: '#FF5733',
                          marginRight: '5px'
                        }}>
                        </span>
                        En cours d'étiquetage
                      </p>
                    <p className='btn-link align-self-center col-12 col-md-3 text-end' style={{cursor: 'pointer'}}  onClick={() => navigate(`/private/Provisoir/dispatch/B2C/${item.id}`)}>Voir détails</p>
                  </div>
                )})
        }
        {
          filteredOrdersB2B?.map((item, index) => {
            const corporate = corporates[item.order.user] || {};


                return (
                  <div className='rounded py-2 pt-3 px-5 d-flex justify-content-between flex-wrap mt-4' style={{fontSize: '1.4rem', fontWeight:'400', background: '#F5F5F5'}}>
                    <p className='text-start align-self-center col-12 col-md-3'><span className='rounded bg-info p-1 px-2 text-light me-2' style={{fontSize: '1.2rem', fontWeight:'400', fontStyle: 'italic', letterSpacing: '1.4px'}}>{item.type}</span>{corporate.nameCompany}</p>
                    <p className='text-start align-self-center col-12 col-md-3'>
                      {formatDate(item.order.date_collecte.toDate())} | 10:00 - 17:00 <br />
                      {formatDate(item.order.under_user[0].date_livraison.toDate())} | 10:00 - 17:00
                      </p>
                      <p className='text-start align-self-center col-12 col-md-3 ps-md-5' style={{fontSize: '1.2rem', fontWeight:'400', fontStyle: 'italic', letterSpacing: '1.4px'}}> 
                        <span style={{
                          display: 'inline-block',
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: '#FF5733',
                          marginRight: '5px'
                        }}>
                        </span>
                        En cours d'étiquetage
                      </p>
                    <p className='btn-link align-self-center col-12 col-md-3 text-end' style={{cursor: 'pointer'}} onClick={() => navigate(`/private/Provisoir/dispatch/B2B/${item.id}`)}>Voir détails</p>
                  </div>
                )})
        }
        {
          (filteredOrdersB2C.length < 1 && filteredOrdersB2B < 1) && <p className='text-center m-5' style={{fontSize: '1.2rem', fontWeight:'400', fontStyle: 'italic', letterSpacing: '1.4px'}}>{searchTerm ? `La commande ${searchTerm} n'a pas été trouvée` : "Aucune commande en cours d'étiquetage"}</p>
        }
        </>
        
        :
        
        <>
          Historique
        </>}


        {/* {
          presta?.map((item, index) => {
                return (
                  <div><p>{item.id} Presta</p></div>
                )})
        } */}
            </>
        )
      }



    </div>
  )
}
