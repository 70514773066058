import React from 'react';


function Titre(props) {
    return(
        <h1 
            className="pt-3 mb-5"
            style={
                {
                    borderBottom: '1px solid grey',
                    display: 'inline-block',
                    fontWeight: '500',
                    letterSpacing: '1.5px',
                }
            }
        >{props.children}</h1>

    )
}
export default Titre