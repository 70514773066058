import React from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { formatDateComplete } from "../../../../../utils/DateToString";
import '../../../pages/Customers/Components/UsersList.css'

const CorporatesList = ({corporates, currentPage, setCurrentPage, ordersB2B}) => {

  const navigate = useNavigate();
  const PAGE_SIZE = 5;
  const nextPage = () => {
      if ((currentPage + 1) * PAGE_SIZE < corporates.length) {
          setCurrentPage(currentPage + 1);
      }
  };
  const prevPage = () => {
      if (currentPage > 0) {
          setCurrentPage(currentPage - 1);
      }
  };
  const paginatedOrders = corporates.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE);
  return (
    <>

      <Table responsive="sm" className=' mx-auto text-start table-personnalisee '>
        <thead>
          <tr>
            <th>Entreprise</th>
            <th>Téléphone</th>
            <th>Date de création</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {paginatedOrders.map((corporate) => {
            return (
              <tr key={corporate.idB2B}>
                <td>{corporate.nameCompany}</td>
                <td>{corporate.telCompany}</td>
                <td>{formatDateComplete(corporate.date_creation?.toDate().toISOString())}</td>
                <td>
                    <span style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        borderRadius: '99%',
                        backgroundColor: corporate.status === 1 ? '#39FF33' : corporate.status === 2 ? '#FF5733' : corporate.status === 3 ? '#FFD700' : 'transparent',
                        marginRight: '5px'
                    }}>
                    </span>{corporate.status === 1 ? 'activé' : corporate.status === 2 ? 'archivé' : corporate.status === 3 ? 'en pause' : ''}</td>
                <td className='btn-link' style={{cursor: 'pointer'}} onClick={() => navigate(`/private/Corporate/${corporate.idB2B}`)}>Voir détails</td>
                
                <td>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="d-flex flex-wrap justify-content-center my-5 bg-light py-2 rounded">
  <div className='flex-fill p-2 d-flex justify-content-center align-items-center'>{corporates.length} résultats</div>
  <div className='flex-fill p-2 d-flex justify-content-center align-items-center'>
  <button onClick={prevPage} disabled={currentPage === 0} style={{border: '1px solid #e0e0e0', borderRadius: '8px', fontSize: '1.2rem'}} className="btn">Précédent</button>
                <button onClick={nextPage} className="ms-3 btn" style={{border: '1px solid #e0e0e0', borderRadius: '8px', fontSize: '1.2rem'}} disabled={(currentPage + 1) * PAGE_SIZE >= corporates.length}>Suivant</button>
  </div>
            </div>
    </>
  );
};

export default CorporatesList;