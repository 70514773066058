import React, { useEffect } from 'react'
import { useState } from 'react';
import { FloatingLabel, Form, Modal } from 'react-bootstrap';
import { formatDate, formatDateComplete } from '../../../../../../utils/DateToString';
import { MdDeleteForever, MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { AiFillEdit } from 'react-icons/ai';
import CorporateDataService from '../../../../../../context/corporates.services';
import { FindUnderUserById } from '../../../../../../utils/Corporate/Orders/FindB2BOrderById';
import Searchbar from '../../../../../../components/Searchbar';
import { collection, doc, onSnapshot, query } from 'firebase/firestore';
import { db } from '../../../../../../firebase-config';
import Skeleton from 'react-loading-skeleton';
import { UpdateStatusOrder } from '../../../../../../utils/Corporate/Orders/Update/UpdateStatusOrder';
import { useSnackbar } from 'notistack';
import ModalConfirmed from './ModalConfirmed';
import RecapItems from './RecapItems';

const ordersCorporateCollectionRef = process.env.REACT_APP_FIREBASE_COLLECTION_ORDERSB2B;

export default function ModalDetailOrderB2B({ showDetailsOrder, setShowDetailsOrder, underUsers, orderB2BID, corporate, orderCorporate, setModalShowDetail, setShowConfirmationDeleteOrder, sommeTotal, editStepOrder, setEditStepOrder, setIdOrder, setOrderCorporate, setSommeTotal, idOrder, getCorporate }) {

    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [orderB2B, setOrderB2B] = useState({});
    const [filtredorderB2B, setFiltredOrderB2B] = useState({});
    const { enqueueSnackbar } = useSnackbar();
    const [showModalConfirmed, setShowModalConfirmed] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(filtredorderB2B.etat_commande);



    const hasNonEmptyRecapDriver = filtredorderB2B?.under_user?.some(user => 
        Object.values(user.recap_driver).some(array => array.length > 0)
    );
    const hasNonEmptyRecapDriverBis = filtredorderB2B?.under_user?.some(user => 
        Object.values(user.recap_driverBis).some(array => array.length > 0)
    );
    const hasNonEmptyRecapPresta = filtredorderB2B?.under_user?.some(user => 
        Object.values(user.recap_presta).some(array => array.length > 0)
    );

    const handleShowConfirmedModal = () => setShowModalConfirmed(true);

    const updateOrderStatus = (event) => {
        const newStatus = event.target.value;
        handleShowConfirmedModal();
        setSelectedStatus(Number(newStatus))
    }

    const updateConfirmed = async () => {
        if (typeof orderB2BID === 'string' && orderB2BID.trim() !== '') {
            await UpdateStatusOrder({ id: orderB2BID, value: selectedStatus, enqueueSnackbar, setIsLoading, orderB2B });
        }
    }
    const showStatusOrder = (status) => {

            switch (status) {
                case 1 : 
                    return ' En cours de récupération';
                    break;
                case 2 : 
                    return ' Récéptionner';
                    break;
                case 3 : 
                    return ' En cours de traitement';
                    break;
                case 4 : 
                    return ' En cours de livraison';
                    break;
                case 5 : 
                    return ' Livrée';
                    break;
                case 6 : 
                    return ' Annulée';
                    break;
                default : return 'ERROR';
              }
    };
    const handleSearch = (term) => {
        let newOrderB2B = { ...orderB2B };

        setSearchTerm(term);
    
        if (term) {
            const lowercasedSearch = term.toLowerCase();
            const filtered = orderB2B?.under_user.filter(order => {
                const userMatch = underUsers.find(user => user.idUnderUser === order.id_userUnder);
                return order.number_order?.toLowerCase().includes(lowercasedSearch) ||
                       userMatch?.last_name.toLowerCase().includes(lowercasedSearch) ||
                       userMatch?.first_name.toLowerCase().includes(lowercasedSearch);
            });

            newOrderB2B.under_user = filtered;
    
            setFiltredOrderB2B(newOrderB2B);
        } else {
            setFiltredOrderB2B(orderB2B);
        }
    };

    useEffect(() => {
        if (typeof orderB2BID === 'string' && orderB2BID.trim() !== '') {
            const unsubscribe = onSnapshot(doc(db, ordersCorporateCollectionRef, orderB2BID), (doc) => {
                const data = doc.data();
                const underUserWithIndex = data.under_user.map((item, index) => ({
                    ...item,
                    initialIndex: index // Ajout de l'indice initial
                }));
    
                setOrderB2B({ ...data, under_user: underUserWithIndex });
                setFiltredOrderB2B({ ...data, under_user: underUserWithIndex });
                setIsLoading(false);
            });
          
            return () => unsubscribe(); 
        }
    }, [orderB2BID]);
    
    // Mettre à jour la valeur sélectionnée lorsque modalShow change
    useEffect(() => {
        if (!showModalConfirmed) {
            setSelectedStatus(filtredorderB2B.etat_commande); // Définir la valeur initiale ou la valeur souhaitée
        }
    }, [showModalConfirmed, filtredorderB2B.etat_commande]);
    






    const [etat, setEtat] = useState(1);
    const [showRecapClient, setShowRecapClient] = useState(false);
    const [showRecapPresta, setShowRecapPresta] = useState(false);
    const [showRecapDriver, setShowRecapDriver] = useState(false);
    const getOrderB2B = async (id) => {
        const docSnap = await CorporateDataService.geOrderCorporate(id)
        setOrderCorporate(docSnap.data());
        setIdOrder(id);
        let ttc = 0;
        for(let i = 0; i < docSnap.data().under_user.length; i++){
          ttc = ttc+  docSnap.data().under_user[i].total
        }
        setSommeTotal(ttc);
        setModalShowDetail(true);  
  
    };

    const handleUpdateStepOrder = async () => {
        await CorporateDataService.updateStepOrder(etat, idOrder);
        getOrderB2B(idOrder);
        setEditStepOrder(false);
      }
      const handleOptionChangeEtat = (event) => {
        setEtat(Number(event.target.value));
      }

  return (
    <>
        <ModalConfirmed 
            show={showModalConfirmed}
            setShow={setShowModalConfirmed}
            selectedStatus={showStatusOrder(selectedStatus)}
            status={selectedStatus}
            isLoading={isLoading}
            onClick={updateConfirmed}
            passages={corporate.slots_passages}
            setIsLoading={setIsLoading}
            id={orderB2B?.user}
            getCorporate={getCorporate}
        />

        <Modal
            show={showDetailsOrder}
            onHide={() => {setShowDetailsOrder(false)}}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
      <Modal.Body className='m-5'>

      { isLoading ? (
          <>
            <p><Skeleton /></p>

            <p><Skeleton count={3}/></p>

            <p><Skeleton count={5}/></p>

          </>
        ) : (
          <>
          <div className="d-flex flex-wrap justify-content-between mb-4 ">
            <p className="m-0 p-0 mb-2 align-self-center" style={{ fontStyle: 'italic', fontSize: '1.4rem'}}> <span style={{fontWeight: '600' }}>Date de Création : </span>{orderB2B && formatDateComplete(orderB2B?.date_creation_order?.toDate().toISOString())}</p>
            <div className='align-self-center'>
                <FloatingLabel
                    controlId="floatingSelectGrid"
                    label="Status"
                    style={{fontSize: '1.4rem'}}
          
                >
                    <Form.Select aria-label="Modifier le status de la commande" style={{fontSize: '1.4rem', height: '55px'}} className='pe-5' value={selectedStatus} onChange={e => updateOrderStatus(e)} >


                        <option value={filtredorderB2B.etat_commande}>{showStatusOrder(filtredorderB2B.etat_commande)}</option>
                        

                        {filtredorderB2B.etat_commande === 1 && (
                            <>
                                <option value={2}>Réceptionner</option>
                                <option value={6}>Annulée</option>
                            </>
                        )}

                        {filtredorderB2B.etat_commande === 2 && (
                            <>
                                <option value={1}>En cours de récupération</option>
                                <option value={3}>En cours de traitement</option>
                                <option value={6}>Annulée</option>
                            </>
                        )}

                        {filtredorderB2B.etat_commande === 3 && (
                            <>
                                <option value={2}>Réceptionner</option>
                                <option value={4}>En cours de livraison</option>
                            </>
                        )}
                        {filtredorderB2B.etat_commande === 4 && (
                            <>
              
                 
                                <option value={3}>En cours de traitement</option>
                                <option value={5}>Livrée</option>
                                <option value={6}>Annulée</option>
                            </>
                        )}
                        {(filtredorderB2B.etat_commande === 5) && (
                            <>
                                <option value={4}>En cours de livraison</option>
                                <option value={6}>Annulée</option>
                            </>
                        )}
                        {filtredorderB2B.etat_commande === 6 && (
                            <>
                                {hasNonEmptyRecapDriver && (
                                    <>
                                        <option value={1}>En cours de récupération</option>
                                        <option value={2}>Réceptionner</option>
                                        {hasNonEmptyRecapDriverBis && (
                                            <option value={3}>En cours de traitement</option>
                                        )}
                                        {hasNonEmptyRecapPresta && (
                                            <>
                                                <option value={4}>En cours de livraison</option>
                                                <option value={5}>Livrée</option>
                                            </>
                                        )}
                                    </>
                                )}

                            </>
                        )}

                    </Form.Select>
                </FloatingLabel>
            </div>
        </div>

        <div className="border mb-2"></div>

        <Searchbar placeholder='Rechercher par nom ou numéro de commande...' onSearch={handleSearch}/>


      {filtredorderB2B.under_user?.map( underUser => {
        const getUnderUser = FindUnderUserById(underUsers, underUser.id_userUnder);
          return(
            <>
                <p key={underUser.idUnderUser} className='p-3' style={{fontWeight: '600', color: '#008080', fontSize: '1.8rem', borderBottom: '1px solid #008080'}}>
                    {getUnderUser?.first_name} {getUnderUser?.last_name.toUpperCase()}
                    <span className='rounded bg-success p-1 px-2 text-light ms-3' style={{fontSize: '1.2rem', fontWeight:'400', fontStyle: 'italic', letterSpacing: '1.4px'}}>{getUnderUser?.status}</span>
                </p>


                <div className='mx-auto px-4 py-3 my-2 mb-3' style={{ background: '#F1F1F1', width: '100%', borderRadius: '5px' }}>
                  <div className="row justify-content-between">
                    <div className='col-sm-12 col-md-6 align-self- text-start'>
                      <p style={{ fontWeight: '600' }}>N° de commande : <span style={{ fontWeight: '500', textTransform : 'uppercase' }}>{underUser.number_order}</span></p>
                      <p style={{ fontWeight: '600' }} className= 'py-1'>Collecte : <span style={{ fontWeight: '500' }}>{formatDate(orderB2B.date_collecte.toDate())} {corporate.homeDelivery ? `| ${orderB2B.slot_collecte_end}` : null}</span><AiFillEdit color='#008080' className='mx-2' onClick={() => setEditStepOrder(true)} style={{cursor: 'pointer'}}/></p>
                      <p style={{ fontWeight: '600' }}>Prestation : <span style={{ fontWeight: '500' }}>{underUser.quality}</span></p>
                    </div>
                    <div className='col-sm-12 col-md-6 align-self-center text-start'>
                    <p style={{ fontWeight: '600' }}>Status : 
                        {editStepOrder ? 
                            <FloatingLabel
                                controlId="floatingSelectGrid"
                                style={{fontSize: '1.4rem'}}
                            >
                                {/* <Form.Select aria-label="Modifier le status de la commande" style={{fontSize: '1.4rem', height: '30px'}} className='pe-5 py-0 my-0' onChange={e => updateOrderStatus(e.target.value)} >
                                    <option value=''>Séléctionner un status</option>
                                    <option value={1}>En cours de récupération</option>
                                    <option value={2}>Récéptionner</option>
                                    <option value={3}>En cours de traitement</option>
                                    <option value={4}>En cours de livraison</option>
                                    <option value={5}>Livrée</option>
                                    <option value={6}>annulée</option>
                                </Form.Select> */}
                            </FloatingLabel> 
                        :
                            <>
                                <span style={{ fontWeight: '500' }}>{underUser.etat_second === '' ? showStatusOrder(orderB2B?.etat_commande) : showStatusOrder(parseInt(underUser.etat_second))}</span><AiFillEdit color='#008080' className='mx-2' onClick={() => setEditStepOrder(true)} style={{cursor: 'pointer'}}/>
                            </>
                        }
                    </p>
                    <p style={{ fontWeight: '600' }}>Livraison : <span style={{ fontWeight: '500' }}>{formatDate(underUser?.date_livraison?.toDate().toISOString())} {corporate.homeDelivery ? `| ${underUser?.slot_livraison_end}` : null}</span><AiFillEdit color='#008080' className='mx-2' onClick={() => setEditStepOrder(true)} style={{cursor: 'pointer'}}/></p>
                    <p style={{ fontWeight: '600' }}>N° de facture : <span style={{ fontWeight: '500' }}>{orderB2B.facture_id ? orderB2B.facture_id : '-' }</span></p>
                    </div>
                    <div className='col-sm-12 col-md-6 align-self-center text-start'>
                        <p className= 'pt-1' style={{ fontWeight: '600' }}>Date d'enregistrement : <span style={{ fontWeight: '500' }}>{formatDateComplete(underUser.date_creation.toDate())}</span></p>
                    </div>
                    <div className='col-sm-12 col-md-6 align-self-center text-start'>
                        <p className= 'pt-1' style={{ fontWeight: '600'}}>Adresse livraison : <span style={{ fontWeight: '500' }}>{corporate.homeDelivery ? `${underUser.address}` : `${corporate.adressLivraisonCompany}`}</span></p>
                    </div>
                  </div>
                </div>

                <p className='p-4 text-light' style={{ fontWeight: '600', fontStyle: 'italic', background: '#008080', borderRadius: '5px', letterSpacing: '1.2px'}}>Récapulatif client</p>
                <RecapItems 
                    customer={true}
                    items={orderB2B.under_user[underUser.initialIndex].recap_driver}
                    isLoading={isLoading}
                    editItem={false}
                    id={corporate.price}
                    underUser={orderB2B.under_user[underUser.initialIndex]}
                    orderB2B={orderB2B}
                    orderB2BID={orderB2BID}
                    setIsLoading={setIsLoading}
                    showAddingItem={false}
                />
                <p className='p-4 text-light' style={{ fontWeight: '600', fontStyle: 'italic', background: '#008080', borderRadius: '5px', letterSpacing: '1.2px'}}>Récapulatif Chauffeur</p>
                <RecapItems 
                    customer={false} 
                    items={orderB2B.under_user[underUser.initialIndex].recap_driverBis} 
                    isLoading={isLoading} 
                    editItem={false} 
                    id={corporate.price} 
                    underUser={orderB2B.under_user[underUser.initialIndex]} 
                    orderB2B={orderB2B}
                    orderB2BID={orderB2BID}
                    setIsLoading={setIsLoading}
                    showAddingItem={false}
                />
                <p className='p-4 text-light' style={{ fontWeight: '600', fontStyle: 'italic', background: '#008080', borderRadius: '5px', letterSpacing: '1.2px'}}>Récapulatif Prestataire</p>
                <RecapItems 
                    customer={false} 
                    items={orderB2B.under_user[underUser.initialIndex].recap_presta} 
                    isLoading={isLoading} 
                    editItem={true} 
                    id={corporate.price} 
                    underUser={orderB2B.under_user[underUser.initialIndex]}
                    orderB2B={orderB2B}
                    orderB2BID={orderB2BID}
                    setIsLoading={setIsLoading}
                    showAddingItem={true}
                />






                <p>Notes Apres le recap de chaque commande</p>
                <div className="row justify-content-end mt-4 mb-3 px-md-3">
                <div className="col-auto text-start mx-3">
                  <p style={{fontWeight: '600'}}>Total HT </p>
                  <p style={{fontWeight: '600'}}>TVA (20%) </p>
                  <p style={{fontWeight: '600'}}>Total TTC </p>
                </div>
                <div className="col-auto text-end">
                  <p>{Number.parseFloat(underUser.total/1.2).toFixed(2)} €</p>
                  <p>{(Number.parseFloat(underUser.total).toFixed(2) - Number.parseFloat(underUser.total/1.2).toFixed(2)).toFixed(2)} €</p>
                  <p>{Number.parseFloat(underUser.total).toFixed(2)} €</p>
                </div>
              </div>


              

         


            </>

          )})}







  
        <div className='col border-bottom mb-2'>
            {/* <p className="m-0 p-0 mb-2"> <span style={{fontWeight: '600'}}>Etat de la commande : </span>{getStatusOrder(orderCorporate?.etat_commande)}</p>              */}
        </div>
      <div className="d-flex flex-wrap justify-content-between mb-4 border-bottom">

          <button className='btn btn-outline-danger mb-3' onClick={() => setShowConfirmationDeleteOrder(true)}><MdDeleteForever color='red' /> Supprimer la commande définitivement</button>
          
        </div>



        <div className='col border-bottom mb-2'>
          {editStepOrder ? 
            <div className="d-flex flex-wrap justify-content-start">
            <div>
            <label className="my-2 p-1 mx-1" style={{fontWeight: 'bold'}}>Etat de la commande : </label>
            <select value={etat} onChange={handleOptionChangeEtat} className="my-2 mx-1 p-1">
                <option value={1}>Commande en cours</option>
                <option value={2}>Commande récupérée</option>
                <option value={3}>Commande livrée</option>
                <option value={4}>Commande annulée</option>
            </select>
            </div>
            <button className='btn btn-success my-2 py-1' onClick={handleUpdateStepOrder}>Valider</button>

        </div> :
            <p className="m-0 p-0 mb-2">
              <span style={{fontWeight: '600'}}>Etat : </span>
              {/* {getStatusOrder(orderCorporate && orderCorporate.etat_commande)} */}
              <AiFillEdit color='#008080' className='mx-2' onClick={() => setEditStepOrder(true)} style={{cursor: 'pointer'}}/>
            </p>
          }
                      
        </div>



        <div>
        <p className='text-center bg-success py-2 text-light' style={{fontWeight: '600'}}>Total global : {orderB2B.total_global.toFixed(2)} €</p>
    

        </div>
          </>)}
       





  
        
      </Modal.Body>
      <Modal.Footer>
        <button onClick={() => setModalShowDetail(false)} className=' btn btn-secondary'>Fermer</button>
      </Modal.Footer>
    </Modal>
    </>

  )
}
