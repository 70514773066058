import CorporateDataService from "../../../../context/corporates.services";

export const UpdatePriceCompany = async ({ id, value, enqueueSnackbar, setIsLoading }) => {
    try {
        if(value !== ''){
            setIsLoading(true);
            await CorporateDataService.updateCorporate('price', value, id);
            enqueueSnackbar(`Le tarif ${value} est appliqué.`, {
                variant: 'success',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}
                   
            });
            return true;
        }else{
            enqueueSnackbar(`Veuillez séléctionner un tarif`, {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}  
            });
        }
    }catch(e) {
        enqueueSnackbar(`${e.message}`, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoHideDuration: 3000,
            style: {fontSize: '1.6rem'}  
        });
        setIsLoading(false);
        return false;
    }
};