export const filterOrdersForCurrentWeekB2B = (orders) => {
    // Obtenir la date actuelle
    const now = new Date();
    
    // Ajuster au début de la semaine (lundi)
    const firstDayOfWeek = new Date(now);
    firstDayOfWeek.setDate(now.getDate() - now.getDay() + (now.getDay() === 0 ? -6 : 1)); 
    firstDayOfWeek.setHours(0, 0, 0, 0); // Réinitialiser les heures

    // Ajuster à la fin de la semaine (dimanche)
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
    lastDayOfWeek.setHours(0, 0, 0, 0); // Fin de la journée




    return orders.filter(order => {
        // Vérifier si dateCollecte existe avant de continuer.
        if (!order.date_collecte) {
            return false; // si dateCollecte n'existe pas, on ignore cette commande.
        }

        const collecteDate = order.date_collecte?.toDate();
        collecteDate.setHours(0, 0, 0, 0);

        // Pour la robustesse, vous pouvez également vérifier si 'toDate' retourne une date valide ici.
        const isCollecteDateMatch = collecteDate.getTime() >= firstDayOfWeek.getTime() && collecteDate.getTime() <= lastDayOfWeek.getTime() && order.etat_commande === 1;

        const isLivraisonDateMatch = order.under_user.some((user) => {
            if (!user.date_livraison) {
                return false; // si date_livraison n'existe pas pour cet utilisateur, on l'ignore.
            }

            const dateLivraison = user.date_livraison.toDate();
            dateLivraison.setHours(0, 0, 0, 0);

            return (order.etat_commande === 3 || order.etat_commande === 2) && (dateLivraison.getTime() >= firstDayOfWeek.getTime() && dateLivraison.getTime() <= lastDayOfWeek.getTime());
        });

        return isCollecteDateMatch || isLivraisonDateMatch;
    });
};
