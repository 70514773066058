import { doc, setDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { db } from '../../../../firebase-config';

export default function CopyPrices({ tarifs }) {

    const { enqueueSnackbar } = useSnackbar();
    const [selectedNamePrice, setSelectedNamePrice] = useState(null);
    const [indexSelected, setIndexSelected] = useState(null);
    const [nameTarif, setNameTarif] = useState('');

    const handleSelect = (item) => {
        setSelectedNamePrice(selectedTarif => tarifs[item].id);
        setIndexSelected(indexSelected => item)
    };
    const handleNameChanged = (e) => setNameTarif(e.target.value);
    const handleCopy = async() => {
        if(selectedNamePrice && indexSelected && nameTarif !== ''){
            const newTarifs = {
                Cordonnerie: tarifs[indexSelected].Cordonnerie,
                Pressing: tarifs[indexSelected].Pressing,
                Retouche: tarifs[indexSelected].Retouche,
                lk: tarifs[indexSelected].lk,
            }
            try {
                // Créez un nouveau document avec le nom "nameTarif" dans la collection "tarifs"
                const tarifDocRef = doc(db, "tarifs", nameTarif);
        
            
                // Enregistrez les données "articlesList" dans le document créé
                await setDoc(tarifDocRef, newTarifs);
                enqueueSnackbar('Grille tarifaire enregistrée avec succès', { 
                    variant: 'success',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    autoHideDuration: 3000,
                    className:'text-center'
                   
                  });
                  setNameTarif('')
                  setIndexSelected(null)
                  setSelectedNamePrice(null)
            } catch (error) {
                enqueueSnackbar(`Erreur lors de l'enregistrement du document : ${error}`, { 
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    autoHideDuration: 3000,
                    className:'text-center'
                   
                  });
            }
        }else{
            enqueueSnackbar('Erreur', { 
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                className:'text-center'
               
              });
        }
        }


  return (
    <div className="d-flex align-items-start align-items-md-center flex-md-row flex-column mb-3">
    <p className='pt-3' style={{ fontWeight: '600' }}>Selectionner un Tarif à copié</p>
    <DropdownButton
        title={selectedNamePrice ? selectedNamePrice : 'Sélectionner un tarif'}
        onSelect={handleSelect}
        id='dropdown-button-drop-down-centered'
        drop='down-centered'
        className='custom-dropdown mx-md-3'
  >
    {tarifs?.map((item, index) => (
        <Dropdown.Item 
            key={index}
            eventKey={index}
            disabled={item.id === 'global'} style={item.id === 'global' ? {background: '#F1F1F1', color: 'grey'} : {}}>
          {item.id}
        </Dropdown.Item>
    ))}
  </DropdownButton>

    <div class="col">
      <input type="text" className="form-control" placeholder="Nom de la nouvelle grille" value={nameTarif} onChange={handleNameChanged}/>
    </div>
    <div class="col mx-3">
      <button className="btn btn-success" onClick={handleCopy}>Valider</button>
    </div>

</div>
  )
}
