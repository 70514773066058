import React from 'react'
import { Modal } from 'react-bootstrap'
import ConfirmedButton from '../../../../../../components/Buttons/ConfirmedButton'

export default function ModalConfirmedDeleteItem({show, onHide, onClick, isLoading}) {
  return (
    <Modal
    show={show}
    onHide={onHide}
    backdrop="static"
    keyboard={false}
    centered
  >
    <Modal.Body className='p-4 text-center' style={{fontWeight: '600'}}>
    <p>Êtes-vous sûr d'avoir supprimé cet article ?</p>
    <p>Cette action est irréversible</p>
     
    </Modal.Body>
    <Modal.Footer>
        <button onClick={onHide} className='btn btn-outline-danger' style={{fontSize: '1.4rem'}}>Annuler</button>
        <ConfirmedButton onClick={onClick} isLoading={isLoading} labelLoading="Traitement en cours" labelButton="Confirmer"/>
    </Modal.Footer>
  </Modal>
  )
}
