import React, { useState, useEffect } from "react";
import { Form, Alert, InputGroup, Button} from "react-bootstrap";
import CodePromoDataService from "../../../../context/codePromo.services";

const AddCodePromo = ({ id, setCodePromoId }) => {

  const [code, setCodePromo] = useState("");
  const [valeur, setValeur] = useState(0);
  const [message, setMessage] = useState({ error: false, msg: "" });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    if (code === "" || valeur === 0) {
      setMessage({ error: true, msg: "Tout les champs sont obligatoire" });
      return;
    }
    const newPromo = {
      code,
      valeur: parseInt(valeur),
      idClient:""
    };
    try {
      if (id !== undefined && id !== "") {
        await CodePromoDataService.updateCodePromo(id, newPromo);
        setCodePromoId("");
        setMessage({ error: false, msg: "Mis à jour avec succés !" });
      } else {
        await CodePromoDataService.addCodesPromos(newPromo);
        setMessage({ error: false, msg: "Nouveau code promo ajouté avec succès !" });
      }
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }

    setCodePromo("");
    setValeur(0);
  };

  const editHandler = async () => {
    setMessage("");
    try {
      const docSnap = await CodePromoDataService.getCodePromo(id);
      setCodePromo(docSnap.data().code);
      setValeur(docSnap.data().valeur);
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  };

  useEffect(() => {
    if (id !== undefined && id !== "") {
      editHandler();
    }
  }, [id]);
  return (
    <>
      <div className="p-4 box">
        {message?.msg && (
          <Alert
            variant={message?.error ? "danger" : "success"}
            dismissible
            onClose={() => setMessage("")}
          >
            {message?.msg}
          </Alert>
        )}

        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBookTitle">
            <InputGroup>
              <InputGroup.Text id="formBookTitle">Code</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="ex : Cleamy10"
                value={code}
                onChange={(e) => setCodePromo(e.target.value)}
              />
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBookAuthor">
            <InputGroup>
              <InputGroup.Text id="formBookAuthor">Valeur</InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="10"
                value={valeur}
                onChange={(e) => setValeur(e.target.value)}
              />
              <InputGroup.Text>€</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <div className="d-grid gap-2">
            <Button variant="info text-light" type="Submit">
              Confirmer
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddCodePromo;