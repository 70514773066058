import { Timestamp } from "firebase/firestore";
import CorporateDataService from "../../../../context/corporates.services";

export const UpdatePassageCompany = async ({ id, value, enqueueSnackbar, setIsLoading }) => {
    try {
        if(value.length > 1){
            setIsLoading(true);
            await CorporateDataService.updateCorporate('slots_passages', value.map((r) => Timestamp.fromDate(new Date(r))), id);
            enqueueSnackbar(`Les passages suivants : ${value.map((date) => date).join(', ')} ont été pris en compte.`, {
                variant: 'success',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}
                   
            });
            return true;
        }else{
            enqueueSnackbar(`Veuillez séléctionner au moins 2 dates`, {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}  
            });
        }
    }catch(e) {
        setIsLoading(false);
        enqueueSnackbar(`${e.message}`, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoHideDuration: 3000,
            style: {fontSize: '1.6rem'}  
        });
        setIsLoading(false);
        return false;
    }
};