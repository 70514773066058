import React, { useState, useEffect } from 'react'
import { Dropdown, DropdownButton, Form, Table } from 'react-bootstrap'
import TbodyItem from './TbodyItem'
import './RecapItem.css'
import { CiCirclePlus } from "react-icons/ci";
import PricesDataService from '../../../../../../context/Prices.services'
import { CalculerSommeTotalsByPassage } from '../../../../../../utils/Calculator/CalculerSommeTotalsByPassage';
import { UpdateRecapPrestaItem } from '../../../../../../utils/Corporate/Orders/Update/UpdateRecapPrestaItem';
import { UpdateTotalGlobal } from '../../../../../../utils/Corporate/Orders/Update/UpdateTotalGlobal';
import { useSnackbar } from 'notistack';

const categories = ['Pressing', 'Cordonnerie', 'Retouche', 'lk'];
const NameCategories = ['Pressing & Blanchisserie', 'Cordonnerie', 'Retouche', 'Linge au kilo'];

export default function RecapItems({customer, items, isLoading, editItem, id, underUser, orderB2B, orderB2BID, setIsLoading, showAddingItem}) {

    const [addItemShow, setAddItemShow] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({name: NameCategories[0], id: 0});
    const [selectedPrice, setSelectedPrice] = useState(null);
    const [prices, setPrices] = useState({});
    const [addPriceManuel, setAddPriceManuel] = useState(false);
    const [newItem, setNewItem] = useState({ article: '', price: '', total: 0, total_article: '' })
    const [qte, setQte] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const getPrices = async () => {
        const docSnap = await PricesDataService.getPrice(id)
        setPrices({ ...docSnap.data(), id: docSnap.id});
    };
    const handleChangeQte = (e) => {
        const val = e.target.value;
        if (!isNaN(val) && (val > 0 || val === '')) {
            setQte(val);
        }
    };
    const handleChange = () => {
        setAddPriceManuel(!addPriceManuel);
        setQte('');
        setNewItem({ article: '', price: '', total: 0, total_article: '' });
    };
    const changerQte = async (newQte, price) => {
        const qteToNumber = Number(newQte);
        if(price && !addPriceManuel){
            if(qteToNumber > 0 && price){
                const newItems = [...items[categories[selectedCategory.id]]];
                const newItem = {
                    article: price.name,
                    price: price.priceTTC.standard,
                    total : Number((price.priceTTC.standard * newQte).toFixed(2)),
                    total_article: Number(newQte)
                }
                newItems.push(newItem);  

                let newOrderB2BUnderUsers = [ ...orderB2B.under_user];
                newOrderB2BUnderUsers[underUser.initialIndex].recap_presta[categories[selectedCategory.id]] = newItems;
                newOrderB2BUnderUsers[underUser.initialIndex].total += Number((price.priceTTC.standard * newQte).toFixed(2));
                await UpdateRecapPrestaItem({id: orderB2BID, value: newOrderB2BUnderUsers, enqueueSnackbar, setIsLoading });
                // changer le total global 
                const totalGlobal = CalculerSommeTotalsByPassage(newOrderB2BUnderUsers).toFixed(2);
                await UpdateTotalGlobal({id: orderB2BID, value: totalGlobal, enqueueSnackbar, setIsLoading })
                setQte('');
                setAddPriceManuel(false);
                console.log('New order', newOrderB2BUnderUsers);
                console.log('Total order', totalGlobal);

            }else{
                enqueueSnackbar(`Veuillez saisir une quantitée.`, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    autoHideDuration: 3000,
                    style: {fontSize: '1.6rem'}  
                });
            }
        }else{
            enqueueSnackbar(`Veuillez séléctionner un article.`, {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}  
            });

        }
    };
    const addItemManuel = async (newItem) => {

        if(addPriceManuel){
            if(newItem.article === ''){
                enqueueSnackbar(`Veuillez Saisir un article.`, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    autoHideDuration: 3000,
                    style: {fontSize: '1.6rem'}  
                });
            }else if(newItem.price === ''){
                enqueueSnackbar(`Veuillez saisir un prix ttc.`, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    autoHideDuration: 3000,
                    style: {fontSize: '1.6rem'}  
                });

            }else if(newItem.total_article === ''){
                enqueueSnackbar(`Veuillez saisie une quantitée.`, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    autoHideDuration: 3000,
                    style: {fontSize: '1.6rem'}  
                });
            }else{
                const newItems = [...items[categories[selectedCategory.id]]];
                const updateItem = { 
                    ...newItem,
                    price: Number(newItem.price),
                    total: Number((newItem.price * newItem.total_article).toFixed(2)),
                    total_article: Number(newItem.total_article)
                };
                newItems.push(updateItem); 
                let newOrderB2BUnderUsers = [ ...orderB2B.under_user];
                newOrderB2BUnderUsers[underUser.initialIndex].recap_presta[categories[selectedCategory.id]] = newItems;
                newOrderB2BUnderUsers[underUser.initialIndex].total += Number((updateItem.price * updateItem.total_article).toFixed(2));
                await UpdateRecapPrestaItem({id: orderB2BID, value: newOrderB2BUnderUsers, enqueueSnackbar, setIsLoading });
                // changer le total global 
                const totalGlobal = CalculerSommeTotalsByPassage(newOrderB2BUnderUsers).toFixed(2);
                await UpdateTotalGlobal({id: orderB2BID, value: totalGlobal, enqueueSnackbar, setIsLoading });
                setNewItem({ article: '', price: '', total: 0, total_article: '' });
            }
        }
    };

    useEffect(() => {
        if (!addPriceManuel) {
            setNewItem({ ...newItem, article: '', price: '', total_article: '' });
        }
    }, [addPriceManuel]);
    
    useEffect(() => {
        if (showAddingItem && addItemShow) {
            const loadPrices = async () => {
                await getPrices();
                if (prices && categories[selectedCategory.id] in prices) {
                    setSelectedPrice(prices[categories[selectedCategory.id]][0]);
                }
            };
    
            loadPrices();
        }
    }, [showAddingItem, addItemShow, categories, selectedCategory.id]);
    

  return (
    <Table responsive="sm" className=' mx-auto text-start table-recap '>
    <thead>
      <tr>
        <th className=''>Description</th>
        {!customer && <th className='text-end'>Prix unitaire <br/>(hors taxe)</th>}
        <th className='text-end'>Qté</th>
        {!customer && <>
            <th className='text-end'>Montant <br/>(hors taxe)</th>
            <th className='text-end'>TVA (20%)</th>
            <th className='text-end'>Total</th>
        </>}

      </tr>
    </thead>
    <tbody>
        <TbodyItem isLoading={isLoading} items={items.Pressing} champs='Pressing' name='Pressing & Blanchisserie' customer={customer} editItem={editItem} id={id} underUser={underUser} orderB2B={orderB2B} orderB2BID={orderB2BID} setIsLoading={setIsLoading} categories={categories}/>

        <TbodyItem isLoading={isLoading} items={items.Cordonnerie} champs='Cordonnerie' name='Cordonnerie' customer={customer} editItem={editItem} id={id} underUser={underUser} orderB2B={orderB2B} orderB2BID={orderB2BID} setIsLoading={setIsLoading} categories={categories}/>

        <TbodyItem isLoading={isLoading} items={items.Retouche} champs='Retouche' name='Retouche' customer={customer} editItem={editItem} id={id} underUser={underUser} orderB2B={orderB2B} orderB2BID={orderB2BID} setIsLoading={setIsLoading} categories={categories}/>

        <TbodyItem isLoading={isLoading} items={items.lk} champs='lk' name='Linge au kilo' customer={customer} editItem={editItem} id={id} underUser={underUser} orderB2B={orderB2B} orderB2BID={orderB2BID} setIsLoading={setIsLoading} categories={categories}/>

        {showAddingItem && 
        (
            addItemShow ?
                <>
                <tr>
                    <td colspan="1">
                    <DropdownButton
                            drop='down-centered'
                            style={{display: 'inline-block' }}
                            className='custom-dropdown1 my-2 col-9 col-sm-6 col-md-5 col-lg-3'
                            id='dropdown-button-drop-down-centered'
                            title={<span style={{display: 'inline-block', fontSize: '1.4rem' }}>{selectedCategory.name}</span>}
                        >
                            {NameCategories?.map((category, index) => (
                                <Dropdown.Item
                                    key={category}
                                    onClick={() => setSelectedCategory({name: category, id: index})}
                                >
                                    {category}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </td>
                    {!addPriceManuel && (
                        <>
                            <td colspan="1">
                        <DropdownButton disabled={addPriceManuel}
                                drop='down-centered'
                                style={{display: 'inline-block' }}
                                className='custom-dropdown1 my-2 col-9 col-sm-6 col-md-5 col-lg-3'
                                id='dropdown-button-drop-down-centered'
                                title={<span style={{display: 'inline', fontSize: '1.4rem' }}>{selectedPrice === null ? `Veuillez séléctionner un article` : selectedPrice.name}</span>}
                            >
                                {prices[categories[selectedCategory.id]]?.map((price) => (
                                    <Dropdown.Item
                                        key={price.name}
                                        onClick={() => setSelectedPrice(price)}
                                    >
                                        {price.name}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                            </td>
                            <td colspan="1">
                                <input
                                        className='mx-2 btn p-1'
                                        style={{background:'#FFF', color: '#008080', border: '1px solid #008080', width: '50px'}}
                                        type="number" 
                                        min="1"
                                        step={1}
                                        disabled={addPriceManuel}
                                        placeholder='Qté...'
                                        value={qte}
                                        onChange={handleChangeQte}
                                    />
                            </td>
                            <td colspan="1">
                                <button className='btn btn-success my-2 py-1 bg-success' disabled={addPriceManuel} onClick={() => changerQte(qte, selectedPrice)}>Valider</button>
                            </td>
                        </>
                    )}

                </tr>
                <tr>
                    <td colspan="1">
                    <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label={
                                        <span 
                                            style={{ fontStyle: 'italic', fontSize: '1.3rem', display: 'inline' }} 
                                            className="text-secondary"
                                        >
                                            Souhaitez-vous ajouter un article manuellement ?
                                        </span>
                                    }
                                    checked={addPriceManuel}
                                    onChange={handleChange}
                                />
                    </td>
                    {addPriceManuel && (
                        <>
                            <td colspan="1">
                        <input
                                className='mx-2 btn p-1'
                                style={{background:'#FFF', color: '#008080', border: '1px solid #008080', width: '150px'}}
                                type="text"
                                disabled={!addPriceManuel}
                                placeholder="Nom de l'article..."
                                value={newItem.article}
                                onChange={(e) => setNewItem({ ...newItem, article: e.target.value})}
                            />
                            </td>
                            <td colspan="1">
                                <input
                                    className='mx-2 btn p-1'
                                    style={{background:'#FFF', color: '#008080', border: '1px solid #008080', width: '70px'}}
                                    type="number" 
                                    min="1"
                                    step={1}
                                    disabled={!addPriceManuel}
                                    placeholder='Prix TTC...'
                                    value={newItem.price}
                                    onChange={(e) => setNewItem({ ...newItem, price: e.target.value})}
                                />
                            </td>
                            <td colspan="1">
                                <input
                                    className='mx-2 btn p-1'
                                    style={{background:'#FFF', color: '#008080', border: '1px solid #008080', width: '50px'}}
                                    type="number" 
                                    min="1"
                                    step={1}
                                    disabled={!addPriceManuel}
                                    placeholder='Qté...'
                                    value={newItem.total_article}
                                    onChange={(e) => setNewItem({ ...newItem, total_article: e.target.value})}
                                />
                            </td>
                            <td colspan="1">
                                <button className='btn btn-success my-2 py-1 bg-success' disabled={!addPriceManuel} onClick={() => addItemManuel(newItem)}>Valider</button>
                            </td>
                        </>

                    )}

                </tr>
                
    

            
                    <button className='btn-link text-danger btn m-0 p-0' style={{display: 'block'}} onClick={() => {setAddItemShow(false); setSelectedCategory({name: NameCategories[0], id: 0})}}>Annuler</button>
                </>
            :
                <tr>
                    <td colspan="6" className='text-start btn-link' style={{fontWeight: '600', color: '#008080', cursor: 'pointer'}} onClick={() => setAddItemShow(true)}><CiCirclePlus size={16} color='#008080'/> Ajouter un nouveau article</td>
                </tr>
        )
        }
    </tbody>
  </Table>
  
  )
}
