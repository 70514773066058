import React, { useContext } from 'react';
import { UserContext } from '../../context/userContext';
import { IoEllipse } from 'react-icons/io5';


/**
 *Component pour l'affichage de la navebar des informations sur l'utilisateur et la déconnexion
 *
 * @param {*} { user : L'utilisateur, signOut: Fonction de déconnexion } 
 */
function NavbarUserInformation() {

  const { user } = useContext(UserContext);
  return (
    <div className='row m-2'>
      <div className='col text-end'>
        <div className='py-1' style={{background:'#f1f1f1', borderRadius: '5px', display: 'inline-block', fontSize: "1.4rem"}}>
        {user && <>
          <IoEllipse className='text-success mx-2 mb-3' size={10}/>
            {user?.firstName} {user?.lastName} | {user?.job}
        </>
        }
        </div>
      </div>
    </div>
  );
}

export default NavbarUserInformation;