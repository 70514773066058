// Fonction pour vérifier si l'utilisateur a accès à une page spécifique

export function checkPageAccess(currentUser, page, currentUserRole) {
    const allowedRoles = page.role_access;

    // Si l'utilisateur n'est pas connecté ou n'a pas de rôle défini, il n'a pas accès
    if (!currentUser || !currentUserRole) {return false;}

    // Si la page n'a pas de contrôle d'accès défini, personne peut y accéder
    if (!allowedRoles) {return true;}

    return allowedRoles.includes(currentUserRole);
  }