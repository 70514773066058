import { db } from '../firebase-config';
import { addDoc, collection, doc, getDocs, orderBy, setDoc, updateDoc } from "firebase/firestore";

const facturesCollectionRef = collection(db, "corporate_invoice");

class FacturesDataService {
  addFacture = (facture) => {
    return addDoc(facturesCollectionRef, facture);
  }

  updateStatus = (newStatus, idDoc) => {
    return updateDoc(doc(db, "corporate_invoice", idDoc), {status: newStatus});
  };
}
export default new FacturesDataService();