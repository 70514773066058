import React from 'react';
import TableRow from './Compoenents/TableRow';
import Pagination from './Compoenents/Pagination';


function DriversTable({ data, dataSize, currentPage, setCurrentPage, rowsPerPage }) {

  const totalPages = Math.ceil(data.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const visibleData = data.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="">
      <table>
        <thead>
          <tr style={{ backgroundColor: 'white' }}>
            <th style={{ fontWeight: 'bold', fontStyle: 'italic', border: 'none' }}></th>
            <th className="text-center" style={{ fontWeight: 'bold', fontStyle: 'italic', border: 'none' }}>Drivers</th>
            <th className="text-center" style={{ fontWeight: 'bold', fontStyle: 'italic', border: 'none' }}>Mail</th>
            <th className="text-center" style={{ fontWeight: 'bold', fontStyle: 'italic', border: 'none' }}>Location</th>
            <th className="text-center" style={{ fontWeight: 'bold', fontStyle: 'italic', border: 'none' }}>Status<br />Compte</th>
            <th className="text-center" style={{ fontWeight: 'bold', fontStyle: 'italic', border: 'none' }}>Tel</th>
            <th className="text-center" style={{ border: 'none' }}></th>
          </tr>
        </thead>
        <tbody>
          {visibleData.map((rowData, index) => (
            <TableRow key={index} rowData={rowData} />
          ))}
        </tbody>
      </table>
      <Pagination dataSize={dataSize} currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} content="Drivers" />
    </div>
  );
}

export default DriversTable;