import { createUserWithEmailAndPassword } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { BsChevronDown } from 'react-icons/bs';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css'
import DriversServices from '../../../../../../context/drivers.services';
import { auth } from '../../../../../../firebase-config';
import DriverPasswordGenerator from './DriverPasswordGenerator';
import { Timestamp } from 'firebase/firestore';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import CheckIcon from "@mui/icons-material/Check";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { AiOutlineLoading } from 'react-icons/ai'; // Assurez-vous d'importer l'icône de chargement
import { useSnackbar } from 'notistack';


const functions = getFunctions();
const addDriverM = httpsCallable(functions, 'addDriver');
const envoiMailCreationDriver = httpsCallable(functions, 'envoiMailCreationDriver');

function AddDriverModal(props) {


  /* const [corporatesData, setCorporatesData] = useState([]); */
  /* 
        ---fonction générer un mdp pour la création du compte
        ---l'inscription sur la bdd du driver (setdoc)
        création d'un nv compte pour les drivers (activation avant la premiere connexion)
        informer par mail les parties concernés
  */

  const [shouldClearForm, setShouldClearForm] = useState(false);

  const initialFormData = {
    firstName: '',
    lastName: '',
    siret: '',
    email: '',
    typeContrat: '',
    phoneNumber: '',
    selectedZone: '',
    selectedLocation: '',
  };

  const initialErrors = {
    firstName: '',
    lastName: '',
    email: '',
    typeContrat: '',
    phoneNumber: '',
    selectedZone: '',
    selectedLocation: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrors);

  const validateForm = () => {
    const newErrors = {};

    if (!/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/.test(formData.firstName)) {
      newErrors.firstName = 'First name can only contain letters and spaces';
    }
    
    if (!/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/.test(formData.lastName)) {
      newErrors.lastName = 'Last name can only contain letters and spaces';
    }

    if (!/^[\w\.-]+@[\w\.-]+\.\w+$/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }

    if (!/^(?:0|\d{2})(?:\d ?){8,12}\d$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = 'Invalid phone number format';
    }

    if (selectedZones.length < 1) {
      newErrors.selectedZone = 'Please select a zone';
    }

    if (formData.selectedLocation === '') {
      newErrors.selectedLocation = 'Please select a location';
    }
    
    if (!/^\d{14}$/.test(formData.siret)) {
      newErrors.siret = 'Invalid SIRET number format';
    }
    
    if (formData.typeContrat === '') {
      newErrors.typeContrat = 'Please select a type of contract';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleChange2 = (event) => {
    const { name, options } = event.target;
    const value = [];
    for (let i = 0, len = options.length; i < len; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    
    // Ensuite, mettez à jour l'état avec le nouveau tableau de valeurs
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };
  
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (shouldClearForm) {
      setFormData(initialFormData);
      setShouldClearForm(false);
    }
  }, [shouldClearForm]);
  const [selectedZones, setSelectedZones] = useState([]);
  const handleZoneChange = (event, newValue) => {
    // Extraire les ID des objets sélectionnés
    const newSelectedIds = newValue.map((item) => item.id_hub);
    setSelectedZones(newSelectedIds); // Mettre à jour l'état avec les nouveaux ID
  };
  const addDriver = async () => {

    if (!validateForm()) {
      return;
    }


    const newDriver = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      siret: formData.siret,
      email: formData.email,
      typeOfContrat: formData.typeContrat,
      nbOrders: 0,
      nbTours: 0,
      note: 0,
      history: [],
      demandsInProgress: [],
      level: "D",
      telNumber: `+${formData.phoneNumber}`,
      status: 1,
      location: [
        {
            city: 'Île-de-France',
            country: 'FR',
            geCode: {
                lat: 48.8499198,
                long: 2.6370411
            }
        }
      ],
      hubs: selectedZones,
      honoraire: 14
      
    };
    const password = DriverPasswordGenerator();
    try {
      // const driver = await createUserWithEmailAndPassword(auth, newDriver.email, password);
      // await DriversServices.addDriverBis(newDriver, driver.user.uid);
      setIsAddingDriver(true); // Commence le chargement
      addDriverM({
        email: newDriver.email,
        password: password,
        driver: newDriver
      })
      .then((result) => {
        // Lire l'UID de l'utilisateur créé
        // const uid = result.data.uid;
        // console.log(`Utilisateur créé avec succès : UID = ${uid}`);
        envoiMailCreationDriver({
          prenom: newDriver.firstName,
          nom: newDriver.lastName,
          email: newDriver.email,
          password: password
        });
        setIsAddingDriver(false); // Arrête le chargement une fois que la fonction est terminée
        enqueueSnackbar(`Le compte pour ${newDriver.firstName} ${newDriver.lastName} a bien été créer`, {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          autoHideDuration: 3000,
          style: {fontSize: '1.6rem'}
         
        });
        setShouldClearForm(true);
        handleClose();
      })
      .catch((error) => {
        // console.error("Erreur lors de la création de l'utilisateur:", error);
        setIsAddingDriver(false); // Arrête le chargement une fois que la fonction est terminée
        enqueueSnackbar(`Erreur lors de la création de l'utilisateur`, {
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          autoHideDuration: 3000,
          style: {fontSize: '1.6rem'}
         
        });
      });
      // drivers();
    } catch (error) {
      // let errorMessage = 'Une erreur inconnue est survenue.';
      // console.error('Une erreur inconnue est survenue.', error);
      setIsAddingDriver(false); // Arrête le chargement une fois que la fonction est terminée
      enqueueSnackbar(`Une erreur inconnue est survenue.`, {
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          autoHideDuration: 3000,
          style: {fontSize: '1.6rem'}
         
        });
  // switch (error.code) {
  //   case 'auth/email-already-in-use':
  //     errorMessage = 'Cette adresse e-mail est déjà utilisée par un autre compte.';
  //     break;
  //   case 'auth/invalid-email':
  //     errorMessage = 'L\'adresse e-mail est invalide.';
  //     break;
  //   case 'auth/operation-not-allowed':
  //     errorMessage = 'La création de compte est désactivée.';
  //     break;
  //   case 'auth/weak-password':
  //     errorMessage = 'Le mot de passe est trop faible.';
  //     break;
  //   default:
  //     errorMessage = 'Une erreur inconnue est survenue: ' + error.message;
  // }
  // console.error(errorMessage);
    }
  };

  const handleClose = () => {
    setFormData(initialFormData);
    setErrors(initialErrors);
    props.onHide();
  };
  const [isAddingDriver, setIsAddingDriver] = useState(false);

  return (
    <Modal show={props.show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title style={{fontSize: '1.8rem', fontWeight: '600', letterSpacing: '1.4px'}}>Créer un nouveau chauffeur</Modal.Title>
      </Modal.Header>
      <Modal.Body className='m-3'>
        <Form >
        <div className="d-flex flex-wrap justify-content-between">
            <div className="flex-fill p-2 me-1 d-flex justify-content-start align-items-start">
            <div className="driver-form-field">
                <Form.Control
                  className="driver-form-input"
                  type="text"
                  name="firstName"
                  required
                  value={formData.firstName}
                  onChange={handleChange}
                />
                <Form.Label className="floating-label">Prénom</Form.Label>
                {errors.firstName && <div className="error-message">{errors.firstName}</div>}
              </div>

            </div>
            <div className="flex-fill p-2 ms-1 d-flex justify-content-start align-items-start">
            <div className="driver-form-field">
                <Form.Control
                  className="driver-form-input"
                  type="text"
                  name="lastName"
                  required
                  value={formData.lastName}
                  onChange={handleChange}
                />
                <Form.Label className="floating-label">Nom</Form.Label>
                {errors.lastName && <div className="error-message">{errors.lastName}</div>}
              </div>
            </div>
        </div>

        <div className="d-flex flex-wrap justify-content-between mt-5">
            <div className="flex-fill p-2 me-1 d-flex justify-content-start align-items-start">
            <div className="driver-form-field">
                <Form.Control
                  className="driver-form-input"
                  type="email"
                  name="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
                <Form.Label className="floating-label">Email</Form.Label>
                {errors.email && <div className="error-message">{errors.email}</div>}
              </div>

            </div>
            <div className="flex-fill p-2 ms-1 d-flex justify-content-start align-items-start">
            <div className="driver-form-field">
                <Form.Control
                  className="driver-form-input"
                  type="text"
                  name="siret"
                  required
                  value={formData.siret}
                  onChange={handleChange}
                />
                <Form.Label className="floating-label">N° Siret</Form.Label>
                {errors.siret && <div className="error-message">{errors.siret}</div>}
              </div>
            </div>
        </div>

        <div className="d-flex flex-wrap justify-content-between mt-5 pt-5">
            <div className="flex-fill p-2 me-1 d-flex justify-content-start align-items-start">
            <div className="driver-form-field">
                <Form.Group controlId="selectedTypeContrat">
                  <Form.Label>Type de Contrat</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      as="select"
                      className={`driver-form-input ${errors.typeContrat ? 'is-invalid' : ''}`}
                      style={{ color: '#808080' }}
                      name="typeContrat"
                      value={formData.typeContrat}
                      onChange={handleChange}
                    >
                      <option value="">Selectionner le type de contrat</option>
                      <option>Auo-entrepreneur</option>
                    </Form.Control>
                    <BsChevronDown className="dropdown-icon" />
                  </div>
                  {errors.typeContrat && <div className="error-message">{errors.typeContrat}</div>}
                </Form.Group>
              </div>

            </div>
            <div className="flex-fill p-2 ms-1 d-flex justify-content-start align-items-start">
            <div className="driver-form-field">
            <Form.Group controlId="selectedLocation">
                <Form.Label>Location</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    as="select"
                    className={`driver-form-input location-field ${errors.selectedLocation ? 'is-invalid' : ''}`}
                    name="selectedLocation"
                    style={{ color: '#808080' }}
                    value={formData.selectedLocation}
                    onChange={handleChange}
                  >
                    <option value="">Select a location</option>
                    <option>Île-de-France [FR]</option>
                    
                    {/* Add more location options here */}
                  </Form.Control>
                  <BsChevronDown className="dropdown-icon" />
                </div>
                {errors.selectedLocation && <div className="error-message">{errors.selectedLocation}</div>}
              </Form.Group>
            </div>
            </div>
        </div>

        <div className="d-flex flex-wrap justify-content-between mt-5">
            <div className="flex-fill p-2 me-1 d-flex justify-content-start align-items-start">
            <div className="driver-form-field">
            <Form.Group controlId="selectedZone">
                <Autocomplete
  sx={{ m: 1, width: 390 ,
  "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#008080", // hover border color
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #008080", // border color when focused
      },
    },
    "& .MuiInputLabel-root": {
      // Default label color
      color: "grey",
      "&:hover": {
        // Label color on hover (when the cursor is over the input field but it is not focused)
        color: "#008080",
      },
    },}}
  multiple
  id="tags-standard"
  options={props.hubs}
  getOptionLabel={(option) => option.name} // Use the 'name' property of the hub as the label
  //defaultValue={[]} // Make sure these are objects from props.hubs
  onChange={handleZoneChange}
  disableCloseOnSelect
  renderOption={(props, option, { selected }) => (
    <MenuItem
    style={{fontSize: '1.4rem'}}
      key={option.id_hub} // Use a unique property of the object for the key
      value={option.name} // Use the 'name' property as the value
      sx={{ justifyContent: "space-between" }}
      {...props}
    >
      {option.name}
      {selected ? <CheckIcon color="info" /> : null}
    </MenuItem>
  )}
  renderInput={(params) => (
    <TextField
    
      {...params}
      variant="outlined"
      label="Zones"
      placeholder="Séléctionner le(s) zone(s)"
      // Target the label component directly to change the font size
      sx={{ 
        "& .MuiInputLabel-outlined": { fontSize: '1.4rem' }, // This targets the label
        "& .MuiInputBase-input": { fontSize: '1.4rem' }, // This targets the input field
        "& .MuiChip-root": { fontSize: '1.4rem' }, // Add styles for the tag elements
      }}
    />
  )}
/>
                  {errors.selectedZone && <div className="error-message">{errors.selectedZone}</div>}
              </Form.Group>
            </div>


            </div>
            <div className="flex-fill p-2 ms-1 d-flex justify-content-start align-items-start">
            <div className="driver-form-field">
                <PhoneInput
                  inputStyle={{ border: '1px solid #008080', padding: '0.6rem 5rem', borderRadius: '5px', marginTop: '1.5rem' }}
                  inputClassName="phone-form-input"
                  name="phoneNumber"
                  country="fr"
                  value={formData.phoneNumber}
                  onChange={(value) => setFormData((prevData) => ({ ...prevData, phoneNumber: value }))}
                />
                {/* <Form.Label className="floating-label">Phone Number</Form.Label> */}
                {errors.phoneNumber && <div className="error-message">{errors.phoneNumber}</div>}
              </div>
            </div>
        </div>


        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={addDriver} className="add-driver-button btn my-3 me-3">
        {isAddingDriver ? <><AiOutlineLoading className="loading-icon me-2" /> en cours d'enregistrement...</> : "Enregistrer"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddDriverModal;