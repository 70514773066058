export default function filterOrdersForTodayB2CWithDateShiftMatin(orders, specificDate) {
    const today = new Date(specificDate);
    today.setHours(0, 0, 0, 0);

    return orders.filter(order => {
        const collecteDate = order.dateCollecte?.toDate();
        const livraisonDate = order.dateLivraison?.toDate();
        const creneauCollecte = order.creneauCollecte;
        const creneauLivraison = order.creneauLivraison;

        const shiftMatinCollecte = parseInt(creneauCollecte.substring(0, 2), 10);
        const shiftMatinLivraison = parseInt(creneauLivraison.substring(0, 2), 10);

        collecteDate.setHours(0, 0, 0, 0);
        livraisonDate.setHours(0, 0, 0, 0);

        return ((collecteDate.getTime() === today.getTime() && shiftMatinCollecte < 14) || (livraisonDate.getTime() === today.getTime() && shiftMatinLivraison < 14)) && order.etat === 'valider';
    });
}
