import CorporateDataService from "../../../../context/corporates.services";

export const UpdateServicesCompany = async ({ id, value, enqueueSnackbar, setIsLoading }) => {
    try {
        if(value.length > 0){
            setIsLoading(true);
            await CorporateDataService.updateCorporate('services', value, id);
            enqueueSnackbar(`Les services suivants sont appliqués : ${value.map((service) => service).join(', ')}.`, {
                variant: 'success',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}
                   
            });
            return true;
        }else{
            enqueueSnackbar(`Veuillez séléctionner au moins un service`, {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}  
            });
        }
    }catch(e) {
        enqueueSnackbar(`${e.message}`, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoHideDuration: 3000,
            style: {fontSize: '1.6rem'}  
        });
        setIsLoading(false);
        return false;
    }
};