import React, { useEffect, useState } from 'react';
import CommandeDataService from '../../../../../context/commande.services'

const GetUser = ({ userId }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
          const docSnapUser = await CommandeDataService.getUser(userId)
          setUser(docSnapUser.data());
          if (!userId) {
            setUser('');
          }

      } catch (err) {
        console.log(err.message);
      }
    };

    fetchOrder();
  }, [userId]);

  if (!userId) {
    return <p>{userId === '' ? 'Personnalisé' : 'Chargement...'}</p>; // ou tout autre indicateur de chargement
  }

  return (
    <span>({user?.nom?.toUpperCase()} {user?.prenom})</span>

  );
};

export default GetUser;
