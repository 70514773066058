import React, { useState } from 'react'

export default function Searchbar(props) {

    const search = (e) => {
        const term = e.target.value;
        props.onSearch(term); // Update parent with the new search term
    }

  return (
    <div className="d-flex justify-content-center align-items-center mx-md-5 mt-5">
        <input 
            type="text" 
            className="form-control my-4 mx-md-5"
            style={{fontSize: '1.4rem'}}
            placeholder={props.placeholder} 
            onChange={search}

        />
    </div>
  )
}
