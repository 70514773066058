import CorporateDataService from "../../../../context/corporates.services";



export const UpdateRecapPrestaItem = async ({ id, value, enqueueSnackbar, setIsLoading }) => {
    try {
        if(value !== '') {
            setIsLoading(true);
            await CorporateDataService.updateOrderCorporate('under_user', value, id);
            enqueueSnackbar(`L'article' a bien été modifié.`, {
                variant: 'success',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}
               
              });
              return true;
        }else{
            enqueueSnackbar(`Veuillez séléctionner un article`, {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}  
            });
        }
    }catch(e) {
        enqueueSnackbar(`${e.message}`, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoHideDuration: 3000,
            style: {fontSize: '1.6rem'}  
        });
        setIsLoading(false);
        return false;
    }
};