import React from 'react'
import { Table } from 'react-bootstrap'
import '../../../Orders/Components/OrdersList.css'
import { useNavigate } from 'react-router-dom';
import { formatDateComplete, formatDate } from '../../../../../../utils/DateToString'
export default function DriversList(props) {

    const navigate = useNavigate();
    const PAGE_SIZE = 5;
    const nextPage = () => {
        if ((props.currentPage + 1) * PAGE_SIZE < props.orders.length) {
            props.setCurrentPage(props.currentPage + 1);
        }
    };


    const prevPage = () => {
        if (props.currentPage > 0) {
            props.setCurrentPage(props.currentPage - 1);
        }
    };

    const paginatedOrders = props.orders.slice(props.currentPage * PAGE_SIZE, (props.currentPage + 1) * PAGE_SIZE);
    // Fonction pour capitaliser la première lettre d'une chaîne
    const capitalizeFirstLetter = (string) => {
        if (!string) return ''; // vérifie si la chaîne est définie
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      }
  // Fonction pour mettre une chaîne entière en majuscules
  const toUpperCase = (string) => {
    return string ? string.toUpperCase() : ''; 
  }
  return (
    <>
    <Table responsive="sm" className=' mx-auto text-start table-personnalisee '>
    <thead>
      <tr>
        <th>Date de création</th>
        <th>Chauffeur</th>
        <th>Email</th>
        <th>Tel</th>
        <th>Status</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      
        {paginatedOrders?.length > 0 ? paginatedOrders?.map((driver) => {
            return (
                <tr key={driver.id_driver} className='py-5'>
                <td>{formatDateComplete(driver.created_at?.toDate().toISOString())}</td>
                <td>{capitalizeFirstLetter(driver.firstName)} {toUpperCase(driver.lastName)}</td>
                <td>{driver.email}</td>
                <td>{driver.telNumber}</td>
                <td>
                    <span style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: driver.status === 3 ? '#FF5733' : driver.status === 2 ? '#FFBC6C' : driver.status === 1 ? '#39FF33' : null,
                        marginRight: '5px'
                    }}>
                    </span>{driver.status === 3 ? 'archivé' : driver.status === 2 ? 'suspendu' : driver.status === 1 ? 'Activé' : null}</td>
                
                <td className='btn-link' style={{cursor: 'pointer'}} onClick={() => navigate(`/private/Drivers/${driver.id_driver}`)}>Voir détails</td>
                </tr>

            )
        }) : <tr><td colSpan="6" className='text-center secondary' style={{fontStyle: 'italic'}}>Aucune résultat</td></tr>}


    </tbody>
  </Table>
  <div className="d-flex flex-wrap justify-content-center my-5 bg-light py-2 rounded">
  <div className='flex-fill p-2 d-flex justify-content-center align-items-center'>{props.orders.length} résultats</div>
  <div className='flex-fill p-2 d-flex justify-content-center align-items-center'>
  <button onClick={prevPage} disabled={props.currentPage === 0} style={{border: '1px solid #e0e0e0', borderRadius: '8px', fontSize: '1.2rem'}} className="btn">Précédent</button>
                <button onClick={nextPage} className="ms-3 btn" style={{border: '1px solid #e0e0e0', borderRadius: '8px', fontSize: '1.2rem'}} disabled={(props.currentPage + 1) * PAGE_SIZE >= props.orders.length}>Suivant</button>
  </div>
            </div>
            </>
  )
}
