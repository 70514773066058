import React, { useEffect, useState } from 'react';
import CommandeDataService from '../../../../../context/commande.services'
import { Badge } from 'react-bootstrap';
import { MdDelete } from 'react-icons/md';

const GetOrderB2B = ({ id, orders, ordre, deleteOrder }) => {
  const [user, setUser] = useState(null);
  const [pu, setPu] = useState(null);
  const [dropp, setDropp] = useState(null);

  const separateByServiceType = (orders) => {
    // Initialisation de deux tableaux pour stocker les résultats séparés
    const puOrders = [];
    const doOrders = [];

    // Parcourir chaque commande
    orders && orders?.forEach(order => {
        // Vérifier si orderUnder est défini et est un tableau
        if (Array.isArray(order.orderUnder)) {
            // Parcourir chaque élément dans orderUnder
            order.orderUnder.forEach(underOrder => {
                // Vérifier le serviceType et ajouter à l'un des deux tableaux
                if (underOrder.serviceType === 'PU') {
                    puOrders.push(underOrder); // Ajouter à PU s'il correspond
                } else if (underOrder.serviceType === 'DO') {
                    doOrders.push(underOrder); // Ajouter à DO s'il correspond
                }
            });
        }
    });

    // Retourner un objet contenant les deux tableaux
    return { puOrders, doOrders };
}

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const docSnap = await CommandeDataService.getCommandeB2B(id);
        setUser(docSnap.data());
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchOrder();
    const {puOrders, doOrders} = separateByServiceType(orders);
    setPu(puOrders);
    setDropp(doOrders);
  }, [id]);

  if (!user) {
    return <p>Chargement...</p>; // ou tout autre indicateur de chargement
  }

  return (
    <div className="col" style={{fontSize: '1.4rem'}}>
            <div className="d-flex flex-wrap justify-content-center text-start p-0 m-0">
            <div className="flex-fill d-flex justify-content-start align-items-center">
            <p className='p-0 m-0' style={{fontWeight: '600'}}>
      {user.nameCompany} ({user.slot_start} - {user.slot_end})
    </p>
            </div>
            <div className="flex-fill d-flex justify-content-end align-items-center">
            <Badge className=' m-0' style={{color: '#008080'}} bg="light">{ordre+1}</Badge>
            </div>
        </div>
    
    <p className='p-0 m-0 mt-1'>
      {user.adressLivraisonCompany}
    </p>
    <p className='p-0 m-0'>
      {pu.length > 0 && `PU : ${pu.length}`} {dropp.length > 0 && `/ DO : ${dropp.length}`} 
    </p>
    <p className='p-0 m-0'>
      
    </p>

    <p className='p-0 m-0 mt-1' style={{fontStyle: 'italic', color: '#008080'}}>
       <MdDelete className='mb-1 text-danger' style={{cursor: 'pointer'}} size={18} onClick={deleteOrder}/>
    </p>
    </div>

  );
};

export default GetOrderB2B;
