import { doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import { format } from 'date-fns'
import { fr } from 'date-fns/locale';
import { db } from '../../../../firebase-config';
import CopyPrices from './CopyPrices';
import { FaEdit } from 'react-icons/fa';

export default function ProduitsList({ selectedTarif, tarifs }) {

    const [tarif, setTarif] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('Pressing');
    const [search, setSearch] = useState('');
    const [editing, setEditing] = useState({index: null, type: null});
    const [filteredItems, setFilteredItems] = useState([]);

    const handleEditClick = (index, type) => {
        setEditing({index, type});
      };
      const handleEditBlur = async (e, index, type) => {
        // Mettez à jour les données avec la nouvelle valeur, en utilisant `index` et `type` pour trouver l'élément correspondant
        // Par exemple, vous pouvez mettre à jour les données dans Firestore
        const newValue = parseFloat(e.target.value);

        if (!isNaN(newValue)) {
            const itemToUpdate = filteredItems[index];
      
          // Récupérez le document courant
          const tarifDocRef = doc(db, "tarifs", selectedTarif);
          const tarifDocSnap = await getDoc(tarifDocRef);
          const tarifData = tarifDocSnap.data();
      
          // Mettez à jour l'élément correspondant dans la liste des items
          const updatedCategoryItems = tarifData[selectedCategory].map((item, i) => {
            if (i === index) {
              return {
                ...item,
                priceTTC: {
                  ...item.priceTTC,
                  [type]: newValue,
                },
                priceHT: {
                  ...item.priceHT,
                  [type]: newValue / 1.2, // Supposons que la TVA est de 20%
                },
              };
            }
            return item;
          });
        //   // Mettez à jour le document avec la nouvelle liste des items
          await updateDoc(tarifDocRef, {
            [selectedCategory]: updatedCategoryItems,
          });
      
        //   // Rechargez les données
          const updatedTarifDocSnap = await getDoc(tarifDocRef);
          setTarif(updatedTarifDocSnap.data());
        }
      
        setEditing({index: null, type: null}); // Réinitialisez l'état d'édition
      };
      
      
    // const listItems = (list, selectedCategory) => {        
    //     const filteredList = listShow?.filter((doc) => {
    //         return search.toLowerCase() === ''
    //           ? doc
    //           : doc.name.toLowerCase().includes(search.toLowerCase());
    //       });
    //     return (
    //         <div>
    //             {filteredList?.length > 0 ? (
    //                 filteredList?.map((item, index) => {
    //             return (
    //                 <div className='mx-auto p-4 py-2 my-2' style={{ background: '#F1F1F1', borderRadius: '5px' }} key={index}>
                    
    //                     <div className='d-flex justify-content-between m-0 p-0'>
    //                     <div className='m-0 p-0'>
    //                         <p className='m-0 p-0' style={{ fontWeight: '600' }}>{item.name}</p>
    //                         <p className='m-0 p-0 align-self-start'>Position : {item.position}</p>
    //                         <p className='m-0 p-0 align-self-start'>Date de création : {format(item.creation_date.toDate(), "EEEE dd MMMM yyyy 'à' HH:mm", { locale: fr })}</p>
    //                         <p className='m-0 p-0 align-self-start'>À partir de : {item.from ? 'Oui' : 'Non'}</p>
    //                     </div>
    //                     <div className='d-flex'>
    //                     <div>
    //                         <p className='m-0 py-1 text-end' style={{fontWeight: '600'}}>standard HT : </p>
    //                         <p className='m-0 py-1 text-end' style={{fontWeight: '600'}}>standard+ HT : </p>
    //                         <p className='m-0 py-1 text-end' style={{fontWeight: '600'}}>Premium HT: </p>
    //                     </div>
    //                     <div className='mx-4'>
    //                         <p className='m-0 py-1' style={{fontWeight: '600'}}>{item.priceHT.standard.toFixed(2)} €</p>
    //                         <p className='m-0 py-1' style={{fontWeight: '600'}}>{item.priceHT.standardPlus.toFixed(2)} €</p>
    //                         <p className='m-0 py-1' style={{fontWeight: '600'}}>{item.priceHT.premium.toFixed(2)} €</p>
    //                     </div>
    //                     <div className='mx-4'>
    //                     <p className='m-0 py-1 text-end' style={{fontWeight: '600'}}>standard TTC : </p>
    //                         <p className='m-0 py-1 text-end' style={{fontWeight: '600'}}>standard+ TTC : </p>
    //                         <p className='m-0 py-1 text-end' style={{fontWeight: '600'}}>Premium TTC : </p>
    //                     </div>
    //                     <div>
    //                         {editing.index === index && editing.type === "standard" ? (
    //               <input
    //                 type="number"
    //                 defaultValue={item.priceTTC.standard.toFixed(2)}
    //                 onBlur={(e) => handleEditBlur(e, index, "standard")}
    //               />
    //             ) : (
    //               <p className='m-0 py-1' style={{fontWeight: '600'}}>
    //                 {item.priceTTC.standard.toFixed(2)} €
    //                 <FaEdit
    //                   className="mb-1 mx-1"
    //                   color="#008080"
    //                   onClick={() => handleEditClick(index, "standard")}
    //                 />
    //               </p>
    //             )}
    //             {editing.index === index && editing.type === "standardPlus" ? (
    //               <input
    //                 type="number"
    //                 defaultValue={item.priceTTC.standardPlus.toFixed(2)}
    //                 onBlur={(e) => handleEditBlur(e, index, "standardPlus")}
    //               />
    //             ) : (
    //               <p className='m-0 py-1' style={{fontWeight: '600'}}>
    //                 {item.priceTTC.standardPlus.toFixed(2)} €
    //                 <FaEdit
    //                   className="mb-1 mx-1"
    //                   color="#008080"
    //                   onClick={() => handleEditClick(index, "standardPlus")}
    //                 />
    //               </p>
    //             )}
    //             {editing.index === index && editing.type === "premium" ? (
    //               <input
    //                 type="number"
    //                 defaultValue={item.priceTTC.premium.toFixed(2)}
    //                 onBlur={(e) => handleEditBlur(e, index, "premium")}
    //               />
    //             ) : (
    //               <p className='m-0 py-1' style={{fontWeight: '600'}}>
    //                 {item.priceTTC.premium.toFixed(2)} €
    //                 <FaEdit
    //                   className="mb-1 mx-1"
    //                   color="#008080"
    //                   onClick={() => handleEditClick(index, "premium")}
    //                 />
    //               </p>
    //             )}
    //           </div>
    //                     </div>
    //                     </div>
                        
    //                 </div>
    //             )
    //         }) 
    //             ) : (
    //                 <p className='text-center text-secondary'>Aucun article trouvé.</p>
    //             )}
    //         </div>
    //     )
    // }
    const listItems = (list, selectedCategory) => {        
        return (
            <div>
                {filteredItems?.length > 0 ? (
                    filteredItems?.map((item, index) => {
                return (
                    <div className='mx-auto p-4 py-2 my-2' style={{ background: '#F1F1F1', borderRadius: '5px' }} key={index}>
                    
                        <div className='d-flex justify-content-between m-0 p-0'>
                        <div className='m-0 p-0'>
                            <p className='m-0 p-0' style={{ fontWeight: '600' }}>{item.name}</p>
                            <p className='m-0 p-0 align-self-start'>Position : {item.position}</p>
                            <p className='m-0 p-0 align-self-start'>Date de création : {format(item.creation_date.toDate(), "EEEE dd MMMM yyyy 'à' HH:mm", { locale: fr })}</p>
                            <p className='m-0 p-0 align-self-start'>À partir de : {item.from ? 'Oui' : 'Non'}</p>
                        </div>
                        <div className='d-flex'>
                        <div>
                            <p className='m-0 py-1 text-end' style={{fontWeight: '600'}}>standard HT : </p>
                            <p className='m-0 py-1 text-end' style={{fontWeight: '600'}}>standard+ HT : </p>
                            <p className='m-0 py-1 text-end' style={{fontWeight: '600'}}>Premium HT: </p>
                        </div>
                        <div className='mx-4'>
                            <p className='m-0 py-1' style={{fontWeight: '600'}}>{item.priceHT.standard.toFixed(2)} €</p>
                            <p className='m-0 py-1' style={{fontWeight: '600'}}>{item.priceHT.standardPlus.toFixed(2)} €</p>
                            <p className='m-0 py-1' style={{fontWeight: '600'}}>{item.priceHT.premium.toFixed(2)} €</p>
                        </div>
                        <div className='mx-4'>
                        <p className='m-0 py-1 text-end' style={{fontWeight: '600'}}>standard TTC : </p>
                            <p className='m-0 py-1 text-end' style={{fontWeight: '600'}}>standard+ TTC : </p>
                            <p className='m-0 py-1 text-end' style={{fontWeight: '600'}}>Premium TTC : </p>
                        </div>
                        <div>
                            {editing.index === index && editing.type === "standard" ? (
                  <input
                    type="number"
                    defaultValue={item.priceTTC.standard.toFixed(2)}
                    onBlur={(e) => handleEditBlur(e, index, "standard")}
                  />
                ) : (
                  <p className='m-0 py-1' style={{fontWeight: '600'}}>
                    {item.priceTTC.standard.toFixed(2)} €
                    <FaEdit
                      className="mb-1 mx-1"
                      color="#008080"
                      onClick={() => handleEditClick(index, "standard")}
                    />
                  </p>
                )}
                {editing.index === index && editing.type === "standardPlus" ? (
                  <input
                    type="number"
                    defaultValue={item.priceTTC.standardPlus.toFixed(2)}
                    onBlur={(e) => handleEditBlur(e, index, "standardPlus")}
                  />
                ) : (
                  <p className='m-0 py-1' style={{fontWeight: '600'}}>
                    {item.priceTTC.standardPlus.toFixed(2)} €
                    <FaEdit
                      className="mb-1 mx-1"
                      color="#008080"
                      onClick={() => handleEditClick(index, "standardPlus")}
                    />
                  </p>
                )}
                {editing.index === index && editing.type === "premium" ? (
                  <input
                    type="number"
                    defaultValue={item.priceTTC.premium.toFixed(2)}
                    onBlur={(e) => handleEditBlur(e, index, "premium")}
                  />
                ) : (
                  <p className='m-0 py-1' style={{fontWeight: '600'}}>
                    {item.priceTTC.premium.toFixed(2)} €
                    <FaEdit
                      className="mb-1 mx-1"
                      color="#008080"
                      onClick={() => handleEditClick(index, "premium")}
                    />
                  </p>
                )}
              </div>
                        </div>
                        </div>
                        
                    </div>
                )
            }) 
                ) : (
                    <p className='text-center text-secondary'>Aucun article trouvé.</p>
                )}
            </div>
        )
    }


      

    useEffect(() => {
		if(selectedTarif){
			const unsubscribe = onSnapshot(doc(db, "tarifs", selectedTarif), (doc) => {
				setTarif(doc.data());
			});
		  
			  return () => unsubscribe(); 
		}
	}, [selectedTarif]);

    const getFilteredItems = (tarifData, category, searchQuery) => {
        let listShow = [];
        switch (category) {
          case "Pressing":
            listShow = tarifData.Pressing;
            break;
          case "Cordonnerie":
            listShow = tarifData.Cordonnerie;
            break;
          case "Retouche":
            listShow = tarifData.Retouche;
            break;
          case "lk":
            listShow = tarifData.lk;
            break;
          default:
            listShow = [];
        }
      
        const filteredList = listShow?.filter((doc) => {
          return searchQuery.toLowerCase() === ""
            ? doc
            : doc.name.toLowerCase().includes(searchQuery.toLowerCase());
        });
      
        return filteredList;
      };
      useEffect(() => {
        const filteredList = getFilteredItems(tarif, selectedCategory, search);
        setFilteredItems(filteredList);
      }, [tarif, selectedCategory, search]);
      

  return (
    <div>
        <Container className='mb-4'>
        <CopyPrices 
            tarifs={tarifs}
        />
      <Row>
        <Col xs="auto" className="">
          <Button className={`btn ${selectedCategory === 'Pressing' ? 'button-navbar-active' : 'button-navbar'}`} onClick={() => setSelectedCategory(selectedCategory => 'Pressing')}>Pressing</Button>
        </Col>
        <Col xs="auto" className="">
          <Button className={`btn ${selectedCategory === 'Retouche' ? 'button-navbar-active' : 'button-navbar'}`} onClick={() => setSelectedCategory(selectedCategory => 'Retouche')}>Retoucherie</Button>
        </Col>
        <Col xs="auto">
          <Button className={`btn ${selectedCategory === 'Cordonnerie' ? 'button-navbar-active' : 'button-navbar'}`}  onClick={() => setSelectedCategory(selectedCategory => 'Cordonnerie')}>Cordonnerie</Button>
        </Col>
        <Col xs="auto">
          <Button className={`btn ${selectedCategory === 'lk' ? 'button-navbar-active' : 'button-navbar'}`}  onClick={() => setSelectedCategory(selectedCategory => 'lk')}>Linge au kilo</Button>
        </Col>
      </Row>
    </Container>
    <input type="text" className="form-control mb-4 inputSearch" placeholder="ex: chemise..." onChange={(e) => setSearch(e.target.value)}/>
      {listItems(tarif, selectedCategory)}
    </div>
  )
}