import React, { useState, useEffect } from 'react'
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import './AddressAutocomplete.css';

export default function AddressAutocomplete({setData}) {

    const handlePlaceSelected = (place) => {
        // console.log(place); // Vous obtenez un objet avec des détails sur le lieu sélectionné
        //.formatted_address pour retourner la dresse en string
        setData(place.formatted_address);
      };
      const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);

    useEffect(() => {
        const loadGoogleMaps = () => {
          if (window.google) {
            setIsGoogleMapsLoaded(true);
            return;
          }
    
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_FIREBASE_API_KEY}&libraries=places`;
          script.async = true;
          script.defer = true;
          script.onload = () => setIsGoogleMapsLoaded(true);
          document.head.appendChild(script);
        };
    
        loadGoogleMaps();
      }, []);
    
      if (!isGoogleMapsLoaded) {
        return <div>Chargement de Google Maps...</div>;
      }
    // Define bounds for Paris and nearby areas
    const bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(48.7645, 2.2196), // Southwest corner of the boundary
        new window.google.maps.LatLng(48.9444, 2.5683) // Northeast corner of the boundary
      );

  return (
    <ReactGoogleAutocomplete
        style={{letterSpacing: '2px', fontWeight: '600', fontSize: '1.4rem'}}
        className='form-control'
        apiKey={process.env.REACT_APP_FIREBASE_API_KEY}
        onPlaceSelected={handlePlaceSelected}
        options={{
            bounds,
            types: ['address'], // Configurez les types de lieux à rechercher
            componentRestrictions: { country: "fr" }, // Limiter les résultats à un pays spécifique
        }}
    />
  )
}
