import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Button, Modal, Row } from 'react-bootstrap'
import { db } from '../../../../firebase-config';
import Titre from '../../../../components/Titre';
import { FaFileDownload } from 'react-icons/fa';
import AddFacture from './Components/AddFacture';
import FacturesTable from './Components/FactureTable';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { formatDate } from '../../../../utils/DateToString';
import DataServiceBills from '../../../../context/Bills.services';
import { useSnackbar } from 'notistack';

export default function BillsPage() {
  const [tableData, setTableData] = useState([]);
  const [corporatesData, setCorporatesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 6;

  const [indexActive, setIndexActive] = useState();

  const [orderField, setOrderField] = useState('date');
  const [orderDirection, setOrderDirection] = useState('desc');
  const { enqueueSnackbar } = useSnackbar();

  const totalTTCAllInvoices = () => {
    return tableData.reduce((acc, invoice) => acc + Number(invoice.ttc), 0);
  }

  const totalTTCByCompany = (companyId) => {
    return tableData
      .filter(invoice => invoice.user === companyId)
      .reduce((acc, invoice) => acc + Number(invoice.ttc), 0);
  }
  
  const totalTTCPaidInvoices = () => {
    return tableData
      .filter(invoice => invoice.status === 'Payée')
      .reduce((acc, invoice) => acc + Number(invoice.ttc), 0);
  }
  
  const totalTTCPendingInvoices = () => {
    return tableData
      .filter(invoice => invoice.status === 'En attente de paiement')
      .reduce((acc, invoice) => acc + Number(invoice.ttc), 0);
  }
  
  const totalTTCPaidByCompany = (companyId) => {
    return tableData
      .filter(invoice => invoice.user === companyId && invoice.status === 'Payée')
      .reduce((acc, invoice) => acc + Number(invoice.ttc), 0);
  }
  
  const totalTTCPendingByCompany = (companyId) => {
    return tableData
      .filter(invoice => invoice.user === companyId && invoice.status === 'En attente de paiement')
      .reduce((acc, invoice) => acc + Number(invoice.ttc), 0);
  }
  
  useEffect(() => {
    const q = query(collection(db, "corporate_invoice"), orderBy("date", "desc"))  ;
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const orders = [];
        querySnapshot.forEach((doc) => {
          orders.push({ ...doc.data(), id: doc.id });
    });
    setTableData(orders);
    });

    return () => unsubscribe(); 
  }, []);

  useEffect(() => {
    const q = query(collection(db, "corporate"), orderBy("date_creation", "desc"))  ;
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const orders = [];
        querySnapshot.forEach((doc) => {
          orders.push({ ...doc.data(), id: doc.id });
    });
    setCorporatesData(orders);
    });

    return () => unsubscribe(); 
  }, []);

  return (
    <div className="global-container">
      <div className='d-flex justify-content-between'>
        <Titre>Factures</Titre>
        <AddFacture corporatesData={corporatesData} />
      </div>
      <p>Total TTC : <span style={{fontWeight: '600'}}>{totalTTCAllInvoices().toLocaleString('fr-FR').replace(',', ', ')} €</span></p>
      <p>Payée : <span style={{fontWeight: '600'}}>{totalTTCPaidInvoices().toLocaleString('fr-FR').replace(',', ', ')} €</span></p>
      <p>En attente de paiement : <span style={{fontWeight: '600'}}>{totalTTCPendingInvoices().toLocaleString('fr-FR').replace(',', ', ')} €</span></p>
      {corporatesData.map((corporateData, index) => {
        return (
          <Row>
                <div className='col-12'>
                <Row>
                <div className='col-md-6'>
                <p className="m-0 p-0 my-3 col-6" style={{fontWeight: 'bold'}}>{corporateData.nameCompany}</p>       
                <p>Total TTC : <span style={{fontWeight: '600'}}>{totalTTCByCompany(corporateData.id).toLocaleString('fr-FR').replace(',', ', ')} €</span> Payée : <span style={{fontWeight: '600'}}>{totalTTCPaidByCompany(corporateData.id).toLocaleString('fr-FR').replace(',', ', ')} €</span> En attente de paiement : <span style={{fontWeight: '600'}}>{totalTTCPendingByCompany(corporateData.id).toLocaleString('fr-FR').replace(',', ', ')} €</span></p>                           
                </div>
                <div className='col-md-6 text-end'>
                    {index === indexActive ? <MdOutlineKeyboardArrowDown size={20} onClick={() => setIndexActive(showHistory => '')}/> : <MdOutlineKeyboardArrowUp size={20} onClick={() => setIndexActive(showHistory => index)}/>}
                </div>
                </Row>  
                {/* <Button className='btn-success-outline' size="sm" onClick={() => setShowModalAddOrder(true)}>Ajouter une commande</Button>              */}
                </div>
                {
                  indexActive === index && 
                  <div className='col-12'>
                  {tableData.map((data, index) => {
                    if(data.user === corporateData.id){
                      return (
                        <div 
                        className='mx-auto p-4 pt-2 pb-2 mt-2 mb-2'
                        style={{ background: '#F1F1F1', width: '95%', borderRadius: '5px' }}
                    >
                    <div className='d-flex justify-content-between'>
                    <p>{formatDate(data.date?.toDate().toISOString())} </p>
                    <p>{data.factureId}</p>
                    </div>

                    <div className='d-flex justify-content-between'>
                    <p className='py-2' style={{fontWeight: '600'}}>{data.numeroOrder}</p>
                    <a href={data.lien} target="_blank" rel="noopener noreferrer">
                      <FaFileDownload size={22} color='#008080'/>
                    </a>
                    </div>
                    
                    <div className='d-flex justify-content-between'>
                    <p>TTC : {data.ttc} € ({data.ht} € ht)</p>
                    <p>Status : 
                      <select
                        value={data.status}
                        onChange={async (e) => {
  const updatedStatus = e.target.value;
  console.log("Tentative de mise à jour du statut avec :", updatedStatus);

  try {
    await DataServiceBills.updateStatus(updatedStatus, data.id);
    console.log("Mise à jour réussie");

    enqueueSnackbar(`L'étape a bien été changée`, {
      variant: 'success',
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      autoHideDuration: 3000,
      style: {fontSize: '1.6rem'}
    });
  } catch (error) {
    console.error("Erreur lors de la mise à jour :", error);
    enqueueSnackbar(`Erreur lors de la mise à jour`, {
      variant: 'error',
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      autoHideDuration: 3000,
      style: {fontSize: '1.6rem'}
    });
  }
}}

>
  <option value="Payée">Payée</option>
  <option value="En attente de paiement">En attente de paiement</option>
</select>
</p>
                    </div>
                    
                    </div>
                      )
                    }

                    })}
                  </div>
                }

              
   
                   
                
            </Row>
        )
      })}

    </div>
  );
}
