import React from 'react'
import TitleCard from '../../../../../components/Card/TitleCard';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const mois = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre"
  ];

export default function CardCostumer(props) {


    const filterCostumerForMonth = (users) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const currentMonth = today.getMonth(); // Les mois vont de 0 (janvier) à 11 (décembre).
        const currentYear = today.getFullYear();

        return users.filter(user => {
            const createdDate = user.date_inscription?.toDate();
            return createdDate?.getMonth() === currentMonth && createdDate?.getFullYear() === currentYear;
        });
    }
    const usersForMonth = filterCostumerForMonth(props.users);

    const filterCostumersNovices = (users) => {
        return users.filter(user => {    
            return (user.nbCommandes === 1);
        });
    } 
    const usersNovices = filterCostumersNovices(props.users);
    const usersNovicesForMonth = filterCostumerForMonth(usersNovices);

    const filterCostumerInactifs = (users) => {
        return users.filter(user => {    
            return (user.nbCommandes < 1 || !user.nbCommandes || user.nbCommandes === undefined);
        });
    }

    const usersInactifs = filterCostumerInactifs(props.users);
    const usersInactifsForMonth = filterCostumerForMonth(usersInactifs);
    
  return (
    <>
        <div className="d-flex flex-wrap justify-content-center">
            <div className="flex-fill p-2 d-flex justify-content-center align-items-center">
                <div className='row'>
                    <div className='col'>
                    {props.isUsersLoading ? <p><Skeleton /></p> : <TitleCard>Nouveaux clients</TitleCard>}
                    </div>
                    <div 
                        className='mx-auto p-4 pt-2 pb-2 mt-2 mb-2'
                        style={{ background: '#F1F1F1', width: '95%', borderRadius: '5px' }}
                    >
                        <div className="row mb-3">
                            <div className="col">
                            <p className='m-0 p-0' style={{fontWeight: 'bold', fontSize: '4rem'}}>{props.isUsersLoading ? <p><Skeleton /></p> : usersForMonth.length}</p>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col">
                                <p className='m-0 p-0'>Durant le mois {(new Date().getMonth() === 3 || new Date().getMonth() === 7 || new Date().getMonth() === 9) ? "d'" : "de "}<span style={{fontWeight: '600'}}>{mois[new Date().getMonth()]}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-fill p-2 d-flex justify-content-center align-items-center">
                <div className='row'>
                    <div className='col'>
                    {props.isUsersLoading ? <p><Skeleton /></p> : <TitleCard>Clients novices</TitleCard>}
                    </div>
                    <div 
                        className='mx-auto p-4 pt-2 pb-2 mt-2 mb-2'
                        style={{ background: '#F1F1F1', width: '95%', borderRadius: '5px' }}
                    >
                        <p className='m-0 p-0 ' style={{fontWeight: 'bold', fontSize: '4rem'}}>{props.isUsersLoading ? <p><Skeleton /></p> : usersNovices.length}</p>
                        <div className="row">
                            <div className="col">
                                <p className='m-0 p-0'><span style={{fontWeight: '600'}}>{props.isUsersLoading ? <p><Skeleton /></p> : usersNovicesForMonth.length}</span> durant le mois {(new Date().getMonth() === 3 || new Date().getMonth() === 7 || new Date().getMonth() === 9) ? "d'" : "de "}<span style={{fontWeight: '600'}}>{mois[new Date().getMonth()]}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-fill p-2 d-flex justify-content-center align-items-center">
            <div className='row'>
                    <div className='col'>
                    {props.isUsersLoading ? <p><Skeleton /></p> : <TitleCard>Clients inactifs</TitleCard>}
                    
  
                    </div>

                    <div 
                        className='mx-auto p-4 pt-2 pb-2 mt-2 mb-2'
                        style={{ background: '#F1F1F1', width: '95%', borderRadius: '5px' }}
                    >
                        <p className='m-0 p-0 ' style={{fontWeight: 'bold', fontSize: '4rem'}}>{props.isUsersLoading ? <p><Skeleton /></p> : usersInactifs.length}</p>

                        <div className="row">
                            <div className="col">
                            <p className='m-0 p-0'><span style={{fontWeight: '600'}}>{props.isUsersLoading ? <p><Skeleton /></p> : usersInactifsForMonth.length}</span> durant le mois {(new Date().getMonth() === 3 || new Date().getMonth() === 7 || new Date().getMonth() === 9) ? "d'" : "de "}<span style={{fontWeight: '600'}}>{mois[new Date().getMonth()]}</span></p>
                            </div>
                        </div>
                    </div>

                    </div>

      
            </div>
        </div>
    </>
  )
}
