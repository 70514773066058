import { db } from "../firebase-config";
import { getDoc, doc } from "firebase/firestore";

class DriversDataService {

  getDriver = (id) => {
    const driverDoc = doc(db, "Drivers", id);
    return getDoc(driverDoc);
  };

}

export default new DriversDataService();