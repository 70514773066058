import CorporateDataService from "../../../../context/corporates.services";

export const UpdateNameContact = async ({ id, value, enqueueSnackbar, setIsLoading }) => {
    try {
        if(value.length < 39 && value.length > 0){
            setIsLoading(true)
            await CorporateDataService.updateCorporate('nameContact', value, id);
            enqueueSnackbar(`Le nom de contact a été modifié en : ${value}.`, {
                variant: 'success',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}
               
              });
              return true;
        }else{
            enqueueSnackbar(`${value.length > 0 ? "Le nom de l'entreprise ne doit pas dépasser 39 caractères." : "Veuillez saisir un nom valide"}`, {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                style: {fontSize: '1.6rem'}
               
              });
              setIsLoading(false);
              return false;
        }
    }catch(e) {
        enqueueSnackbar(`${e.message}`, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoHideDuration: 3000,
            style: {fontSize: '1.6rem'}  
        });
        return false;
    }
};