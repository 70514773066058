import React, { useState } from 'react'
import { Button, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import CardMap from './CardMap'
import { FaPlus } from 'react-icons/fa';
import { MdDelete, MdLocationOn, MdMoreHoriz, MdMoreVert, MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { formatDateComplete } from '../../../../../utils/DateToString';
import { classifyClientTours } from '../../../../../utils/ClassifyClientTours';
import './ConfirmedTours.css'
import { useEffect } from 'react';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../../../../../firebase-config';
import { BsChevronDown } from 'react-icons/bs';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import  iconPU  from '../../../../../images/iconPU.png'
import  iconDO  from '../../../../../images/iconDO.png'
import GetOrder from './GetOrder';
import { IoIosAdd } from 'react-icons/io';
import ModelAddOrder from './ModelAddOrder';


// Données initiales pour les colonnes et les éléments
const initialColumns = {
    'column-1': {
      name: 'Jake',
      items: [
        { id: 'item-1', content: 'Sucking at something is the first step towards being sorta good at something.' },
        { id: 'item-2', content: 'You got to focus on what\'s real, man' }
      ],
    },
    'column-2': {
      name: 'BMO',
      items: [
        { id: 'item-3', content: 'Sometimes life is scary and dark' }
      ],
    },
    'column-3': {
        name: 'SALAH',
        items: [
          { id: 'item-4', content: 'Sometimes life is scary and dark' }
        ],
      },
    // Autres colonnes...
  };

export default function ConfirmedTours(props) {



    // const initialTours = () => {
    //     const initialColumns = {
    //         'column-1': {
    //           name: 'Jake',
    //           items: [
    //             { id: 'item-1', content: 'Sucking at something is the first step towards being sorta good at something.' },
    //             { id: 'item', content: 'You got to focus on what\'s real, man' }
    //           ],
    //         },
    //         'column-2': {
    //           name: 'BMO',
    //           items: [
    //             { id: 'item-3', content: 'Sometimes life is scary and dark' }
    //           ],
    //         },
    //         'column-3': {
    //             name: 'SALAH',
    //             items: [
    //               { id: 'item-4', content: 'Sometimes life is scary and dark' }
    //             ],
    //           },
    //         // Autres colonnes...
    //       };
    //     props.tours?.shifts && props.tours?.shifts.map((shift, index) => {
    //         const commandePourChauffeurs = filterOrdersFinalForDriver(shift.orders);
    //         // ici je souhaite ajouter des element a initialColumns 
    //         // 'column-{index}': {
    //         //     name: 'Ajouter Chauffeur',
    //         //     items: [
    //         //         commandePourChauffeurs.map((order) => { 
    //         //             { id: {order.id_order}, content: {order} },
    //         //             })
    //         //     ],
    //         //   },
    //        })
        
    // }
    const initialTours = () => {
        let initialColumns = {};
    
        // Ajouter des colonnes dynamiques
        props.tours?.shifts?.forEach((shift, index) => {
            const commandePourChauffeurs = filterOrdersFinalForDriver(shift.orders);
    
            // Créer un ID de colonne dynamique
            const columnId = `column-${index+1}`; // Assurez-vous que l'index ne se chevauche pas avec des colonnes statiques
            initialColumns[columnId] = {
                title: shift.id_tour,
                name: 'Ajouter Chauffeur',
                items: commandePourChauffeurs.map(order => ({ 
                    id: order.id_order, 
                    content: order 
                }))
            };
        });
    
        // Maintenant, initialColumns contient vos colonnes dynamiques
        return initialColumns;
       
    }
    
    const findHubById = () => {
        return props.hubs?.find(hub => hub.id_hub === props.tours?.depart_point?.hub);
    }
    const findOrderById = (idCheking) => {
        return props.ordersB2C?.find(order => order.idCommande === idCheking);
    }
    const filtrerOrdersDO  = () => {
        const result = [];

        props.tours?.shifts?.forEach(shift => {
            const ordersAvecDoTrue = shift.orders.filter(order => order.do === true);
            result.push(...ordersAvecDoTrue);
        });
    
        return result;
    }
    const hub = findHubById();
    const commandeAvecAction = filtrerOrdersDO();

    // const chunkArray = (array, chunkSize) => {
    //     let results = [];
    //     let chunkId = 1;
    //     for (let i = 0; i < array.length; i += chunkSize) {
    //         results.push(array.slice(i, i + chunkSize));
    //     }
    //     return results;
    // }

    // const myArray = [
    //     {id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5},
    //     {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10},
    //     {id: 11}, {id: 12}, {id: 13}, {id: 14}, {id: 15},
    //     {id: 16}, {id: 17}, {id: 18}, {id: 19}, {id: 20},
    //     {id: 21}, {id: 22}, {id: 23}, {id: 24}, {id: 25},
    //     {id: 26}, {id: 27}, {id: 28}, {id: 29}, {id: 30},
    //     {id: 31}, {id: 32}
    // ];
    
    // const chunkedArrays = chunkArray(myArray, 15);
    const [showOrdersAction, setShowOrdersAction] = useState(false);
    function filterUntrackedOrders() {
        // Filtrer les commandes qui n'ont pas de suivi
        const untrackedOrders = commandeAvecAction.filter(order => 
          // Vérifier si l'ordre actuel n'a pas de suivi correspondant dans orderTrackings
          !props.ordersTrakings.some(tracking => tracking.id_order_traking === order.id_order)
        );
        return untrackedOrders;
      }
      const commandeExistePasDansSuivie = filterUntrackedOrders();
      useEffect(() => {
        setShowOrdersAction(filterUntrackedOrders().length > 0);
      }, [props.tours]);



      function filterOrdersFinalForDriver(shift) {
        // Filtrer les commandes qui ont de suivi
        const untrackedOrders = shift.filter(order => 
            order.do === false ||
            // Si 'do' est true, inclure l'ordre seulement s'il n'a pas de suivi correspondant dans orderTrackings
            (order.do === true && props.ordersTrakings.some(tracking => tracking.id_order_traking === order.id_order))
          );
        return untrackedOrders;
      }
    //   const commandePourChauffeurs = filterOrdersFinalForDriver(props.tours?.shifts);
    const [drivers, setDrivers] = useState([]);
    useEffect(() => {
        const q = query(collection(db, "Drivers"), orderBy("created_at", "desc"))  ;
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const drivers = [];
            querySnapshot.forEach((doc) => {
                drivers.push({...doc.data(), id_driver: doc.id});
        });
        setDrivers(drivers);
        });
    
        return () => unsubscribe(); 
      }, []);
      
//       const handleSelectChange = (event, indexTour) => {
//         const newTours = {...props.tours};
//         // Récupérer l'ID du chauffeur sélectionné
//         const selectedDriverId = event.target.value;
//         // Vérifier si l'indexTour est dans la plage valide
//   if (newTours.shifts && indexTour >= 0 && indexTour < newTours.shifts.length) {
//     // Mettre à jour l'id_driver pour le shift spécifié
//     newTours.shifts[indexTour].id_driver = selectedDriverId;
//   }
//         console.log(newTours);
//         props.setTours(newTours);
//         // setSelectedHub(event.target.value);
//         // Collecter les ID des chauffeurs déjà sélectionnés
//       };
const handleSelectChange = (event, indexTour) => {
    const newTours = {...props.tours};
    // Récupérer l'ID du chauffeur sélectionné
    const selectedDriverId = event.target.value;
    // Vérifier si l'indexTour est dans la plage valide
if (newTours.shifts && indexTour >= 0 && indexTour < newTours.shifts.length) {
// Mettre à jour l'id_driver pour le shift spécifié
newTours.shifts[indexTour].id_driver = selectedDriverId;
}
 
    // props.setTours(newTours);
           // Mettre à jour les colonnes
           setColumns(prevColumns => {
            const newColumns = { ...prevColumns };
            const columnKey = `column-${indexTour+1}`; // Construire la clé de colonne
       
             if (newColumns[columnKey]) {
                newColumns[columnKey].name = selectedDriverId;
            }
       
            return newColumns;
        });
    // setSelectedHub(event.target.value);
    // Collecter les ID des chauffeurs déjà sélectionnés
  };
      const selectedDriverIds = props.tours?.shifts?.map(shift => shift.id_driver).filter(id => id);

// Filtrer les chauffeurs pour chaque shift
const getAvailableDriversForShift = (indexShift) => {
  return drivers.filter(driver => 
    !selectedDriverIds.includes(driver.id_driver) || 
    driver.id_driver === props.tours?.shifts?.[indexShift].id_driver
  );
};
  
const deleteTour = (key) => {
  setColumns(prevColumns => {
    const newColumns = { ...prevColumns };
    if (newColumns[key] && newColumns[key].items.length === 0) {
      delete newColumns[key];
    }

    console.log(newColumns);
    return newColumns;
  });
};

const deleteOrder = (key, indexItem) => {
  setColumns(prevColumns => {
    // Créez une copie profonde de l'objet columns pour éviter de modifier l'état directement
    const newColumns = { ...prevColumns };

    // Vérifiez si la clé existe et que l'indexItem est dans la plage valide du tableau items
    if (newColumns[key] && indexItem >= 0 && indexItem < newColumns[key].items.length) {
      // Supprimez 1 élément à l'index indexItem
      newColumns[key].items.splice(indexItem, 1);
    }

    // Affichez le nouvel état pour le débogage
    console.log(newColumns);
    // Mettez à jour l'état avec les nouvelles colonnes
    return newColumns;
  });
};


const getNextColumnIndex = (prevColumns) => {
  const columnNumbers = Object.keys(prevColumns)
    .map(key => parseInt(key.replace('column-', ''), 10))
    .sort((a, b) => a - b);
  
  // Trouvez le premier nombre manquant dans la séquence
  let missingNumber = 1;
  for (let i = 0; i < columnNumbers.length; i++) {
    if (columnNumbers[i] !== i + 1) {
      missingNumber = i + 1;
      break;
    }
    missingNumber = columnNumbers[i] + 1;
  }
  
  return missingNumber;
};
const addTour = () => {
  // Créez un tableau des valeurs de 'columns', qui sont les objets de chaque colonne
  const columnValues = Object.values(columns);
  // Créez un tableau de toutes les lettres de l'alphabet
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

  // Mappez sur chaque colonne pour extraire le 'title' et le stocker dans un nouveau tableau
  const titles = columnValues.map(column => column.title);

  // Trouvez la première lettre qui n'est pas dans 'titles'
  const firstAvailableLetter = alphabet.find(letter => !titles.includes(letter.toUpperCase())).toUpperCase();

  setColumns(prevColumns => {
    const newColumnIndex = getNextColumnIndex(prevColumns);
    const columnKey = `column-${newColumnIndex}`;



  


    const title = firstAvailableLetter;
    const name = 'Ajouter Chauffeur';
    const items = [];

    const newColumns = {
      ...prevColumns,
      [columnKey]: {
        name,
        title,
        items
      }
    };

    console.log(newColumns);
    return newColumns;
  });
console.log('Première lettre disponible :', firstAvailableLetter);
  console.log('addTour', titles);
};


const [columns, setColumns] = useState({});
useEffect(() => {
    setColumns(initialTours());
}, [props.tours]);
const onDragEnd = (result) => {
  if (!result.destination) return;
  
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems
      }
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems
      }
    });
  }
};

const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
    <ModelAddOrder show={show} handleClose={handleClose} />
      <Modal show={props.showConfirmedTours} fullscreen={true} onHide={props.handleCloseConfirmedTours}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize: '1.6rem', fontWeight: '600', letterSpacing: '1.2px'}}>Validations des tourneés</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pb-5'>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end my-4">
                <button className="btn btn-primary me-md-2" type="button" style={{fontSize: '1.4rem', fontWeight: '600', letterSpacing: '1.2px'}} onClick={() => console.log(props.tours, props.ordersTrakings, commandeExistePasDansSuivie)}><MdLocationOn />Ajouter un point</button>
                <button className="btn btn-primary" type="button" style={{fontSize: '1.4rem', fontWeight: '600', letterSpacing: '1.2px'}}onClick={() => {console.log(props.tours); initialTours(); console.log(columns)}}><FaPlus /> Ajouter une tournée</button>
            </div>
            {/* <CardMap /> */}
            <div className="d-flex flex-wrap justify-content-center my-5 border-bottom" style={{fontSize: '1.6rem'}}>
                <div className="flex-fill p-2 d-flex justify-content-center align-items-center">
                    <p>Point de départ : <span style={{fontWeight: '600'}}>{hub?.name}</span></p>
                </div>
                <div className="flex-fill p-2 d-flex justify-content-center align-items-center">
                    <p>Type : <span style={{fontWeight: '600'}}>{props.tours?.type}</span></p>
                </div>
                {
                    props.tours?.type === 'B2C' && 
                    <div className="flex-fill p-2 d-flex justify-content-center align-items-center">
                    <p>Période : <span style={{fontWeight: '600'}}>{props.tours?.evening ? 'Soir' : 'Matin'}</span></p>
                </div>
                }
                <div className="flex-fill p-2 d-flex justify-content-center align-items-center">
                    <p>Chauffeur(s) : <span style={{fontWeight: '600'}}>{props.tours?.nb_drivers}</span></p>
                </div>
        </div>
               
        <div className='container-fluid'>
        <Row className='mb-5'>
            <div className='col-12 border-bottom'>
                <Row>
                    <div className='col-md-6'>
                        <p className="m-0 p-0 my-3 col-6" style={{fontWeight: 'bold'}}>Commande nécessite une action ({commandeExistePasDansSuivie.length})</p>                                  
                    </div>
                    <div className='col-md-6 text-end'>
                    {showOrdersAction ? <MdOutlineKeyboardArrowDown size={20} onClick={() => setShowOrdersAction(showHistory => false)}/> : <MdOutlineKeyboardArrowRight size={20} onClick={() => setShowOrdersAction(showHistory => true)}/>}
                </div>
                </Row>  
                {/* <Button className='btn-success-outline' size="sm" onClick={() => setShowModalAddOrder(true)}>Ajouter une commande</Button>         {/* <p></p> */}      
                </div>
        </Row>
        {
            showOrdersAction &&
            <Row className='scrollable-list'>
            {commandeExistePasDansSuivie && commandeExistePasDansSuivie.map((order) => {
                const idFacture = findOrderById(order.id_order)?.idFacture;
                const date_creation = formatDateComplete(findOrderById(order.id_order)?.date_creation?.toDate())
                {/* 
                const status = classifyClientTours(findOrderById(order.id_order)?.idClient, props.ordersB2C);
                 */}

            return (
               
                <div className="d-flex flex-wrap justify-content-center my-2 border-bottom text-start" style={{fontSize: '1.6rem'}}>
                <div className="flex-fill p-2 d-flex justify-content-start align-items-center">
                
                    <p style={{fontWeight: '600'}}>
                     <span style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        borderRadius: '35%',
                        backgroundColor: 'red',
                        marginRight: '5px'
                    }}>
                    </span>{idFacture}
                    <br />
                    <span style={{fontWeight: '400', fontStyle: 'italic', fontSize: '1.4rem'}} className='text-secondary'>
                    Date de création : {date_creation}
                    </span>
                    </p>
                </div>
                <div className="flex-fill p-2 d-flex justify-content-start align-items-center">
                    <p style={{fontWeight: '400', fontStyle: 'italic', fontSize: '1.4rem'}} className='text-secondary'>La commande ne se trouve pas dans le systeme</p>
                </div>
                <div className="flex-fill p-2 d-flex justify-content-center align-items-center">
                    <OverlayTrigger
          
                        placement='bottom'
                        overlay={
                            <Tooltip id={`tooltip-bottom`}>
                            Accées à la commande.
                            </Tooltip>
                        }
        >
          <button className='btn'><MdMoreHoriz className='btn-link' size={18} style={{cursor: 'pointer'}}/></button>
        </OverlayTrigger>
                    
                </div>
                
        </div>
            )})
        }
            </Row>
        }
        </div>
                  
                  <DragDropContext onDragEnd={onDragEnd}>
      <div style={{ display: 'flex', justifyContent: 'flex-start', overflowX: 'auto' }} className='flex-wrap'>
        {Object.entries(columns).map(([columnId, column], index) => {
          return (
            <Droppable droppableId={columnId} key={columnId}>
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    background: snapshot.isDraggingOver ? '#008080' : '#F0F0F0',
                    padding: 4,
                    width: 350,
                    minHeight: 500,
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '5px',
                    borderBottom: '5px solid #008080',
                  }}
                  className='mt-5 mx-3 p-3'
                >
                <h6 className='text-center my-3 mb-4' style={{fontSize: '1.8rem', fontWeight: '600', letterSpacing: '1.2px'}}>Tournée {column.title} {column.items.length === 0 && <MdDelete className='mb-1 text-danger' style={{cursor: 'pointer'}} onClick={() => deleteTour(columnId)}/>}</h6>
                                <Form.Group controlId="selectedDriver" className="d-flex align-items-center mb-4">
    <div className="flex-grow-1 position-relative">
            <Form.Control
                as="select"
                className="w-100"
                style={{ color: '#000', fontSize: '1.4rem'}}
                name="selectedDriver"
                value={column.name}
                onChange={(event) => handleSelectChange(event, index)} // Utilisez une fonction fléchée
            >
            <option value="" >Sélectionnez un chauffeur</option>
                {getAvailableDriversForShift(index).map((driver) => (
                    <option key={driver.id_driver} value={driver.id_driver}>{driver.firstName} {driver.lastName}</option>
                ))}
            </Form.Control>
            <BsChevronDown className="dropdown-icon" />
        </div>
</Form.Group>
<div className='mb-3' style={{height: '2px', background: '#D3D3D3'}}></div>
                  {/* <h2>{column.name}</h2> */}
                  {column.items.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            userSelect: 'none',
                            minHeight: '50px',
                            borderRadius: '3px',
                            backgroundColor: snapshot.isDragging ? 'white' : 'white',
                            color: '#000',
                            ...provided.draggableProps.style
                          }}
                          className='p-3 m-2'
                        >
                        <div className="row" style={{fontSize: '1.6rem'}}>
                        <div class="col-2 d-flex justify-content-center align-items-center">
    <img src={item.content.do ? iconDO : iconPU} alt="" class="img-fluid"/>
</div>

            
            {/* <p className='p-0 m-0' style={{fontWeight: '600'}}>{getOrder(item.content.id_order).adresse} sa</p> */}
            {<GetOrder orderId={item.content.id_order} isDO={item.content.do} ordre={index} deleteOrder={() => deleteOrder(columnId, index)} />}
            
          
        </div>
                          {/* {item.content.id_order} */}
                          
                        </div>
                        
                      )}
                      
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <div className='mx-auto mt-5' style={{border: '2px solid #008080', borderRadius: '5px', cursor: 'pointer'}} onClick={handleShow}>
                  <IoIosAdd className='mx-auto' size={26} color='#008080'/>
                  </div>
                  
                </div>
              )}
            </Droppable>
          );
        })}
        <div className='d-flex justify-content-center align-items-center'>
        <div className='ms-3' style={{ border: '2px solid #008080', borderRadius: '99px', cursor: 'pointer', display: 'inline' }} onClick={() => addTour()}>
        <IoIosAdd className='mx-auto' size={26} color='#008080'/>
    </div>
        </div>

      </div>
    </DragDropContext>
                  

                
        






        {/* {
            props.tours?.shifts && props.tours?.shifts.map((shift, index) => {
                const commandePourChauffeurs = filterOrdersFinalForDriver(shift.orders);
            return (
                <div className="d-flex flex-wrap justify-content-center my-2 border-bottom text-start" style={{fontSize: '1.6rem'}}>
                <div className="flex-fill p-2 d-flex justify-content-start align-items-center bg-danger">
                <Form.Group controlId="selectedDriver" className="d-flex align-items-center me-5 ps-1">
    <div className="flex-grow-1 position-relative">
            <Form.Control
                as="select"
                className="w-100 px-3 me-5"
                style={{ color: '#000', fontSize: '1.4rem'}}
                name="selectedDriver"
                value={shift.id_driver || 'Sélectionnez un chauffeur'}
                onChange={(event) => handleSelectChange(event, index)} // Utilisez une fonction fléchée
            >
            <option value="" >Sélectionnez un chauffeur</option>
                {getAvailableDriversForShift(index).map((driver) => (
                    <option key={driver.id_driver} value={driver.id_driver}>{driver.firstName} {driver.lastName}</option>
                ))}
            </Form.Control>
            <BsChevronDown className="dropdown-icon" />
        </div>
</Form.Group>
                </div>
                {
                    commandePourChauffeurs.map((order) => { 
                        return (
                            <div className="flex-fill p-2 d-flex justify-content-start align-items-center bg-danger">{order.id_order}</div>
                        )})
                }</div>
            )})
        } */}

        </Modal.Body>
      </Modal>
    </div>
  )
}
