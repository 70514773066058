import React, { useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../../context/userContext'
import logo from '../../images/logoNavbar.png';
import './navbar.css';
import { checkPageAccess } from '../../utils/navbar/AccessControl';

export default function NavbarPer({navItems}) {
  const [navigationOpen, setNavigationOpen] = useState(false);
  const { logOut, currentUser, user } = useContext(UserContext);
  const location = useLocation();

  const handleToggleClick = () => {
    setNavigationOpen(navigationOpen => !navigationOpen);
  };

  
    return (
        <div className='navCustomer'>
        <div className={navigationOpen ? 'navigation active' : 'navigation'}>
          <div className="logo my-4 py-2">
            <span className="icon"><img src={logo} alt="logo de l'entreprise" height={60} width={60}/></span>
            <span className="title">CLEAMY</span>
            </div>
          <ul className='navScroll'>
            {navItems.map((item, index) =>{ 
              const isActive = item.url === location.pathname;
              const hasAccess = checkPageAccess(currentUser, item, user.role);
              return(
                <li key={index}>
                    <Link to={hasAccess && item.url} onClick={() => {(item.label === 'Sign Out' && hasAccess) && logOut()}} className={`linkCustomer ${(isActive && hasAccess) ? 'activeItem' : ''} ${!hasAccess ? 'notAccess' : ''}`} >
                        <span className="icon">{item.icon}</span>
                        <span className="title">{item.label}</span>
                    </Link>
                </li>
              )
            })}
          </ul>
          <div className="toggle" onClick={handleToggleClick}></div>
        </div>
        </div>
    );
}