import React from "react";
import { Table } from "react-bootstrap";
import { formatDate, formatDateComplete } from "../../../../../../utils/DateToString";
import '../../../../pages/Customers/Components/UsersList.css'

const OrdersCorporateList = ({OrdersCorporates, currentPage, setCurrentPage, setShowDetailsOrder, setOrderB2BID}) => {

  const getLivraisonDate = (data) => {
    if (data && data.under_user) {
        const arrayWithDateLivraison = data.under_user.filter(obj => obj.hasOwnProperty("date_livraison"));
        const arrayWithoutDateLivraison = data.under_user.filter(obj => !obj.hasOwnProperty("date_livraison"));
    
        arrayWithDateLivraison.sort((a, b) => b.date_livraison.toMillis() - a.date_livraison.toMillis());
    
        return arrayWithDateLivraison.concat(arrayWithoutDateLivraison);
    }
    return [];
  }
  const getStatusOrder = (etat) => {
    var etatActuel = "";
    switch (etat) {
      case 1 : etatActuel = 'En cours de récupération'; break
      case 2 : etatActuel = 'Réceptionnée'; break
      case 3 : etatActuel = 'En cours de traitement'; break
      case 4 : etatActuel = 'En cours de livraison'; break
      case 5 : etatActuel = 'Livrée'; break
      case 6 : etatActuel = 'Annulée'; break
      default : etatActuel = ''
    }
    return etatActuel
}
  const PAGE_SIZE = 5;
  const nextPage = () => {
      if ((currentPage + 1) * PAGE_SIZE < OrdersCorporates.length) {
          setCurrentPage(currentPage + 1);
      }
  };
  const prevPage = () => {
      if (currentPage > 0) {
          setCurrentPage(currentPage - 1);
      }
  };
  const paginatedOrders = OrdersCorporates.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE);
  return (
    <>
      {paginatedOrders.length > 0 ?
        <>
          <Table responsive="sm" className=' mx-auto text-start table-personnalisee '>
          <thead>
            <tr>
              <th>Date de création</th>
              <th>Collecte / Livraison</th>
              <th className="text-center">Nombre de commandes</th>
              <th className="text-center">Status</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {paginatedOrders.map((order, index) => {
              return (
                <tr key={order.idB2B}>
                  <td>{formatDateComplete(order?.date_creation_order?.toDate().toISOString())}</td>
                  <td>
                      {formatDate(order?.date_collecte?.toDate().toISOString())}<br />
                      {formatDate(getLivraisonDate(order)[0]?.date_livraison?.toDate().toISOString())}
                  </td>
                  <td className="text-center">{order?.under_user?.length}</td>
                  <td className="text-center">{getStatusOrder(order?.etat_commande)}</td>
                  <td className='btn-link text-center' style={{cursor: 'pointer'}} onClick={() => {setShowDetailsOrder(true); setOrderB2BID(order.idB2B);}}>Voir détails </td>
                  
                  <td>
                  </td>
                </tr>
              );
            })}
          </tbody>
          </Table>
          <div className="d-flex flex-wrap justify-content-center my-5 bg-light py-2 rounded">
      <div className='flex-fill p-2 d-flex justify-content-center align-items-center'>{OrdersCorporates.length} résultats</div>
      <div className='flex-fill p-2 d-flex justify-content-center align-items-center'>
      <button onClick={prevPage} disabled={currentPage === 0} style={{border: '1px solid #e0e0e0', borderRadius: '8px', fontSize: '1.2rem'}} className="btn">Précédent</button>
                    <button onClick={nextPage} className="ms-3 btn" style={{border: '1px solid #e0e0e0', borderRadius: '8px', fontSize: '1.2rem'}} disabled={(currentPage + 1) * PAGE_SIZE >= OrdersCorporates.length}>Suivant</button>
      </div>
          </div>
        </>
      :
      <p className="text-secondary text-center" style={{fontStyle: 'italic'}}>Aucune commande passée.</p>
      }

    </>
  );
};

export default OrdersCorporateList;