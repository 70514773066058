import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import SlotsDataService from '../../../../context/creneaux.services';
import { Timestamp } from "firebase/firestore";

const AddSlots = () => {
  const [message, setMessage] = useState({ error: false, msg: "" });

  const generateHoursInterval = (startHourInMinute, endHourInMinute, interval,) => {
    const times = [];
    for (let i = 0; startHourInMinute < 24 * 60; i++) {
      if (startHourInMinute > endHourInMinute) break;
      var hh = Math.floor(startHourInMinute / 60); // getting hours of day in 0-24 format
      var mm = startHourInMinute % 60; // getting minutes of the hour in 0-55 format
      times[i] = ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2);
      startHourInMinute = startHourInMinute + interval;
    }
    return times;
  };
  const handleSubmit = async () => {
    const today = new Date();
    const res = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    const resEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    const interval = 30; //minutes interval
    const startDate = 60 * 11; // start time in minutes
    const endDate = 60 * 14.5; // end time in minutes
    const startDim = 60 * 20;
    const finDim = 60 * 23.5;
    const startJour = 60 * 8.5;
    const finJour = 60 * 11.5;
    const foo = generateHoursInterval(startDate, endDate, interval);
    const dimanches =generateHoursInterval(startDim, finDim, interval);
    const jours =generateHoursInterval(startJour, finJour, interval);
    try {
        for(let i = 0; i < 26; i++){
            if(res.getDay() === 6){//Samedi
                for(let j = 0; j < foo.length; j++){
                    res.setHours(parseInt(foo[j].substr(0,2)));
                    res.setMinutes(parseInt(foo[j].substr(3,4)));
                    if(j+1 < foo.length){
                        resEnd.setHours(parseInt(foo[j+1].substr(0,2)));
                        resEnd.setMinutes(parseInt(foo[j+1].substr(3,4)));
                    }else{
                        resEnd.setHours(15);
                        resEnd.setMinutes(0);
                    }
                    let newSlots = {start: Timestamp.fromDate(res), end: Timestamp.fromDate(resEnd)};
                    await SlotsDataService.addSlots(newSlots, i.toString()+'s'+j.toString());
                }
            }else if(res.getDay() === 0){//Dimanche
                for(let z = 0; z < dimanches.length; z++){
                    res.setHours(parseInt(dimanches[z].substr(0,2)));
                    res.setMinutes(parseInt(dimanches[z].substr(3,4)));
                    if(z+1 < dimanches.length){
                        resEnd.setHours(parseInt(dimanches[z+1].substr(0,2)));
                        resEnd.setMinutes(parseInt(dimanches[z+1].substr(3,4)));
                    }else{
                        resEnd.setHours(0);
                        resEnd.setMinutes(0);
                    }
                    let newSlots = {start: Timestamp.fromDate(res), end: Timestamp.fromDate(resEnd)};
                    await SlotsDataService.addSlots(newSlots, i.toString()+'d'+z.toString());
                }

            }else{//les autres jours
                for(let k = 0; k < dimanches.length; k++){
                    res.setHours(parseInt(dimanches[k].substr(0,2)));
                    res.setMinutes(parseInt(dimanches[k].substr(3,4)));
                    if(k+1 < dimanches.length){
                        resEnd.setHours(parseInt(dimanches[k+1].substr(0,2)));
                        resEnd.setMinutes(parseInt(dimanches[k+1].substr(3,4)));
                    }else{
                        resEnd.setHours(0);
                        resEnd.setMinutes(0);
                    }
                    let newSlots = {start: Timestamp.fromDate(res), end: Timestamp.fromDate(resEnd)};
                    await SlotsDataService.addSlots(newSlots, i.toString()+'soir'+k.toString());
                }
                for(let y = 0; y < jours.length; y++){
                    res.setHours(parseInt(jours[y].substr(0,2)));
                    res.setMinutes(parseInt(jours[y].substr(3,4)));
                    if(y+1 < jours.length){
                        resEnd.setHours(parseInt(jours[y+1].substr(0,2)));
                        resEnd.setMinutes(parseInt(jours[y+1].substr(3,4)));
                    }else{
                        resEnd.setHours(12);
                        resEnd.setMinutes(0);
                    }
                    let newSlots = {start: Timestamp.fromDate(res), end: Timestamp.fromDate(resEnd)};
                    await SlotsDataService.addSlots(newSlots, i.toString()+'matin'+y.toString());
                }
            }
            res.setDate(res.getDate() + 1);
            resEnd.setDate(resEnd.getDate() + 1);
        }
        setMessage({ error: false, msg: "Les créneaux sont bien ajouter !" });
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  };
  return (
    <>
      <div className="p-4 box">
        {message?.msg && (
          <Alert
            variant={message?.error ? "danger" : "success"}
            dismissible
            onClose={() => setMessage("")}
          >
            {message?.msg}
          </Alert>
        )}
      </div>
      <Button variant="btn btn-outline-info btn-sm btn-block mb-3" onClick={handleSubmit}>
          Ajouter Créneaux
        </Button>
    </>
  );
};

export default AddSlots;