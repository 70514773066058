import React from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import './ConfirmedButton.css';

export default function ConfirmedButton({onClick, isLoading, labelLoading, labelButton}) {
  return (
    <button 
        className="add-driver-button btn my-3 me-4 ms-3"
        onClick={onClick}
    >
        {isLoading ? 
            <><AiOutlineLoading className="loading-icon me-2" /> {labelLoading}</>
        : 
            <>{labelButton}</>
        }
    </button>
  )
}
